import styled from 'styled-components';
import searchIcon from 'app/assets/images/searchIcon.svg';
import downloadInfo from 'app/assets/images/download-info.svg';
import progress from 'app/assets/images/Progress_bar.svg';
import assessmentList from 'app/assets/images/assessment-list.svg';

export const BodyDiv = styled.div`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  h1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #3d3d3d;
  }
`;
export const OopsSpan = styled.span`
  display: table;
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 131%;

  color: #3d3d3d;
`;

export const AddCategoryModal = styled.div`
  width: 480px;
  @media (max-width: 800px) {
    width: 100%;
  }
  input {
    top: 20px;
    width: 380px;
  }
`;

export const SearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  width: 100%;
  background-color: #fdb82c;
  padding: 10px;
  select {
    width: 133px;
    cursor: pointer;
    margin-left: 20px;
    height: 36px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  h5 {
    font-weight: 600;
    font-size: 16px;
    color: #3d3d3d;
    margin: 20px;
  }
  .btnSaveDraft {
    border: solid 1px #000;
    padding: 10px 15px;
    color: #000;
  }
  .create-assessment {
    /* border: solid 1px #000; */
    padding: 10px 15px;
    color: #fff;
    background: #000;
  }
  .backto-assessment {
    margin-right: 25px;
    padding: 10px 20px;
    width: 10%;
    color: #fff;
    background: #000;
  }
  .btnCancel {
    border: solid 1px #000;
    padding: 10px 15px;
    color: #000;
    background: transparent;
  }
  button {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    padding: 10px 15px;
    width: auto;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border: solid 1px #000;
    border-radius: 5px;
    margin-top: 0px;
    margin-right: 10px;
  }
`;

export const CatDiv = styled.div`
  .assessment-list {
    background-color: #000000;
    padding: 10px 32px 10px 8px;
    color: #fff;
    margin-bottom: 0px;
    margin-right: 0px;
    background-image: url(${assessmentList});
    background-repeat: no-repeat;
    background-position: center right 6px;
    background-size: 20px;
  }

  width: 100%;
  justify-content: space-between !important;

  h1 {
    margin: 0px !important;
    padding: 0px;
  }
`;
export const SearchInpDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  button {
    background: #fdb82c;
    height: 39px;
    margin-bottom: 0px;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0;
    background-image: url(${searchIcon});
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 0px;
    width: 70px;
  }
  input {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-right: -8px;
    width: 200px;
    height: 38px;
    text-indent: 10px;
    margin-bottom: 0px;
  }
  button {
    width: 40px;
  }
  & select {
    width: 133px;
    cursor: pointer;
    margin-left: 20px;
    height: 36px;
  }
  .skills-input {
    width: 25% !important;
    margin-right: 20px;
    border-radius: 20px;
    padding: 20px 0px;
  }
  .location-input {
    width: 25% !important;
    margin-right: 0px;
    border-radius: 20px;
    padding: 20px 0px;
  }
  .search-icon {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    float: left !important;
    margin-left: -40px !important;
  }
`;

export const ScoreLevelDiv = styled.div`
  margin: 0px auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .row-score {
    margin-right: 10px;
    white-space: nowrap;
  }
`;
export const DownloadDiv = styled.div`
  background-image: url(${downloadInfo});
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
export const InprogressIcon = styled.div`
  background-image: url(${progress});
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
`;
