import styled from 'styled-components';

export const AssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0;
`;

export const AssessmentTitle = styled.div`
  font-weight: 600;
  font-style: normal;
  color: #3d3d3d;
`;

export const AssessmentTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 400;
`;

export const CardTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding-top: 30px;
  font-weight: 600;
  text-align: left;
  color: #000000;
  margin-top: 25px;
`;
export const CopyLinkButton = styled.div`
  margin: 35px 0;
  width: 180px;
  height: 50px;
  outline: solid 1px #e53366;
  background: #ffffff;
  font-weight: 400;
  border-radius: 10px;
`;

export const CategoriesWrapper = styled.div`
  display: block;
  width: auto;
  height: auto;
  padding-inline: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;
export const CategoriesHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 40px;
`;

export const CategoriesHeaderLeft = styled.div`
  width: 50%;
`;

export const CategoriesHeaderRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: bold;
`;
export const DurationTitle = styled.span`
  padding-left: 5px;
  color: #3d3d3d;
`;
export const TimerTitle = styled.span`
  padding-left: 5px;
  color: #fdb82c;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 50%;
  thead,
  th {
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
    line-height: ;
  }
  tbody {
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: ;
  }
  tr {
    min-height: 68px;
  }
  th,
  td {
    padding: 10px 0px;
    text-align: left;
  }
`;
