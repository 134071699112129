// import React from 'react';
import styled from 'styled-components';
import userlogo from 'app/assets/images/userlogo.png';
import bulbbefore from 'app/assets/images/bulbbefore.png';
import bagbefore from 'app/assets/images/bagbefore.png';
import capbefore from 'app/assets/images/capbefore.png';
import badgebefore from 'app/assets/images/badgebefore.png';
import plusround from 'app/assets/images/plusround.png';
import star1 from 'app/assets/images/star1.png';
import bulbactive from 'app/assets/images/bulbactive.png';
import bagactive from 'app/assets/images/bagactive.png';
// import degreeactive from 'app/assets/images/degreeactive.png';
import userbefore from 'app/assets/images/userbefore.png';
import badgeactive from 'app/assets/images/badgeactive.png';
import 'react-tabs/style/react-tabs.css';

export const Screen = styled.div`
  min-height: 100%;
  background-color: #393939;
`;
export const ResumeInfoWrapper = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
`;

export const HelloHeading = styled.h1`
  color: #ffffff;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 10px;
  position: relative;
  bottom: 150px;
  left: 23%;
  color: #ffd361;
`;
export const CertificateHeading = styled.h1`
  color: #ffffff;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 10px;
  position: relative;
  bottom: 150px;
  left: 17%;
  color: #ffd361;
`;
export const EducationHeading = styled.h1`
  color: #ffffff;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 10px;
  position: relative;
  bottom: 180px;
  left: 23%;
  color: #ffd361;
`;
export const JobHelloHeading = styled.h1`
  /* font-style: normal;
  font-weight: 400;
  font-size: 24px; */
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 10px;
  position: relative;
  bottom: 300px;
  left: 19%;
  color: #ffd361;
  span {
    color: #ffff;
    font-style: normal;
  }
`;
export const KeySkillHelloHeading = styled.h1`
  /* font-style: normal;
  font-weight: 400;
  font-size: 24px; */
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 10px;
  position: relative;
  bottom: 160px;
  left: 10%;
  color: #ffd361;
  span {
    color: #ffff;
    font-style: normal;
  }
`;
export const JobExperienceHeader = styled.h1`
  color: #ffd361;
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 20px;
  position: absolute;
  bottom: 100%;
  left: 26%;

  span {
    color: #ffffff;
    font-weight: normal;
  }
`;
export const KeySkillHeader = styled.h1`
  /* color: red; */
  color: #ffd361;
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 20px;
  position: relative;
  bottom: 17vh;
  left: 15%;
`;
export const EducationHearder = styled.h1`
  color: #ffd361;
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 20px;
  position: absolute;
  bottom: 56vh;
  left: 31%;
`;
export const CertificationHearder = styled.h1`
  color: #ffd361;
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 20px;
  position: absolute;
  bottom: 56vh;

  left: 31%;
`;
export const Anan = styled.h1`
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 20px;
  position: absolute;
  bottom: 87%;
  span {
    color: #ffd361;
    font-weight: bold;
  }
`;

export const MaincontainerDiv = styled.div`
  background-color: #393939;
  min-height: 100vh;
`;

export const ActiveStepperContainer = styled.div`
  border-radius: 20px;
  position: relative;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;
export const StepperConatiner = styled.div`
  width: 80%;
  padding: 30px;
  position: relative;
  /* border: 5px solid; */
  top: 4%;
`;
export const StarRating = styled.div`
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .on {
    /* color: #000; */
    color: #ffd361;
  }
  .off {
    color: #ccc;
  }
`;
export const ProgressSteps = styled.ul`
  counter-reset: step;
  li {
    list-style-type: none;
    cursor: pointer;
    width: 20%;
    float: left;
    font-size: 0.7em;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #999;

    &:before {
      width: 32px;
      height: 32px;
      content: counter(step);
      font-size: 0px;
      counter-increment: step;
      line-height: 2.5em;
      border: 2px solid #656565;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
      display: block;
      text-align: center;
      margin: 0 auto 0.7em auto;
      border-radius: 50%;
      background-color: #999;
    }

    &:after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #999;
      top: 1.3em;
      left: -50%;
      z-index: -1;
    }

    &:first-child:after {
      content: none;
    }

    &.active {
      color: #f86;
    }

    &.active:before {
      border-color: #ffd361;

      background-color: #ffd361;
    }

    &.progress-user.active:before {
      background-image: url(${userlogo});

      background-position: center 15px;
      background-repeat: no-repeat;
      background-size: 32px;
      width: 13%;
      height: 56px;
      position: relative;
      bottom: 10px;
    }

    &.progress-user:before {
      background-image: url(${userbefore});
      background-position: center 5px;
      background-repeat: no-repeat;
      background-size: 22px;
    }

    &.progress-bulb:before {
      background-image: url(${bulbbefore});

      background-position: center 8px;
      background-repeat: no-repeat;
      background-size: 32px;
      width: 13%;
      height: 56px;
      position: relative;
      bottom: 10px;
    }

    &.progress-bag:before {
      background-image: url(${bagbefore});
      background-position: center 15px;
      background-repeat: no-repeat;
      background-size: 32px;
      width: 13%;
      height: 56px;
      position: relative;
      bottom: 10px;
    }

    &.progress-degree:before {
      background-image: url(${capbefore});
      background-position: center 15px;
      background-repeat: no-repeat;
      background-size: 38px;
      width: 13%;
      height: 56px;
      position: relative;
      bottom: 10px;
    }

    &.progress-badge:before {
      background-image: url(${badgebefore});
      background-position: center 15px;
      background-repeat: no-repeat;
      background-size: 25px;
      width: 13%;
      height: 56px;
      position: relative;
      bottom: 10px;
    }

    &.progress-bulb.active:before {
      background-image: url(${bulbactive});
      background-position: center 5px;
      background-repeat: no-repeat;
      background-size: 22px;
    }

    &.progress-bag.active:before {
      background-image: url(${bagactive});
      background-position: center 5px;
      background-repeat: no-repeat;
      background-size: 22px;
    }

    &.progress-degree.active:before {
      background-image: url(&{degreeactive});
      background-position: center 10px;
      background-repeat: no-repeat;
      background-size: 22px;
      position: relative;
      bottom: 10px;
    }

    &.progress-badge.active:before {
      background-image: url(${badgeactive});
      background-position: center 7px;
      background-repeat: no-repeat;
      background-size: 16px;
    }
  }
  li.active + li:after {
    background-color: #ffd361;
  }
`;

export const TabContent = styled.div``;
export const StepperContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  min-height: 498px;
  min-width: 960px;
  padding: 33px;
`;
export const JobStepperContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  min-height: 498px;
  min-width: 960px;
  padding: 33px;
  position: relative;
  bottom: 18%;
  /* border: 5px solid red; */
`;
export const KeySkillsStepperContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  min-height: 331px;
  min-width: 960px;
  padding: 29px;

  border-radius: 5px;
`;

// export const JobStepperContainer = styled.div`
//   background-color: #ffffff;
//   border-radius: 10px;
//   min-height: 522px;
//   /* bottom: 20%; */
//   position: relative;
//   min-width: 960px;
//   padding: 33px;
/* border: 5px solid red; */
// `;

export const Stepper = styled.div`
  border: 10px solid;
`;
export const JobHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const NextPrevious = styled.button`
  width: 98px;
  height: 38px;
  border-radius: 5px;
  &:hover {
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
  }
`;
export const NextButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

export const Next = styled.button`
  background-color: #ffd361;
  width: 98px;
  height: 38px;
  border-radius: 5px;
  margin-left: 12px;
  &:hover {
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
  }
`;
export const RemoveButton = styled.button`
  background-color: #ffd361;
  width: 98px;
  height: 38px;
  border-radius: 5px;
  margin-left: 12px;
  &:hover {
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
  }
`;
export const Previous = styled.button`
  width: 100px;
  height: 40px;
  position: relative;
  bottom: 120px;
  left: 22%;
  radius: 6px;
  border-radius: 10px;
  border-color: black;
  &:hover {
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
  }
`;

export const TabPane = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 110%;
  position: relative;
  right: 5%;
  .active {
  }
  a.active {
    color: #ffffff;
    background-color: #3d3d3d;
    border-radius: 10px;
  }
`;
export const JobTabPane = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 120%;
  position: relative;
  right: 10%;
  bottom: 4%;
  height: 100vh !important;
  .active {
  }
  a.active {
    color: #ffffff;
    background-color: #3d3d3d;
    border-radius: 10px;
  }
`;
export const CertificationJobTabPane = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 120%;
  position: relative;
  right: 10%;
  .active {
  }
  a.active {
    color: #ffffff;
    background-color: #3d3d3d;
    border-radius: 10px;
  }
`;
export const EducationFlexWrapp = styled.input`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  input {
    width: 49%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #000000;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    margin-bottom: 0px;
    width: 60vh;
  }
`;
export const FlexTab = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 425px;
  padding: 10px;
  height: auto;

  input {
    min-height: 38px;
    width: 49%;
    margin: 5px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input.invalid {
    border: 2px solid red;
  }
`;

export const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f9f9f9;
  padding: 10px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const SelectProof = styled.select`
  flex: 1;
  padding: 10px;
  margin: 0 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const SkillsWrapper = styled.div`
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

export const SkillSelect = styled.select`
  width: 208px;
  height: 38px;
  margin: 10px;
`;

export const ExperienceWrapper = styled.div`
  background-color: #f2f2f2;
  width: 229px;
  height: 76px;
  border-radius: 5px;
  margin: 10px;
`;
export const FairHireLogo = styled.img`
  padding: 50px;
`;

export const MonthsSelect = styled.select`
  padding: 10px;
  border: 1px solid #000000;
  margin-left: 10px;

  border-radius: 5px;
  @media (max-width: 1130px) {
    width: 99%;
    margin-top: 20px;
  }
`;
export const LanguageSelect = styled.select`
  padding: 10px;
  border: 1px solid #000000;
  width: 33vh;
  border-radius: 5px;
  @media (max-width: 1130px) {
    width: 99%;
    margin-top: 20px;
  }
`;
export const LanguageInput = styled.input`
  padding: 10px;
  border: 1px solid #000000;
  width: 33vh;
  border-radius: 5px;
  @media (max-width: 1130px) {
    width: 99%;
    margin-top: 20px;
  }
`;
export const ProffecencySelect = styled.select`
  padding: 10px;
  border: 1px solid #000000;
  margin-left: 30px;
  height: 45px;
  width: 30vh;
  border-radius: 5px;
  @media (max-width: 1130px) {
    width: 99%;
    margin-top: 20px;
  }
`;
export const ResumeLogo = styled.img`
  margin: 20px auto 40px auto;
  width: 9%;
  position: relative;
  top: 10px;
`;
export const RateSkillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1130px) {
    margin-top: 20px;
    text-align: center;
  }
`;

export const ExpLabel = styled.label`
  /* margin-bottom: 8px; */
  font-size: 14px;
`;

export const JobWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 500px;
  font-size: 25px;
`;

export const JobExpWrapper = styled.div``;

export const ExperienceInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1130px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const AddLanguage = styled.button`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: transparent;
  width: 100%;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
  background-image: url(${plusround});

  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-size: 15px;
`;

export const EmpProof = styled.select`
  padding: 10px;
  border: 1px solid #000000;

  border-radius: 5px;
  flex: 1;
  width: 30px;

  margin-left: 20px;
  @media (max-width: 700px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const LocationBox = styled.input`
  padding: 10px;
  border: 1px solid #000000;

  border-radius: 5px;
  flex: 1;
  width: 30px;

  margin-left: 9px;
  @media (max-width: 700px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const CheckCompanyWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  &:label {
    cursor: pointer;
  }
`;

export const IndustryProof = styled.select`
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  width: 49%;
  margin-right: 0px;
  @media (max-width: 700px) {
    width: auto !important;
  }
`;

export const TabPane2 = styled.h2`
  color: #3d3d3d;

  font-weight: 500px;
  font-size: 25px;
`;

export const LabelSwitch = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
  position: relative;
  z-index: 10000;
  display: flex;
  flex-direction: right;
  right: 1150%;
  padding: 15px;
  margin-right: 50px;

  box-sizing: border-box;
`;

export const CurrentlyStudy = styled.span``;

export const Switch = styled.label``;

export const Slider = styled.span``;

export const SkipWrap = styled.button`
  width: 10%;
  border: 1px solid #000000;
  background-color: transparent;
  cursor: pointer;
  border-radius: 10px;

  height: 38px;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

export const PrevNextWrapper = styled.div`
  display: flex;
  justify-content: right;
`;
export const Star = styled.button`
  display: inline-block;
  width: 20px;
  margin: 0 5px;
  background-image: url(${star1});
  background-repeat: no-repeat;
  background-position: 0 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;

export const NextWrap = styled.button`
  width: 98px;
  padding: 8px 10px;
  color: #000000;
  font-weight: bold;
  background-color: #ffd361;
  border: 1px solid #ffd361;
  border-radius: 5px;
  cursor: pointer;
`;
export const PreWrap = styled.button`
  width: 98px;
  padding: 8px 10px;
  margin-right: 15px;
  border-radius: 5px;
  border: 1px solid #000000;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;
export const Description = styled.textarea`
  width: 40%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  border: 1px solid #000000;

  @media (max-width: 700px) {
    width: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const SkillInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #000000;
  width: 208px;
  height: 38px;
  margin: 10px;
`;
export const VersionBox = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #000000;
  @media (max-width: 1130px) {
    width: 90%;
    margin-bottom: 20px;
  }
`;
export const TitleBox = styled.input`
  background: #fbfafb;
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: none;
  color: #474747;
  font-size: 14px;
  font-weight: 400;
  text-indent: 40px;
  width: 100px;
  margin-bottom: 20px;
  @media (max-width: 1130px) {
    width: 90%;
    margin-bottom: 20px;
  }
`;
export const CompanyBox = styled.input`
  background: #fbfafb;
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: none;
  color: #474747;
  font-size: 14px;
  font-weight: 400;
  text-indent: 40px;
  width: 100px;
  margin-bottom: 20px;
  @media (max-width: 1130px) {
    width: 90%;
    margin-bottom: 20px;
  }
`;
export const CityBox = styled.input``;
export const DataCal = styled.input`
  background-image: url('../images/date-cal.png');
  width: 24% !important;
  margin-right: 25px;
  border-radius: 8px;

  border-color: #a8a8a8;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
`;

export const DateBox = styled.input`
  background-image: url('../images/date-cal.png');
  width: 25% !important;
  margin-left: 15px;

  width: 208px;
  height: 48px;
  border-radius: 8px;
  background-repeat: no-repeat;
`;

export const AddProjects = styled.button`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ffffff;
  width: 218px;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 0px auto;
  display: block;
  cursor: pointer;
  background-image: url(${plusround});
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-size: 15px;
  @media (max-width: 700px) {
    margin: 20px auto 0px auto;
    position: relative;
    right: 10%;
  }
`;

export const MainGrade = styled.input`
  @media (max-width: 700px) {
    margin-top: -20px;
  }
`;

export const InputText = styled.input`
  margin-right: 20px;
  @media (max-width: 700px) {
    margin-top: 20px;
  }
`;
export const CoCheck = styled.input`
  width: auto !important;
  margin-right: 10px;
`;
export const Degreeinput = styled.input`
  margin-top: 20px;
  @media (max-width: 700px) {
    margin-top: 20px;
  }
`;

export const CurrentStudy = styled.div`
  /* border: 5px solid; */
  /* height: 40px; */
`;
export const StepperCommonContainer = styled.div``;
export const ErrorDiv = styled.span`
  color: #e92626;
  font-size: 14px;
`;
export const BodyDiv = styled.div`
  width: 100%;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #3d3d3d;
  }
`;
