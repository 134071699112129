import styled from 'styled-components';
import comingSoon from 'app/assets/images/coming-soon.svg';
import candidatesHoverIcon from 'app/assets/images/candidates-hover.svg';
import groupsHoverIcon from 'app/assets/images/groups-hover.svg';
import reviewersHoverIcon from 'app/assets/images/reviewers-hover.svg';
import supportHoverIcon from 'app/assets/images/support-main-hover.svg';
import helpHoverIcon from 'app/assets/images/help-hover.svg';
import subscriptionHoverIcon from 'app/assets/images/subscription-hover.svg';
import logoutIcon from 'app/assets/images/logout-main.svg';
import logoutIconHover from 'app/assets/images/logout-hover.svg';
// import postJobHover from 'app/assets/images/post-job.svg';
// import postedJobsHover from 'app/assets/images/postedjobshover.svg';
// import profileHover from 'app/assets/images/profile.svg';
import buyTestsHover from 'app/assets/images/job_hover.svg';
// import screeningTestsHover from 'app/assets/images/04-screening-tests.svg';
// import testReportsHover from 'app/assets/images/05-test-reports.svg';
// import inboxHover from 'app/assets/images/inbox-hover.svg';

import assessments from 'app/assets/images/assessment_hover.svg';
import logOut from 'app/assets/images/logoutmenuhover.svg';
import plusIconHover from 'app/assets/images/plus_hover.svg';
import inbox from 'app/assets/images/mail_hover.svg';
import postedjob from 'app/assets/images/doc_hover.svg';
import reports from 'app/assets/images/chart_hover.svg';
import profile from 'app/assets/images/profile_hover.svg';
import searchjob from 'app/assets/images/job_hover.svg';
import buytests from 'app/assets/images/buy_hover.svg';
import interviewProgress from 'app/assets/images/interview_hover.svg';
import postedJobIcon from 'app/assets/images/application_hover.svg';
import careerPath from 'app/assets/images/career_hover.svg';
import resumeBuilder from 'app/assets/images/resume_hover.svg';
import questionBuilder from 'app/assets/images/interview_group.svg';

export const LogoImg = styled.img<any>`
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px !important;
  :hover {
    cursor: pointer;
  }
`;
export const SideLogoImg = styled.img<any>`
  margin: 30px auto;
  display: block;
`;
export const FooterLogoImg = styled.img<any>`
  margin-top: 20px;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const LeftSideNavDiv = styled.div<any>`
  height: 100%;
  width: 290px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fdb82c;
  overflow-x: hidden;
  @media (max-width: 800px) {
    width: 186px;
  }
  a {
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
    text-decoration: none;
    display: block;
    &:hover {
      color: #ffffff;
      background-color: #3d3d3d;
      border-radius: 10px;
    }
  }
  img {
    margin: 0px auto;
    display: block;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const MainMenu = styled.ul`
  margin-top: 50px;
  padding-left: 0px;
`;

export const MainMenuLi = styled.li`
  list-style: none;
  position: relative;

  a {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    font-weight: 500;
    text-indent: 20px;
    background-repeat: no-repeat;
    background-position: left 5px center;
    padding-left: 20px;
    background-size: 9%;
    margin-bottom: 2px;
  }
  .logout-button {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    font-weight: 500;
    text-indent: 30px;
    background-repeat: no-repeat;
    background-position: left 5px center;
    background-size: 9%;
    margin-bottom: 2px;
    float: none;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: 95%;
    text-align: left;
    background-image: url(${logoutIcon});
    background-size: 7%;
  }

  .logout-button:hover,
  .logout-button.active {
    background-image: url(${logoutIconHover});
  }

  a.active {
    color: #ffffff;
    background-color: #3d3d3d;
    border-radius: 10px;
  }

  button:hover {
    color: #ffffff;
    background-color: #3d3d3d;
    border-radius: 10px;
  }

  a.Log-Out {
    background-size: 7%;
  }
  /* added for new left side menu starts */
  a.postjob:hover,
  a.postjob.active {
    background-image: url(${plusIconHover});
    background-size: 9%;
  }
  a.assessments:hover,
  a.assessments.active {
    background-image: url(${assessments});
    background-size: 9%;
  }
  a.inbox:hover,
  a.inbox.active {
    background-image: url(${inbox});
    background-size: 9%;
  }
  a.postedjob:hover,
  a.postedjob.active {
    background-image: url(${postedjob});
    background-size: 9%;
  }
  a.reports:hover,
  a.reports.active {
    background-image: url(${reports});
    background-size: 9%;
  }
  a.profile:hover,
  a.profile.active {
    background-image: url(${profile});
    background-size: 9%;
  }
  a.searchjob:hover,
  a.searchjob.active {
    background-image: url(${searchjob});
    background-size: 9%;
  }
  a.buy-tests:hover,
  a.buy-tests.active {
    background-image: url(${buytests});
    background-size: 9%;
  }
  a.interview-progress:hover,
  a.interview-progress.active {
    background-image: url(${interviewProgress});
    background-size: 9%;
  }
  /* added for new left side menu ends */

  a.subscription:hover,
  a.subscription.active {
    background-image: url(${subscriptionHoverIcon});
    background-size: 9%;
  }

  a.candidates:hover,
  a.candidates.active {
    background-image: url(${candidatesHoverIcon});
  }
  a.reviewers:hover,
  a.reviewers.active {
    background-image: url(${reviewersHoverIcon});
  }
  a.groups:hover,
  a.groups.active {
    background-image: url(${groupsHoverIcon});
  }
  a.help:hover,
  a.help.active {
    background-image: url(${helpHoverIcon});
  }
  a.my-applications:hover,
  a.my-applications.active {
    background-image: url(${postedJobIcon});
  }
  a.searchjob:hover,
  a.searchjob:active {
    background-image: url(${buyTestsHover});
  }
  a.profile:hover,
  a.profile:active {
    background-image: url(${profile});
  }
  a.career-path:hover,
  a.career-path.active {
    background-image: url(${careerPath});
    background-size: 9%;
  }
  a.resume-builder:hover,
  a.resume-builder.active {
    background-image: url(${resumeBuilder});
    background-size: 9%;
  }
  a.question-bank:hover,
  a.question-bank.active {
    background-image: url(${questionBuilder});
    background-size: 9%;
  }

  a.Support:hover,
  a.Support.active {
    background-image: url(${supportHoverIcon});
    background-size: 9%;
  }

  a.my-assessments:hover,
  a.my-assessments.active {
    background-image: url(${logOut});
    background-size: 9%;
  }

  a.Log-Out:hover,
  a.Log-Out.active {
    background-image: url(${logOut});
    background-size: 7%;
  }
`;
export const FooterLeft = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  .platform {
    color: #ffffff;
    font-size: 10px;
  }
`;
export const FooterLogo = styled.div`
  background-color: #3d3d3d;
  width: 100%;
  text-align: center;
  padding: 5px 0px;
  bottom: 0;
`;
export const ComingSoonSpan = styled.span`
  background-image: url(${comingSoon});
  width: 93px;
  height: 18px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff;
  position: absolute;
  right: 10px;
`;
