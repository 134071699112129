import styled from 'styled-components';

interface ButtonProps {
  bgImage?: string;
  bgColor?: string;
}
export const StyledButton = styled.button<ButtonProps>`
  padding: 10px 15px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#000000')};
  color: ${props => (props.color ? props.color : '#000000')};
  font-size: 12px;
  border-radius: 5px;
  margin-top: 20px;
  margin-right: 20px;
  border: 1px solid #999999;
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
  background-image: ${props => (props.bgImage ? `url(${props.bgImage})` : '')};
  &:disabled {
    border: 1px solid #999999;
    /* background-color: #fdb82c; */
    color: #666666;
    cursor: not-allowed !important;
  }
`;

export const StyledButtonSecondary = styled.button<ButtonProps>`
  background-color: transparent;
  cursor: pointer;
  border: none;
  float: right;
  color: #474747;
  font-size: 14px;
  font-weight: 500;
  background-image: ${props => (props.bgImage ? `url(${props.bgImage})` : '')};
  &:disabled {
    border: 1px solid #999999;
    background-color: #fdb82c;
    color: #666666;
    cursor: not-allowed !important;
  }
`;

export const ButtonContent = styled.span`
  font-size: inherit;
  font-weight: inherit;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
`;
export const Icon = styled.img`
  color: inherit;
  font-size: inherit;
  margin: 0 10px;
`;
