import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  
  Select {
    color: #959595;
    font-size: 14px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    height: inherit;
    padding: 0 5px;
    /* border-radius: 10px; */
    border: 1px solid #000000;
    align-items: center;
    background: #ffffff;
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    &:focus {
      color: #404040;
      border: 1px solid #000000;
      transform: translateX(0%);
    }
    &:valid {
      color: #404040;
      border: 1px solid #000000;
      transform: translateX(0%);
    }
    &:focus & :invalid {
      border: 1px solid #ed5063;
    }
  }
    &:check {
      border: 1px solid #000000;
    }
    &::after {
      background: red;
    }
    // option {
    //   background: #ffffff;
    //   &::after {
    //     background: #ffffff;
    //   }
  }
`;

export const Label = styled.label`
  color: #959595;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  background: white;
  padding: 0 5px;
  left: 18px;
  top: 16px;
  align-items: center;
  transition: 0.2s ease all;
  Select:focus ~ &,
  Select:not(:placeholder-shown).Select:not(:focus) ~ .label,
  Select:focus.Select:invalid,
  Select:valid ~ & {
    top: -10px;
    font-size: 12px;
    color: #959595;
    opacity: 1;
  }
`;
