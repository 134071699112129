/* eslint-disable react/style-prop-object */
import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  JobDetailsButtonWrapper,
  JobDetailsContent,
  JobDetailsFameInfo,
  JobDetailsFooter,
  JobDetailsFooterSubDiv,
  JobDetailsImgWrapper,
  JobDetailsLeftWrapper,
  JobDetailsList,
  JobDetailsListLi,
  JobDetailsPostSubWrapper,
  JobDetailsPostWrapper,
  JobDetailsWrapper,
  JobPara,
  JobRightWrapper,
  JobSkillMain,
  JobSkills,
} from '../jobDetailsStyles';
import everserveIcon from 'app/assets/images/everserve.png';
import mapsIcon from 'app/assets/images/maps.png';
import globeIcon from 'app/assets/images/globe.png';
// import postIcon from 'app/assets/images/post.png';
import rupeeIcon from 'app/assets/images/ruppee.png';
import suiteIcon from 'app/assets/images/suite.png';
import timeIcon from 'app/assets/images/time.png';
import fbIcon from 'app/assets/images/facebook.png';
import linkdinIcon from 'app/assets/images/LinkedIn.png';
import twitterIcon from 'app/assets/images/twitter.png';

export const JobDetails = ({ JobData, handleMultiClick }) => {
  // const [selectedLocationOption, setSelectedLocationOption] = useState<any>();
  // let navigate = useNavigate();

  useEffect(() => {
    console.log();
  }, []);

  return (
    <JobDetailsWrapper className="job-details-wrapper">
      <JobDetailsLeftWrapper className="job-left-details-wrapper">
        <h1>Job Details </h1>
        <h2> Required Skills </h2>
        <JobSkills className="job-star-wrapper">
          <JobSkillMain className="job-star-main">
            <h3>Java </h3>
            {/* <div className="star-rating" data-rating="3"></div> */}
          </JobSkillMain>
          <JobSkillMain className="job-star-main">
            <h3>Agile </h3>
            {/* <div className="star-rating" data-rating="4"></div> */}
          </JobSkillMain>
          <JobSkillMain className="job-star-main">
            <h3>Testing </h3>
            {/* <div className="star-rating" data-rating="2"></div> */}
          </JobSkillMain>
          <JobSkillMain className="job-star-main">
            <h3>PHP </h3>
            {/* <div className="star-rating" data-rating="5"></div> */}
          </JobSkillMain>
        </JobSkills>
        <h4>Job Description </h4>
        <JobPara className="job-det-para">
          At Everserve Solutions, our Project Managers excel by leading and
          coordinating a project team’s overall performance, scope, cost, and
          deliverables. Our clients rely on timely and efficient status reports,
          and as Project Manager, you will drive the charge with project
          direction, metric definition, and performance management. If you are
          ready to help our clients and project teams succeed, we would love to
          meet you!
        </JobPara>
        <h5>Your Role and Responsibilities </h5>
        <JobDetailsList className="job-details-list">
          <JobDetailsListLi>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
            ullamcorper.
          </JobDetailsListLi>
          <JobDetailsListLi>
            Curabitur ullamcorper. Orci varius natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus
          </JobDetailsListLi>
          <JobDetailsListLi>
            In pellentesque dui eros, sit amet hendrerit magna tincidunt et. Sed
            dictum. Vestibulum convallis
          </JobDetailsListLi>
          <JobDetailsListLi>Sed dictum. Vestibulum convallis </JobDetailsListLi>
        </JobDetailsList>
        <h5>Qualification and Requirements</h5>
        <JobDetailsList className="job-details-list">
          <JobDetailsListLi>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
            ullamcorper.
          </JobDetailsListLi>
          <JobDetailsListLi>
            Curabitur ullamcorper. Orci varius natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus
          </JobDetailsListLi>
          <JobDetailsListLi>
            In pellentesque dui eros, sit amet hendrerit magna tincidunt et. Sed
            dictum. Vestibulum convallis{' '}
          </JobDetailsListLi>
          <JobDetailsListLi>Sed dictum. Vestibulum convallis </JobDetailsListLi>
        </JobDetailsList>
      </JobDetailsLeftWrapper>
      <JobRightWrapper className="job-right-details-wrapper">
        <img src={everserveIcon} alt="wipro" className="wipro-logo" />
        <h1>Project Manager </h1>
        <h2>Everserve Solutions </h2>
        {/* <div className="star-rating" data-rating="3"></div> */}
        <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={mapsIcon} alt="maps" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            Bangalore, Noida
          </JobDetailsContent>
        </JobDetailsFameInfo>
        <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={globeIcon} alt="globe" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            www.everservesolutions.com
          </JobDetailsContent>
        </JobDetailsFameInfo>
        {/* <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={postIcon} alt="post" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            hr.neha@everservesolutions.com
          </JobDetailsContent>
        </JobDetailsFameInfo> */}
        <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={rupeeIcon} alt="rupeeIcon" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            <b> 18,00,000 - 25,00,000</b>
          </JobDetailsContent>
        </JobDetailsFameInfo>
        <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={suiteIcon} alt="suite" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            <b> 7 to 10 Years Experience</b>
          </JobDetailsContent>
        </JobDetailsFameInfo>
        <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={timeIcon} alt="time" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            Fulltime (Remote)
          </JobDetailsContent>
        </JobDetailsFameInfo>
        <JobDetailsPostWrapper className="job-details-post-wrapper">
          <JobDetailsPostSubWrapper className="job-details-posted">
            Posted <span>:</span>
          </JobDetailsPostSubWrapper>
          <div>3 days before</div>
        </JobDetailsPostWrapper>
        <JobDetailsPostWrapper className="job-details-post-wrapper">
          <JobDetailsPostSubWrapper className="job-details-posted">
            Openings <span>:</span>
          </JobDetailsPostSubWrapper>
          <div>2</div>
        </JobDetailsPostWrapper>

        <JobDetailsButtonWrapper className="job-details-button-wrapper">
          <button
            type="submit"
            className="job-details-cancel"
            onClick={() => handleMultiClick(false)}
          >
            Cancel{' '}
          </button>
          <button
            type="submit"
            className="job-details-apply"
            onClick={() => handleMultiClick(true, JobData.id)}
          >
            Apply
          </button>
        </JobDetailsButtonWrapper>

        <JobDetailsFooter className="job-details-footer">
          <JobDetailsFooterSubDiv>Share the job</JobDetailsFooterSubDiv>
          <img src={fbIcon} alt="fb" />
          <img src={linkdinIcon} alt="li" />
          <img src={twitterIcon} alt="tw" />
        </JobDetailsFooter>
      </JobRightWrapper>
    </JobDetailsWrapper>
  );
};
