import AWS from 'aws-sdk';

const S3_BUCKET = 'fairhiree-dev-media-recordings';
const REGION = 'ap-south-1';

AWS.config.update({
  accessKeyId: 'AKIAT2HOT3SYDU3GC5D5',
  secretAccessKey: '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',
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const ScreenRecording = async () => {
  let captureStream: any = null;

  try {
    const mediaDevices: any = navigator.mediaDevices;
    captureStream = await mediaDevices.getDisplayMedia({
      audio: true,
      video: { MediaSource: 'screen' },
    });
  } catch (err) {
    console.error('Error: ' + err);
  }
  return captureStream;
  // const mediaDevices:any = navigator.mediaDevices;
  // const stream = await mediaDevices.getDisplayMedia({
  //     audio: false,
  //     video:{MediaSource: "screen"},
  // });
  // return stream;
};
export const VideoRecording = async () => {
  const mediaDevices: any = navigator.mediaDevices;
  const stream = await mediaDevices.getUserMedia({
    audio: true,
    video: true,
  });
  return stream;
};

export const StartRecording = (
  mediarecorder,
  type: string,
  assessmentId: string,
  userId: string,
  ucaId: string,
) => {
  let chunks: any = [];
  mediarecorder.ondataavailable = function (ev: any) {
    const currentTimeSatmp = new Date().getTime();
    chunks.push(ev.data);
    const length = pad(chunks.length, 3);
    const pathName = `${type}/${assessmentId}_${userId}_${ucaId}_${length}_${currentTimeSatmp}`;
    let recordedBlob = new Blob([ev.data], {
      type: 'video/webm;codecs=vp8,opus',
    });
    const params = {
      Body: new File([recordedBlob], pathName, { type: 'video/webm' }),
      Bucket: S3_BUCKET,
      Key: `${pathName}.webm`,
      // Key: `${pathName}/${assessmentId}_${sessionStorage.getItem('attempt')}.webm`,
    };
    myBucket
      .putObject(params)
      .on('httpUploadProgress', evt => {
        //alert("Sucess");
        // setProgress(Math.round((evt.loaded / evt.total) * 100))
      })
      .send(err => {
        if (err) console.log(err);
      });
  };
  mediarecorder.start(5000);
};
export const StopRecording = mediarecorder => {
  // console.log('MediaRecording', mediarecorder);
  mediarecorder.stop();
};

const pad = (n, length) => {
  var len = length - ('' + n).length;
  return (len > 0 ? new Array(++len).join('0') : '') + n;
};
