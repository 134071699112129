import React, { useEffect } from 'react';
import { Header } from 'app/components/UI/organisms/Header';
import {
  ContentDivWrapper,
  TitleWrapper,
} from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import emailSupport from 'app/assets/images/email-support.svg';
import request from 'app/assets/images/Request.svg';
import whatsapp from 'app/assets/images/whatsapp.svg';
import phonenum from 'app/assets/images/phone-num.svg';
import { BodyDiv } from './supportStyles';

export const Support = () => {
  useEffect(() => {
    // getUserProfile();
  }, []);

  // const content = (
  //   <ModelContent>
  //     <MailIcon>
  //       <img src={successIcon} alt="mail-icon" width="80px" height="50px" />
  //     </MailIcon>
  //     <Message>
  //       <PrimaryParagraph>Profile Edited successfully!</PrimaryParagraph>
  //     </Message>
  //   </ModelContent>
  // );

  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <TitleWrapper>Support</TitleWrapper>
            <div className="support-wrapper">
              <div className="support-frame">
                <div className="support-image">
                  <img src={emailSupport} alt="email" />
                </div>
                <div className="support-content">
                  <h2 className="customer-service">
                    Would you like to email our customer service department?
                  </h2>
                  <a href="mailto:care@fairhiree.com"> care@fairhiree.com</a>
                </div>
              </div>
              <div className="support-frame">
                <div className="support-image">
                  <img src={request} alt="request" />
                </div>
                <div className="support-content">
                  <h3 className="prefer-service">
                    Would you prefer any new technology added to the screening
                    tests?
                  </h3>

                  <h4 className="prefer-service">
                    Please send the name and version of the technology. We will
                    add the technology in 3 to 5 working days.
                  </h4>

                  <a href="mailto:request@fairhiree.com">
                    {' '}
                    request@fairhiree.com
                  </a>
                </div>
              </div>
              <div className="support-frame last-frame">
                <div className="support-image">
                  <img src={whatsapp} alt="whatsapp" />
                </div>
                <div className="support-content">
                  <h5 className="prefer-service">
                    Would you like to contact our customer service staff via
                    WhatsApp?
                  </h5>

                  <b className="phonenum">
                    {' '}
                    <img src={phonenum} alt="phone" width={20} /> +918660509906
                  </b>
                </div>
              </div>
            </div>
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
    </>
  );
};
