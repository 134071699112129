import React, { useEffect, useState, useMemo } from 'react';
import Button from 'app/components/UI/atoms/Button';
import { Select } from 'app/components/UI/atoms/Select';
import { TextArea } from 'app/components/UI/atoms/TextArea';
// import { Title1 } from 'app/components/UI/atoms/Titles';
import { Input } from 'app/components/UI/atoms/Input';
import {
  DivQues,
  DivQuesText,
  FrameDiv,
  InputWrapperDiv,
  ModalDiv,
  ModalEdit,
  ModalFlexDiv,
  ModalTagFlex,
  ModalTextFlex,
  ModalTextFlex2,
  QuesFrameDiv,
  SubDiv,
  ContentSubDiv,
} from './addQuesModal';
import editIcon from 'app/assets/images/edit-icon.svg';
import { McqsQuestionModal } from 'app/components/UI/organisms/McqsQuestionModal';
import { WritingQuestionModal } from 'app/components/UI/organisms/WritingQuestionModal';

export const AddQuestionModal = ({
  categoryId,
  onAddQuestionSubmit,
  saveDraftRef,
  closeQuesModal,
  displayType = 'addQuestion',
  categories,
  submitRef,
}) => {
  const [quesType, setQuesType] = useState<string>('MCQ');
  const [quesLevel, setQuesLevel] = useState<string>();
  const [quesTech, setQuesTech] = useState<string>();

  let techOptions: any = useMemo(() => [], []);
  const levelOptions = [
    { label: 'Beginner', value: 'Beginner' },
    { label: 'Intermediate', value: 'Intermediate' },
    { label: 'Expert', value: 'Expert' },
  ];

  // const handleQuesTypeChange = e => {
  //   setQuesType(e.target.value);
  // };
  const handleLevelChange = e => {
    setQuesLevel(e.target.value);
  };
  const handleQuesTechChange = e => {
    setQuesTech(e.target.value);
  };

  useEffect(() => {
    setQuesType('MCQ');
    setQuesLevel('Beginner');
    localStorage.removeItem('draftedQuest');
    categories.map(category =>
      techOptions.push({ label: category.name, value: category.id }),
    );
    setQuesTech(categoryId);
  }, [categories, techOptions, categoryId]);

  return (
    <ModalDiv>
      {/* <span className="close">&times;</span> */}
      {/* <Title1 className="question-bank-heading">Add Question</Title1> */}
      <SubDiv className="techLevelWrapper">
        <Select
          id={'tech'}
          label={'Select Technology Type'}
          options={techOptions}
          onChange={handleQuesTechChange}
          value={quesTech ? quesTech : ''}
          className="techWrapper"
        />
        <Select
          id={'level'}
          // label={'Select Type'}
          options={levelOptions}
          onChange={handleLevelChange}
          value={quesLevel ? quesLevel : ''}
          className="levelWrapper"
        />
        {/* <Button type="button" content="  Add" bgColor="#fdb82c" /> */}
      </SubDiv>
      {quesType === 'MCQ' ? (
        <ContentSubDiv>
          <McqsQuestionModal
            quesLevel={quesLevel}
            quesTech={quesTech}
            categoryId={categoryId}
            onAddQuestionSubmit={onAddQuestionSubmit}
            saveDraftRef={saveDraftRef}
            closeQuesModal={closeQuesModal}
            type={displayType}
            questData={undefined}
            submitRef={submitRef}
          />
        </ContentSubDiv>
      ) : (
        ''
      )}
      {quesType === 'writing' ? (
        <WritingQuestionModal
          quesLevel={quesLevel}
          categoryId={categoryId}
          onAddQuestionSubmit={onAddQuestionSubmit}
        />
      ) : (
        ''
      )}
      {quesType === 'reading' ? (
        <ModalFlexDiv>
          <ModalTextFlex>
            <TextArea
              placeholder=""
              label=""
              id="quesText"
              value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit
            amet blandit turpis. Sed leo metus, sagittis sed elit in, maximus
            facilisis quam. Donec non nisi libero. Proin ac erat libero.
            Praesent at mi sit amet urna aliquam blandit. Fusce imperdiet,
            lectus volutpat faucibus cursus, nulla ante hendrerit metus, sed
            varius dui nisi eget diam. Mauris dictum elit non dui lobortis
            tempus sit amet quis lorem. Mauris ullamcorper lectus aliquam,
            placerat elit hendrerit, laoreet libero. Quisque porta lectus quis
            mollis vestibulum. Cras lacinia ornare urna, at rhoncus odio
            porttitor vitae. Praesent et elementum velit, in iaculis dolor.
            Phasellus lobortis nisi eget nisi pellentesque, eget feugiat libero
            efficitur. Quisque varius vehicula quam, et tincidunt turpis
            placerat a. Vestibulum ullamcorper orci id enim fringilla, sed
            ultricies velit porta. Maecenas vel massa turpis."
              rows={20}
              cols={50}
            />
          </ModalTextFlex>
          <ModalTextFlex2>
            <ModalTagFlex>
              <InputWrapperDiv>
                {/* <input type="text" className="modal-input" /> */}
                <Input
                  id="questionText"
                  type="text"
                  // label="question text2"
                  placeholder="question text"
                  // onChange={(e) => setPassword(e.target.value)}
                />
                <Button type="button" content="+" />
              </InputWrapperDiv>
              <Button type="button" content="+ Add" bgColor="#fdb82c" />
            </ModalTagFlex>
            <ModalEdit>
              <FrameDiv>
                <QuesFrameDiv>
                  <DivQues>Q1</DivQues>
                  <DivQuesText>
                    Fusce imperdiet, lectus volutpat faucibus cursus,?
                  </DivQuesText>
                  <Button type="button" bgImage={editIcon} content="" />
                </QuesFrameDiv>
                <QuesFrameDiv>
                  <DivQues>Ans</DivQues>
                  <DivQuesText>Mauris,ante,porta,eget</DivQuesText>
                </QuesFrameDiv>
              </FrameDiv>
              <FrameDiv>
                <QuesFrameDiv>
                  <DivQues>Q2</DivQues>
                  <DivQuesText>
                    Fusce imperdiet, lectus volutpat faucibus cursus,?
                  </DivQuesText>
                  <Button type="button" bgImage={editIcon} content="" />
                </QuesFrameDiv>
                <QuesFrameDiv>
                  <DivQues>Ans</DivQues>
                  <DivQuesText>Mauris,ante,porta,eget</DivQuesText>
                </QuesFrameDiv>
              </FrameDiv>
            </ModalEdit>
          </ModalTextFlex2>
        </ModalFlexDiv>
      ) : (
        ''
      )}
    </ModalDiv>
  );
};
