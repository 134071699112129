import styled from 'styled-components';
// import searchIcon from 'app/assets/images/searchIcon.svg';

export const BodyDiv = styled.div`
  width: 100%;
  .profile-heading {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    background-color: #fdb82c;
    padding: 10px;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #3d3d3d;
    font-family: 'Montserrat', sans-serif;
  }

  .subscription-h2 {
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
  }

  .subscription-wrapper {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 40px;
    justify-content: center;
  }

  .subscription-content {
    padding: 20px;
  }

  .subscription-info {
    padding: 10px;
    border-bottom: 1px solid #3d3d3d;
    font-weight: 500;
  }

  .subscription-info b {
    float: right;
    padding-left: 10px;
    color: #3d3d3d;
  }

  .subscription-frame {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 308px;
    min-height: 100%;
    align-items: center;
    border-radius: 30px;
  }

  .subscription-image {
    width: 100%;
    padding: 20px;
    background: #fdb82c;
    border-top-left-radius: 30px;
  }

  .subscription-image img {
    width: 50px;
  }

  .jobs-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .breadcrumbwrapper {
    display: flex;
  }
`;

export const CloseModal = styled.div``;
export const ClosePara = styled.div``;
export const CloseTitle = styled.h3``;
