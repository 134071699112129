import styled from 'styled-components';
import rectBg from 'app/assets/images/rectangle245.png';

export const LogoImg = styled.img<any>`
  margin: 50px 0px 50px 50px;
`;
export const FlyImg = styled.img<any>`
  position: absolute;
  margin: 0px auto;
  left: -130px;
  right: 0;
  width: 400px;
  top: 50%;
  @media (max-width: 1000px) {
    display: none;
  }
`;
export const RocketImg = styled.img<any>`
  margin-left: 0;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  position: absolute;
  left: -225px;
  top: -20px;
`;
export const MainWrapper = styled.div<any>`
  background: #fdb82c;
  min-height: 100vh;
  align-items: center;
`;
export const LeftWrapper = styled.div<any>`
  background-color: #393939;
  border-top-right-radius: 50px;
  font-weight: 400;
  color: #ffffff;
  padding: 50px;
  width: 50%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: baseline;
  justify-content: flex-start;
  h1 {
    font-size: 22px;
    font-weight: 300;
    @media (min-width: 1000px) {
      font-size: 40px;
    }
  }
  h2 {
    font-size: 22px;
    font-weight: 300;
    @media (min-width: 1000px) {
      font-size: 40px;
    }
  }
  h3 {
    font-size: 22px;
    line-height: 60px;
    margin-top: 40px;
    @media (min-width: 1000px) {
      font-size: 40px;
    }
  }
  @media (max-width: 1000px) {
    color: #ffffff;
    padding: 40px 20px;
    width: 100%;
    min-height: auto !important;
    border-radius: 0px;
  }
`;

export const DivWrapper = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const IllustrationWrapper = styled.img<any>`
  display: flex;
  align-items: center;
  padding: 50px;
  flex-direction: column;
  position: absolute;
  bottom: -30px;
  right: -120px;
  padding: 50px;
`;
export const RightWrapper = styled.div<any>`
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  form {
    width: 75%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1000px) {
      width: 90%;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  label.free-tool-create-label {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
    color: #151515;
  }
  label.free-tool-create-label1 {
    font-weight: 700;
    margin-top: 30px;
    font-size: 20px;
    margin-bottom: 20px;
    color: #151515;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
export const ButtonWrap = styled.div`
  width: 100%;
  text-align: right;
  button {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
    color: #ffffff;
    width: 218px;
    height: 50px;
    cursor: pointer;
    background: #000000;
    border-radius: 5px;
  }
`;
export const Title1 = styled.h1`
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #393939;
`;

export const Heading1 = styled.h2`
  font-weight: 400;
  font-size: 22px;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
`;
export const Heading2 = styled.h2`
  font-weight: 700;
  font-size: 28px;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 20px;
`;
export const InputHeading = styled.h3`
  font-weight: 700;
  font-size: 20px;
  text-align: left;
  color: #000000;
  margin-bottom: 20px;
`;
export const BoxSubDiv = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: url(${rectBg});
  background-size: cover;
  min-height: 75%;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 50px;
`;
export const InputMain = styled.div<any>`
  width: 80%;
  input[type='text'] {
    background-position: left center;
    background-repeat: no-repeat;
  }
  button {
    line-height: 40px;
    color: #fff;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    &.forgot {
      color: #474747;
      font-weight: 500;
      font-size: 14px;
    }
  }
`;

export const ClearDiv = styled.div`
  clear: both;
`;
export const ErrorDiv = styled.span`
  color: #e92626;
  font-size: 14px;
`;
