/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
// import SelectMulti from 'react-select';
import Button from 'app/components/UI/atoms/Button';
// import { Select } from 'app/components/UI/atoms/Select';
import { Header } from 'app/components/UI/organisms/Header';
import {
  ContentDivWrapper,
  TitleWrapper,
} from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
// import { useParams } from 'react-router-dom';
import {
  BodyDiv,
  // TitleDiv,
  TopDiv,
  SearchWrap,
  ContentDiv,
  // ErrorDiv,
  InpWrapper,
  ContentSubDiv,
} from './profileListingStyles';
import { Input } from 'app/components/UI/atoms/Input';
import { useForm } from 'react-hook-form';
import { editUsersProfile, getUsersProfile } from 'app/services/users';
import {
  MailIcon,
  Message,
  ModalContainer,
  ModelContent,
} from 'app/components/UI/globalStyles/modalStyles';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import successIcon from 'app/assets/images/success-icon.svg';
import { Modal } from 'app/components/UI/organisms/Modal/SimpleModal';
import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import buttonLoader from 'app/assets/images/loader-yellow.svg';
import { useNavigate } from 'react-router-dom';

export const Profile = () => {
  // const [selectedLocationOption, setSelectedLocationOption] = useState<any>();
  // const [salVal, setSalValue] = useState('');
  // const [curVal, setCurValue] = useState('');
  // const [titleVal, settitleVal] = useState('');
  // const [goodToHaveVal, setGoodToHaveVal] = useState('');
  // const [skillsReqVal, setSkillsReqVal] = useState('');
  // const [jobDescVal, setJobDescVal] = useState('');
  // const maxSalDigits = 9; // Set max salary in crores
  const [profileData, setProfileData] = useState<any>();
  const [profileImage, setProfileImage] = useState<any>();
  const { isShown, toggle } = useModal();
  const [isLoading, setLoading] = useState<boolean>(false);
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    // setError,
    // formState: { errors },
  } = useForm();

  const getUserProfile = async () => {
    const userProfileResp = await getUsersProfile();
    // console.log('usersProfileResp', userProfileResp);
    if (userProfileResp?.id) {
      setProfileData(userProfileResp);
      setValue('name', userProfileResp.name);
      setValue('phoneNumber', userProfileResp.phoneNumber);
    }
  };
  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
  }, [reset]);

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  const handleFileRead = async event => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setProfileImage(base64);
  };

  const onSubmit = handleSubmit(async (data: any) => {
    setLoading(true);
    const { name, phoneNumber } = data;
    const postData = {
      name,
      phoneNumber,
      email: profileData.email,
      profilePic: profileImage,
    };

    const editResp = await editUsersProfile(postData);
    // console.log('editResp', editResp);
    if (editResp?.statusCode === 0) {
      toggle();
      setLoading(false);
    }
    // if (
    //   signUpResp.name === 'AxiosError' ||
    //   signUpResp.code === 'ERR_BAD_REQUEST'
    // ) {
    //   setError('email', {
    //     type: 'server',
    //     message: 'Something went wrong!',
    //   });
    // } else {
    //   // setToken(loginResp?.result.accessToken);
    //   // setEmail(data.email);
    //   // navigate('/dashboard');
    //   // signUpToggle();
    //   // reset();
    //   // changeModalVisibility(isShowModal, true);
    //   // navigate('/', {
    //   //   replace: false,
    //   // });
    // }
  });

  const content = (
    <ModelContent>
      <MailIcon>
        <img src={successIcon} alt="mail-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <PrimaryParagraph>Profile Edited successfully!</PrimaryParagraph>
      </Message>
    </ModelContent>
  );

  const toggleAndMove = () => {
    navigate('/assessments');
  };
  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <form
              noValidate={true}
              onSubmit={onSubmit}
              encType="multipart/form-data"
            >
              <SearchWrap className="job-post-wrapper">
                <TitleWrapper>Profile</TitleWrapper>
              </SearchWrap>
              <ContentDiv>
                <ContentSubDiv>
                  <div className="profile-form">
                    {/* {errors?.jobTitle?.type === 'required' ? (
                    <ErrorDiv>Enter Job Title!</ErrorDiv>
                  ) : errors?.goodTohave?.type === 'required' ? (
                    <ErrorDiv>Enter good to have!</ErrorDiv>
                  ) : errors?.skills?.type === 'required' ? (
                    <ErrorDiv>Enter skills!</ErrorDiv>
                  ) : errors?.experience?.type === 'required' ? (
                    <ErrorDiv>Select experience level!</ErrorDiv>
                  ) : errors?.joinTime?.type === 'required' ? (
                    <ErrorDiv>Select candiate join time!</ErrorDiv>
                  ) : errors?.salary?.type === 'required' ? (
                    <ErrorDiv>Enter Salary!</ErrorDiv>
                  ) : errors?.workType?.type === 'required' ? (
                    <ErrorDiv>Select work type!</ErrorDiv>
                  ) : errors?.workMode?.type === 'required' ? (
                    <ErrorDiv>Select work mode!</ErrorDiv>
                  ) : errors?.locations?.type === 'required' ? (
                    <ErrorDiv>Select locations!</ErrorDiv>
                  ) : (
                    ''
                  )} */}
                    <InpWrapper className="post-job-content-flex">
                      <div className="custom-file-input">
                        <Input
                          type="file"
                          id="profileIcon"
                          placeholder="Profile Icon*"
                          {...register('profileIcon', {
                            required: false,
                          })}
                          onChange={handleFileRead}
                        />
                        <label htmlFor="profileIcon"> Profile Image</label>
                      </div>
                    </InpWrapper>

                    <InpWrapper className="post-job-content-flex">
                      <Input
                        type={'text'}
                        id={'compName'}
                        placeholder="Company Name*"
                        value={profileData?.organisationName || ''}
                        disabled
                      />
                    </InpWrapper>
                    <InpWrapper className="post-job-content-flex">
                      <Input
                        type={'text'}
                        id={'phoneNumber'}
                        placeholder="Phone Number*"
                        // value={profileData?.phoneNumber || ''}
                        {...register('phoneNumber', {
                          required: true,
                        })}
                        // // onChange={handleMaxChars}
                        // required
                      />
                    </InpWrapper>
                    <InpWrapper className="post-job-content-flex">
                      <Input
                        type={'text'}
                        id={'email'}
                        placeholder="Email*"
                        value={profileData?.email || ''}
                        disabled
                        // onChange={handleMaxChars}
                      />
                    </InpWrapper>
                    <InpWrapper className="post-job-content-flex">
                      <Input
                        type={'text'}
                        id={'name'}
                        // value={profileData?.name || ''}
                        placeholder="Name*"
                        {...register('name', {
                          required: true,
                        })}
                        // onChange={handleMaxChars}
                      />
                    </InpWrapper>

                    <TopDiv>
                      {/* <Button
                      type="submit"
                      content="Create Job Listing Page"
                      className="job-post create-job-btn"
                      disabled
                    /> */}
                      <Button
                        type="submit"
                        content="Save"
                        className="job-post"
                        leftIcon={isLoading === true ? buttonLoader : ''}
                        disabled={isLoading === true ? true : false}
                      />
                    </TopDiv>
                  </div>
                </ContentSubDiv>
              </ContentDiv>
            </form>
          </BodyDiv>
        </RightContainerDiv>
        <ModalContainer>
          <Modal
            isShown={isShown}
            isShowCloseButton={true}
            hide={toggleAndMove}
            modalContent={content}
            width="30%"
          />
        </ModalContainer>
      </ContentDivWrapper>
    </>
  );
};
