import React from 'react';
import { A } from 'app/components/UI/atoms/Anchor';
import {
  LeftSideNavDiv,
  LogoImg,
  MainMenu,
  MainMenuLi,
  FooterLogo,
  FooterLogoImg,
  FooterLeft,
  ComingSoonSpan,
} from './leftSideNavStyles';
import footerLogo from 'app/assets/images/footer-logo.svg';
import interviewIcon from 'app/assets/images/interview.svg';
import reportsIcon from 'app/assets/images/chart.svg';
import supportIcon from 'app/assets/images/support-main.svg';
import logoutIcon from 'app/assets/images/logout.svg';
import subscriptionIcon from 'app/assets/images/subscription.svg';
// import postJobIcon from 'app/assets/images/plus.svg';
// import postedJobIcon from 'app/assets/images/application.svg';
// import jobSearch from 'app/assets/images/job.svg';
// import inboxIcon from 'app/assets/images/mail.svg';
// import testsIcon from 'app/assets/images/assessment.svg';
// import buyIcon from 'app/assets/images/buy_menu.svg';
import userIcon from 'app/assets/images/profile.svg';
// import careerIcon from 'app/assets/images/career.svg';
// import resumeBuilder from 'app/assets/images/resume.svg';
import assessmentIcon from 'app/assets/images/assessment.svg';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import fairhireelogo from 'app/assets/images/logo-faihiree.png';
import { getToken } from 'app/services/auth/auth';
import jwt_decode from 'jwt-decode';
import { ConfirmationModal } from 'app/components/UI/Modal/confirmationModal/confirmationModal';
import { useModal } from 'app/components/UI/Modal/SimpleModal/userModal';
import { ModalContainer } from '../Modal/MessageModelStyles';
import { Modal } from '../Modal/SimpleModal';
import Button from '../../atoms/Button';

export const LeftSideNav = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { isShown, toggle } = useModal();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const token = getToken();
  if (!token || token === null) {
    return <Navigate replace to="/" />;
  }
  const decodedJT: any = jwt_decode(token);
  const { role } = decodedJT.user;
  const logoutAction = e => {
    e.preventDefault();
    toggle();
  };
  const onConfirm = () => {
    // return true;
    navigate('/logout');
  };

  const onCancel = () => {
    toggle();
  };

  const logoutContent = (
    <ConfirmationModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      message=""
      title="Are you sure you want to Logout?"
      confirmButtonContent="Log out"
      cancelButtonContent="Cancel"
    />
  );

  const leftMenus = [
    {
      id: 25,
      name: 'Assessments',
      path: '/assessments',
      linkActivePaths: ['assessments'],
      lineIcon: assessmentIcon,
      isLive: true,
      className: 'assessments',
      role: 'employee',
    },
    // {
    //   id: 1,
    //   name: 'Post a Job',
    //   path: '/post-job',
    //   linkActivePaths: ['post-job'],
    //   lineIcon: postJobIcon,
    //   isLive: true,
    //   className: 'postjob',
    //   role: 'employee',
    // },
    {
      id: 2,
      name: 'Question Bank',
      path: '/question-bank/category',
      linkActivePaths: ['question-bank', 'category'],
      lineIcon: interviewIcon, //questionsIcon,
      isLive: true,
      className: 'question-bank',
      role: 'employee',
    },
    {
      id: 3,
      name: 'Profile',
      path: '/profile',
      linkActivePaths: ['profile'],
      lineIcon: userIcon,
      isLive: true,
      className: 'profile',
      role: 'employee',
    },

    {
      id: 5,
      name: 'Subscription',
      path: '/subscription',
      linkActivePaths: ['subscription'],
      lineIcon: subscriptionIcon,
      isLive: true,
      className: 'subscription',
      role: 'employee',
    },

    {
      id: 6,
      name: 'Support',
      path: '/support',
      linkActivePaths: ['support'],
      lineIcon: supportIcon,
      isLive: true,
      className: 'Support',
      role: 'employee',
    },

    {
      id: 7,
      name: 'Log Out',
      path: '#',
      linkActivePaths: ['logoutIcon'],
      lineIcon: logoutIcon,
      isLive: true,
      className: 'Log-Out',
      type: 'button',
      clickAction: logoutAction,
      role: 'employee',
    },

    // {
    //   id: 3,
    //   name: 'Posted Jobs',
    //   path: '/jobs',
    //   linkActivePaths: ['jobs'],
    //   lineIcon: postedJobIcon,
    //   isLive: true,
    //   className: 'postedjob',
    //   role: 'employee',
    // },
    // {
    //   id: 4,
    //   name: 'Inbox',
    //   path: '/inbox',
    //   linkActivePaths: ['inbox'],
    //   lineIcon: inboxIcon,
    //   isLive: true,
    //   className: 'inbox',
    //   role: 'employee',
    // },
    // {
    //   id: 5,
    //   name: 'Interview Progress',
    //   path: '/interview-progress',
    //   linkActivePaths: ['interview-progress'],
    //   lineIcon: interviewIcon,
    //   isLive: true,
    //   className: 'interview-progress',
    //   role: 'employee',
    // },
    // {
    //   id: 6,
    //   name: 'Screening Tests',
    //   path: '/assessment',
    //   linkActivePaths: ['assessment', 'create-test', 'test'],
    //   lineIcon: testsIcon,
    //   isLive: true,
    //   className: 'assessments',
    //   role: 'employee',
    // },
    // {
    //   id: 7,
    //   name: 'Test Reports',
    //   path: '/test-reports',
    //   linkActivePaths: ['test-reports'],
    //   lineIcon: reportsIcon,
    //   isLive: true,
    //   className: 'reports',
    //   role: 'employee',
    // },
    // {
    //   id: 8,
    //   name: 'Buy Tests',
    //   path: '#',
    //   linkActivePaths: ['buy-tests'],
    //   lineIcon: buyIcon,
    //   isLive: true,
    //   className: 'buy-tests',
    //   role: 'employee',
    // },
    // {
    //   id: 9,
    //   name: 'Profile',
    //   path: '/profile',
    //   linkActivePaths: ['profile'],
    //   lineIcon: userIcon,
    //   isLive: true,
    //   className: 'profile',
    //   role: 'employee',
    // },
    // {
    //   id: 21,
    //   name: 'Search Jobs',
    //   path: '/search-jobs',
    //   linkActivePaths: ['search-jobs'],
    //   lineIcon: jobSearch,
    //   isLive: true,
    //   className: 'searchjob',
    //   role: 'user',
    // },
    // {
    //   id: 22,
    //   name: 'My Applications',
    //   path: '/my-applications',
    //   linkActivePaths: ['my-applications'],
    //   lineIcon: postedJobIcon,
    //   isLive: true,
    //   className: 'my-applications',
    //   role: 'user',
    // },
    // {
    //   id: 23,
    //   name: 'Career Guidence',
    //   path: 'career-path',
    //   linkActivePaths: ['career-path'],
    //   lineIcon: careerIcon,
    //   isLive: true,
    //   className: 'career-path',
    //   role: 'user',
    // },
    // {
    //   id: 24,
    //   name: 'Resume Builder',
    //   path: 'resume-info',
    //   linkActivePaths: ['resume-info'],
    //   lineIcon: resumeBuilder,
    //   isLive: true,
    //   className: 'resume-builder',
    //   role: 'user',
    // },
    {
      id: 25,
      name: 'Assessments',
      path: 'my-assessments',
      linkActivePaths: ['my-assessments'],
      lineIcon: assessmentIcon,
      isLive: true,
      className: 'my-assessments',
      role: 'user',
    },
    {
      id: 26,
      name: 'Reports',
      path: 'my-reports',
      linkActivePaths: ['my-reports'],
      lineIcon: reportsIcon,
      isLive: true,
      className: 'reports',
      role: 'user',
    },
    {
      id: 27,
      name: 'Profile',
      path: '#',
      linkActivePaths: ['profile'],
      lineIcon: userIcon,
      isLive: true,
      className: 'profile',
      role: 'user',
    },
  ];
  return (
    <LeftSideNavDiv>
      <LogoImg
        src={fairhireelogo}
        alt="fairhiree login"
        width="125"
        onClick={() => navigate('/assessments')}
      />
      <MainMenu>
        {leftMenus.map(li => {
          return (
            li.role === role && (
              <MainMenuLi key={li.id}>
                {li.type === 'button' ? (
                  <Button
                    content={li.name}
                    className="logout-button"
                    type={'button'}
                    onClick={li.clickAction}
                    bgType="secondary"
                  />
                ) : (
                  <A
                    href={li.path}
                    bgImage={li.lineIcon}
                    className={`${li.className} ${
                      li?.linkActivePaths?.includes(splitLocation[1])
                        ? 'active'
                        : ''
                    }`}
                  >
                    {!li.isLive ? (
                      <>
                        {li.name}
                        <ComingSoonSpan></ComingSoonSpan>
                      </>
                    ) : (
                      <>{li.name}</>
                    )}
                  </A>
                )}
              </MainMenuLi>
            )
          );
        })}
      </MainMenu>
      <FooterLeft>
        <FooterLogo className="footer-image">
          <FooterLogoImg src={footerLogo} alt="evolve login"></FooterLogoImg>
          <div className="platform">Platform Version - 1.06</div>
        </FooterLogo>
      </FooterLeft>
      <ModalContainer>
        <Modal
          width="50%"
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={true}
          modalContent={logoutContent}
        />
      </ModalContainer>
    </LeftSideNavDiv>
  );
};
