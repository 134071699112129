import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: inherit;

  input[type='text'],
  input[type='password'] {
    color: #959595;
    font-size: 14px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    height: 40px;
    padding: 18px;
    border-radius: 10px;
    border: 1px solid #000000;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    &:focus {
      color: #404040;
      border: 1px solid #000000;
    }
    &:valid {
      color: #404040;
      border: 1px solid #000000;
    }
    &:focus {
      &:not(:placeholder-shown) {
        &:invalid {
          border: 1px solid #ed5063;
        }
      }
    }
    // &:-webkit-autofill{
    //   -webkit-text-fill-background: yellow !important;
    // }
  }
  input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const InputField = styled.input.attrs(() => ({
  required: true,
}))`
  background: #fff;
  /* box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.06); */
  border-radius: 6px;
  border: 1px solid #000000;
  color: #474747;
  font-size: 14px;
  font-weight: 400;
  text-indent: 10px;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  &:focus {
    color: #404040;
  }
  &:valid {
    color: #404040;
  }
  input[type='password'] {
    background-image: url('../images/lock.png');
    background-position: left 10px center;
    background-repeat: no-repeat;
  }
  input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;
