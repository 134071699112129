/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import SelectMulti from 'react-select';
import Button from 'app/components/UI/atoms/Button';
import { Select } from 'app/components/UI/atoms/Select';
import { Header } from 'app/components/UI/organisms/Header';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
// import { useParams } from 'react-router-dom';
import {
  BodyDiv,
  TitleDiv,
  TopDiv,
  SearchWrap,
  ContentDiv,
  ErrorDiv,
  InpWrapper,
  ContentSubDiv,
} from './jobListingStyles';
// import { AssessmentTable } from './AssessmentTable';
// import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import { Input } from 'app/components/UI/atoms/Input';
import { useForm } from 'react-hook-form';
import { TextArea } from 'app/components/UI/atoms/TextArea';
import { commonPostCall } from 'app/services/auth/auth';
import { useNavigate } from 'react-router-dom';

export const PostJob = () => {
  const [selectedLocationOption, setSelectedLocationOption] = useState<any>();
  const [salVal, setSalValue] = useState('');
  const [curVal, setCurValue] = useState('');
  const [titleVal, setTitleVal] = useState('');
  const [goodToHaveVal, setGoodToHaveVal] = useState('');
  const [skillsReqVal, setSkillsReqVal] = useState('');
  const [jobDescVal, setJobDescVal] = useState('');
  const maxSalDigits = 9; // Set max salary in crores
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
  }, [reset]);

  const countries = [
    { value: 'india', label: 'India' },
    { value: 'us', label: 'U.S' },
    { value: 'uk', label: 'U.K' },
    { value: 'uae', label: 'U.A.E' },
    { value: 'singapore', label: 'Singapore' },
    { value: 'china', label: 'China' },
  ];

  const locations = [
    { value: 'mumbai', label: 'Mumbai' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Kolkata', label: 'Kolkata' },
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Bengaluru', label: 'Bengaluru' },
    { value: 'Hyderabad', label: 'Hyderabad' },
    { value: 'Ahmedabad', label: 'Ahmedabad' },
    { value: 'Pune', label: 'Pune' },
    { value: 'Surat', label: 'Surat' },
    { value: 'Jaipur', label: 'Jaipur' },
  ];

  const expOptions = [
    { label: 'Select Experience Level', value: '' },
    { label: 'Entry-level', value: 'entrylevel' },
    { label: 'Junior', value: 'junior' },
    { label: 'Mid-level', value: 'midlevel' },
    { label: 'Senior', value: 'senior' },
    { label: 'Principal/Lead', value: 'lead' },
    { label: 'Director', value: 'director' },
    { label: 'Vice President', value: 'vicepresident' },
    { label: 'Executive', value: 'executive' },
    { label: 'C-level (e.g., CEO, CTO, CFO)', value: 'clevel' },
    { label: 'Board-level', value: 'boardlevel' },
  ];
  const joinTimeOptions = [
    { label: 'Select Candidate join with in', value: '' },
    { label: '15 days or less', value: '15days' },
    { label: '1 Month', value: '1month' },
    { label: '2 Month', value: '2month' },
    { label: '3 Month', value: '3month' },
  ];

  const workTypeOptions = [
    { label: 'Work type', value: '' },
    { label: 'contract', value: 'Contract' },
    { label: 'permanent', value: 'Permanent' },
    { label: 'fresher', value: 'Fresher' },
    { label: 'internship', value: 'Internship' },
  ];
  const workModeOptions = [
    { label: 'Select work mode', value: '' },
    { label: 'workfromoffice', value: 'Work from Office' },
    { label: 'remote', value: 'Remote' },
    { label: 'hybrid', value: 'Hybrid' },
  ];

  const currencies = [
    { label: 'Currency', value: '' },
    { label: 'Rs', value: 'rupees' },
    { label: '$', value: 'dollars' },
    { label: 'Euro', value: 'euro' },
    { label: 'Yen', value: 'yen' },
  ];

  const onSubmit = handleSubmit(async (data: any) => {
    console.log('data', data);
    console.log('selectedLocationOption', selectedLocationOption);
    // data.role = role === 'js' ? 'job_seeker' : 'job_provider';
    const locations = selectedLocationOption.map(loc => {
      return loc.value;
    });

    const {
      jobTitle: title,
      jobDesc: description,
      experience,
      salary,
      workType,
      workMode,
      joinTime: noticePeriod,
    } = data;
    const postData = {
      title,
      description,
      locations,
      experience,
      salary,
      workType,
      workMode,
      skillsGoodToHave: ['Express, Python'],
      skillsRequired: ['Selenium', 'Manual Testing'],
      noticePeriod,
    };
    const url = '/dev/v1/job';
    const signUpResp = await commonPostCall(postData, url);
    if (
      signUpResp.name === 'AxiosError' ||
      signUpResp.code === 'ERR_BAD_REQUEST'
    ) {
      setError('email', {
        type: 'server',
        message: 'Something went wrong!',
      });
    } else {
      // setToken(loginResp?.result.accessToken);
      // setEmail(data.email);
      // navigate('/dashboard');
      // signUpToggle();
      // reset();
      // changeModalVisibility(isShowModal, true);
      navigate('/jobs', {
        replace: false,
      });
    }
  });

  const handleExpChange = val => {
    //Created for future functionalities
  };

  const handleCountryChange = val => {
    //Created for future functionalities
  };

  const handleMultiSelectChange = selectedOption => {
    console.log('here1', selectedOption);
    setSelectedLocationOption(selectedOption);
    console.log('selectedLocationOption --- 2', selectedLocationOption);
  };

  const handleCurChange = selectedOption => {
    console.log('here1', selectedOption);
    setCurValue(selectedOption);
    console.log('selectedCurrencyOption --- 3', curVal);
  };

  const handleSalChange = event => {
    const { value } = event.target;
    // Remove any non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, '');
    // Limit the value to 6 characters
    const truncatedValue = numericValue.slice(0, maxSalDigits);
    setSalValue(truncatedValue);
  };

  const handleMaxChars = event => {
    const { id, value } = event.target;
    switch (id) {
      case 'jobTitle':
        const jobTitleValue = value.slice(0, 30);
        setTitleVal(jobTitleValue);
        break;
      case 'goodToHave':
        const goodToHaveValue = value.slice(0, 30);
        setGoodToHaveVal(goodToHaveValue);
        break;
      case 'skillsReq':
        const skillsReqValue = value.slice(0, 30);
        setSkillsReqVal(skillsReqValue);
        break;
      case 'jobDesc':
        const jobDescValue = value.slice(0, 50);
        setJobDescVal(jobDescValue);
        break;
    }
  };

  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <form noValidate={true} onSubmit={onSubmit}>
              <SearchWrap className="job-post-wrapper">
                <TitleDiv>
                  <TopDiv>
                    <Button
                      type="submit"
                      content="Post the job"
                      className="job-post"
                    />
                  </TopDiv>
                </TitleDiv>
              </SearchWrap>
              <ContentDiv>
                <ContentSubDiv>
                  <h1>Post a Job</h1>
                  {errors?.jobTitle?.type === 'required' ? (
                    <ErrorDiv>Enter Job Title!</ErrorDiv>
                  ) : errors?.goodTohave?.type === 'required' ? (
                    <ErrorDiv>Enter good to have!</ErrorDiv>
                  ) : errors?.skills?.type === 'required' ? (
                    <ErrorDiv>Enter skills!</ErrorDiv>
                  ) : errors?.experience?.type === 'required' ? (
                    <ErrorDiv>Select experience level!</ErrorDiv>
                  ) : errors?.joinTime?.type === 'required' ? (
                    <ErrorDiv>Select candiate join time!</ErrorDiv>
                  ) : errors?.salary?.type === 'required' ? (
                    <ErrorDiv>Enter Salary!</ErrorDiv>
                  ) : errors?.workType?.type === 'required' ? (
                    <ErrorDiv>Select work type!</ErrorDiv>
                  ) : errors?.workMode?.type === 'required' ? (
                    <ErrorDiv>Select work mode!</ErrorDiv>
                  ) : errors?.locations?.type === 'required' ? (
                    <ErrorDiv>Select locations!</ErrorDiv>
                  ) : (
                    ''
                  )}
                  <InpWrapper className="post-job-content-flex">
                    {/* <HolderDiv> */}
                    <Input
                      type={'text'}
                      id={'jobTitle'}
                      value={titleVal}
                      placeholder="Job Title*"
                      {...register('jobTitle', {
                        required: true,
                      })}
                      onChange={handleMaxChars}
                      required
                    />
                    {/* <ReqPlaceholder>
                        Job Title <ReqSpan>*</ReqSpan>
                      </ReqPlaceholder>
                    </HolderDiv> */}
                    <Select
                      id="workType"
                      aria-label="workType"
                      className="rigt-select-drop"
                      // label={'experience'}
                      options={workTypeOptions}
                      {...register('workType', {
                        required: true,
                      })}
                      // onChange={e => handleExpChange(e.target.value)}
                    />
                  </InpWrapper>
                  <InpWrapper className="post-job-content-flex">
                    <Input
                      type={'text'}
                      id={'skillsReq'}
                      value={skillsReqVal}
                      placeholder="Skills Required*"
                      {...register('skills', {
                        required: true,
                      })}
                      onChange={handleMaxChars}
                    />
                    <Select
                      id="workMode"
                      aria-label="workMode"
                      className="select-drop width-margin"
                      // label={'experience'}
                      options={workModeOptions}
                      {...register('workMode', {
                        required: true,
                      })}
                      // onChange={e => handleExpChange(e.target.value)}
                    />
                  </InpWrapper>
                  <InpWrapper className="post-job-content-flex">
                    <Select
                      // defaultValue={ }
                      id="countries"
                      options={countries}
                      className="select-drop"
                      placeholder="Country*"
                      name="countries"
                      // {...register('locations', {
                      //   required: true,
                      // })}
                      onChange={e => handleCountryChange(e.target.value)}
                    />
                    <Input
                      type={'number'}
                      className="select-drop"
                      id="salary"
                      value={salVal}
                      maxLength={6}
                      placeholder="Salary"
                      {...register('salary', {
                        required: true,
                      })}
                      onChange={handleSalChange}
                    />
                  </InpWrapper>
                  <InpWrapper className="post-job-content-flex">
                    <Input
                      type={'text'}
                      id={'states'}
                      placeholder="State*"
                      {...register('state', {
                        required: true,
                      })}
                      onChange={handleMaxChars}
                    />
                    <Select
                      id="joinTime"
                      aria-label="joinTime"
                      className="right-select-drop"
                      // label={'experience'}
                      options={joinTimeOptions}
                      {...register('joinTime', {
                        required: true,
                      })}
                      onChange={e => handleExpChange(e.target.value)}
                    />
                  </InpWrapper>
                  <InpWrapper className="post-job-content-flex">
                    <SelectMulti
                      // defaultValue={ }
                      isMulti
                      options={locations}
                      className="location-multi-select"
                      classNamePrefix="select"
                      placeholder="Location (Multiselect)*"
                      name="locations"
                      // {...register('locations', {
                      //   required: true,
                      // })}
                      onChange={handleMultiSelectChange}
                    />
                    <Select
                      id="experience"
                      aria-label="experience"
                      className="select-drop exp"
                      // label={'experience'}
                      options={expOptions}
                      {...register('experience', {
                        required: true,
                      })}
                      onChange={e => handleExpChange(e.target.value)}
                    />
                  </InpWrapper>
                  <InpWrapper className="post-job-content-flex">
                    <Input
                      type={'text'}
                      id={'goodToHave'}
                      value={goodToHaveVal}
                      placeholder="Candidate good to have*"
                      {...register('goodTohave', {
                        required: true,
                      })}
                      onChange={handleMaxChars}
                    />
                    {/* {errors?.experience?.type === 'required' && (
                      <ErrorDiv>Select experience level!</ErrorDiv>
                    )} */}
                    <Select
                      className="select-drop"
                      options={currencies}
                      id="currency"
                      value={curVal}
                      maxLength={6}
                      placeholder="Currency"
                      {...register('currency', {
                        required: true,
                      })}
                      onChange={handleCurChange}
                    />
                  </InpWrapper>
                  <InpWrapper className="post-job-content-flex">
                    <InpWrapper className="textareawrapper">
                      <TextArea
                        label="Job Description"
                        rows={3}
                        className="jobdesc"
                        id={'jobDesc'}
                        value={jobDescVal}
                        placeholder="Job Description"
                        {...register('jobDesc', {
                          required: false,
                        })}
                        onChange={handleMaxChars}
                      ></TextArea>
                    </InpWrapper>
                  </InpWrapper>
                </ContentSubDiv>
              </ContentDiv>
            </form>
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
      {/* <ModalContainer>
        <Modal
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={false}
          modalContent={
            <AddQuestionModal
              categoryId={categoryId}
              onAddQuestionSubmit={onAddQuestionSubmit}
            />
          }
        />
      </ModalContainer> */}
    </>
  );
};
