import styled from 'styled-components';
import leftbgwrap from 'app/assets/images/leftbgwrap.svg';
import resultBg from 'app/assets/images/resultBg.png';

export const LogoImg = styled.img<any>`
  margin-left: 0;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 50px;
`;
export const RocketImg = styled.img<any>`
  margin-left: 0;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  position: absolute;
  left: -225px;
  top: -20px;
`;
export const MainWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url(${resultBg});
  width: 100%;
`;
export const LeftWrapper = styled.div<any>`
  width: 60%;
  display: flex;
  align-items: center;
  padding: 50px;
  flex-direction: column;
`;

export const RightWrapper = styled.div<any>`
  width: 40%;
  min-height: 100%;
  align-items: center;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background-image: url(${leftbgwrap});
  background-size: cover;
`;
export const Title1 = styled.h1`
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #393939;
`;
export const ResultMain = styled.div<any>`
  background: #ffffff;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.1);
  border-radius: 27px;
  padding: 50px;
  margin-right: auto;
  margin-left: auto;
  width: 960px;
  margin-bottom: 50px;
`;

export const ClearDiv = styled.div`
  clear: both;
`;
export const ErrorDiv = styled.span`
  color: #e92626;
  font-size: 14px;
`;
