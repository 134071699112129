import styled from 'styled-components';
import goldIcon from 'app/assets/images/gold.png';

export const HomeHeader = styled.div<any>`
  background-color: #fdb82c;
  padding: 20px;
`;

export const MenuDiv = styled.div<any>`
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  background-image: url(../images/hum.png);
  background-size: 30px;
  background-color: #000000;
  padding: 5px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  :before {
    top: -7px;
  }
  :after {
    bottom: -7px;
  }
`;

export const HomeHeaderSubDiv = styled.div<any>`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DivSlider = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 50px;
`;
export const SlidesDiv = styled.div`
  display: flex;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  text-align: left;
  h1,
  h2 {
    color: #000000;
    font-size: 28px;
    text-align: left;
  }
`;
export const Slides = styled.div`
  flex: 0 0 100%;
  width: 100%;
  text-align: left;
  font-size: 18px;
  button {
    background-color: #000000;
    padding: 10px 40px;
    color: #ffffff;
    font-size: 14px;
    margin-top: 30px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    :nth-of-type(2) {
      margin-left: 20px;
    }
  }
`;

export const SlidePara = styled.p`
  text-align: left;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  width: 50%;
`;
export const MainContentDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 50px 20px;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const MainContentSubDiv = styled.div`
  padding: 20px;
  box-shadow: 0px 0px 15px 0px #0000000d;
  border: 1px solid #dbdbdb;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: calc(20% - 20px);
  /* Adjust the width calculation to account for the 20px space */
  margin-right: 20px;
  :last-child {
    margin-right: 0;
  }
  h3 {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }
  h4 {
    font-size: 20px;
    margin-top: 10px;
  }
  h5 {
    font-size: 17px;
    margin-top: 5px;
  }
  @media (max-width: 768px) {
    padding: 20px;
    width: 90%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;
export const ImgAnimation = styled.img<any>`
  animation: heart-pump 2s infinite;
`;
export const WrapperDiv = styled.div`
  position: relative;
  background-color: #fdb82c;
  padding: 60px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const TechWrapDiv = styled.div`
  position: relative;
  padding: 16px;
  width: 25%;
  text-align: center;
  @media (max-width: 1000px) {
    width: 250px;
    height: 250px;
    background-image: url(../images/varma.png);
    background-size: 176px;
    background-repeat: no-repeat;
    margin: 40px auto;
    border-radius: 200px;
    border: 10px solid grey;
    background-position: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    animation: heartPump 1.5s ease-in-out infinite;
  }
`;

export const MainFooterDiv = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;
  gap: 20px;
  align-items: flex-start;
  background-color: #000000;
  color: #ffffff;
  padding: 60px;
  line-height: 29px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    img.rocket {
      right: 0px !important;
    }
  }
  img.rocket {
    /* right: 93px !important; */
    animation: rocket 4s linear infinite;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0px auto;
    @keyframes rocket {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-1000px);
      }
    }
    @media (max-width: 768px) {
      right: 0px !important;
    }
  }
`;

export const MainFooterDivContent = styled.div`
  width: auto;
`;

export const CommWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const CommWrapSubDiv = styled.div`
  display: flex;
  align-items: self-start;
  h1 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
    margin-top: 15px;
  }
`;
export const CommWrapSubFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  @keyframes heartPump {
    margin-left: 0px;
  }
`;

export const CultDiv = styled.div`
  display: flex;
  align-items: self-start;
  margin-top: 50px;
  h1 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
    margin-top: 15px;
  }
`;
export const CultFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  @keyframes heartPump {
    margin-left: 0px;
  }
`;
export const FooterContentDiv = styled.div`
  padding: 10px;
  text-align: left;
  p {
    width: 80%;
  }
`;

export const PhoneContainerSpan = styled.span`
  font-size: 13px;
  text-decoration: underline;
  margin-top: 40px;
  display: block;
`;
export const EmailContainerSpan = styled.span`
  font-size: 13px;
  text-decoration: underline;
  margin-top: 10px;
  display: block;
`;
export const FooterContent3Div = styled.div`
  padding: 10px;
  text-align: left;
  ul li {
    list-style: none;
    cursor: pointer;
    font-size: 12px;
  }
  ul li a {
    color: #ffffff;
  }
  span {
    font-size: 13px;
    margin-top: 40px;
    display: block;
  }
`;
/////////////

export const LogoDiv = styled.div`
  position: absolute;
  left: 40px;
  @media (max-width: 768px) {
    position: initial;
    margin-bottom: 30px;
  }
`;
export const LogoImg = styled.img<any>`
  margin-left: 20px;
  :hover {
    cursor: pointer;
  }
`;

export const ButtonGroupDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  button {
    border-radius: 21px;
    width: 217px;
    line-height: 34px;
    background: #000000;
    border: none;
    cursor: pointer;
    padding: 10px 0px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    font-size: 20px;
    @media (max-width: 768px) {
      width: 150px;
      padding: 20px 0px;
      line-height: 20px;
    }
  }
  button.job-listing-sign-btn1 {
    margin-bottom: 100px;
  }
`;
export const LineTextDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
`;
export const LineHr = styled.hr`
  flex-grow: 1;
  border: none;
  border-top: 1.5px solid black;
  width: 122px;
  margin: 10px 10px;
`;

export const RightSecDiv = styled.div`
  width: 50%;
  padding: 30px 40px 100px 40px;
  background-color: #242424;
  min-height: 100vh;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ClearDiv = styled.div`
  clear: both;
`;

export const MainList = styled.ul`
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
`;

export const MainLi = styled.li`
  float: left;
  a {
    display: block;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    padding: 14px 16px;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const RightSingUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  button {
    width: 217px;
    height: 50px;
    border-radius: 21px;
    background: #fdb82c;
    border: none;
    cursor: pointer;
    padding: 10px 0px;
    text-align: center;
    font-weight: bold;
    color: #000000;
    font-size: 20px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
  }
`;

export const SignUpWrapperH1 = styled.h1`
  color: #ffffff;
  font-size: 30px;
  width: 341px;
  text-align: center;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  .free-text {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 25px;
    margin-top: 0px;
    width: auto;
  }
`;
///

export const JobListingContainer = styled.div`
  width: 100%;
  /* display: flex; */
  /* overflow: auto; */
  min-height: 100vh;
  /* align-items: center; */
  /* flex-direction: column; */
  font-family: 'Montserrat', sans-serif;
  background-color: #232323;
`;

export const JobListingHomeHeader = styled.header`
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 30px 0px 30px;
`;

export const JobListingHomeHeaderLogo = styled.div`
  width: 100px;
`;

export const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  a {
    position: relative;
    color: #fff;
    text-decoration: none;
    margin-left: 20px;
  }
  a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: #fff;
    transition: width 0.3s ease;
  }
  a:hover::after {
    width: 100%;
  }
  a:hover {
    color: #ffc107;
  }
`;
export const Menu = styled.nav`
  display: none;
  cursor: pointer;
`;
export const LoginContentWrapper = styled.div`
  padding: 0px 40px 40px 40px;
  background-image: url(${goldIcon});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
  h1 {
    white-space: nowrap;
  }
  h1,
  h2,
  h3 {
    color: #ffffff;
    font-weight: normal;
    font-size: 60px;
    line-height: 1.6em;
  }
  h4 {
    color: #ffffff;
    font-weight: normal;
    font-size: 20px;
    margin-top: 40px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    h1,
    h2,
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 14px;
      margin-top: 20px;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1028px) {
    h1 {
      white-space: normal;
    }
  }
`;
export const LoginHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const JumpSpan = styled.span`
  display: inline-block;
  color: #ffc107;
  font-weight: bold;
  font-size: 50px;
  animation: jump 0.5s infinite;
  @keyframes jump {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
      /* Adjust the jump height as needed */
    }

    100% {
      transform: translateY(0);
    }
  }
`;
export const JumpImg = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  img {
    width: 300px;
    margin-top: 200px;
    position: absolute;
    right: -64px;
  }
  @media screen and (max-width: 768px) {
    img {
      width: 200px;
      height: fit-content;
      margin-bottom: 40px;
      margin-top: 40px;
      position: revert;
    }
  }
  @media screen and (min-width: 1400px) {
    img {
      width: 500px;
      margin-top: 50px;
      right: -177px;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1028px) {
    img {
      width: 187px;
      margin-top: 0;
      position: revert;
    }
  }
`;
export const LoginFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 100px;
  margin-top: 40px;
  form {
    margin-left: 20px;
  }
  button {
    width: 300px;
    height: 50px;
    font-size: 14px;
    font-weight: 600;
  }
  @media screen and (max-width: 768px) {
    form {
      margin: 0px;
    }
  }
  .otp-container {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 4px;
    padding: 0px 10px 5px 0px;
    margin-bottom: 20px;
  }
`;
export const InputContainer = styled.div`
  position: relative;
  /* margin-bottom: 20px; */
  display: flex;
  width: 300px;
  margin-top: 10px;
  input {
    padding: 10px 30px 10px 15px;
    border: 1px solid #ccc;
    background: #eaf0f7;
    border-radius: 4px;
    outline: none;
    width: 100%;
  }
  img {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  input.otp-input {
    margin-bottom: 20px;
    width: 60px !important;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 20px;
    padding: 10px 0px 10px 0px;
    justify-content: center;
  }
  input.otp-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
export const OrWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  margin-top: 20px;
  white-space: nowrap;
`;
export const DashDiv = styled.div`
  width: 60px;
  height: 1px;
  background: #ffffff;
  margin: 8px;
`;
export const FooterDiv = styled.div`
  color: #ffffff;
  margin-left: 30px;
  position: absolute;
  bottom: 20px;
  @media screen and (max-width: 768px) {
    position: relative;
    bottom: 0;
    left: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
`;
export const SignInButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #fdb82b;
  border: none;
  border-radius: 4px;
  color: #3d3d3d;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 40px;
  :hover {
    text-decoration: underline;
  }
`;
export const ResetButton = styled.a`
  display: block;
  text-align: right;
  margin-bottom: 15px;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
`;
