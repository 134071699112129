import React from 'react';
import { PageFooter } from 'app/components/UI/atoms/Footer';
import { FooterDiv, FullPageWrapper } from './PageWrapperStyles';

export const PageWrapper = (props: {
  children: any;
  justifyContent: string;
}) => {
  const { children, justifyContent } = props;
  return (
    <FullPageWrapper property={justifyContent}>
      {children}
      <FooterDiv>
        <PageFooter />
      </FooterDiv>
    </FullPageWrapper>
  );
};
