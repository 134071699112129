import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Label, Wrapper } from './SelectStyles';

export type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

export interface Props extends SelectProps {
  id?: string;
  label?: string;
  className?: string;
  placeholder?: string;
  value?: any;
  options?: any[] | any;
}

export const Select = forwardRef<HTMLSelectElement, Props>(
  (
    {
      id,
      className,
      placeholder,
      label,
      options,
      value,
      onChange,
      ...props
    }: Props,
    ref,
  ) => {
    return (
      <Wrapper className={className}>
        <select
          ref={ref}
          id={id}
          value={value}
          placeholder="Select"
          onChange={onChange}
          {...props}
          required
        >
          {options &&
            options.map((opt: any, i) => {
              return (
                <OptionDiv
                  key={i}
                  label={opt.label}
                  value={opt.value}
                  {...opt}
                />
              );
            })}
        </select>
        <Label htmlFor={id}>{label}</Label>
      </Wrapper>
    );
  },
);

const OptionDiv = styled.option`
  background: white;
  color: #3d3d3d;
`;
