import styled from 'styled-components';
import { device } from 'styles/media';

export const FullPageWrapper = styled.div`
  width: inherit;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: ${props => props.property};
  background: transparent;
  background-color: #3d3d3d;
  @media ${device.mobileL} {
    width: 100%;
    padding: 0 20px;
  }
  @media ${device.mobileM} {
    width: 100%;
    padding: 0 20px;
  }
  @media ${device.mobileS} {
    width: 100%;
    padding: 0 20px;
  }
`;

export const FooterDiv = styled.div`
  margin-top: 40px;
  bottom: 20px;
`;
