import React from 'react';
import { FunctionComponent } from 'react';
import Button from '../../atoms/Button';
import { PrimaryParagraph } from '../../atoms/Paragraph';
import { Title3 } from '../../atoms/Titles';
import {
  ButtonGroup,
  CancelButton,
  ConfirmButton,
  ModelContentWrapper,
} from 'app/components/UI/Modal/confirmationModal/ConfirmModalStyles';
interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  message: string;
  cancelButtonContent: string;
  confirmButtonContent: string;
}

export const ConfirmationModal: FunctionComponent<
  ConfirmationModalProps
> = props => {
  return (
    <ModelContentWrapper>
      <Title3 className="logout-heading">{props.title}</Title3>
      <PrimaryParagraph>{props.message}</PrimaryParagraph>
      <ButtonGroup>
        <CancelButton>
          <Button
            type="button"
            className="logoutmodalbuttons"
            content={props.cancelButtonContent}
            onClick={props.onCancel}
            bgColor="#fff"
          />
        </CancelButton>
        <ConfirmButton>
          <Button
            type="button"
            className="logoutmodalbuttons"
            content={props.confirmButtonContent}
            onClick={props.onConfirm}
            bgColor="#fdb82c"
          />
        </ConfirmButton>
      </ButtonGroup>
    </ModelContentWrapper>
  );
};
