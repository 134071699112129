import React from 'react';
import { MainWrapper, Title1, ResultMain } from './careerResultStyles';
// import evolve from 'app/assets/images/evolve.png';
//import evolve from 'app/assets/images/evolve.png';
// import Captcha from 'app/components/UI/molecules/Captcha';
import { useForm } from 'react-hook-form';
import { Stepper } from 'app/components/UI/atoms/Stepper';

export const CareerResult = () => {
  // const [email, setEmail] = useState<string>('');

  // if (typeof token !== 'undefined' && token !== null) {
  //   navigate('/dashboard', { replace: true });
  // }
  const { reset } = useForm();

  React.useEffect(() => {
    reset();
  }, [reset]);

  const ArryList: string[] = [
    "Apply to Google: When you feel ready, you can apply to Google through their careers website. It's important to tailor your resume and cover letter to the position you're applying for and to showcase your relevant skills and experience",
    'Gain hands-on experience: Start building projects and contributing to open-source projects to gain practical experience. You can also look for internships or co-op opportunities to work on real-world projects and get exposure to the industry.,',
    "Apply to Google: When you feel ready, you can apply to Google through their careers website. It's important to tailor your resume and cover letter to the position you're applying for and to showcase your relevant skills and experience",
  ];
  return (
    <MainWrapper>
      {/* <LogoImg src={evolve} alt="evolve login" /> */}
      <ResultMain>
        <Title1 style={{ marginBottom: '20px' }}>
          <h5>{`Here's a suggested career path to help you achieve that:`}</h5>
        </Title1>
        {ArryList.map((item, index) => (
          <Stepper id="0" index={index.toString()} resultData={item} />
        ))}
      </ResultMain>
    </MainWrapper>
  );
};
