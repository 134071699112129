import React from 'react';
import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableTd,
  TableTh,
  TableWrapper,
} from './ListStyles';
import Button from 'app/components/UI/atoms/Button';
// import { getJobById } from 'app/services/jobs';
import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import {
  Message,
  ModelContent,
} from 'app/components/UI/Modal/MessageModelStyles';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import { Title4 } from 'app/components/UI/atoms/Titles';
import DownloadIcon from 'app/assets/images/download-resume.svg';
// import { useHistory, Redirect } from 'react-router-dom';
// import { update } from 'app/services/';
export const JobListTable = ({ headers, rowData, role }) => {
  const { toggle } = useModal();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [status, setStatus] = useState<Array<string>>([]);
  // const [action, setAction] = useState<boolean>(false);
  // const [id, setId] = useState<number>();
  // const [contentData, setContentData] = useState<any>();
  const changeModalVisibility = (nval = !isShowModal, isSuccess = false) => {
    if (nval === true || nval === false) {
      setIsShowModal(nval);
    } else {
      setIsShowModal(false);
    }

    if (isSuccess === true) {
      toggle();
    }
  };
  const showJobDetails = async (JobId: string) => {
    // const jobResp = await getJobById(JobId);
    // setContentData(jobResp);
    toggle();
  };

  // const closeJobAction = () => {
  //   changeModalVisibility();
  //   // implement here
  //   // const closeResp = await closeTheJob();
  // };
  const closeJobModal = (
    <ModelContent>
      {/* <MailIcon>
          <img src={SuccessIcon} alt="mail-icon" width="80px" height="50px" />
        </MailIcon> */}
      <Message>
        <Title4>Are you sure want to close the job?</Title4>
        <PrimaryParagraph>
          Once you close the job, system will automatically closed the job on
          Fairhiree platform and your job listing page as well!
        </PrimaryParagraph>
        <Button
          content=" No"
          type="button"
          bgType="secondary"
          onClick={() => changeModalVisibility()}
        />
        <Button content="Yes" type="button" bgColor="#fdb82c" />
      </Message>
    </ModelContent>
  );
  console.log(closeJobModal);
  // const closeJobModal = (
  //   <CloseModal>
  //     <CloseTitle>Are you sure want to close the job?</CloseTitle>
  //     <ClosePara>
  //       Once you close the job, system will automatically closed the job on
  //       Fairhiree platform and your job listing page as well!
  //     </ClosePara>
  //     <Button
  //       content=" No"
  //       type="button"
  //       bgType="secondary"
  //       onClick={() => changeModalVisibility()}
  //     />
  //     <Button content="Yes" type="button" bgColor="#fdb82c" />
  //   </CloseModal>
  // );

  useEffect(() => {
    rowData &&
      rowData.length > 0 &&
      rowData.map(ques => {
        if (ques.status === 'ACTIVE') {
          status.push(ques.id);
          setStatus(status);
        }
        return ques;
      });
  }, [rowData, status]);

  return (
    <TableWrapper>
      {/* {redirectToques ? (
        <Redirect
          to={{
            pathname: '/ques',
            state: { quesId: redirectToques },
          }}
        />
      ) : null} */}
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableTh key={header.id}>{header.name}</TableTh>
            ))}
            {/* <TableTh>Action</TableTh> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData &&
            rowData.length > 0 &&
            rowData.map((row, index) => {
              // const days = Math.floor(
              //   (new Date().getTime() - ques.dateUpdated) / 1000 / 60 / 60 / 24,
              // );
              return (
                <TableRow key={row.id}>
                  {role !== 'user' ? (
                    <>
                      <TableTd>{row.name}</TableTd>
                      <TableTd>{row.email}</TableTd>
                    </>
                  ) : (
                    ''
                  )}
                  <TableTd>{row.testName}</TableTd>
                  <TableTd>{row.receivedDate}</TableTd>
                  <TableTd>{row.completedDate}</TableTd>
                  <TableTd>{row.score}</TableTd>
                  <TableTd>
                    <Button
                      content="Download Report"
                      className="download-resume"
                      type={'button'}
                      bgColor="#fff"
                      onClick={() => {
                        showJobDetails(row.id);
                      }}
                      bgImage={DownloadIcon}
                    ></Button>
                    {/* {<Switch id={row.id} checked={row.status} />} */}
                  </TableTd>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
