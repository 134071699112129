import styled from 'styled-components';
import iconPlus from 'app/assets/images/iconPlus.svg';

export const ModalDiv = styled.div`
  background-color: #fefefe;
  /* margin: auto; */
  padding: 20px;
  border-radius: 6px;
  width: auto;
  @media (min-width: 1010px) {
    /* width: 1000px; */
  }
  @media (max-width: 1009px) {
    width: 500px;
  }
  h1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #3d3d3d;
    text-align: left;
  }
  select {
    cursor: pointer;
    height: 36px;
    border-radius: 5px;
    @media (max-width: 1009px) {
      width: 220px;
    }
  }
`;

export const SubDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-top: 15px;
  button {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    width: 118px;
    height: 38px;
    background-color: #fdb82c;
    background-image: url(${iconPlus});
    background-position: center left 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border-radius: 5px;
    color: #000000;
    margin-top: 0px;
    text-indent: 14px;
  }
  .techWrapper {
    width: 25%;
    margin-right: 20px;
  }
  .levelWrapper {
    width: 25%;
  }
`;

export const ModalFlexDiv = styled.div`
  display: flex;
  padding-bottom: 20px;
  margin-top: 15px;
  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;
export const ModalTextFlex = styled.div`
  padding-right: 20px;
  @media (max-width: 1010px) {
    width: 100%;
  }
  @media (max-width: 1010px) {
    width: 420px;
  }
`;
export const ModalTextFlex2 = styled.div``;
export const InputWrapperDiv = styled.div`
  position: relative;
  width: 100%;
  margin-right: 20px;
  button {
    width: 24px !important;
    cursor: pointer !important;
    height: 26px !important;
    background: #000000 !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-size: 25px !important;
    line-height: 21px !important;
    position: absolute !important;
    right: 0 !important;
    top: 7px !important;
  }
  @media (min-width: 1010px) {
    width: 420px;
  }
  @media (max-width: 1010px) {
    width: 100%;
  }
  input {
    width: 70%;
    height: 36px;
    margin-right: 20px;
    text-indent: 10px;
  }
`;

export const ModalTagFlex = styled.div`
  display: flex;
  button {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    width: 118px;
    height: 38px;
    background-color: #fdb82c !important;
    background-image: url('../images/plus.svg') !important;
    background-position: center left 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border-radius: 5px;
    color: #000000;
    margin-top: 0px;
  }
`;
export const ModalEdit = styled.div`
  margin-top: 30px;
  max-height: 200px;
  overflow-y: auto;
  width: 560px;
  @media (max-width: 1009px) {
    width: 100%;
  }
`;

export const FrameDiv = styled.div`
  width: auto;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
  button {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
`;
export const QuesFrameDiv = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-bottom: 10px;
`;

export const DivQues = styled.div``;
export const DivQuesText = styled.div``;
export const ButtonDiv = styled.div`
  display: flex;
  justify-content: right;
  .duration {
    width: 114px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #141414;
    margin-right: 10px;
    cursor: pointer;
  }

  .cancel {
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #141414;
    margin-right: 10px;
    width: 114px;
    height: 38px;
    background: #ffffff;
  }

  .save {
    width: 94px;
    height: 38px;
    background: #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
  }
`;

export const ContentSubDiv = styled.div`
  background: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  padding: 25px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  height: 500px;
  h1 {
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 16px;
    color: #3d3d3d;
  }
  button {
    float: right;
  }

  .textareawrapper {
    width: 100%;
    display: block;
  }
  select {
    padding: 0px 18px 0px 5px !important;
  }
`;
