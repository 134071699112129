import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from 'app/components/UI/atoms/Input';
import Button from 'app/components/UI/atoms/Button';
import { Title1 } from 'app/components/UI/atoms/Titles';
import {
  LoginMain,
  ClearDiv,
  ErrorDiv,
  // SubTitle,
  // SubTitle2,
  // HeadWrapDiv,
} from './loginStyles';
import {
  getLogin,
  setToken,
  setEmail,
  getToken,
  sendOtp,
  verifyOtp,
  setPassword,
} from 'app/services/auth/auth';
import { useForm } from 'react-hook-form';
// import { A } from 'app/components/UI/atoms/Anchor';
import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import { ModalContainer } from 'app/components/UI/Modal/MessageModelStyles';
import { Modal } from 'app/components/UI/Modal/SimpleModal';
import { SignUpModal } from '../SignUpModal';
import buttonLoader from 'app/assets/images/loader-yellow.svg';
import {
  getUsersProfile,
  setProfileImage,
  setUserId,
} from 'app/services/users';
import { Snackbar, Alert } from '@mui/material';

export const LoginModal = ({ toggle: LoginToggle, role }) => {
  const token = getToken();
  const [isLoading, setLoading] = useState<boolean>(false);
  let navigate = useNavigate();
  const { isShown, toggle } = useModal();
  const [modalType, setModalType] = useState<string>('login');
  const [open, setOpen] = React.useState(true);
  const [userEmail, setUserEmail] = useState<string>('');

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // const callSignUp = async () => {
  //   // LoginToggle();
  //   toggle();
  //   // setTimeout(() => {
  //   //   // LoginToggle();
  //   //   toggle();
  //   // }, 2000);
  // };

  if (typeof token !== 'undefined' && token !== null) {
    navigate('/assessments', { replace: false });
  }
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
    getValues,
  } = useForm();

  React.useEffect(() => {
    reset();
  }, [reset]);

  const sendOtpHandler = async () => {
    try {
      setLoading(true);
      const emailValue = getValues('email');
      await sendOtp(emailValue);
      setModalType('resetPwd');
      setUserEmail(emailValue);
      setLoading(false);
    } catch (err) {
      // Handle errors or show an error message
      setError('email', {
        type: 'server',
        message: 'Couldnt send otp!',
      });
      console.error('Error sending OTP:', err);
      setLoading(false);
    }
  };

  const changePasswordHandler = async () => {
    try {
      setLoading(true);
      const otp = getValues('otp');
      const password = getValues('password');
      const repassword = getValues('repassword');
      if (password !== repassword) {
        return setError('password', {
          type: 'client',
          message: 'Passwords doesnt match',
        });
      }
      const { userId, token } = await verifyOtp(userEmail, otp);
      await setPassword(userId, password, token);
      setLoading(false);
      // toggle();
      setModalType('login');
    } catch (err) {
      setError('repassword', {
        type: 'server',
        message: 'Couldnt set new password!',
      });
    }
  };

  const onSubmit = handleSubmit(async (data: any) => {
    // data.role = role === 'js' ? 'job_seeker' : 'job_provider';
    setLoading(true);
    const loginResp = await getLogin(data);
    if (
      loginResp.name === 'AxiosError' ||
      loginResp.code === 'ERR_BAD_REQUEST'
    ) {
      setError('email', {
        type: 'server',
        message: 'Something went wrong!',
      });
    } else {
      setToken(loginResp?.token);
      setEmail(data.email);
      const userProfileData = await getUsersProfile();
      if (userProfileData?.id) {
        setUserId(userProfileData?.id);
      }
      if (userProfileData?.profileUrl) {
        setProfileImage(userProfileData?.profileUrl);
      }

      navigate('/assessments');
      reset();
    }
    setLoading(false);
  });

  if (role === 'js') {
    return (
      // <LoginWrapper>
      // <LogoImg src={evolve} alt="evolve login" />
      <LoginMain>
        <Title1>Coming soon...</Title1>
        <span>
          We are as excited as you. Keep watching this space. We will have this
          feature released soon.
        </span>
        <ModalContainer>
          <Modal
            isShown={isShown}
            width="fit-content"
            hide={toggle}
            isShowCloseButton={true}
            modalContent={
              <SignUpModal
                changeModalVisibility={toggle}
                role={role}
                isShowModal={isShown}
              />
            }
          />
        </ModalContainer>
      </LoginMain>
    );
  }

  if (modalType === 'forgotPwd') {
    return (
      // <LoginWrapper>
      // <LogoImg src={evolve} alt="evolve login" />
      <LoginMain>
        <Title1>Forgot Password </Title1>

        <form className="Input-Wrapper" noValidate={true} onSubmit={onSubmit}>
          <Input
            id="email"
            type="text"
            label="Please enter User name / Email"
            placeholder="User name / Email"
            {...register('email', {
              required: true,
            })}
            required
            // onChange={(e) => setPassword(e.target.value)}
          />

          {/* <Input
            id="password"
            type="password"
            label="Password"
            placeholder="Password"
            {...register('password', {
              required: true,
            })}
            required
          //onChange={(e) => setPassword(e.target.value)}
          /> */}
          {errors?.email?.type === 'server' ? (
            <ErrorDiv>
              {(errors?.email?.message as string) ||
                'Incorrect email or password!'}
            </ErrorDiv>
          ) : (
            ''
          )}
          {errors?.email?.type === 'required' ? (
            <ErrorDiv>Enter user email!</ErrorDiv>
          ) : (
            ''
          )}
          {/* <Button
            type="button"
            content="Forgot Password?"
            bgType="secondary"
            className="forgot"
          /> */}
          <ClearDiv />
          {/* <Captcha />
            <ClearDiv /> */}
          <Button
            type="button"
            content="Send OTP"
            leftIcon={isLoading === true ? buttonLoader : ''}
            disabled={isLoading === true}
            onClick={sendOtpHandler}
          />
        </form>
        <ModalContainer>
          <Modal
            isShown={isShown}
            width="fit-content"
            hide={toggle}
            isShowCloseButton={true}
            modalContent={
              <SignUpModal
                changeModalVisibility={toggle}
                role={role}
                isShowModal={isShown}
              />
            }
          />
        </ModalContainer>
      </LoginMain>
    );
  } else if (modalType === 'resetPwd') {
    return (
      // <LoginWrapper>
      // <LogoImg src={evolve} alt="evolve login" />
      <LoginMain>
        <Title1>Forgot Password </Title1>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            OTP has been sent to your email.
          </Alert>
        </Snackbar>
        <form className="Input-Wrapper" noValidate={true} onSubmit={onSubmit}>
          <Input
            id="otp"
            type="text"
            label="Please enter your received OTP"
            placeholder="Enter OTP"
            {...register('otp', {
              required: true,
            })}
            required
            // onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            id="password"
            type="password"
            label="Please enter your new password"
            placeholder="New password"
            {...register('password', {
              required: true,
            })}
            required
            // onChange={(e) => setPassword(e.target.value)}
          />

          <Input
            id="repassword"
            type="password"
            label="Retype Password"
            placeholder="Retype Password"
            {...register('repassword', {
              required: true,
            })}
            required
            //onChange={(e) => setPassword(e.target.value)}
          />
          {errors?.password?.type === 'client' ? (
            <ErrorDiv>
              {(errors?.password?.message as string) ||
                'Incorrect email or password!'}
            </ErrorDiv>
          ) : (
            ''
          )}
          {errors?.email?.type === 'required' ? (
            <ErrorDiv>Enter user email!</ErrorDiv>
          ) : (
            ''
          )}
          {/* <Button
            type="button"
            content="Forgot Password?"
            bgType="secondary"
            className="forgot"
          /> */}
          <ClearDiv />
          {/* <Captcha />
            <ClearDiv /> */}
          <Button
            type="button"
            content="Submit"
            leftIcon={isLoading === true ? buttonLoader : ''}
            disabled={isLoading === true}
            onClick={changePasswordHandler}
          />
        </form>
        <ModalContainer>
          <Modal
            isShown={isShown}
            width="fit-content"
            hide={toggle}
            isShowCloseButton={true}
            modalContent={
              <SignUpModal
                changeModalVisibility={toggle}
                role={role}
                isShowModal={isShown}
              />
            }
          />
        </ModalContainer>
      </LoginMain>
    );
  } else {
    return (
      // <LoginWrapper>
      // <LogoImg src={evolve} alt="evolve login" />
      <LoginMain>
        <Title1>WELCOME </Title1>

        <form className="Input-Wrapper" noValidate={true} onSubmit={onSubmit}>
          <Input
            id="email"
            type="text"
            label="Please enter User name / Email"
            placeholder="User name / Email"
            {...register('email', {
              required: true,
            })}
            required
            // onChange={(e) => setPassword(e.target.value)}
          />

          <Input
            id="password"
            type="password"
            label="Password"
            placeholder="Password"
            {...register('password', {
              required: true,
            })}
            required
            //onChange={(e) => setPassword(e.target.value)}
          />
          {errors?.email?.type === 'server' ? (
            <ErrorDiv>Incorrect email or password!</ErrorDiv>
          ) : (
            ''
          )}
          {errors?.email?.type === 'required' ? (
            <ErrorDiv>Enter user email!</ErrorDiv>
          ) : errors?.password?.type === 'required' ? (
            <ErrorDiv>Enter password!</ErrorDiv>
          ) : (
            ''
          )}
          <Button
            type="button"
            content="Forgot Password?"
            bgType="secondary"
            className="forgot"
            onClick={() => {
              setModalType('forgotPwd');
            }}
          />
          <ClearDiv />
          {/* <Captcha />
            <ClearDiv /> */}
          <Button
            type="submit"
            content="Sign In"
            leftIcon={isLoading === true ? buttonLoader : ''}
            disabled={isLoading === true}
          />
        </form>
        <ModalContainer>
          <Modal
            isShown={isShown}
            width="fit-content"
            hide={toggle}
            isShowCloseButton={true}
            modalContent={
              <SignUpModal
                changeModalVisibility={toggle}
                role={role}
                isShowModal={isShown}
              />
            }
          />
        </ModalContainer>
      </LoginMain>
    );
  }
};
