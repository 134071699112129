import styled from 'styled-components';
import searchIcon from 'app/assets/images/searchIcon.svg';

export const BodyDiv = styled.div`
  width: 100%;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #3d3d3d;
  }
  .jobs-wrapper {
    display: flex;
    justify-content: space-between;
  }
`;
export const OopsSpan = styled.span`
  display: table;
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 131%;
  color: #3d3d3d;
`;

export const AddCategoryModal = styled.div`
  width: 480px;
  @media (max-width: 800px) {
    width: 100%;
  }
  input {
    top: 20px;
    width: 380px;
  }
`;

export const SearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: left;
  select {
    width: 133px;
    cursor: pointer;
    margin-left: 20px;
    height: 36px;
  }
  h5 {
    padding-right: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #3d3d3d;
  }
  button {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    height: 38px;
    width: auto;
    background-position: center left 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border-radius: 5px;
  }
`;
export const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    text-align: left;
  }
`;
export const TopDiv = styled.div`
  display: flex;
  align-items: center;
  .job-post {
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgba(0, 0, 0, 0.7);
  }
  input {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-right: -8px;
    width: 200px;
    height: 38px;
    text-indent: 10px;
    margin-bottom: 0px;
  }
  & select {
    width: 133px;
    cursor: pointer;
    margin-left: 20px;
    height: 36px;
  }
`;

export const ContentDiv = styled.div`
  .post-job-content-flex {
    margin-bottom: 20px;
  }

  #workMode {
    margin-right: 3%;
  }
`;
export const ContentSubDiv = styled.div`
  background: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  padding: 25px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  h1 {
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 16px;
    color: #3d3d3d;
  }
  button {
    float: right;
  }

  .textareawrapper {
    width: 100%;
    display: block;
  }
  select {
    padding: 0px 18px 0px 5px !important;
  }
`;
export const ErrorDiv = styled.span`
  color: #e92626;
  font-size: 14px;
`;

export const ClearDiv = styled.div`
  clear: both;
`;

export const InpWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  div.location-multi-select {
    width: 100%;
    margin-right: 3%;
    font-size: 14px;
  }
  div.select-drop {
    position: relative;
    width: 100%;
    height: 40px;
    margin-right: 3%;
  }

  .right-select-drop {
    position: relative;
    width: 100%;
    height: 40px;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 38px;
    text-indent: 10px;
    :nth-child(1) {
      margin-right: 3%;
    }
  }
  textarea {
    height: 40px;
    border-radius: 5px;
    resize: none;
    font-family: 'Montserrat', sans-serif;
  }
  select {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 38px;
    cursor: pointer;
    width: 100%;
  }
`;

export const SearchInpDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  button {
    background: #fdb82c;
    height: 39px;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0;
    background-image: url(${searchIcon});
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 0px;
    width: 70px;
  }
  input {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-right: -8px;
    width: 200px;
    height: 38px;
    text-indent: 10px;
    margin-bottom: 0px;
  }
  button {
    width: 40px;
  }
  & select {
    width: 133px;
    cursor: pointer;
    margin-left: 20px;
    height: 36px;
  }
  .skills-input {
    width: 25% !important;
    margin-right: 20px;
    border-radius: 20px;
    padding: 20px 0px;
  }
  .location-input {
    width: 25% !important;
    margin-right: 0px;
    border-radius: 20px;
    padding: 20px 0px;
  }
  .search-icon {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    float: left !important;
    margin-left: -40px !important;
  }
`;

export const CloseModal = styled.div``;
export const ClosePara = styled.div``;
export const CloseTitle = styled.h3``;
