export const mockData = [
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    name: 'PHP',
    questionCount: '50',
    status: 'active',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b911',
    name: 'Nodejs',
    questionCount: '200',
    status: 'active',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b912',
    name: 'Phython',
    questionCount: '250',
    status: 'active',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b913',
    name: 'Java',
    questionCount: '250',
    status: 'active',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b914',
    name: 'ReactJs',
    questionCount: '200',
    status: 'active',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b915',
    name: 'NextJs',
    questionCount: '150',
    status: 'active',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b916',
    name: 'AngularJs',
    questionCount: '200',
    status: 'active',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b917',
    name: 'MongoDB',
    questionCount: '250',
    status: 'active',
    dateCreated: 1690280019702,
  },
];
