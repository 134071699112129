/*
 * Media queries utility
 */

/*
 * Inspired by https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32914
 */

// Update your breakpoints if you want
export const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  laptopXl: '1920px',
};
export const device = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.tablet}) and (min-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.laptop}) and (min-width: ${sizes.tablet}) `,
  laptop: `(max-width: ${sizes.laptopL}) and (min-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopXl}) and (min-width: ${sizes.laptopL})`,
  laptopXl: `(max-width: ${sizes.laptopXl})`,
  desktop: `(min-width: ${sizes.laptopXl})`,
};

// Iterate through the sizes and create min-width media queries
export const media = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce(
  (acc, size) => {
    acc[size] = () => `@media (min-width:${sizes[size]}px)`;
    return acc;
  },
  {} as { [key in keyof typeof sizes]: () => string },
);

/* Example
const SomeDiv = styled.div`
  display: flex;
  ....
  ${media.medium} {
    display: block
  }
`;
*/
