// import axios from 'axios';
import React from 'react';
// import { Description } from '../ResumeInfo/ResumeInfoStyles';
import {
  CertificationCourse,
  CertificationDate,
  CertificationStudy,
  Conference,
  ConferenceData,
  CountryTag,
  Education,
  EducationFirstSubHeading,
  EducationSecondSubHeading,
  EducationSubHeading,
  Experiance,
  ExpObjective,
  // FifthProgress,
  FirstDate,
  FirstPara,
  FirstSubHeading,
  FourthDate,
  // FourthProgress,
  Header,
  // Linkdin,
  Mail,
  MainConatiner,
  Objective,
  Phone,
  Profecciency,
  Progress,
  // SecondCountryTag,
  // SecondDate,
  SecondPara,
  // SecondProgress,
  // SecondSubHeading,
  SkillData,
  Skills,
  SkillVersion,
  ThirdDate,
  // ThirdProgress,
  UxDesigner,
} from './ResumePrintStylings';

export const ResumePrint = ({ formStepperData, basicInfoData }) => {
  console.log('formStepperData', formStepperData.name);
  return (
    <MainConatiner>
      <Header>{formStepperData.name}</Header>
      <UxDesigner>{formStepperData.role}</UxDesigner>
      <Phone>
        <b>Phone </b> {formStepperData.phone_no}
      </Phone>

      <Mail>
        {' '}
        <b> EMail </b>
        {formStepperData.Email}
      </Mail>

      <Objective>
        {formStepperData.Description}
        Experienced Node.js Developer with 6 years of expertise in full-stack
        web development. Proficient in building scalable and high-performance
        applications using Node.js, Express.js, and MongoDB. Skilled in RESTful
        API development, database design, and optimization. Strong knowledge of
        JavaScript and its modern frameworks such as React.js and Angular. Adept
        at collaborating with cross-functional teams to deliver robust and
        efficient solutions. Committed to writing clean and maintainable code
        while adhering to industry best practices. Seeking challenging
        opportunities to leverage my skills and contribute to innovative
        projects.
      </Objective>
      <Experiance>
        <b>Experiance</b>
      </Experiance>

      <FirstDate> {formStepperData.From_Date}</FirstDate>

      <FirstSubHeading> </FirstSubHeading>
      <CountryTag>
        <b>{formStepperData.Location} </b>
      </CountryTag>
      <Profecciency>
        <b> {formStepperData.proficiency}</b>{' '}
      </Profecciency>
      <ExpObjective>
        Excellent leadership and communication skills, with the ability to
        inspire and motivate team members Proficiency in UX research techniques,
        such as user interviews, persona development, and usability testing.{' '}
        <br />
      </ExpObjective>
      <FirstPara>{formStepperData.Description}</FirstPara>
      {/* <SecondDate> {formStepperData.From_Date} </SecondDate>

      <SecondSubHeading>
        <b> {formStepperData.proficiency} </b>
      </SecondSubHeading>
      <SecondCountryTag>
        <b> {formStepperData.Location}</b>
      </SecondCountryTag> */}
      <SecondPara>{formStepperData.Description}</SecondPara>

      <Education>
        <b> Education </b>
      </Education>
      <ThirdDate> {formStepperData.To_Date}</ThirdDate>
      <EducationFirstSubHeading>
        <b>Masters in Intraction Design {formStepperData.Field_study} </b>
      </EducationFirstSubHeading>
      <EducationSubHeading> {formStepperData.Location}</EducationSubHeading>

      <FourthDate> {formStepperData.From_Date}</FourthDate>
      <EducationSecondSubHeading>
        {' '}
        <b> Bachelore in Computer Science {formStepperData.Field_study}</b>
      </EducationSecondSubHeading>
      <EducationSubHeading> {formStepperData.Location} </EducationSubHeading>

      <Conference>
        <b> Certification</b>
        {/* {formStepperData.Certificate_ID}
        {formStepperData.Credential_Url} */}
      </Conference>
      <CertificationStudy>
        <b> {formStepperData.Study}</b>
      </CertificationStudy>
      <ConferenceData>
        {' '}
        <CertificationCourse>
          {formStepperData.Certification}
        </CertificationCourse>
        <CertificationDate>
          {formStepperData.To_Date} -{formStepperData.From_Date}
        </CertificationDate>
      </ConferenceData>
      {/* <ConferenceData>
        {' '}
        {formStepperData.To_Date}
        {formStepperData.From_Date}
      </ConferenceData> */}
      <Skills>
        {' '}
        <b> Skills</b>
      </Skills>
      <Progress value="75" max="100">
        75%
      </Progress>
      {/* <SecondProgress value="60" max="100">
        60%
      </SecondProgress>
      <ThirdProgress value="45" max="100">
        45%
      </ThirdProgress>
      <FourthProgress value="50" max="100">
        50%
      </FourthProgress>
      <FifthProgress value="90" max="100">
        90%
      </FifthProgress> */}
      <SkillData>
        <p>
          {' '}
          <b>{formStepperData.skill} </b>
        </p>
        <br />
      </SkillData>
      <SkillVersion>{formStepperData.version}</SkillVersion>
    </MainConatiner>
  );
};
