import styled from 'styled-components';
import shareImg from 'app/assets/images/share.svg';
import downloadImg from 'app/assets/images/download.svg';

export const StandingImg = styled.img<any>`
  margin: 0px auto;
  display: block;
`;
export const RocketImg1 = styled.img<any>`
  margin-left: 0;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  position: absolute;
  left: -225px;
  top: -20px;
  width: 100%;
`;
export const ResultWrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  background-color: #393939;
  width: 100%;
`;

export const ResultWrapperLogo = styled.div<any>`
  display: flex;
  width: 100%;
  margin: 40px 0px 40px 40px;
`;
export const ResultWrapperContent = styled.div<any>`
  @media (min-width: 960px) {
    background-color: #ffffff;
    padding: 20px 30px;
    margin: 0px auto;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 960px;
  }
  @media (max-width: 959px) {
    background-color: #ffffff;
    padding: 20px;
    margin: 0px auto;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 80%;
  }
  h1 {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    margin-top: 50px;
    color: #393939;
  }
  h2 {
    font-weight: bold;
    margin-top: 8px;
    font-size: 20px;
    text-align: center;
    color: #393939;
    margin-top: 50px;
  }
  h1.good-luck {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    margin: 30px auto;

    color: #000000;
  }
  h6.free-tool-heading6-top {
    margin-top: 50px;
  }
  h6.free-tool-heading6 {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #000000;
  }
`;

export const DivStep0 = styled.div`
  position: relative;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  img {
    width: 237px;
    position: absolute;
    top: -8px;
    right: -43px;
  }
`;
export const DivStepN = styled.div`
  position: relative;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
`;
export const DivStepCircle = styled.div`
  font-weight: 700;
  margin: 0px auto;
  font-size: 20px;
  text-align: center;
  color: #000000;
  width: 103px;
  height: 103px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdb82c;
`;
export const DivStepBorder = styled.div`
  border-left: 3px solid #fdb82c;
  width: 1px;
  height: 78px;
  margin: 0px auto;
`;
export const DivStepContent = styled.div`
  border: 3px solid #fdb82c;
  border-radius: 20px;
  padding: 40px;
  h3 {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #000000;
  }
  h4 {
    font-weight: 400;
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
    color: #000000;
    text-align: left;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  button.share-button {
    width: 46px;
    height: 46px;
    background-image: url(${shareImg});
    border: none;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    :before {
      content: 'Share this';
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      padding: 6px 12px;
      color: #fff;
      background-color: #333;
      border-radius: 4px;
      font-size: 14px;
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.2s linear;
    }
    :hover:before {
      visibility: visible;
      opacity: 1;
    }
  }
  button.download-free-button {
    width: 46px;
    height: 46px;
    background-image: url(${downloadImg});
    border: none;
    background-color: transparent;
    margin-left: 30px;
    position: relative;
    cursor: pointer;
    :before {
      content: 'Download';
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      padding: 6px 12px;
      color: #fff;
      background-color: #333;
      border-radius: 4px;
      font-size: 14px;
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.2s linear;
    }
    :hover:before {
      visibility: visible;
      opacity: 1;
    }
  }
`;
export const FooterLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;
