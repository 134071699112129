import styled from 'styled-components';
import eye from 'app/assets/images/viewIcon.png';
import copy from 'app/assets/images/recycle-bin.png';
import viewAttendees from 'app/assets/images/view-attendees.png';
import share from 'app/assets/images/shareIcon.png';
import download from 'app/assets/images/download-resume.svg';
import progress from 'app/assets/images/Progress_bar.svg';
import downloadInfo from 'app/assets/images/download-info.svg';

export const ListWrapper = styled.div`
  .respondent-list th:nth-child(3) {
    width: 11%;
    white-space: nowrap;
  }
  .respondent-list th:nth-child(4) {
    width: 19%;
    white-space: nowrap;
  }
  .respondent-list th:nth-child(5) {
    width: 9%;
    white-space: nowrap;
  }

  .respondent-list td:nth-child(5) {
    padding: 10px 5px;
  }
  width: 100%;
  margin-bottom: 20px;
  margin-top: 0px;
  padding: 20px;
  /* border-radius: 20px; */
  overflow: auto;
`;

export const ListTable = styled.table<any>`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  // width: ${props => (props.width ? props.width : '96%')};

  @media (max-width: 1000px) {
    table-layout: auto;
    white-space: nowrap;
  }
`;

export const ListHead = styled.thead`
  font-weight: 600;
  font-size: 18px;
  font-style: normal;
  background: #f9f9f9;
`;

export const ListBody = styled.tbody`
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: ;
`;
export const AssessmentDetails = styled.thead`
  .ListHead {
    tr > th {
      /* font-size: 14px !important;
      font-weight: bold; */
    }
  }
  .ListBody {
    tr > td {
      font-size: 15px !important;
    }
  }
`;
export const ListTh = styled.th`
  text-align: center;
  padding: 5px;
  font-weight: bold;
  font-size: 14px;
  background-color: #3d3d3d;
  color: #ffffff;
  border: 1px solid rgba(239, 239, 239, 1);
  /* :first-child {
    width: 10px;
  } */
  /* :nth-child(5) {
    text-align: center;
  } */
`;

export const ListTabDiv = styled.td`
  text-align: left;
  padding: 10px;
  font-weight: 500;
  color: #000000;
  font-size: 14px;
  border: 1px solid rgba(239, 239, 239, 1);
  .inprogress {
    background-image: url(${progress});
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    /* margin: 0px auto; */
    margin-left: 10px;
  }
  .profile-image-wrapper {
    display: flex;
    align-items: center;
  }
  .profile-image-wrapper img {
    width: 27px;
    margin-right: 8px;
    margin-left: 0px !important;
  }

  .indownload {
    background-image: url(${downloadInfo});
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    margin-left: 10px;
  }
  .views-wrapper {
    display: flex;
    justify-content: space-evenly;
  }
  button {
    font-weight: 600;
    font-size: 11px;
    text-align: right;
    padding-right: 15px;
    height: 38px;
    background-position: center left 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 0px auto;
  }
  :nth-child(6) {
    padding-top: 10px;
  }

  :last-child {
    /* padding-left: 20px;
    padding-right: 20px; */
    padding: 10px 20px;
  }
`;

export const durationcell = styled.td`
  text-align: center;
`;

export const ListRow = styled.tr`
  min-height: 68px;
  color: #3d3d3d;
  .durationcell {
    text-align: center;
  }
  /* :nth-child(even) {
    background-color: #f8f8f8;
  } */
`;

export const AddFuncBtn = styled.button`
  font-weight: 600;
  font-size: 14px;
  text-align: right;
  padding-right: 15px;
  height: 38px;
  background-position: center left 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #fdb82c;
`;

export const ToggleView = styled.div`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-image: url(${eye});
  cursor: pointer;
  opacity: 0.9;
  transition: transform 0.3s ease-in-out;
  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
`;

export const Copy = styled.div`
  width: 30px;
  height: 30px;
  background-size: contain;
  background-image: url(${copy});
  cursor: not-allowed;
  opacity: 0.8;
  &:focus {
    outline: none !important;
    border: none !important;
  }
`;

export const ViewAttendees = styled.div`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-image: url(${viewAttendees});
  margin: 0 10px;
  opacity: 0.9;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    outline: none !important;
    border: none !important;
    opacity: 1;
    transform: scale(1.2);
  }
`;

export const Share = styled.div`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-image: url(${share});
  cursor: pointer;
  opacity: 0.9;
  transition: transform 0.3s ease-in-out;
  &.disabledShare {
    pointer-events: none;
    opacity: 0.4;
  }
  &:hover {
    opacity: 1;
  }
`;

export const DownloadReport = styled.div`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-image: url(${download});
  cursor: pointer;
`;
