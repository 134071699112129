/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Title1, Title3 } from 'app/components/UI/atoms/Titles';
import {
  CategoriesWrapper,
  InvitePageWrapper,
  CardTitle,
  BodyDiv,
} from './testDetails';
import { useModal } from 'app/components/UI/Modal/SimpleModal/userModal';

import { Modal } from 'app/components/UI/Modal/SimpleModal';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import SuccessIcon from 'app/assets/images/success-icon.svg';
import {
  Categories,
  CategoriesList,
  CategoriesTitle,
} from 'app/components/UI/organisms/CategoriesCard';
import {
  ModelContent,
  MailIcon,
  Message,
  ModalContainer,
} from 'app/components/UI/Modal/MessageModelStyles';
import { Invitation } from 'app/components/UI/organisms/InvitationLink';
// import { getTestById, getAssessmentByTestId } from 'app/services/tests';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { Header } from 'app/components/UI/organisms/Header';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';

// import {
//   Table,
//   TableBody,
//   TableHead,
//   TableRow,
//   TableTd,
//   TableTh,
//   TableWrapper,
// } from '../../../components/List/ListStyles';
// import { Input } from 'app/components/atoms/Input';
// import {
//   CandidateListHeader,
//   CandidatesInputWrapper,
//   CandidatesListTitle,
//   InputDiv,
// } from './myCandidatesStyles';
// import { InputSearch } from 'app/components/atoms/InputSearch';
// import { A } from 'app/components/atoms/AnchorTag';
// import downloadIcon from 'app/assets/images/download.svg';

export const TestDetails = props => {
  const [totalTime, setTotalTime] = useState(30);
  const assessmentBaseUrl = process.env.REACT_APP_ASSESSMENT_BASE_URL;
  const location: any = useLocation();
  const { testId, testsData } = location.state;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inviteLink, setInviteLink] = useState(
    `${assessmentBaseUrl}test/${testId}`,
  );
  const navigate = useNavigate();

  const [testDetail, setTestDetail] = useState<any>();
  const [candidateData, setCandidateData] = useState<any>();
  const [candidateOriginalData, setCandidateOriginalData] = useState<any>();
  const { isShown, toggle } = useModal();

  const getTestDetails = async () => {
    if (!testId) {
      navigate('/');
    }
    // const testAssessmentDetailsResp = await getAssessmentByTestId(testId);
    // setCandidateData(testAssessmentDetailsResp.assessments);
    // setCandidateOriginalData(testAssessmentDetailsResp.assessments);
    // setTestDetail(testAssessmentDetailsResp);
    setTestDetail({
      createdByName: 'Barry Allen',
      dateCreated: 1640356272886,
      companyId: 'ff32c54b-d8ac-4879-abfa-d98ffac78d87',
      welcomeText: 'This job is a joke, do not take this test.',
      status: 'ACTIVE',
      id: '46212e0e-de51-4ba3-880a-3904863cb77e1',
      title: 'Frontend developer with experience in React',
      createdById: 'ac234a56-6845-4108-bdb7-d2d998d33aa9',
      dateUpdated: 1640356272886,
      testData: [
        {
          questionCount: '50',
          parentCategoryId: '38f5c58a-1797-4f9a-b3ff-a9390d831873',
          sequenceNo: '1',
          categoryId: 'b8145df7-f583-4e8a-8bd5-d69f163fc8bf',
          difficultyLevel: 'EXPERT',
          parentCategoryDisplayName: 'Backend',
          categoryDisplayName: 'Swift',
          subCategory: [
            {
              categoryName: 'Nodejs',
              questionCount: 25,
              level: 'expert',
            },
            {
              categoryName: 'python',
              questionCount: 10,
              level: 'INTERMEDIATE',
            },
          ],
        },
        {
          questionCount: '50',
          parentCategoryId: '38f5c58a-1797-4f9a-b3ff-a9390d8318732',
          sequenceNo: '1',
          categoryId: 'b8145df7-f583-4e8a-8bd5-d69f163fc8bf',
          difficultyLevel: 'EXPERT',
          parentCategoryDisplayName: 'Frontend',
          categoryDisplayName: 'Swift',
          subCategory: [
            {
              categoryName: 'React',
              questionCount: 10,
              level: 'expert',
            },
            {
              categoryName: 'Javascript',
              questionCount: 5,
              level: 'INTERMEDIATE',
            },
          ],
        },
      ],
      assessmentsTaken: 0,
      editable: false,
    });
  };

  useEffect(() => {
    getTestDetails();
  }, []);

  const onCopy = () => {
    toggle();
  };

  const getDateString = date => {
    if (date) {
      const dateObj = new Date(date);
      return (
        ('0' + dateObj.getDate()).slice(-2) +
        '.' +
        ('0' + (dateObj.getMonth() + 1)).slice(-2) +
        '.' +
        dateObj.getFullYear()
      );
    }
    return '-';
  };
  const handleSearch = event => {
    const newCandidateObj = candidateOriginalData.filter(candidate => {
      return candidate.name.toLowerCase().includes(event.target.value);
    });
    setCandidateData(newCandidateObj);
  };

  const content = (
    <ModelContent>
      <MailIcon>
        <img src={SuccessIcon} alt="mail-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <PrimaryParagraph>The public link has been copied</PrimaryParagraph>
      </Message>
    </ModelContent>
  );

  return (
    <>
      <ModalContainer>
        <Modal
          isShown={isShown}
          isShowCloseButton={true}
          hide={toggle}
          modalContent={content}
        />
      </ModalContainer>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <Title1>{testDetail && testDetail.title}</Title1>
            <CategoriesWrapper>
              <InvitePageWrapper>
                <CardTitle>
                  <Title3>Invite Candidates</Title3>
                </CardTitle>
                <Invitation inviteLink={inviteLink} />
              </InvitePageWrapper>
              <Categories TotalTime={totalTime} />
              {testDetail &&
                Object.keys(testDetail?.testData).map((test: any) => {
                  return (
                    <InvitePageWrapper>
                      <CategoriesTitle
                        CategoryName={
                          testDetail.testData[test].parentCategoryDisplayName
                        }
                      />
                      <CategoriesList
                        data={testDetail.testData[test].subCategory}
                      />
                    </InvitePageWrapper>
                  );
                })}
            </CategoriesWrapper>
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
      {/* <HrAssessmentWrapper
        assessmentTitle={testDetail && testDetail.title}
        children={
          <>
            
          </>
        }
      /> */}
    </>
  );
};
