import styled from 'styled-components';

export const MainWrapperDiv = styled.div`
  background: #393939;
  position: fixed;
  overflow: auto;
  width: 100%;
  padding-bottom: 3%;
  font-family: 'Montserrat', sans-serif;
  min-height: 100%;
  height: 100%;
`;
export const MainWrapperSubDiv = styled.div`
  width: 70%;
  background: #fff;
  margin: auto;
  margin-top: 50px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;

  .note-exp {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    padding: 20px;
    background: darkgray;
    font-weight: bold;
  }
`;
export const HeaderDiv = styled.div`
  background: #fdb82c;
  border-radius: 10px 10px 0px 0px;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
`;
export const BorderLine = styled.div`
  border-radius: 5px;
  margin: 30px 30px 0px 30px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  .assessment-logo {
    width: 200px;
    margin-bottom: 0px;
  }
  .good-luck-heading {
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 300;
  }
  img {
    margin: 30px 0px;
  }
  h5 {
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 30px;
  }
`;

export const Logo = styled.img``;
export const TitleDesc = styled.div`
  background: #f4f4f4;
  font-size: 20px;
  font-weight: bold;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 30px;
  font-family: 'Montserrat', sans-serif;
`;
export const PhotoTitleDiv = styled.h2`
  font-size: '1.5rem';
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  margin-top: '1rem';
  margin-bottom: '0.5rem';
`;
export const UserPhotoImg = styled.img`
  border-radius: 50%;
  width: 240px;
  height: 240px;
  object-fit: cover;
  border: 5px solid #fdb82c;
`;
export const PhotoBodyDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
`;
export const PhotoContainerDiv = styled.div`
  position: relative;
  height: 360px;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DescPara = styled.p``;
export const BodyDiv = styled.div`
  padding: 40px;
  width: 50%;
  margin: 0px auto;
  h5 {
    font-size: 16px;
    padding-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
`;
export const InpWrapper = styled.div`
  width: 100%;
  text-align: center;
  input {
    font-family: 'Montserrat', sans-serif;
    appearance: none;
    -webkit-apperance: none;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0; /* Optional, if you want to remove any remaining margin */
  }

  select {
    padding: 5px !important;
    border-color: #000000;
  }
  select option {
    border: 1px solid #000000;
  }

  .select-drop {
    height: auto !important;
  }

  .select-info-table {
    border: 1px solid grey;
  }
  .select-info-table td {
    padding: 8px;
    font-size: 15px;
    border-bottom: 1px solid grey;
  }
  .select-info-table tr:nth-child(odd) {
  }
  .select-info-table td.tech-set {
    text-align: right;
    background: #f2f2f2;
    font-weight: bold;
    border-right: 1px solid grey;
  }
  .select-info-table td:nth-child(2) {
    text-align: left;
  }
`;
export const FooterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
  padding-bottom: 3%;
  font-family: 'Montserrat', sans-serif;
  &.cameraCapture {
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 0;
    button {
      clear: both;
      margin-block: 10px;
    }
  }
  button {
    padding: 10px 20px;
    background: #000;
    border: none;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    &.btnCancel {
      background: transparent;
      border: solid 1px #000;
      margin-right: 20px;
      padding: 10px 20px;
      font-family: 'Montserrat', sans-serif;
    }
    &.btnTakePhoto {
      font-weight: normal;
      background: #fff;
      border: 1px solid #000;
    }
    &.btnStartAsst {
      font-weight: 500;
      background: #fdb82c;
    }
  }
`;
export const ErrorDiv = styled.span`
  color: #e92626;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
`;

export const TechSetWraper = styled.div`
  display: flex;
  line-height: 2.5;
  font-family: 'Montserrat', sans-serif;
  > span {
    width: 50%;
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif;
  }
  > div {
    width: 50%;
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif;
  }
`;
export const TechSetWraperSpan = styled.span``;
