/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Line } from 'rc-progress';
import { PageWrapper } from 'app/components/UI/organisms/PageWrapper';
import TimerIcon from 'app/assets/images/timer.svg';
import HandIcon from 'app/assets/images/hand-icon.svg';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { Title3, Title5, Title6 } from 'app/components/UI/atoms/Titles';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import Button from 'app/components/UI/atoms/Button';
import RightArrowIcon from 'app/assets/images/right-arrow-icon.svg';
import { useModal } from 'app/components/UI/Modal/SimpleModal/userModal';
import { Modal } from 'app/components/UI/Modal/SimpleModal';
import { ConfirmationModal } from 'app/components/UI/Modal/confirmationModal/confirmationModal';
import { Radio } from 'app/components/UI/atoms/Radio';
import SuccessIcon from 'app/assets/images/success-icon.svg';
import WaitImage from 'app/assets/images/wait-image.svg';
import { TextArea } from 'app/components/UI/atoms/TextArea';
import {
  AnswerChoice,
  AnswerChoiceCheckboxContainer,
  CardBody,
  CardBodyLeft,
  CardIcon,
  CardWrapper,
  DescriptiveAnswer,
  HeadWrapper,
  ProgressBarLeft,
  ProgressBarLeftText,
  ProgressBarRight,
  ProgressBarWrapper,
  QuestionCardBodyRight,
  QuestionCardButton,
  QuestionCardButtonWrapper,
  QuestionCardSkipButton,
  QuestionDescription,
  QuestionLabel,
  QuestionNumber,
  TimeText,
  WordCount,
} from './questionStyles';
import {
  ModelContent,
  ModalHeader,
  MailIcon,
  Message,
  ModalContainer,
} from 'app/components/UI/globalStyles/modalStyles';
import { TestFinish } from 'app/services/auth/auth';
import {
  SubmitAnswer,
  fetchQuestionAndSubmitAnswer,
} from 'app/services/assessments';
import { setToken } from 'app/services/auth/auth';
import { P } from 'app/components/NotFoundPage/P';
import errorIcon from 'app/assets/images/errorIcon.svg';
import { captureIncident } from 'app/services/Recording/incident';
import {
  ScreenRecording,
  StartRecording,
  StopRecording,
  VideoRecording,
} from 'app/services/Recording/MediaRecording';

interface Question {
  questionId: string;
  metadata: string;
  text: string;
  type: string;
  proficiency: string;
  time: number;
  categoryId: string;
  dateCreated: number;
  dateUpdated: number;
  status: string;
}

interface Answer {
  metadata: string;
  text: string;
  weightage: number;
}

interface AssessmentQuestion {
  question: Question;
  answer: Answer[];
  assessmentQuestionId: string;
}

interface LocationState {
  questionCount: number;
  duration: number;
  mediarecorder: any;
  isCheatDetectionEnabled?: boolean;
}

export const ScreenTests = () => {
  const { assessmentId, userId, ucaId } = useParams<string>();
  const { state } = useLocation();

  // export const ScreenTests = props => {
  // const { totalquestion, stream } =
  //   (props.location && props.location.state) || {};
  const {
    questionCount: totalquestion,
    duration,
    isCheatDetectionEnabled,
  } = state as LocationState;
  // const question: any = [{ questionId: '', displayText: '' }];

  const answerChoices: any = [{ asnwerId: '', answerText: '' }];
  // const navigate = useNavigate();
  const id = sessionStorage.getItem('assessmentId') || '';
  const [isSelected, setIsSelected] = useState('');
  const { isShown, toggle } = useModal();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState<boolean>(false);
  const [status, setStatus] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [questionNumber, setQuestionNumber] = useState(1);
  const [questionData, setQuestionData] = useState<any>(null);
  const [answerChoiceTitle, setAnswerChoiceTitle] = useState('Select only one');
  const [answers, setAnswers] = useState(answerChoices);
  const [totalTime, setTotalTime] = useState(0);
  const [totalseconds, setTotalSeconds] = useState(duration);
  const [secondsLeft, setsecondLeft] = useState(totalseconds);
  const [totalQuestion, setTotalQuestion] = useState(totalquestion);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [currentQuestionData, setCurrentQuestionData] =
    useState<AssessmentQuestion | null>(null);
  const [questionProgress, setQuestionProgress] = useState(0);
  const [timerProgress, setTimerProgress] = useState(totalseconds);
  const [paused, setPaused] = useState(false);
  const [over, setOver] = useState(false);
  const [isSkipping, setSkip] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<any>();
  // const [assessmentId, setassessmentId] = useState(id);
  const [time, setTime] = useState({
    hours: 0,
    minutes: totalTime,
    seconds: 0,
  });
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  let navigate = useNavigate();
  const format = (time: any) => {
    let seconds: any = time % 60;
    let minutes: any = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;
    seconds = seconds.toString().length === 1 ? '0' + seconds : seconds;
    return minutes + ':' + seconds;
  };

  const timeProgressBar = () => {
    if (secondsLeft > 0) {
      let timeleft = ((secondsLeft - 1) / totalseconds) * 100;
      let seconds = secondsLeft - 1;
      setsecondLeft(seconds);
      setTimerProgress(timeleft);
      let assessmentContext = JSON.parse(
        sessionStorage.getItem('assessmentContext') as string,
      );
      sessionStorage.setItem(
        'assessmentContext',
        JSON.stringify({ ...assessmentContext, timeRemaining: seconds }),
      );
    }
  };

  useEffect(() => {
    const assessmentContext = JSON.parse(
      sessionStorage.getItem('assessmentContext') as string,
    );
    if (assessmentContext && assessmentContext.timeRemaining > 0) {
      if (
        assessmentContext.assessmentId === assessmentId &&
        assessmentContext.ucaId === ucaId &&
        assessmentContext.userId === userId
      ) {
        const { lastAnsweredQuestion, timeRemaining } = assessmentContext;
        const currentQuestionNumber = JSON.parse(lastAnsweredQuestion) + 1;
        setQuestionNumber(currentQuestionNumber);
        setCurrentQuestion(currentQuestionNumber);
        setsecondLeft(timeRemaining);
        sessionStorage.removeItem('assessmentContext');
      }
    }

    if (isCheatDetectionEnabled === true) {
      reportViolations();
    }
    fetchQuestion();
  }, []);

  useEffect(() => {
    completeProgress();
  }, [currentQuestion]);

  useEffect(() => {
    if (!secondsLeft) {
      TestFinish();
      toggle();
      return;
    }
    let timerID = setInterval(() => {
      timeProgressBar();
    }, 1000);
    return () => clearInterval(timerID);
  }, [secondsLeft]);

  if (!assessmentId || !userId || !ucaId) {
    //redirect to an error page or take other appropriate actions.
    console.error('One or more parameters are missing in the URL.');
    // For example, you can return or render an error message component.
    return <div>Error: One or more parameters are missing in the URL.</div>;
  }

  function convertAssessmentQuestionToComponentFormat(
    assessmentQuestion: AssessmentQuestion,
  ) {
    const result = {
      questionId: assessmentQuestion?.question?.questionId,
      displayText: assessmentQuestion?.question?.text,
      answer: assessmentQuestion?.answer?.map((answer, index) => ({
        asnwerId: answer.metadata,
        answerText: answer.text,
      })),
    };

    return result;
  }

  const fetchQuestion = async () => {
    const data = await fetchQuestionAndSubmitAnswer({
      questionNumber: currentQuestion,
      userId,
      assessmentId,
      ucaId,
    });

    if (data?.error && data?.statusCode === 30303) {
      setErrorMsg(data.error);
      toggle();
      alert(data.error);
      navigate(`/`);
    } else if (data && data.result.message === 'End of assessment!') {
      setIsAlreadySubmitted(true);
      setIsShowModal(true);
      StopRecording(mediaRecorder);
      setTimeout(() => {
        window.location.href =
          'https://www.linkedin.com/company/fairhiree/?originalSubdomain=in';
      }, 2000);
    } else {
      setCurrentQuestionData(data.result);
      const assessmentQuestion = convertAssessmentQuestionToComponentFormat(
        data.result,
      );

      if (data) {
        setQuestionData(assessmentQuestion);
        setAnswers(assessmentQuestion.answer);
        // setToken(data.token);
      }

      if (isCheatDetectionEnabled === true) {
        const screenstream: any = await ScreenRecording();
        const videostream: any = await VideoRecording();

        if (screenstream && videostream && assessmentId) {
          let screenrecorder = new MediaRecorder(screenstream);
          StartRecording(screenrecorder, 'screen', assessmentId, userId, ucaId);

          let mediarecorder = new MediaRecorder(videostream);
          StartRecording(mediarecorder, 'video', assessmentId, userId, ucaId);

          setMediaRecorder(mediarecorder);
        }
      }
    }
  };

  function round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  }

  const fetchAndProcessQuestion = async (answer: string) => {
    const data = await fetchQuestionAndSubmitAnswer({
      questionNumber: currentQuestion + 1,
      assessmentId,
      ucaId,
      userId,
      questionId: currentQuestionData?.question?.questionId,
      assessmentQuestionId: currentQuestionData?.assessmentQuestionId,
      answer,
    });

    if (
      data &&
      (data?.status === 'COMPLETED' ||
        data.result.message === 'End of assessment!')
    ) {
      TestFinish();
      setIsShowModal(true);
      StopRecording(mediaRecorder);
      setTimeout(() => {
        window.location.href =
          'https://www.linkedin.com/company/fairhiree/?originalSubdomain=in';
      }, 2000);
      // toggle();
      setStatus(data.status);
      return;
    }

    setCurrentQuestionData(data.result);

    const assessmentQuestion = convertAssessmentQuestionToComponentFormat(
      data.result,
    );
    setIsSelected('');
    setQuestionData(assessmentQuestion);
    setAnswers(assessmentQuestion.answer);
    const assessmentContext = {
      assessmentId,
      userId,
      ucaId,
      lastAnsweredQuestion: currentQuestion.toString(),
      timeRemaining: secondsLeft.toString(),
    };
    sessionStorage.setItem(
      'assessmentContext',
      JSON.stringify(assessmentContext),
    );
  };

  const fetchNextQuestion = async () => {
    await fetchAndProcessQuestion(isSelected);
  };

  const SkipQuestion = async () => {
    await fetchAndProcessQuestion('SKIPPED');
  };

  const ReportIncident = (assessmentId, incident, incidentTime) => {
    console.log('Ctrl + S, Ctrl+C, Ctrl+V not allowed');
    if (sessionStorage.length > 0) {
      captureIncident({
        assessmentId,
        userId,
        ucaId,
        incident,
        timestamp: incidentTime,
      });
    }
  };

  const answerHandler = (e, id) => {
    setIsSelected(id);
  };

  const reportViolations = () => {
    // Prevent Ctrl+S, Ctrl+C & Ctrl+V
    document.onkeydown = function (e) {
      e = e || window.event; //Get event
      if (e.ctrlKey || e.metaKey) {
        var c = e.code; //Get key code
        if (['KeyS', 'KeyC', 'KeyV', 'ControlLeft', 'MetaLeft'].includes(c)) {
          e.preventDefault();
          e.stopPropagation();
          ReportIncident(
            assessmentId,
            'Ctrl + S, Ctrl+C, Ctrl+V not allowed',
            new Date().getTime(),
          );
        }
      }
    };

    // detect tab switching
    document.addEventListener('visibilitychange', event => {
      if (document.visibilityState !== 'visible') {
        ReportIncident(
          assessmentId,
          'Tab switching not allowed',
          new Date().getTime(),
        );
        // setViolations([...violations, 'Violation : Tab switching not allowed']);
      }
    });

    // full screen detection - chrome
    document.addEventListener('webkitfullscreenchange', function () {
      // full screen disabled
      if (!document.fullscreenElement) {
        ReportIncident(
          assessmentId,
          'Exiting Full Screen not allowed',
          new Date().getTime(),
        );
      }
    });
  };

  const onErrorContent = (
    <ModelContent>
      <ModalHeader>
        <Title3>OOPS! </Title3>
      </ModalHeader>
      <MailIcon>
        <img src={errorIcon} alt="success-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <PrimaryParagraph>{errorMsg}</PrimaryParagraph>
      </Message>
    </ModelContent>
  );

  const onSubmitSuccessContent = (
    <ModelContent>
      <ModalHeader>
        <Title3>Congratulations! </Title3>
      </ModalHeader>
      <MailIcon>
        <img src={SuccessIcon} alt="success-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <PrimaryParagraph>
          Test {isAlreadySubmitted ? 'is already ' : ''}submitted successfully.{' '}
          <br />
          Related HR will get back if eligible for next round. <br />
          All the best!
        </PrimaryParagraph>
      </Message>
    </ModelContent>
  );

  // const onAlreadySubmittedContent = (
  //   <ModelContent>
  //     <ModalHeader>
  //       <Title3>Congratulations! </Title3>
  //     </ModalHeader>
  //     <MailIcon>
  //       <img src={SuccessIcon} alt="success-icon" width="80px" height="50px" />
  //     </MailIcon>
  //     <Message>
  //       <PrimaryParagraph>
  //         Test is Already submitted successfully. <br />
  //         Related HR will get back if eligible for next round. <br />
  //         All the best!
  //       </PrimaryParagraph>
  //     </Message>
  //   </ModelContent>
  // );

  const onConfirm = () => {
    SkipQuestion();
    setSkip(false);
    toggle();
    completeProgress();
    setQuestionNumber(questionNumber + 1);
    if (currentQuestion < totalQuestion) {
      setCurrentQuestion(currentQuestion + 1);
    }
    setCurrentQuestion(currentQuestion + 1);
  };

  const onCancel = () => {
    toggle();
    setSkip(false);
  };
  const next = () => {
    if (!isSelected) {
      alert('Please select an answer');
      return;
    }

    setQuestionNumber(questionNumber + 1);
    fetchNextQuestion();
    completeProgress();
    setIsSelected('');

    if (currentQuestion < totalQuestion) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const submit = () => {
    if (isCheatDetectionEnabled === true) {
      StopRecording(mediaRecorder);
    }
    fetchNextQuestion();
    toggle();
  };

  const skip = () => {
    setSkip(true);
    toggle();
    // setTimeout(() => {
    //   setSkip(false);
    // }, 2000);
  };

  const onSubmitContent = (
    <ModelContent>
      <ModalHeader>
        <Title3>Your time is up!</Title3>
      </ModalHeader>
      <MailIcon>
        <img src={WaitImage} alt="wait-icon" width="300px" height="200px" />
      </MailIcon>
      {/* <ModalProgressBar>
              <Line percent={timerProgress} trailWidth={2} strokeWidth={2} strokeColor="#e53366" />
              </ModalProgressBar> */}
      <Message>
        <PrimaryParagraph>
          We are submitting the test to related HR, hang on for a moment do not
          cancel or leave the browser else your test will not be submitted
          successfully.
        </PrimaryParagraph>
      </Message>
    </ModelContent>
  );
  function progress(timeleft, timetotal) {
    var progressBarWidth = (timeleft / timetotal) * 100;
    if (timeleft > 0) {
      setTimeout(function () {
        progress(timeleft - 1, timetotal);
      }, 1000);
    }
    return progressBarWidth;
  }
  const onSkipcontent = (
    <ConfirmationModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      message="You cannot come back to this question later. Even if you don’t know the
          answer, just pick the answer you feel best about. You might get it
          right!"
      title="Are you sure you want to skip this question ?"
      confirmButtonContent="Skip question"
      cancelButtonContent="Choose answer"
    />
  );

  const completeProgress = () => {
    let current = currentQuestion;
    let percent = (current / totalQuestion) * 100;
    setQuestionProgress(percent);
  };
  if (!questionData) return <></>;

  const toggleAndMove = () => {
    if (!isShowModal) {
      toggle();
    }
    setIsShowModal(false);
    window.location.href =
      'https://www.linkedin.com/company/fairhiree/?originalSubdomain=in';
  };
  return (
    <>
      <PageWrapper justifyContent={'flex-start'}>
        <CardWrapper>
          <HeadWrapper>
            <ProgressBarWrapper>
              <ProgressBarLeft>
                <CardIcon src={TimerIcon} />
                <ProgressBarLeftText>
                  <Title6>{`${format(secondsLeft)}`}</Title6>
                </ProgressBarLeftText>
              </ProgressBarLeft>
              <ProgressBarRight>
                <Line
                  percent={timerProgress}
                  strokeWidth={1}
                  strokeColor="#fdb82c"
                />
              </ProgressBarRight>
              <TimeText>
                <Title6>{`${format(totalseconds)} min`}</Title6>
              </TimeText>
            </ProgressBarWrapper>
            <ProgressBarWrapper>
              <ProgressBarLeft>
                <CardIcon src={HandIcon} />
                <ProgressBarLeftText>
                  <Title6>
                    {currentQuestion < 10
                      ? '0' + currentQuestion
                      : currentQuestion}
                    /{totalQuestion}
                  </Title6>
                </ProgressBarLeftText>
              </ProgressBarLeft>
              <ProgressBarRight>
                <Line
                  percent={questionProgress}
                  strokeWidth={1}
                  strokeColor="#fdb82c"
                />
              </ProgressBarRight>
            </ProgressBarWrapper>
          </HeadWrapper>
          <CardBody>
            <CardBodyLeft>
              <QuestionNumber>
                <Title3>
                  {questionNumber < 10 ? '0' + questionNumber : questionNumber}
                </Title3>
              </QuestionNumber>
              <QuestionDescription>
                <PrimaryParagraph>{questionData?.displayText}</PrimaryParagraph>
              </QuestionDescription>
            </CardBodyLeft>
            <QuestionCardBodyRight>
              <QuestionLabel>
                <Title5>{answerChoiceTitle}</Title5>
              </QuestionLabel>
              {questionType !== 'descriptive' ? (
                <AnswerChoice>
                  {answers.map((r, i) => (
                    <AnswerChoiceCheckboxContainer
                      color={r.asnwerId === isSelected ? '#fdb82c' : '#f5f3f3'}
                      key={i}
                    >
                      <Radio
                        label={r.answerText}
                        name="Radio"
                        id={r.asnwerId}
                        value={r.answerText}
                        isSelected={r.asnwerId === isSelected}
                        onChange={e => answerHandler(e, r.asnwerId)}
                      />
                    </AnswerChoiceCheckboxContainer>
                  ))}
                </AnswerChoice>
              ) : (
                <DescriptiveAnswer>
                  <TextArea
                    id={'1'}
                    placeholder="Answer"
                    rows={5}
                    onChange={e => answerHandler(e, '1')}
                    label={''}
                  />
                  <WordCount>
                    <Title6>{'1/50'}</Title6>
                  </WordCount>
                </DescriptiveAnswer>
              )}
            </QuestionCardBodyRight>
          </CardBody>
          <QuestionCardButtonWrapper>
            <QuestionCardSkipButton>
              <Button
                type="button"
                content="Skip"
                onClick={skip}
                bgType="secondary"
              />
            </QuestionCardSkipButton>
            <QuestionCardButton>
              <Button
                type="button"
                bgType="secondary"
                content={currentQuestion === totalQuestion ? 'Submit' : 'Next'}
                icon={currentQuestion === totalQuestion ? '' : RightArrowIcon}
                onClick={currentQuestion === totalQuestion ? submit : next}
              />
            </QuestionCardButton>
          </QuestionCardButtonWrapper>
        </CardWrapper>
      </PageWrapper>
      <ModalContainer>
        <Modal
          isShown={isShown}
          hide={toggleAndMove}
          isShowCloseButton={true}
          modalContent={
            errorMsg !== null
              ? onErrorContent
              : secondsLeft === 0
              ? onSubmitContent
              : currentQuestion === totalQuestion && !isSkipping
              ? onSubmitSuccessContent
              : onSkipcontent
          }
        />
      </ModalContainer>
      <ModalContainer>
        <Modal
          isShown={isShowModal}
          hide={toggleAndMove}
          isShowCloseButton={true}
          modalContent={onSubmitSuccessContent}
        />
      </ModalContainer>
    </>
  );
};
