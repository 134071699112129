import React from 'react';
import { StyledPagination } from './styled';

const Pagination = ({ count, currentPage, onPageChangeHandler }) => {
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = allItems.slice(indexOfFirstItem, indexOfLastItem);
  // const count = Math.ceil(allItems.length / itemsPerPage);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChangeHandler(value);
  };

  return (
    <StyledPagination
      count={count}
      page={currentPage}
      onChange={handleChange}
      variant="outlined"
      shape="rounded"
    />
  );
};

export default Pagination;
