import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  textarea {
    color: #959595;
    font-size: 14px;
    padding: 10px;
    display: block;
    width: 100%;
    font-weight: normal;
    border-radius: 10px;
    border: 1px solid #000000;
    align-items: center;
    &:focus {
      color: #404040;
      border: 1px solid #000000;
    }
    &:valid {
      color: #404040;
      border: 1px solid #000000;
    }
    &:focus &:invalid {
      outline: 1px solid #ed5063;
      border: 1px solid #ed5063;
    }
  }
`;
