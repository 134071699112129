import React from 'react';
import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableTd,
  TableTh,
  TableWrapper,
} from './ListStyles';
import Button from 'app/components/UI/atoms/Button';
export const JobListTable = ({ headers, rowData }) => {
  const [status, setStatus] = useState<Array<string>>([]);
  const [action, setAction] = useState<boolean>(false);

  useEffect(() => {
    rowData &&
      rowData.length > 0 &&
      rowData.map(ques => {
        if (ques.status === 'ACTIVE') {
          status.push(ques.id);
          setStatus(status);
        }
        return ques;
      });
  }, [rowData, status]);

  return (
    <TableWrapper>
      {/* {redirectToques ? (
        <Redirect
          to={{
            pathname: '/ques',
            state: { quesId: redirectToques },
          }}
        />
      ) : null} */}
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableTh key={header.id}>{header.name}</TableTh>
            ))}
            {/* <TableTh>Action</TableTh> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData &&
            rowData.length > 0 &&
            rowData.map((row, index) => {
              // const days = Math.floor(
              //   (new Date().getTime() - ques.dateUpdated) / 1000 / 60 / 60 / 24,
              // );
              return (
                <TableRow key={row.id}>
                  <TableTd
                  // onClick={() => {
                  //   handleShowQues(row.jobId);
                  // }}
                  >
                    {row.company}
                  </TableTd>
                  <TableTd>{row.assessmentName}</TableTd>
                  <TableTd>{row.receivedDate}</TableTd>
                  <TableTd>{row.expiryDate}</TableTd>
                  <TableTd
                    onClick={() => {
                      setAction(!action);
                      // setId(row.id);
                    }}
                  >
                    <Button
                      className="Shortlist"
                      content={row.status}
                      type={'button'}
                      bgType=""
                      bgColor={row.statusColor ? row.statusColor : '#fdb82c'}
                      disabled={row.workMode === 'closed' ? true : false}
                      // onClick={closeJobAction}
                    ></Button>
                  </TableTd>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
