import React from 'react';
import { Footer } from './FooterStyles';
import footerImage from 'app/assets/images/footer-image.svg';

export const PageFooter = () => {
  return (
    <Footer>
      <img src={footerImage} alt="footer" />
    </Footer>
  );
};
