import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MainWrapper,
  LeftWrapper,
  RightWrapper,
  ErrorDiv,
  RocketImg,
  ParaWrap,
  ButtonWrap,
} from './careerguideStyles';
import rocket from 'app/assets/images/rocketImg.svg';
import { useForm } from 'react-hook-form';
import Button from 'app/components/UI/atoms/Button';
import { Input } from 'app/components/UI/atoms/Input';
import { createLeads } from 'app/services/chatgpt';

export const CareerGuide = () => {
  // const [email, setEmail] = useState<string>('');
  let navigate = useNavigate();

  // if (typeof token !== 'undefined' && token !== null) {
  //   navigate('/dashboard', { replace: true });
  // }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  React.useEffect(() => {
    reset();
  }, [reset]);

  const onSubmit = handleSubmit(async (data: any) => {
    await createLeads(data);
    navigate('/career-path', { replace: true, state: { data } });
  });

  return (
    <MainWrapper>
      <LeftWrapper>
        {/* <img src="images/black-logo.svg" class="black-logo" /> */}

        <h2>Revolutionise Your Career Path with </h2>
        <h3>Fairhiree Industry Experts & Artificial Intelligence Together! </h3>
        <ParaWrap>
          Unleash your true potential and take charge of your career path with
          the help of Fairhiree! Our team of{' '}
          <b>
            Industry Experts and Advanced Artificial Intelligence technology
          </b>{' '}
          work together to provide you with personalized career guidance that's
          tailored just for you. Say goodbye to generic job recommendations and
          hello to a journey that's as unique as you are!
          <br /> <br />
          At Fairhiree, we believe in empowering you to reach your dreams and
          achieve success. Our cutting-edge technology provides accurate
          insights and expert advice, helping you discover your ideal career
          path. With us by your side, you'll have the tools and support you need
          to unlock your full potential and take your career to new heights.
        </ParaWrap>
      </LeftWrapper>
      {/* <img src="images/rocket.svg" class="rocket" /> */}
      <RocketImg src={rocket} alt="evolve rocket" />
      <RightWrapper>
        <h1> It’s Totally Free</h1>
        {/* <input type="text" class="free-login-text-input" placeholder="Name" />
       <input type="password" class="free-login-text-input" placeholder="password" /> */}
        <form className="Input-Wrapper" noValidate={true} onSubmit={onSubmit}>
          <Input
            id="name"
            type="text"
            label="Name"
            placeholder="Name"
            {...register('name', {
              required: true,
            })}
            required
            // onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            id="email"
            type="text"
            label="Please enter Email"
            aria-label="please enter your email"
            placeholder="Email"
            {...register('email', {
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
            required
            // onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          {errors?.email?.type === 'required' ? (
            <ErrorDiv role="alert" aria-atomic="true">
              Enter email!
            </ErrorDiv>
          ) : errors?.name?.type === 'required' ? (
            <ErrorDiv role="alert" aria-atomic="true">
              Enter name!
            </ErrorDiv>
          ) : (
            ''
          )}
          {/* <button class="proceed">Proceed </button> */}
          <ButtonWrap>
            <Button type="submit" content="Proceed" />
          </ButtonWrap>
        </form>
      </RightWrapper>
    </MainWrapper>
  );
};
