import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  *{
    margin:0px;
    padding: 0px;
  }

  *:focus{
    outline: none;

  }

  body {
    margin:0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
    -webkit-print-color-adjust: exact;
    
  }
  @media print {

  }
`;
export const Background = styled.div`
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Content = styled.div`
  margin: 0;
  padding: 0;
  letter-spacing: 0.2px;
  width: 100%;
`;
