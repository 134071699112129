import axios from 'axios';
import { getToken } from './auth/auth';

const baseUrl = process.env.REACT_APP_API_URL;

// export const getQuestions = async (credentials: any) => {
//   const res = await axios({
//     url: `${baseUrl}/v1/user/authenticate`,
//     data: credentials,
//     method: 'post',
//     headers: {
//       'content-type': 'application/json',
//     },
//   });
//   console.log('data :-   ', JSON.stringify(res));
//   return res;
// };

// export const addQuestion = async (data: any) => {
//   try {
//     const token = getToken();
//     const res = await axios({
//       url: `${baseUrl}/mcqquestion/v1/question/mcq`,
//       // url: `https://6rdz3sv6c1.execute-api.ap-south-1.amazonaws.com/dev/v1/question/mcq`,
//       data,
//       method: 'post',
//       headers: {
//         'content-type': 'application/json',
//         Authorization: token,
//       },
//     });
//     return res.data;
//   } catch (err) {
//     console.log('createCategory post error: ', err);
//     return err;
//   }
// };

export const getQuestions = async (data: any) => {
  try {
    let { categoryId, type, proficiency, status } = data;
    if (!categoryId || typeof categoryId === 'undefined' || categoryId === '') {
      console.log('Invalid category ID: ', categoryId);
      return 'Questions retriving failed!';
    }

    // If got category Id then fetch questions
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}questionbank/category/${categoryId}/question`,
      data,
      method: 'get',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
      params: {
        type,
        proficiency,
        status,
      },
    });
    return res.data;
  } catch (err) {
    console.log('Get questions by category error: ', err);
    return err;
  }
};

export const getAllQuestions = async categoryId => {
  try {
    // const allStatus = ['active', 'inactive'];
    const allProficiency = ['beginner', 'intermediate', 'expert'];
    let allQuestions: any[] = [];

    for (const profIndex in allProficiency) {
      let data = {
        categoryId,
        // status: allStatus[statIndex],
        proficiency: allProficiency[profIndex],
        type: 'mcq',
      };

      try {
        const response = await getQuestions(data);
        if (
          response &&
          response.result.questions &&
          response.result.questions.length > 0
        ) {
          allQuestions.push(...response.result.questions);
        }
      } catch (err) {
        console.log(err);
        return err;
      }
    }

    // Filter questions created by the user
    const userId = window.localStorage.getItem('ui');
    const userCreatedQuestions = allQuestions.filter(
      i => i.question.createdBy === userId,
    );

    // Sort the remaining questions by dateUpdated in descending order
    const remainingQuestions = allQuestions
      .filter(i => i.question.createdBy !== userId)
      .sort((a, b) => b.question.dateUpdated - a.question.dateUpdated);

    // Concatenate user-created questions and sorted questions
    const sortedQuestions = [...userCreatedQuestions, ...remainingQuestions];
    return { categoryId, allQuestions: sortedQuestions };
  } catch (err) {
    console.log('Get questions by category error: ', err);
    return err;
  }
};

export const addQuestion = async (data: any) => {
  try {
    let { categoryId } = data;
    if (!categoryId || typeof categoryId === 'undefined' || categoryId === '') {
      console.log('Invalid category ID: ', categoryId);
      return 'Question saving failed!';
    }
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}questionbank/category/${categoryId}/question`,
      data,
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res.data;
  } catch (err) {
    console.log('Add question post error: ', err);
    return err;
  }
};

export const editQuestion = async (data: any) => {
  try {
    let { categoryId, questionId } = data;
    if (!categoryId || typeof categoryId === 'undefined' || categoryId === '') {
      console.log('Invalid category ID: ', categoryId);
      return 'Question saving failed!';
    }
    if (!questionId || typeof questionId === 'undefined' || questionId === '') {
      console.log('Invalid question ID: ', questionId);
      return 'Question saving failed!';
    }
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}questionbank/category/${categoryId}/question/${questionId}`,
      data,
      method: 'put',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res.data;
  } catch (err) {
    console.log('Add question post error: ', err);
    return err;
  }
};

export const updateQuestionStatus = async (questId: string, status: string) => {
  try {
    if (typeof questId === 'undefined' || questId === '')
      if (typeof status === 'undefined' || status === '')
        return 'Invalid Question ID';

    const token = getToken();
    let res;
    if (status.toLowerCase() === 'active') {
      res = await axios({
        url: `${baseUrl}questionbank/question/${questId}/activate`,
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: token,
        },
      });
    } else if (status.toLowerCase() === 'inactive') {
      res = await axios({
        url: `${baseUrl}questionbank/question/${questId}/deactivate`,
        method: 'post',
        headers: {
          'content-type': 'application/json',
          Authorization: token,
        },
      });
    }
    return res.data;
  } catch (err) {
    console.log(
      `Status updation of Question with ID: ${questId} failed with error: `,
      err,
    );
  }
};
