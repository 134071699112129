import React from 'react';
import { Switch, Input, Slider } from './MultiSwitchStyles';

const MultiSwitch = ({ checked = false, onChange }) => {
  // const MultiSwitch = ({ checked = false }) => {
  return (
    <Switch>
      <Input type="checkbox" onChange={onChange} checked={checked} />
      <Slider className="slider rounded" />
    </Switch>
  );
};

export default MultiSwitch;
