import styled from 'styled-components/macro';

export const ModalContainer = styled.div`
  width: 100% !important;
  margin-left: 50px;
`;

export const ModelContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  font-weight: 400;
  padding: 40px;
  padding-top: 0;
  align-items: center;
`;

export const MailIcon = styled.div`
  font-size: 14px;
  align-self: center;
  justify-self: center;
`;

export const Message = styled.div`
  padding-bottom: 20px;
  font-weight: 400;
  align-self: auto;
  justify-self: center;
  .no-button {
    width: 150px;
    height: 50px;
    border-radius: 20px;
    border: none;
    font-size: 20px;
    font-weight: 500;
    float: none;
    border: 1px solid #fdb82c;
    float: left;
    margin-top: 30px;
    margin-right: 30px;
  }

  .yes-button {
    width: 150px;
    height: 50px;
    border-radius: 20px;
    border: none;
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
  }
`;
