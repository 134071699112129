/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  VerificationPending,
  ResendMainDiv,
  TextDiv,
  TextParentDiv,
  TextChildDiv,
} from './loginStyles';
import { resendVerificationEmail } from 'app/services/auth/auth';
// import OtpInput from 'react-otp-input';
import Button from 'app/components/UI/atoms/Button';
// import jwt_decode from 'jwt-decode';
import { Title1 } from 'app/components/UI/atoms/Titles';
import successIcon from 'app/assets/images/success-icon.svg';
import {
  MailIcon,
  Message,
  ModalContainer,
  ModelContent,
} from 'app/components/UI/organisms/Modal/MessageModelStyles';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import { Modal } from 'app/components/UI/organisms/Modal/SimpleModal';
import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import spinnerLoad from 'app/assets/images/loader.gif';

export const Verification = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isShown, toggle } = useModal();
  // let navigate = useNavigate();
  const locationData: any = useLocation();
  // const [otp, setOtp] = useState('');
  let jt = '';

  // if (locationData.state === null || locationData.state.jt === null) {
  // navigate('/', { replace: true });
  // }
  // else {
  //   jt = locationData.state.jt;
  // }

  // if (typeof token !== 'undefined' && token !== null) {
  //   navigate('/dashboard', { replace: true });
  // }

  // const { code } = useParams<string>();

  // const verifyToken = () => {
  //   const decodedJT: any = jwt_decode(jt);

  //   // decrio
  //   if (decodedJT.user.emailVerified === true) {
  //     navigate('/', { replace: true });
  //   }
  //   resendEmail();
  //   setIsLoading(false);
  // };
  const resendEmail = async () => {
    const verifyResp = await resendVerificationEmail(
      locationData.state.email,
      jt,
    );
    console.log('verifyResp', JSON.stringify(verifyResp));
    setIsLoading(false);
  };
  // const verifyOtp = async () => {
  //   if (!otp) {
  //     return false;
  //   }
  //   const verifyResp = await getOtpVerified(locationData.state.email, otp);
  //   console.log('verifyResp', JSON.stringify(verifyResp));
  //   setIsLoading(false);
  //   toggle();
  // };

  useEffect(() => {
    setIsLoading(false);
    // if (jt !== '') {
    //   verifyToken();
    // }
    // navigate('/', { replace: true });
  }, [jt]);

  const content = (
    <ModelContent>
      <MailIcon>
        <img src={successIcon} alt="mail-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <PrimaryParagraph>OTP verified successfully!</PrimaryParagraph>
      </Message>
    </ModelContent>
  );
  return (
    <VerificationPending>
      {isLoading === true ? (
        <img
          src={spinnerLoad}
          alt=""
          width={100}
          height={100}
          style={{ marginTop: '20%', marginLeft: '40%' }}
        />
      ) : (
        <ResendMainDiv>
          <Title1 className="congratulations"> Congratulations</Title1>
          <img src={successIcon} alt="success" className="success" />
          <TextDiv>
            Congratulations! We have successfully sent an email verification to
            your inbox for Fairhiree account creation. Please check your email
            and verify the account and once again thank you for choosing
            Fairhiree as your trusted partner
          </TextDiv>
          <TextParentDiv>
            <TextChildDiv className="text-or-border"></TextChildDiv>
            <TextChildDiv className="text-or">OR</TextChildDiv>
            <TextChildDiv className="text-or-border"></TextChildDiv>
          </TextParentDiv>
          {/* <OtpInput
            // inputType="number"
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span></span>}
            renderInput={props => <input {...props} />}
          /> */}
          <Button
            content="Resend Email"
            className="resend-email"
            type={'button'}
            onClick={resendEmail}
          />
          {/* <Button
            content="Verify OTP"
            className="resend-email"
            type={'button'}
            onClick={verifyOtp}
          /> */}
        </ResendMainDiv>
      )}
      <ModalContainer>
        <Modal
          isShown={isShown}
          isShowCloseButton={true}
          hide={toggle}
          modalContent={content}
        />
      </ModalContainer>
    </VerificationPending>
  );
};
