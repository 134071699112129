import React from 'react';
import { Title5 } from 'app/components/UI/atoms/Titles';
import {
  CardTitle,
  CategoriesHeader,
  CategoriesHeaderLeft,
  CategoriesHeaderRight,
  DurationTitle,
  Table,
  TimerTitle,
} from './categoriesStyle';
import TimerIcon from 'app/assets/images/pink-timer.svg';

export const Categories = ({ TotalTime }) => {
  return (
    <CategoriesHeader>
      <CategoriesHeaderLeft>
        <CardTitle>Categories</CardTitle>
      </CategoriesHeaderLeft>
      <CategoriesHeaderRight>
        <img src={TimerIcon} width="20px" alt="n" />
        <Title5>
          <DurationTitle>Duration</DurationTitle>{' '}
          <TimerTitle>{TotalTime} min</TimerTitle>
        </Title5>
      </CategoriesHeaderRight>
    </CategoriesHeader>
  );
};

export const CategoriesTitle = ({ CategoryName }) => {
  return <CardTitle>{CategoryName}</CardTitle>;
};

export const CategoriesList = ({ data }) => {
  return (
    <Table>
      <thead>
        <tr key={data.length > 0 && data.id}>
          <th>Sub Category</th>
          <th>No. of questions</th>
          <th>Level</th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 &&
          data.map(subC => {
            return (
              <tr>
                <td>{subC.categoryName}</td>
                <td>{subC.questionCount}</td>
                <td>{subC.level || 'INTERMEDIATE'}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};
