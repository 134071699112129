import React from 'react';
import { AnchorWrapper } from './anchorStyles';

interface Props {
  href?: string;
  children: JSX.Element | string;
  bgImage?: string;
  className?: string;
  target?: string;
  onClick?: any;
}

export const A = ({
  href,
  children,
  bgImage,
  className,
  target,
  onClick,
}: Props) => {
  return (
    <AnchorWrapper
      href={href}
      bgImage={bgImage}
      className={className}
      target={target}
      onClick={onClick}
    >
      {children}
    </AnchorWrapper>
  );
};
