import axios from 'axios';
import { getToken } from './auth/auth';

const baseUrl = process.env.REACT_APP_API_URL;

export const getAllJobs = async () => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}/dev/v1/job`,
      method: 'get',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res.data.result;
  } catch (err) {
    console.log('getCategory error: ', err);
    return err;
  }
};
export const getJobById = async (jobId: string) => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}/dev/v1/job/${jobId}`,
      method: 'get',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res.data.result.job;
  } catch (err) {
    console.log('getCategory error: ', err);
    return err;
  }
};

// export const createCategory = async ({ name }: { name: string }) => {
//   try {
//     const token = getToken();
//     const res = await axios({
//       url: `${baseUrl}/category/v1/category`,
//       data: { name },
//       method: 'post',
//       headers: {
//         'content-type': 'application/json',
//         Authorization: token,
//       },
//     });
//     return res;
//   } catch (err) {
//     console.log('createCategory post error: ', err);
//     return err;
//   }
// };
