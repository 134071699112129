import Button from 'app/components/UI/atoms/Button';
import { Header } from 'app/components/UI/organisms/Header';
import { A } from 'app/components/UI/atoms/Anchor';
import {
  ContentDivWrapper,
  DropDownDiv,
  // ArrowDown,
  DropDownContainer,
  MenuTogglerDiv,
} from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';

import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import React, { useEffect, useState, useRef } from 'react';
import spinnerLoad from 'app/assets/images/loader.gif';
import {
  BodyDiv,
  CatDiv,
  SearchInpDiv,
  SearchWrap,
  SearchLayout,
} from './RenameLaterAssessmentStyles';
import { AssessmentTable } from './AssessmentTable';
import filterIcon from 'app/assets/images/filter.svg';
import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import { Input } from 'app/components/UI/atoms/Input';
import { ModalContainer } from 'app/components/UI/Modal/MessageModelStyles';
import { Modal } from 'app/components/UI/Modal/SimpleModal';
import { Title } from 'app/components/UI/atoms/Titles';
// import { AddQuestionModal } from '../QuestionBank/addQuestion/addQuestionModal';
import { useNavigate } from 'react-router-dom';
import { getAllAssessments } from 'app/services/assessments';
// import { addQuestion } from 'app/services/questions';
import thinkingIcon from 'app/assets/images/thinking-face.svg';
import { OopsSpan } from 'app/components/pages/QuestionBank/questionsStyles';
import { getUserId } from 'app/services/users';

export const Assessments = () => {
  // const { categoryId } = useParams();
  const { isShown, toggle } = useModal();

  const navigate = useNavigate();
  const [assessmentList, setAssessmentList] = useState<any>();
  const [firstLoadData, setFirstLoadData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [filterSelected, setFilterSelected] = useState<string>('');
  const FilterdownRef = useRef(null); // Initialize with null

  const toggleAssessment = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const headers = [
    { id: 'assessmentName', name: 'Assessment Name' },
    // { id: 'technologies', name: 'Technologies' },
    // { id: 'respondents', name: 'Respondents' },
    { id: 'createby', name: 'Created By' },
    { id: 'createdDate', name: 'Created On' },
    { id: 'validity', name: 'Valid Till' },
    { id: 'cheatDetectionEnabled', name: 'Cheat Detection' },
    { id: 'status', name: 'Status' },
    { id: 'action', name: 'Action' },
  ];

  const fetchAssessments = async () => {
    const assessmentResp = await getAllAssessments();
    const assessmentRespSorted = assessmentResp?.sort((a, b) => {
      return b.dateUpdated - a.dateUpdated;
    });
    setAssessmentList(assessmentRespSorted);
    setFirstLoadData(assessmentRespSorted);
    setIsLoading(!isLoading);
  };

  useEffect(() => {
    fetchAssessments();
    const handleClickOutside = event => {
      if (
        FilterdownRef.current &&
        !(FilterdownRef.current as HTMLElement).contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Detach the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // window.onclick = e => {
    //   if (e.target !== FilterdownRef.current) {
    //     setDropdownOpen(false);
    //   }
    // };
    // eslint-disable-next-line
  }, []);
  // const startsWith = (array, key) => {
  //   const matcher = new RegExp(`^${key.toLowerCase()}`, 'g');
  //   return array.filter(word => word.toLowerCase().match(matcher));
  // };
  const handleSearch = event => {
    const newObj = firstLoadData?.filter(fl => {
      return fl.name.toLowerCase().includes(event.target.value.toLowerCase());
    });
    console.log('result', newObj);
    setAssessmentList(newObj);
  };

  const handleFilter = filterBy => {
    setFilterSelected(filterBy);
    let newObj = {};
    const userId = getUserId();
    if (filterBy === 'me') {
      newObj = firstLoadData.filter(fl => {
        return fl.createdById === userId;
      });
    } else if (filterBy === 'org') {
      newObj = firstLoadData.filter(fl => {
        return fl.createdById !== userId;
      });
    } else {
      newObj = firstLoadData;
    }

    console.log('result', newObj);
    setAssessmentList(newObj);
    toggleAssessment();
  };

  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <SearchWrap>
              <CatDiv>
                <Title> Assessment List</Title>
                <Button
                  type="button"
                  content="Create Assessment"
                  className="create-assessment"
                  bgColor="#fdb82c"
                  onClick={() => navigate('/create-assessment')}
                />
              </CatDiv>
            </SearchWrap>
            <SearchLayout>
              <SearchInpDiv>
                <Input
                  id={'search'}
                  type={'text'}
                  label={'Search '}
                  onChange={handleSearch}
                  placeholder="search"
                />
                <Button type="button" content="" />
              </SearchInpDiv>
              <div className="filter-dropdown" ref={FilterdownRef}>
                <MenuTogglerDiv
                  tabIndex={0}
                  // ref={FilterdownRef}
                  onClick={toggleAssessment}
                >
                  <div className="filter-icon">
                    {' '}
                    <img src={filterIcon} alt="filter" ref={FilterdownRef} />
                  </div>
                </MenuTogglerDiv>
                {isDropdownOpen && (
                  <DropDownDiv className="tempClass">
                    {isDropdownOpen && (
                      <DropDownContainer>
                        <A onClick={() => handleFilter('me')}>
                          <>
                            {filterSelected === 'me' ? (
                              <>&#x2713; &nbsp;</>
                            ) : (
                              ''
                            )}
                            created by me
                          </>
                        </A>
                        <A onClick={() => handleFilter('org')}>
                          <>
                            {filterSelected === 'org' ? (
                              <>&#x2713; &nbsp;</>
                            ) : (
                              ''
                            )}
                            created by org
                          </>
                        </A>
                        <A onClick={() => handleFilter('all')}>
                          <>
                            {filterSelected !== 'me' &&
                            filterSelected !== 'org' ? (
                              <>&#x2713; &nbsp;</>
                            ) : (
                              ''
                            )}
                            created by all
                          </>
                        </A>
                      </DropDownContainer>
                    )}
                  </DropDownDiv>
                )}
              </div>
            </SearchLayout>
            {isLoading === true ? (
              <img
                src={spinnerLoad}
                alt=""
                width={100}
                height={100}
                style={{ marginTop: '20%', marginLeft: '40%' }}
              />
            ) : assessmentList && assessmentList.length > 0 ? (
              <AssessmentTable headers={headers} rowData={assessmentList} />
            ) : (
              <>
                <img src={thinkingIcon} alt="thinking face" />
                <OopsSpan>Oops! There are no Assessments.</OopsSpan>
              </>
            )}
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
      <ModalContainer>
        <Modal
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={false}
          modalContent={<div></div>}
        />
      </ModalContainer>
    </>
  );
};
