import React, { useEffect, useState, useRef } from 'react';

import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import {
  ContentDivWrapper,
  DropDownContainer,
  DropDownDiv,
  MenuTogglerDiv,
} from 'app/components/UI/organisms/Header/headerStyles';
import { Header } from 'app/components/UI/organisms/Header';
import spinnerLoad from 'app/assets/images/loader.gif';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
// import Breadcrumb from 'app/components/UI/molecules/Breadcrumb';
import {
  BodyDiv,
  SearchWrap,
  CatDiv,
  SearchInpDiv,
  OopsSpan,
  StyledSearchLayout,
} from './questionsStyles';
import thinkingIcon from 'app/assets/images/thinking-face.svg';
import { getAllCategories } from 'app/services/categories';
import { getAllQuestions } from 'app/services/questions';
import { Title } from 'app/components/UI/atoms/Titles';
import Button from 'app/components/UI/atoms/Button';
import { Input } from 'app/components/UI/atoms/Input';
// import { CategoryList } from 'app/components/UI/organisms/CategoriesList';
import CategoryList from './CategoryList';
import { QuestionList } from './QuestionList';
import { AddQuestionModal } from './addQuestion/addQuestionModal';
import { ViewQuestionModal } from './viewQuestion/viewQuestionModal';
// import { AddCategoryBtn } from './CategoryStyles';
import { ButtonDiv } from 'app/components/UI/organisms/McqsQuestionModal/mcqQuestionMOdalStyles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import filterIcon from 'app/assets/images/filter.svg';
import { getUserId } from 'app/services/users';
import { A } from 'app/components/UI/atoms/Anchor';

export const Questions = () => {
  const [title, setTitle] = useState('Technology Categories');
  const [categories, setCategories] = useState([]);
  const [firstCategoryLoad, setFirstCategoryLoad] = useState([]);
  const [questions, setQuestions] = useState<any>([]);
  const [firstQuestionsLoad, setfirstQuestionsLoad] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCategoryName, setSelectedCategoryName] = useState<string>('');
  const [display, setDisplay] = useState('category');
  const [viewQuestObj, setViewQuestObj]: any = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [filterSelected, setFilterSelected] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const submitRef = useRef(null);
  const saveDraftRef = useRef(null);
  const FilterdownRef = useRef(null); // Initialize with null
  const userId = window.localStorage.getItem('ui');

  const getCategories = async () => {
    let allCategories = await getAllCategories();
    // const sortedCategoriesList = allCategories.sort((a, b) => {
    //   return b.dateUpdated - a.dateUpdated;
    // });
    // setIsLoading(!isLoading);
    if (
      typeof allCategories === 'undefined' ||
      allCategories.length < 1 ||
      allCategories?.name === 'AxiosError'
    ) {
      allCategories = [];
    }
    setCategories(allCategories);
    setFirstCategoryLoad(allCategories);
    setIsLoading(!isLoading);
  };

  const handleSearch = event => {
    if (display === 'category') {
      const newObj = firstCategoryLoad.filter((fl: any) => {
        return fl.name.toLowerCase().includes(event.target.value.toLowerCase());
      });
      setCategories(newObj);
    } else if (display === 'question') {
      const newObj = firstQuestionsLoad.allQuestions.filter((fl: any) => {
        return fl.question.text
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setQuestions({ ...firstQuestionsLoad, allQuestions: newObj });
    }
  };

  const addComponent = () => {
    console.log('clicked add component: ', display);
    if (display === 'question') {
      console.log('display to add question');
      setTitle('Create Question');
      setDisplay('addQuestion');
      navigate(`/question-bank/category/${categoryId}/create`);
    }
  };

  const viewQuest = (categoryId, questObj, choices) => {
    setDisplay('viewQuestion');
    setViewQuestObj({ ...questObj, choices });
    setTitle('View Question');
    setDisplay('viewQuestion');
  };

  const editQuest = () => {
    setDisplay('editQuestion');
    // setViewQuestObj({ ...questObj, choices });
    setTitle('Edit Question');
    setDisplay('editQuestion');
  };

  const cancelEditQuestModal = () => {
    setDisplay('viewQuestion');
    setViewQuestObj(viewQuestObj);
    setTitle('View Question');
    setDisplay('viewQuestion');
  };

  const fetchQuestions = async (catId: any, name: string | null = null) => {
    console.log(selectedCategory);
    setIsLoading(true);
    setSelectedCategory(catId);
    setSelectedCategoryName(name as string);
    // Fetch questions based on id
    if (catId) {
      const questions = await getAllQuestions(catId);
      setQuestions(questions);
      setfirstQuestionsLoad(questions);
      setDisplay('question');
      setIsLoading(false);
    }
  };

  const toggleFilter = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeQuesModal = () => {
    // if (selectedCategory) {
    //   fetchQuestions(selectedCategory);
    // }
    navigate(-1);
  };

  const onAddQuestionSubmit = (data: any) => {
    console.log('User submitted new question: ', data);
  };

  // const saveToDraft = e => {
  //   // e.preventDefault();
  //   console.log('Saving.. to draft');
  //   // @ts-ignore
  //   saveDraftRef.current.click();
  // };

  useEffect(() => {
    getCategories();
    const handleClickOutside = event => {
      if (
        FilterdownRef.current &&
        !(FilterdownRef.current as HTMLElement).contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Detach the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === '/question-bank/category') {
      setTitle('Technology Categories');
      setDisplay('category');
    }

    if (
      /^\/question-bank\/category\/[0-9a-f-]+\/questions$/.test(
        location.pathname,
      )
    ) {
      const { categoryName } = location.state as { categoryName: string };
      fetchQuestions(categoryId, categoryName);
    }

    if (
      /^\/question-bank\/category\/[0-9a-f-]+\/create$/.test(location.pathname)
    ) {
      console.log('display to add question');
      setTitle('Create Question');
      setDisplay('addQuestion');
    }

    if (
      /^\/question-bank\/category\/[0-9a-f-]+\/questions\/[0-9a-f-]+$/.test(
        location.pathname,
      )
    ) {
      const { categoryId, question, answers } = location.state as {
        categoryId: string;
        question: any;
        answers: any;
      };
      viewQuest(categoryId, question, answers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, categoryId]);

  useEffect(() => {
    if (display === 'question' && selectedCategoryName !== '')
      setTitle(`Question List - ${selectedCategoryName}`);
    else if (display === 'question') setTitle('Question List');
  }, [display, selectedCategoryName]);

  const submitHandler = e => {
    e.preventDefault();
    // @ts-ignore
    submitRef.current.click();
  };

  const handleFilter = filterBy => {
    setFilterSelected(filterBy);
    const { allQuestions } = questions;
    const userId = getUserId();
    if (filterBy === 'me') {
      const filteredQuestions = allQuestions.filter(
        question => question.question.createdBy === userId,
      );
      console.log('result', filteredQuestions);
      setQuestions({ allQuestions: filteredQuestions });
    } else {
      setQuestions(firstQuestionsLoad);
    }
    toggleFilter();
  };

  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            {isLoading === true ? (
              <img
                src={spinnerLoad}
                alt=""
                width={100}
                height={100}
                style={{ marginTop: '20%', marginLeft: '40%' }}
              />
            ) : firstCategoryLoad && firstCategoryLoad.length > 0 ? (
              <>
                <SearchWrap>
                  <CatDiv>
                    {/* <div className="breadcrumbwrapper">
                      <Breadcrumb url="/assessments" text="Assessment List" />
                      <Breadcrumb
                        url="/create-assessment"
                        text="Create Assessment"
                      />
                    </div> */}
                    <Title className="category-list">{title}</Title>
                    {(display === 'category' || display === 'question') && (
                      <StyledSearchLayout>
                        <SearchInpDiv>
                          <Input
                            id={'search'}
                            type={'text'}
                            label={'Search '}
                            onChange={handleSearch}
                            placeholder="search"
                          />
                          <Button
                            type="button"
                            content=""
                            style={{ maxWidth: '10px' }}
                          />
                        </SearchInpDiv>
                        {display === 'question' && (
                          <div className="filter-dropdown" ref={FilterdownRef}>
                            <MenuTogglerDiv tabIndex={0} onClick={toggleFilter}>
                              <div className="filter-icon">
                                {' '}
                                <img src={filterIcon} alt="filter" />
                              </div>
                            </MenuTogglerDiv>
                            {isDropdownOpen && (
                              <DropDownDiv className="tempClass">
                                {isDropdownOpen && (
                                  <DropDownContainer>
                                    <A onClick={() => handleFilter('me')}>
                                      <>
                                        {filterSelected === 'me' ? (
                                          <>&#x2713; &nbsp;</>
                                        ) : (
                                          ''
                                        )}
                                        created by me
                                      </>
                                    </A>
                                    <A onClick={() => handleFilter('all')}>
                                      <>
                                        {filterSelected !== 'me' &&
                                        filterSelected !== 'org' ? (
                                          <>&#x2713; &nbsp;</>
                                        ) : (
                                          ''
                                        )}
                                        created by all
                                      </>
                                    </A>
                                  </DropDownContainer>
                                )}
                              </DropDownDiv>
                            )}
                          </div>
                        )}
                      </StyledSearchLayout>
                    )}
                    {display === 'question' && (
                      <ButtonDiv>
                        <Button
                          type="button"
                          content="Create Question"
                          className="save"
                          onClick={() => addComponent()}
                        />
                      </ButtonDiv>
                    )}
                    {display === 'addQuestion' && (
                      <ButtonDiv>
                        <Button
                          type="submit"
                          content="Save"
                          className="save"
                          onClick={submitHandler}
                        />
                        <Button
                          type="button"
                          content="Cancel"
                          className="cancel"
                          onClick={() => navigate(-1)}
                        />
                        {/* <Button
                          type="button"
                          content="Save as Draft"
                          className="cancel"
                          onClick={saveToDraft}
                        /> */}
                      </ButtonDiv>
                    )}
                    {display === 'viewQuestion' && (
                      <ButtonDiv>
                        <Button
                          type="button"
                          content="Back"
                          className="cancel"
                          onClick={() => navigate(-1)}
                        />
                        {viewQuestObj.createdBy === userId &&
                          viewQuestObj.status === 'draft' && (
                            <Button
                              type="button"
                              content="Edit Question"
                              className="cancel"
                              onClick={() => editQuest()}
                            />
                          )}
                      </ButtonDiv>
                    )}
                    {display === 'editQuestion' &&
                      viewQuestObj.createdBy === userId && (
                        <ButtonDiv>
                          <Button
                            type="button"
                            content="Cancel"
                            className="cancel"
                            onClick={() => cancelEditQuestModal()}
                          />
                          <Button
                            type="submit"
                            content="Save"
                            className="save"
                            onClick={submitHandler}
                          />
                        </ButtonDiv>
                      )}
                  </CatDiv>
                </SearchWrap>
                {display === 'category' && (
                  <>
                    {categories && categories?.length > 0 ? (
                      <CategoryList
                        data={categories}
                        getQuestions={fetchQuestions}
                      />
                    ) : (
                      <>
                        <img src={thinkingIcon} alt="thinking face" />
                        <OopsSpan>
                          Oops! There are no Categories with the searched terms.
                        </OopsSpan>
                      </>
                    )}
                  </>
                )}
                {display === 'question' && (
                  <>
                    {questions &&
                    questions?.allQuestions &&
                    questions?.allQuestions.length > 0 ? (
                      <QuestionList data={questions} viewQuest={viewQuest} />
                    ) : (
                      <>
                        <img src={thinkingIcon} alt="thinking face" />
                        <OopsSpan>
                          {!firstQuestionsLoad ||
                          firstQuestionsLoad?.allQuestions.length <= 0 ? (
                            <>Oops! There are no Questions added yet.</>
                          ) : (
                            <>
                              Oops! There are no Questions with the searched
                              terms.
                            </>
                          )}
                        </OopsSpan>
                      </>
                    )}
                  </>
                )}
                {display === 'addQuestion' && (
                  <AddQuestionModal
                    categoryId={categoryId}
                    onAddQuestionSubmit={onAddQuestionSubmit}
                    saveDraftRef={saveDraftRef}
                    closeQuesModal={closeQuesModal}
                    categories={categories}
                    submitRef={submitRef}
                  />
                )}
                {display === 'viewQuestion' && (
                  <ViewQuestionModal
                    categoryId={categoryId}
                    onAddQuestionSubmit={onAddQuestionSubmit}
                    saveDraftRef={saveDraftRef}
                    closeQuesModal={closeQuesModal}
                    questData={viewQuestObj}
                    categories={categories}
                    submitRef={submitRef}
                  />
                )}
                {display === 'editQuestion' && (
                  <ViewQuestionModal
                    categoryId={categoryId}
                    onAddQuestionSubmit={onAddQuestionSubmit}
                    saveDraftRef={saveDraftRef}
                    closeQuesModal={closeQuesModal}
                    questData={viewQuestObj}
                    categories={categories}
                    displayType={display}
                    submitRef={submitRef}
                  />
                )}
              </>
            ) : (
              <>
                <img src={thinkingIcon} alt="thinking face" />
                <OopsSpan>Oops! There are no Categories added yet.</OopsSpan>
                {/* <Button
                  type="button"
                  content="+ Add Question"
                  bgColor="#fdb82c"
                /> */}
              </>
            )}
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
    </>
  );
};
