/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import fairhireelogo from 'app/assets/images/fairhirewhite.svg';
import resetpwdIcon from 'app/assets/images/resetpwd-delivery.svg';
import resetpwdOtpPageIcon from 'app/assets/images/resetpwd-otp.svg';
import OtpInput from 'react-otp-input';
import {
  FooterDiv,
  InputContainer,
  JobListingContainer,
  JobListingHomeHeader,
  JobListingHomeHeaderLogo,
  JumpImg,
  LoginContentWrapper,
  LoginFormWrapper,
  LoginHeadingWrapper,
  Menu,
  NavMenu,
  OtpSpan,
} from './Styles/resetPasswordStyles';
import {
  Message,
  ModalContainer,
  ModelContent,
  MailIcon,
} from 'app/components/UI/Modal/MessageModelStyles';
import { Modal } from 'app/components/UI/Modal/SimpleModal';

import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';

import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import { useForm } from 'react-hook-form';
import { sendOtp, verifyOtp, setPassword } from 'app/services/auth/auth';
import {} from 'app/services/users';
import { ErrorDiv } from '../LoginModal/loginStyles';
import { Input } from 'app/components/UI/atoms/Input';

import otpSentIcon from 'app/assets/images/otp-sent.svg';
import resetpwdSuccessIcon from 'app/assets/images/resetpwd-success.svg';

import Button from 'app/components/UI/atoms/Button';
import buttonLoader from 'app/assets/images/loader-black.svg';

export const ResetPwdPage = () => {
  const { isShown, toggle } = useModal();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    getValues,
  } = useForm();

  const [actionType, setActionType] = useState<string>('login');

  const [isLoading, setLoading] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [otp, setOtp] = useState('');

  const sendOtpHandler = handleSubmit(async (data: any) => {
    try {
      setLoading(true);
      const emailValue = getValues('email');
      await sendOtp(emailValue);
      toggle();
      setUserEmail(emailValue);
      setLoading(false);
      setActionType('resetPwd');
    } catch (err) {
      setError('email', {
        type: 'server',
        message: 'Couldnt send otp!',
      });
      console.error('Error sending OTP:', err);
      setLoading(false);
    }
  });

  const changePasswordHandler = handleSubmit(async (data: any) => {
    try {
      setLoading(true);
      // const otpValue = otp;
      const password = getValues('password');
      const repassword = getValues('repassword');
      if (password !== repassword) {
        return setError('password', {
          type: 'client',
          message: 'Passwords does not match!',
        });
      }
      const { userId, token } = await verifyOtp(userEmail, otp);
      await setPassword(userId, password, token);
      setLoading(false);
      toggle();
      setTimeout(() => {
        navigate('/');
      }, 2500);
    } catch (err) {
      setError('repassword', {
        type: 'server',
        message: 'Couldnt set new password!',
      });
      setLoading(false);
    }
  });

  const otpSentContent = (
    <ModelContent>
      <MailIcon>
        <img src={otpSentIcon} alt="wait-icon" width="300px" height="200px" />
      </MailIcon>
      <Message>
        <PrimaryParagraph>
          Code Courier: Your Secure 6-Digit OTP En Route ToYour Inbox.
        </PrimaryParagraph>
      </Message>
    </ModelContent>
  );
  const successContent = (
    <ModelContent>
      <MailIcon>
        <img
          src={resetpwdSuccessIcon}
          alt="wait-icon"
          width="300px"
          height="200px"
        />
      </MailIcon>
      <Message>
        <PrimaryParagraph>
          Passcode Power-Up Congratulations on Resetting Your Password!
        </PrimaryParagraph>
      </Message>
    </ModelContent>
  );
  return (
    <JobListingContainer>
      <JobListingHomeHeader>
        <JobListingHomeHeaderLogo>
          <img src={fairhireelogo} alt="Logo" onClick={() => navigate('/')} />
        </JobListingHomeHeaderLogo>
        <NavMenu>
          <a href="https://www.fairhiree.com">Home</a>
          <a href="https://www.fairhiree.com/products">Products</a>
          <a href="https://www.fairhiree.com/blog/blog">Blog</a>
          <a href="https://share.hsforms.com/1GOyZZdJ8T1Sz06KoQpUDcgqk5d2">
            Demo
          </a>
          <a href="https://www.fairhiree.com/contact-us">Contact Us</a>
        </NavMenu>

        <Menu>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="#ffffff"
              d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"
            />
          </svg>
        </Menu>
      </JobListingHomeHeader>
      {actionType === 'resetPwd' ? (
        <LoginContentWrapper>
          <LoginHeadingWrapper>
            <h1>
              Let's perform a<br />
              <b>Digital Locksmithing!</b>
            </h1>

            <h4>Spice Up Your Password with a Reset </h4>
            <JumpImg>
              <img src={resetpwdOtpPageIcon} alt="jump" />
            </JumpImg>
          </LoginHeadingWrapper>

          <LoginFormWrapper>
            <form noValidate={true}>
              {errors?.repassword?.type === 'server' ? (
                <ErrorDiv>
                  {(errors?.repassword?.message as string) ||
                    'Problem setting new password!'}
                </ErrorDiv>
              ) : (
                ''
              )}
              {errors?.password?.type === 'client' ? (
                <ErrorDiv>
                  {(errors?.password?.message as string) ||
                    'Problem setting new password!'}
                </ErrorDiv>
              ) : (
                ''
              )}
              {errors?.password?.type === 'required' ? (
                <ErrorDiv>Enter password!</ErrorDiv>
              ) : errors?.repassword?.type === 'required' ? (
                <ErrorDiv>Enter re-password!</ErrorDiv>
              ) : errors?.otp?.type === 'required' ? (
                <ErrorDiv>Enter otp!</ErrorDiv>
              ) : (
                ''
              )}

              <InputContainer className="otp-container">
                <OtpSpan>OTP </OtpSpan>
                <OtpInput
                  inputType="number"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>&nbsp;</span>}
                  renderInput={props => <input {...props} />}
                  inputStyle="otp-input"
                  // shouldAutoFocus
                />
              </InputContainer>
              <InputContainer>
                <Input
                  id="password"
                  type="password"
                  label="Please enter your new password"
                  placeholder="New password"
                  {...register('password', {
                    required: true,
                  })}
                  required
                />
              </InputContainer>
              <InputContainer>
                <Input
                  id="repassword"
                  type="password"
                  label="Retype Password"
                  placeholder="Retype Password"
                  {...register('repassword', {
                    required: true,
                  })}
                  required
                />
              </InputContainer>
              {/* <SignInButton type="submit" onClick={changePasswordHandler}>
                Reset
              </SignInButton> */}
              <Button
                onClick={changePasswordHandler}
                type="submit"
                className="mainsavebutton"
                content="Reset"
                bgColor="#fdb82c"
                leftIcon={isLoading === true ? buttonLoader : ''}
                disabled={isLoading === true ? true : false}
              />
            </form>
          </LoginFormWrapper>
        </LoginContentWrapper>
      ) : (
        <LoginContentWrapper>
          <LoginHeadingWrapper>
            <h1>
              <b>Forgotten</b> Secrets?
              <br />
              Reset Your <b>Password</b>{' '}
            </h1>
            <h3>in Style </h3>

            <h4>Let Us Save the Day! </h4>
            <JumpImg>
              <img src={resetpwdIcon} alt="jump" />
            </JumpImg>
          </LoginHeadingWrapper>

          <LoginFormWrapper>
            <form noValidate={true}>
              {errors?.email?.type === 'server' ? (
                <ErrorDiv>
                  {(errors?.email?.message as string) ||
                    'Incorrect email or password!'}
                </ErrorDiv>
              ) : (
                ''
              )}
              {errors?.email?.type === 'required' ? (
                <ErrorDiv>Enter user email!</ErrorDiv>
              ) : (
                ''
              )}
              <InputContainer>
                {/* <input type="email" placeholder="Enter Email " required /> */}
                <Input
                  id="email"
                  type="text"
                  label="Enter Email"
                  placeholder="Enter Email"
                  {...register('email', {
                    required: true,
                  })}
                  required
                />
              </InputContainer>
              {/* <SignInButton type="submit" onClick={sendOtpHandler}>
                Send OTP
              </SignInButton> */}
              <Button
                onClick={sendOtpHandler}
                type="submit"
                className="mainsavebutton"
                content="Send OTP"
                bgColor="#fdb82c"
                leftIcon={isLoading === true ? buttonLoader : ''}
                disabled={isLoading === true ? true : false}
              />
            </form>
          </LoginFormWrapper>
        </LoginContentWrapper>
      )}
      <FooterDiv> ​Copyright @2024 | Fairhiree India Technology</FooterDiv>
      <ModalContainer>
        <Modal
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={true}
          width="40%"
          modalContent={otp !== '' ? successContent : otpSentContent}
        />
      </ModalContainer>
    </JobListingContainer>
  );
};
