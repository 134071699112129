// import Button from 'app/components/UI/atoms/Button';
import { Input } from 'app/components/UI/atoms/Input';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// import fairhirewhite from 'app/assets/images/fairhirewhite.svg';
// import ReactToPrint from 'react-to-print';

import {
  AddLanguage,
  // CityBox,
  FlexTab,
  HelloHeading,
  LabelSwitch,
  // LanguageSelect,
  LanguageWrapper,
  ProffecencySelect,
  Slider,
  Switch,
  SwitchWrapper,
  StepperContainer,
  TabPane,
  TabPane2,
  Next,
  NextButton,
  StepperCommonContainer,
  RemoveButton,
  LanguageInput,
} from '../ResumeInfoStyles';
const BasicInformation = ({
  activeStep,
  steps,
  setActiveStep,
  handleFormData,
  formStepperData,
  basicInfoData,
}) => {
  const [serviceList, setServiceList] = useState([{ service: '' }]);
  // const [inputValue, setInputValue] = useState('');
  // const [isValid, setIsValid] = useState(true);
  // const [inputVaalue, setInputVaalue] = useState('');
  // const [isValidd, setIsValidd] = useState(true);

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: '' }]);
  };
  const handleServiceRemove = index => {
    const List = [...serviceList];
    List.splice(index, 1);
    setServiceList(List);
  };

  // const handleInputChange = event => {
  //   const newValue = event.target.value;
  //   const isAlphabet = /^[A-Za-z]+$/.test(newValue);
  //   if (isAlphabet || newValue === '') {
  //     setInputValue(newValue);
  //     setIsValid(true);
  //   } else {
  //     setIsValid(false);
  //   }
  // };
  // const handleInnnputChange = event => {
  //   const newValue = event.target.value;
  //   const isAlphabet = /^[A-Za-z]+$/.test(newValue);
  //   if (isAlphabet || newValue === '') {
  //     setInputVaalue(newValue);
  //     setIsValidd(true);
  //   } else {
  //     setIsValidd(false);
  //   }
  // };

  // const inputStyles = {
  //   border: isValid ? '1px solid black' : '3px solid red',
  //   borderBottom: isValid ? 'none' : '3px solid red',
  // };
  // const inputSthyles = {
  //   border: isValidd ? '1px solid black' : '3px solid red',
  //   borderBottom: isValidd ? 'none' : '3px solid red',
  // };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const basicInformationHandle = data => {
    console.log('7823468', data);
    handleFormData(data);
    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <TabPane>
        <HelloHeading>
          “Hello.. you are very few steps away from your
          <span> Unique Resume”</span>
        </HelloHeading>
        <StepperContainer>
          <TabPane2> Basic Information</TabPane2>
          <form
            onSubmit={handleSubmit(basicInformationHandle)}
            noValidate={true}
          >
            <FlexTab>
              <Input
                id="name"
                type="text"
                label="Name"
                placeholder="Name"
                autoComplete="off"
                defaultValue={basicInfoData.name}
                {...register('name', {
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'atleast 3 letters are requierd',
                  },
                  maxLength: {
                    value: 30,
                    message: 'You cant add letters which are more than 30',
                  },
                })}
                className={errors?.name?.type === 'required' ? 'invalid' : ''}
              />
              {/* {errors?.name?.type === 'required' ? (
                <ErrorDiv>Enter user email!</ErrorDiv>
              ) : (
                ''
              )} */}
              {/* {!isValid && (
                <div style={{ color: 'red' }}>Please enter only alphabets.</div>
              )} */}
              <Input
                type="text"
                id="role"
                placeholder="role"
                defaultValue={formStepperData.role}
                className={errors?.role?.type === 'required' ? 'invalid' : ''}
                {...register('role', {
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'atleast 3 letters are requierd',
                  },
                  maxLength: {
                    value: 30,
                    message: 'You cant add letters which are more than 30',
                  },
                })}
              />
              {/* <>{errors.role?.message} </>
              {!isValid && (
                <div style={{ color: 'red' }}>Please enter only alphabets.</div>
              )} */}

              <Input
                id="email"
                type="text"
                label="Please enter Email"
                aria-label="please enter your email"
                placeholder="Email"
                defaultValue={formStepperData.Email}
                className={errors?.email?.type === 'required' ? 'invalid' : ''}
                {...register('email', {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                required
              />
              <Input
                type="number"
                id="phone"
                placeholder="Phone"
                defaultValue={formStepperData.phone_no}
                maxLength={12}
                className={errors?.phone?.type === 'required' ? 'invalid' : ''}
                {...register('phone', {
                  required: true,
                  minLength: {
                    value: 10,
                    message: 'atleast 10 digit are requierd',
                  },
                  maxLength: {
                    value: 12,
                    message: 'You cant add more than 12 digits',
                  },
                })}
              />
              {/* {errors?.phone_no?.type === 'required' ? (
                <ErrorDiv>Enter phone number!</ErrorDiv>
              ) : errors?.phone_no?.type === 'minLength' ? (
                <ErrorDiv>10 digit are required!</ErrorDiv>
              ) : (
                ''
              )} */}
              <Input
                type="text"
                id="city"
                name="city"
                placeholder="City"
                defaultValue={formStepperData.city}
                className="input-text"
                required
                //   style={inputSthyles}
              />
              {/* {!isValidd && (
                <div style={{ color: ' red' }}>
                  Please enter only alphabets.
                </div>
              )} */}
            </FlexTab>

            {serviceList.map((singleService, index) => (
              <StepperCommonContainer>
                <LanguageWrapper key={index}>
                  <LanguageInput
                    type="text"
                    id="Language"
                    name="Language"
                    placeholder="Language"
                    className="input-text"
                    required
                  />
                  <ProffecencySelect defaultValue={formStepperData.proficiency}>
                    <option value="Type">Proficiency</option>
                    <option value="Type">Beginner</option>
                    <option value="Type">Intermidate</option>
                    <option value="Type">Advanced</option>
                    <option value="Type">Proficient</option>
                  </ProffecencySelect>

                  <SwitchWrapper>
                    <LabelSwitch> </LabelSwitch>
                    Read
                    <Switch>
                      {<input type="checkbox" id="toggleSwitch" />}

                      <Slider> </Slider>
                    </Switch>
                    <LabelSwitch> </LabelSwitch>
                    Write
                    <Switch>
                      <input type="checkbox" id="toggleSwitch" />

                      <Slider> </Slider>
                    </Switch>
                    <LabelSwitch> </LabelSwitch>
                    Speak
                    <Switch>
                      <input type="checkbox" id="toggleSwitch" />

                      <Slider> </Slider>
                    </Switch>
                  </SwitchWrapper>
                  {serviceList.length > 1 && (
                    <RemoveButton onClick={() => handleServiceRemove(index)}>
                      Remove
                    </RemoveButton>
                  )}
                </LanguageWrapper>
                {serviceList.length - 1 === index && serviceList.length < 6 && (
                  <AddLanguage type="submit" onClick={handleServiceAdd}>
                    Add Language
                  </AddLanguage>
                )}
              </StepperCommonContainer>
            ))}
            {activeStep !== steps.length - 1 && (
              <NextButton>
                <Next type="submit">Next</Next>
              </NextButton>
            )}
          </form>
        </StepperContainer>
      </TabPane>
    </>
  );
};

export default BasicInformation;
