import styled from 'styled-components';

export const InviteLinkWrapper = styled.div`
  display: flex;
  flex-direction: inherit;
  position: relative;
  justify-content: inherit;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 10px;
`;

export const InviteLinkTitle = styled.div`
  font-weight: 600;
  color: #3d3d3d;
  text-transform: capitalize;
  // margin: 0 20px;
  padding: 10px 15px;
  border-right: 1px solid #ccc;
  height: 38px;
  width: 38px;
  align-items: center;
`;

export const InviteLinkCard = styled.div`
  display: flex;
  /* min-width: 406px; */
  width: auto;
  height: 38px;
  /* background-color: #f9f9f9; */
  color: #000000;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 0 10px;
`;

export const CopyLinkButton = styled.div`
  padding: 10px 18px;
  position: absolute;
  right: 0px;
  width: auto;
  height: 38px;
  background: #fdb82c;
  align-items: 'center';
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  .main-copy-button {
    font-weight: bold;
  }
`;
