export const mockData = [
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    company: 'Infosys',
    role: 'Fullstack developer',
    experience: '4-7 years',
    location: 'Bangalore',
    salary: '₹ 2800000',
    postedDate: 'Few hours ago',
    dateCreated: 1690280019702,
    status: 'Applied',
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b911',
    company: 'Prime Innovatives',
    role: 'NodeJs developer',
    experience: '8-10 years',
    location: 'Gurugram',
    salary: '₹ 4000000',
    postedDate: '1 day back',
    dateCreated: 1690280019702,
    status: 'Selected for next round',
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b912',
    company: 'Right point solutions',
    role: 'NodeJs developer',
    experience: '6-8 years',
    location: 'Hyderabad',
    salary: '₹ 3000000',
    postedDate: '3 day back',
    dateCreated: 1690280019702,
    status: 'On Hold',
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b913',
    company: 'Everserve solutions',
    role: 'React developer',
    experience: '5-10 years',
    location: 'Bangalore',
    salary: '₹ 4000000',
    postedDate: '1 day back',
    dateCreated: 1690280019702,
    status: 'Job Offered',
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b914',
    company: 'Tech M',
    role: 'Fullstack developer',
    experience: '8-10 years',
    location: 'Bangalore',
    salary: '₹ 3500000',
    postedDate: '5 day back',
    dateCreated: 1690280019702,
    status: 'Shortlisted',
  },
];
