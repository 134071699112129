import React from 'react';
import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableTd,
  TableTh,
  TableWrapper,
  TableTdDiv,
  TdDivStatus,
  TdStatusDiv,
} from './ListStyles';
// import { getJobById } from 'app/services/jobs';
// import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
// import {
//   Message,
//   ModelContent,
// } from 'app/components/UI/Modal/MessageModelStyles';
// import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
// import { Title4 } from 'app/components/UI/atoms/Titles';
import appliedIcon from 'app/assets/images/applied-icon.svg';
import jobOfferedIcon from 'app/assets/images/job-offered-icon.svg';
import nextRoundIcon from 'app/assets/images/next-round-icon.svg';
import shortlistedIcon from 'app/assets/images/shortlisted-icon.svg';
import onHoldIcon from 'app/assets/images/onhold.svg';

export const JobListTable = ({ headers, rowData }) => {
  // const { isShown, toggle } = useModal();
  // const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [status, setStatus] = useState<Array<string>>([]);
  // const [action, setAction] = useState<boolean>(false);
  // const [id, setId] = useState<number>();
  // const [contentData, setContentData] = useState<any>();
  // const changeModalVisibility = (nval = !isShowModal, isSuccess = false) => {
  //   if (nval === true || nval === false) {
  //     setIsShowModal(nval);
  //   } else {
  //     setIsShowModal(false);
  //   }

  //   if (isSuccess === true) {
  //     // toggle();
  //   }
  // };
  // const showJobDetails = async (JobId: string) => {
  //   const jobResp = await getJobById(JobId);
  //   // setContentData(jobResp);
  //   // toggle();
  // };

  // const closeJobAction = () => {
  //   changeModalVisibility();
  //   // implement here
  //   // const closeResp = await closeTheJob();
  // };
  useEffect(() => {
    rowData &&
      rowData.length > 0 &&
      rowData.map(ques => {
        if (ques.status === 'ACTIVE') {
          status.push(ques.id);
          setStatus(status);
        }
        return ques;
      });
  }, [rowData, status]);
  // const processOptions = [
  //   // { label: 'Select Experience Level', value: '' },
  //   { label: 'Shortlisted', value: 'shortlisted' },
  //   { label: 'Select for next round', value: 'next-round' },
  //   { label: 'Rejected', value: 'rejected' },
  //   { label: 'On Hold', value: 'onhold' },
  //   { label: 'Selected for the job', value: 'selected' },
  // ];

  // const handleProcessChange = e => { };
  return (
    <TableWrapper>
      {/* {redirectToques ? (
        <Redirect
          to={{
            pathname: '/ques',
            state: { quesId: redirectToques },
          }}
        />
      ) : null} */}
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableTh key={header.id}>{header.name}</TableTh>
            ))}
            {/* <TableTh>Action</TableTh> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData &&
            rowData.length > 0 &&
            rowData.map((row, index) => {
              const statusIcon =
                row.status === 'Applied'
                  ? appliedIcon
                  : row.status === 'Selected for next round'
                  ? nextRoundIcon
                  : row.status === 'On Hold'
                  ? onHoldIcon
                  : row.status === 'Job Offered'
                  ? jobOfferedIcon
                  : row.status === 'Shortlisted'
                  ? shortlistedIcon
                  : '';
              return (
                <TableRow key={row.id}>
                  <TableTd
                  // onClick={() => {
                  //   handleShowQues(row.jobId);
                  // }}
                  >
                    {row.company}
                  </TableTd>
                  <TableTd>{row.role}</TableTd>
                  <TableTd>{row.experience}</TableTd>
                  <TableTd>{row.location}</TableTd>
                  <TableTd>
                    <TableTdDiv>{row.salary}</TableTdDiv>
                  </TableTd>
                  <TableTd>{row.postedDate}</TableTd>
                  <TableTd>
                    <TdDivStatus>
                      <TdStatusDiv>{row.status}</TdStatusDiv>
                      <img src={statusIcon} alt="status icon"></img>
                    </TdDivStatus>
                  </TableTd>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
