import React from 'react';
import {
  CategoryListWrapper,
  CategoryListHead,
  CategoryListBody,
  CategoryListTh,
  CategoryListTabDiv,
  CategoryListTable,
  CategoryListRow,
} from './CategoryStyles';
import Button from 'app/components/UI/atoms/Button';
import { useNavigate } from 'react-router-dom';

const CategoryList = ({ data, getQuestions }) => {
  const navigate = useNavigate();
  const headers = [
    '',
    'Category',
    'Available Levels',
    'Active Question Count',
    'Action',
  ];

  const showQuestions = cat => {
    console.log('category came as: ', cat.name);
    const { id, name } = cat;
    getQuestions(id, name);
    navigate(`/question-bank/category/${id}/questions`, {
      state: { categoryName: name },
    });
  };
  return (
    <CategoryListWrapper>
      <CategoryListTable className="techcategorytable">
        <CategoryListHead>
          <CategoryListRow>
            {headers.map((header, index) => (
              <CategoryListTh key={index}>{header}</CategoryListTh>
            ))}
          </CategoryListRow>
        </CategoryListHead>
        <CategoryListBody>
          {data &&
            data.length > 0 &&
            data.map(cat => {
              const getQuestCount = questCount => {
                let totalCount = 0;
                for (const level in questCount.mcq) {
                  if (questCount.mcq.hasOwnProperty(level)) {
                    totalCount += parseInt(questCount.mcq[level].total);
                  }
                }
                return totalCount;
              };
              return (
                <CategoryListRow key={cat.id}>
                  <CategoryListTabDiv>#</CategoryListTabDiv>
                  <CategoryListTabDiv>{cat.name}</CategoryListTabDiv>
                  <CategoryListTabDiv>
                    <div className="expert-level-wrapper">
                      <div className="expertise">Beginner</div>
                      <div className="expertise">Intermediate</div>
                      <div className="expertise">Expert</div>
                    </div>
                  </CategoryListTabDiv>
                  <CategoryListTabDiv>
                    {getQuestCount(cat.questionCount)}
                  </CategoryListTabDiv>
                  <CategoryListTabDiv>
                    <Button
                      type="submit"
                      content="Add / View Question"
                      className="add-view-question"
                      bgColor="#fdb82c"
                      onClick={() => showQuestions(cat)}
                    ></Button>
                  </CategoryListTabDiv>
                </CategoryListRow>
              );
            })}
        </CategoryListBody>
      </CategoryListTable>
    </CategoryListWrapper>
  );
};

export default CategoryList;
