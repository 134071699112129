import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 50%;
  height: auto;
`;

export const ModelContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  font-weight: 400;
  width: 100%;
  height: auto;
  font-family: 'Montserrat', sans-serif;
`;

export const ModalHeader = styled.div`
  display: flex;
  font-size: inherit;
  align-items: center;
  justify-content: center;
`;

export const MailIcon = styled.div`
  font-size: 14px;
  align-self: center;
  justify-self: center;
  .success-icon {
    margin-top: 20px;
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
`;

export const ModalProgressBar = styled.div`
  margin-top: 10px;
  width: 100%;
`;
