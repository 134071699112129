import styled from 'styled-components';

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 21px;
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  :checked + .slider::before {
    transform: translateX(26px);
    background-color: #519872;
  }

  :checked + .slider {
    background-color: #9fe6a0;
  }
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #ff0000;
  transition: 0.4s;
  border-radius: 34px;

  ::before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 0px;
    bottom: 0px;
    background-color: #ffffff;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  }

  .slider.rounded {
    border-radius: 34px;
  }
  .slider.rounded::before {
    border-radius: 50%;
  }
`;
