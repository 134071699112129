import styled from 'styled-components';
import searchIcon from 'app/assets/images/searchIcon.svg';

export const BreadCrumb = styled.div`
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;
export const BodyDiv = styled.div`
  width: 100%;
  padding: 0px;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #3d3d3d;
  }
  .jobs-wrapper {
    display: flex;
    justify-content: space-between;
  }
`;

export const SearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fdb82c;
  margin-top: 20px;
  select {
    width: 133px;
    cursor: pointer;
    margin-left: 20px;
    height: 36px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  h5 {
    font-weight: 600;
    font-size: 16px;
    color: #3d3d3d;
    margin: 20px;
  }
  .btnSaveDraft {
    border: solid 1px #000;
    padding: 10px 15px;
    color: #000;
  }
  .btnCancel {
    border: solid 1px #000;
    padding: 10px 15px;
    color: #000;
    background: transparent;
  }

  .mainsavebutton,
  .btnCancel,
  .btnSaveDraft {
    position: relative;
  }

  .btnCancel img,
  .mainsavebutton img,
  .btnSaveDraft img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  button {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    padding: 10px 15px;
    width: auto;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border: solid 1px #000;
    border-radius: 5px;
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 0;
  }
`;
export const CatDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  .breadcrumbwrapper {
    display: flex;
  }
  .category-list {
    margin-top: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  h1 {
    text-align: left;
  }
`;
export const ContentDiv = styled.div`
  .post-job-content-flex {
    margin-bottom: 20px;
  }
  .btnAddTechnology {
    border: none;
    position: relative;
    background-color: #000000;
    font-weight: bold;
    color: #ffffff;
    float: right;
    bottom: 37px;
    right: 3%;
    margin-top: 0px;
    padding: 10px 20px;
  }
`;
export const ContentSubDiv = styled.div`
  background: #f6f6f6;
  border-radius: 5px;
  padding: 25px;
  position: relative;
  .base-wrapper-category {
    display: flex;
    flexbasis: 100%;
    position: relative;
  }

  @media screen and (max-width: 1000px) {
    .base-wrapper-category,
    .validity-duration-wrapper {
      flex-direction: column;
    }
    .percentage-wrapper,
    .duration-wrapper,
    .first-wrapper {
      width: 100% !important;
    }
  }
  .assessment-title-input {
    width: 94%;
  }
  .test-name-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
  }

  .first-wrapper {
    width: 40%;
    padding-right: 0px;
  }
  .percentage-wrapper,
  .duration-wrapper {
    width: 20%;
  }

  .test-name-wrapper input {
    text-indent: 5px !important;
  }

  .name-label {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .assessment-title-duration {
  }
  h1 {
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 16px;
    color: #3d3d3d;
  }
  button {
    float: right;
  }
  input {
    background-color: transparent;
  }

  .textareawrapper {
    width: 100%;
    display: block;
  }
  select {
    padding: 0px 18px 0px 5px !important;
  }
`;

export const AntiCheatWrapper = styled.div`
  width: 12%;
  div:first-child {
    padding-left: 20px;
  }
  @media screen and (max-width: 1000px) {
    width: 50%;
  }
`;
export const AssessmentDetailWrapper = styled.div`
  display: flex;

  input {
    margin-right: 20px;
    background-color: transparent;
  }
  select {
    margin-right: 20px;
    background-color: transparent;
  }
  .select-drop {
    margin-right: 20px;
    width: 20%;
  }
  .percentage {
  }
  .date-pick {
    width: auto;
    text-transform: uppercase;
    padding-right: 0px;
    margin-right: 0px;
  }
  input[type='datetime-local']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;
export const CategorySet = styled.div`
  margin-bottom: 20px;
  margin: 25px 25px 50px;
  box-shadow: 0px 4px 10px -1px #888888;
  border: 10px;
  background: #ffffff;
  position: relative;

  .circle-frame {
    background-color: #fdb82c;
    border-radius: 50px;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    width: 30px;
    height: 30px;
    text-align: center;
    margin-left: 10px;
  }
  .category-set {
    background-position: center right;
    width: 200px;
    height: 27px;
    cursor: pointer;
    margin-right: 30px;
  }
  .prof-level {
    display: flex;
    margin-right: 30px;
    flex-direction: column;
    font-size: 16px;
    width: 20%;
  }
`;
export const DeleteBtn = styled.div`
  position: relative;
  button {
    margin-top: 0px;
    margin-left: 20px;
    border: solid 1px #adadad;
    padding: 10px 20px;
    background: transparent;
  }
`;

export const CategorySectionWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-item: center;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  width: 100%;
  .btnAdd {
    padding: 8px 12px;
    border: none;
    margin-top: 0px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
  }
  &.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
    align-items: baseline;
  }
`;
export const ProficiencySelect = styled.div`
  margin-right: 10px;
  select {
    border-radius: 5px;
    color: #ffffff !important;
    border: solid 1px #fff !important;
  }
`;

export const QuestionDetails = styled.div`
  margin-right: 10px;
  input {
    box-shadow: none;
    background: #fff;
    border: solid 1px #fff;
    height: 40px;
    margin-bottom: 0px;
    color: #fff !important;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

export const TechnologyDetails = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 100%;
  position: relative;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  & > * {
    flex-basis: 32%;
  }
  select {
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #000000;
  }
  .btnAdd {
    border: none;
    margin-top: 0px;
    height: 40px;
    width: 40px !important;
    z-index: 100;
    font-size: 20px;
    font-weight: bold;
    background-color: #fdb82c;
    color: #000000;
    text-decoration: none;
    flex-basis: auto;
    align-items: center;
    display: flex;
  }
  @media screen and (max-width: 1000px) {
    .btnAdd {
      margin-left: auto;
      margin-right: auto;
    }
  }
  button {
    background: transparent;
    border: none;
    text-decoration: underline;
    color: #097be3;
    margin-top: 5px;
    margin-bottom: 10px;
    font-weight: bold;
  }
`;

// export const CategorySectionDetails = styled.div`
//   display: flex;
//   justify-content: space-between;
//   overflow: hidden;
//   max-height: 0;
//   transition: all 0.5s cubic-bezier(0, 1, 0, 1);
//   button {
//     width: 10%;
//   justify-content: start;
//   align-items: center;
//   div{
//     flex-basis: 100%;
//     padding: 0px 20px;
//     height: 60px;
//   }
//   input{
//     margin: 0px;
//   }
//   button {
//     margin: 0px;
//     height: auto;
//   }
//   select{
//     height: 40px;
//   }
//   &.show {
//     height: auto;
//     max-height: 9999px;
//     transition: all 0.5s cubic-bezier(1, 0, 1, 0);
//   }
// `;

export const TechnologyWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  .select-wrapper {
    width: 90%;
  }

  .or-button {
    background-color: #000000;
    color: #ffffff;
    padding: 10px;
    height: 40px;
    width: 40px;
    margin: 0px 20px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50px;
  }

  .frameflex {
    display: flex;
    justify-content: left;
    flex-direction: row-reverse;
    align-items: center;
  }
  .btnDelete {
    border: none;
    margin-top: 0px;
    height: 40px;
    width: 40px !important ;
    font-size: 20px;
    font-weight: bold;
    background-color: #fdb82c;
    color: #000000;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .btnAddMandatory {
    font-size: 12px;
    padding-left: 2px;
    white-space: nowrap;
    width: auto;
    font-weight: 600;
    border: 1px solid #3d3d3d;
    text-align: center !important;
    padding: 5px;
    background-color: #f2f2f2;
    margin-top: 14px;
    margin-right: 0px;
    text-decoration: none;
    cursor: pointer;
  }

  .btnAddMandatory:hover {
    background-color: #3d3d3d;
    color: #ffffff;
  }
  button {
    width: 30%;
    text-align: left;
    padding-left: 20px;
  }
  select {
    width: 100%;
  }
`;
export const SearchInpDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  margin-left: 20px;
  button {
    background: #fdb82c;
    height: 39px;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0;
    background-image: url(${searchIcon});
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 0px;
    width: 70px;
    padding: 10px;
  }
  input {
    background: #ffffff;
    border: 1px solid #000000;
    width: 200px;
    height: 38px;
    text-indent: 10px;
    margin-bottom: 0px;
  }
  button {
    width: 40px;
  }
  .search-icon {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    position: relative;
    right: 5px;
    top: 10px;
  }
`;

export const AddQuestionMain = styled.div`
  table {
    margin-top: 0px;
    thead tr th {
      background: #3d3d3d !important;
      color: #ffffff !important;
    }
    tbody tr td {
      .chkQuestion {
        border: solid 1px red !important;
        ::after {
          color: red !important;
        }
      }
    }
  }
`;

export const MainSearchMandatory = styled.div`
  display: flex;
`;

export const AddQuesTitle = styled.div`
  font-weight: 500;
  margin-bottom: 0px;
  text-align: center;
  font-size: 20px;
  height: 80px;
  padding: 20px 0px;
`;
export const CollpasedWrap = styled.div`
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-between'; 
  background: '#fff',
  padding: '20px',
  width: 50%; 
`;
export const CategoryWrap = styled.div`
  width: 100%;
  flex: 0 0 100%;
  flex-wrap: nowrap;
  flex-direction: unset;
  h1 {
    width: 18%;
  }
`;
export const CategorySetInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: #3d3d3d;
  padding: 10px;
  h1 {
    margin-right: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  input,
  select {
    background-color: transparent;
    color: #ffffff;
    border: solid 1px #ffffff;
  }
`;

export const MinmaxRequired = styled.div`
  position: absolute;
  right: 1%;
  top: -58px;
  font-size: 14px;
`;
export const ProficienyDiv = styled.div``;
export const TotalTimeDiv = styled.div``;
export const CategoriesName = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const CategorySpan = styled.span`
  margin: 0px 10px 10px 10px;
  background-color: #fdb82c;
  border-radius: 20px;
`;

export const ErrorDiv = styled.span`
  color: #e92626;
  font-size: 14px;
  display: block;
  position: absolute;
  bottom: 0px;
`;

export const MandatoryQuesDiv = styled.div`
  table,
  th {
    font-size: 14px;
    font-weight: 600;
    background-color: #f9f9f9;
    border: 1px solid #d8d8d8;
    padding: 10px;
    text-align: left;
  }
  td {
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #d8d8d8;
    background: #ebf6ff;
    padding: 10px;
  }

  table {
    width: 100%;
  }
`;
export const MandatoryQuesHeadDiv = styled.span``;
export const MandatoryQuesBodyDiv = styled.span``;

export const TechnologyDetailsWrap = styled.div`
  display: 'flex';
  width: '80%';
  flex-direction: 'column';
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  &.show {
    height: auto;
    max-height: 9999px;
    padding: 20px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
    align-items: baseline;
  }
`;

export const InfoDiv = styled.div`
  text-align: center;
  margin-bottom: 10px;
  color: #ff0000;
`;
