import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 20px;
  overflow: auto;
`;

export const Table = styled.table<any>`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  // width: ${props => (props.width ? props.width : '96%')};
`;

export const TableHead = styled.thead`
  font-weight: bold;
  font-size: 16px;
  font-style: normal;
  line-height: ;
  background: #3d3d3d;
`;

export const TableBody = styled.tbody`
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableTh = styled.th`
  text-align: left;
  padding: 15px;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :nth-child(5) {
    //text-align: center;
  }
`;

export const TableTd = styled.td`
  text-align: left;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  border-right: 1px solid rgba(239, 239, 239, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  button {
    border-radius: 5px;
    width: auto;
    border: 0px solid #fdb82c;
    padding: 10px;
    margin: 0;
    box-shadow: 1px 2px 4px #a6a2a2;
  }
  .download-resume {
    background-position: center right 10px;
    width: 100%;
    text-align: left;
  }
  .shortlisted {
    width: 100%;
    opacity: 0.6;
  }
  .Shortlist {
    width: 100%;
  }
`;

export const TableTdDiv = styled.div``;

export const TableRow = styled.tr`
  min-height: 68px;
  color: #3d3d3d;
  cursor: pointer;
  :nth-child(even) {
    background-color: #f8f8f8;
  }
`;

export const ActionDiv = styled.div`
  position: absolute;
  width: 118px;
  background: #ffffff;
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  left: 0;
  right: 0;
  z-index: 10;
  margin-left: auto;
`;

export const ActionUl = styled.ul``;

export const ActionLi = styled.li`
  list-style: none;
  padding: 10px;
  border-bottom: 1px solid #f3f3f3;
  font-weight: 400;
  font-size: 12px;
  color: #141414;
  cursor: pointer;
`;
