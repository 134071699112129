import React from 'react';
import { Title6 } from '../Titles';
import { ErrorTextWrapper } from './ErrorMessageStyles';

export interface TooltipProps {
  text: JSX.Element;
}

export const ErrorText = ({ text }) => {
  return (
    <ErrorTextWrapper>
      <Title6>{text}</Title6>
    </ErrorTextWrapper>
  );
};
