import styled from 'styled-components';
export const Wrapper = styled.div<any>`
  position: absolute;
  left: 0px;
  top: 40px;
  right: 0px;
  left: 0px;
  margin-left: auto;
  margin-right: auto;
  z-index: 700;
  width: ${props => (props.width ? props.width : '70%')};
  outline: 0;
`;
export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #1d1d1d;
  z-index: 500;
  opacity: 0.76;
`;
export const StyledModal = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 27px;
`;
export const Close = styled.div`
  /* border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: end;
  padding: 0.3rem; */
  text-align: right;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
`;
export const HeaderText = styled.div`
  color: #fff;
  align-self: center;
  color: #3d3d3d;
`;

export const Content = styled.div`
  padding: 10px;
  /* max-height: 30rem; */
  overflow-x: hidden;
  overflow-y: auto;
`;

export const CloseButton = styled.img`
  padding: 10px;
  cursor: pointer;
`;
