import styled from 'styled-components';

interface AnchorProps {
  bgImage?: string;
}

export const AnchorWrapper = styled.a<AnchorProps>`
  cursor: pointer;
  text-decoration: inherit;
  outline: inherit;
  background-image: ${props => (props.bgImage ? `url(${props.bgImage})` : '')};
`;
