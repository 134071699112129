import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

export const getLogin = async (credentials: any) => {
  try {
    const res = await axios({
      url: `${baseUrl}iam/user/auth`,
      data: credentials,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    });
    return res.data;
  } catch (err) {
    console.log('login error: ', err);
    return err;
  }
};

export const getToken = () => {
  const tokenString = localStorage.getItem('at');
  if (tokenString) {
    const userToken = JSON.parse(tokenString);
    return userToken;
  }
  return null;
};

export const setToken = (userToken: string) => {
  localStorage.setItem('at', JSON.stringify(userToken));
};

export const logout = async () => {
  localStorage.removeItem('at');
};

export const setEmail = (email: string) => {
  localStorage.setItem('userEmail', email);
};

export const getEmail = () => {
  return localStorage.getItem('userEmail');
};

export const commonPostCall = async (
  data: any,
  url: string,
  useToken: boolean = true,
) => {
  try {
    const res = await axios({
      url: `${baseUrl}${url}`,
      data,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        // 'x-api-key': 'xEYsxsUKjq410YOZ5UP082MkQ2nagee12sOipZNZ',
        Authorization: useToken === true && getToken(),
      },
    });
    return res.data;
  } catch (err: any) {
    console.log('login error: ', err);
    // if (
    //   typeof err.response !== 'undefined' &&
    //   typeof err.response.data !== 'undefined' &&
    //   typeof err.response.data.result.error !== 'undefined' &&
    //   err.response.data.result.error === 'TokenExpiredError: jwt expired'
    // ) {
    //   logout();
    // }
    return err.response.data;
  }
};

export const getUserVerifiedByCode = async (code: string) => {
  try {
    const res = await axios({
      url: `${baseUrl}/dev/v1/user/verify/${code}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        // 'x-api-key': 'xEYsxsUKjq410YOZ5UP082MkQ2nagee12sOipZNZ',
        // Authorization: jt,
      },
    });
    return res.data;
  } catch (err) {
    console.log('login error: ', err);
    return err;
  }
};

export const resendVerificationEmail = async (email: string, jt: string) => {
  try {
    const res = await axios({
      url: `${baseUrl}/dev/v1/user/email/verify/${email}`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        // 'x-api-key': 'xEYsxsUKjq410YOZ5UP082MkQ2nagee12sOipZNZ',
        Authorization: jt,
      },
    });
    return res.data;
  } catch (err) {
    console.log('login error: ', err);
    return err;
  }
};

export const sendOtp = async email => {
  try {
    const res = await axios({
      url: `${baseUrl}iam/email/${encodeURIComponent(email)}/otp`,
      method: 'GET',
    });

    if (res.status >= 400) {
      throw res.data.error;
    }
    return res;
  } catch (err) {
    console.log('sendOtp error: ', err);
    throw err;
  }
};

export const verifyOtp = async (email: string, otp: string) => {
  try {
    const res = await axios({
      url: `${baseUrl}iam/email/${encodeURIComponent(email)}/otp`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { otp },
    });

    if (res.status >= 400) {
      throw res.data.error;
    }
    return res.data;
  } catch (err) {
    console.log('verifyOtp error: ', err);
    throw err;
  }
};

export const setPassword = async (userId: string, password: string, token) => {
  try {
    const res = await axios({
      url: `${baseUrl}iam/user/${userId}/password`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: { password },
    });

    if (res.status >= 400) {
      throw res.data.error;
    }
    return res;
  } catch (err) {
    console.log('setPassword error: ', err);
    throw err;
  }
};

export const getOtpVerified = async (email: string, otp: string) => {
  try {
    const res = await axios({
      url: `${baseUrl}/email/${email}/otp`,
      method: 'POST',
      data: { otp },
      headers: {
        'content-type': 'application/json',
        // 'x-api-key': 'xEYsxsUKjq410YOZ5UP082MkQ2nagee12sOipZNZ',
        // Authorization: jt,
      },
    });
    return res.data;
  } catch (err) {
    console.log('login error: ', err);
    return err;
  }
};

export const getAssessmentId = () => {
  const id = sessionStorage.getItem('assessmentId');
  if (id) {
    const assessmentId = JSON.parse(id);
    return assessmentId;
  }
  return null;
};

export const setAssessmentId = (assessment: string) => {
  sessionStorage.setItem('assessmentId', assessment);
};

export const getAttempt = () => {
  const id = sessionStorage.getItem('attempt');
  return id;
};

export const setAttempt = (attempt: string) => {
  sessionStorage.setItem('attempt', attempt);
};

export const TestFinish = () => {
  sessionStorage.removeItem('assessmentId');
  sessionStorage.removeItem('attempt');
  sessionStorage.removeItem('token');
};
