import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { GlobalStyle, Background, Content } from 'styles/global-styles';

// import { HomePage } from './components/pages/HomePage/Loadable';
// import { Login } from './components/pages/Login';
// import { Dashboard } from './components/pages/Dashboard';
// import { Questions } from './components/pages/QuestionBank';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { ProtectedRoute } from './protectedRoute';
// import { CategoryQues } from './components/pages/QuestionBank/categoryQues';
import { Logout } from './components/pages/Logout';
import { Assessments } from './components/pages/Assessments';
import { CareerGuide } from './components/pages/CareerGuide';
// import { LandingPage } from './components/pages/LandingPages';
import { LandingPageLogin } from './components/pages/LandingPages/login';
import { ResetPwdPage } from './components/pages/LandingPages/resetPassword';
import { CreatePath } from './components/pages/CreatePath';
import { CareerResult } from './components/pages/CareerResult';

// import { ResumeBuilder } from './components/pages/ResumeBuilder/ResumeBuilder';
import { ResumeInfo } from './components/pages/ResumeInfo/ResumeInfo';
// import { ResumePrint } from './components/pages/Resume/ResumePrint';
import { VerifyUser } from './components/pages/LoginModal/verifyUser';
import { Verification } from './components/pages/LoginModal/verificationPending';
import { PostJob } from './components/pages/Jobs/postJob';
import { MyApplications } from './components/pages/MyApplications';
import { Jobs } from './components/pages/Jobs';
import { SearchJobs } from './components/pages/SearchJobs';
import { JobSeekerLandingPage } from './components/pages/LandingPages/jobSeekerLanding';
import { Inbox } from './components/pages/Inbox';
import { InterviewProgress } from './components/pages/InterviewProgress';
// import { CategoryQues } from './components/pages/QuestionBank_Old/categoryQues';
// import { Questions_old } from './components/pages/QuestionBank_Old';
import { Questions } from './components/pages/QuestionBank';
// import { CreateTest } from './components/pages/CreateTest';
import { TestDetails } from './components/pages/Tests/testDetails';
import { TestReports } from './components/pages/Reports';
import { Profile } from './components/pages/Profile';
import { MyAssessments } from './components/pages/Assessments/MyAssessments';
import { Categories } from './components/pages/Categories';
import { CreateAssessment } from './components/pages/Assessments/CreateAssessment';
import { ScreenTests } from './components/pages/ScreenTest';
import { GettingStarted } from './components/pages/ScreenTest/gettingStarted';
import { StartAssessment } from './components/pages/ScreenTest/startAssessment';
import { ViewAssessment } from './components/pages/Assessments/CreateAssessment/viewAssessment';
import { Respondants } from './components/pages/Respondants';
import { Support } from './components/pages/Support';
import { Subscription } from './components/pages/Subscription';
export function App() {
  const { i18n } = useTranslation();
  // const location = useLocation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Fairhiree"
        defaultTitle="Fairhiree"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Fairhiree" />
      </Helmet>
      <GlobalStyle />
      <Background>
        <Content>
          <Routes>
            {/* Job providers routes */}
            <Route
              path="/post-job"
              element={
                <ProtectedRoute name="PostJob">
                  <PostJob />
                </ProtectedRoute>
              }
            />
            <Route
              path="/jobs"
              element={
                <ProtectedRoute name="Jobs">
                  <Jobs />
                </ProtectedRoute>
              }
            />
            <Route
              path="/inbox"
              element={
                <ProtectedRoute name="Inbox">
                  <Inbox />
                </ProtectedRoute>
              }
            />
            <Route
              path="/interview-progress"
              element={
                <ProtectedRoute name="InterviewProgress">
                  <InterviewProgress />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assessments"
              element={
                <ProtectedRoute name="Assessments">
                  <Assessments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assessment/:assessmentId"
              element={
                <ProtectedRoute name="Assessments">
                  <ViewAssessment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/respondents/:assessmentId"
              element={
                <ProtectedRoute name="Respondants">
                  <Respondants />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute name="Profile">
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/test-reports"
              element={
                <ProtectedRoute name="TestReports">
                  <TestReports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/categories"
              element={
                <ProtectedRoute name="Categories">
                  <Categories />
                </ProtectedRoute>
              }
            />

            {/* common routes */}
            {/* <Route path="/" element={<LandingPage />} /> */}
            <Route
              path="/support"
              element={
                <ProtectedRoute name="Support">
                  <Support />
                </ProtectedRoute>
              }
            />

            <Route
              path="/subscription"
              element={
                <ProtectedRoute name="Subscription">
                  <Subscription />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<LandingPageLogin />} />
            <Route path="/reset-pwd" element={<ResetPwdPage />} />
            <Route path="/js" element={<JobSeekerLandingPage />} />
            <Route path="/verifyuser/:code" element={<VerifyUser />} />
            <Route path="/verification-pending" element={<Verification />} />
            <Route path="/logout" element={<Logout />} />

            {/* Job seekers routes */}
            <Route
              path="/start-screening/:assessmentId"
              element={<GettingStarted />}
            />
            <Route
              path="/ast/:assessmentId/:userId/:ucaId"
              element={<StartAssessment />}
            />
            <Route
              path="/st/:assessmentId/:userId/:ucaId"
              element={<ScreenTests />}
            />
            <Route
              path="/search-jobs"
              element={
                <ProtectedRoute name="SearchJobs">
                  <SearchJobs />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-applications"
              element={
                <ProtectedRoute name="MyApplications">
                  <MyApplications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-assessments"
              element={
                <ProtectedRoute name="MyAssessments">
                  <MyAssessments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-reports"
              element={
                <ProtectedRoute name="TestReports">
                  <TestReports />
                </ProtectedRoute>
              }
            />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/career" element={<CareerGuide />} />
            <Route
              path="/career-path"
              element={
                <ProtectedRoute name="CreatePath">
                  <CreatePath />
                </ProtectedRoute>
              }
            />
            <Route
              path="/career-result"
              element={
                <ProtectedRoute name="CareerResult">
                  <CareerResult />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
            {/* <Route path="/resume-builder" element={<ResumeBuilder />} /> */}
            <Route
              path="/resume-info"
              element={
                <ProtectedRoute name="ResumeInfo">
                  <ResumeInfo />
                </ProtectedRoute>
              }
            />
            {/* <ProtectedRoute path="/" element={<HomePage />} />
            <Route path="" element={<NotFoundPage />} /> */}
            {/* <Route path="/login" element={<Login />}></Route> */}
            {/* <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />*/}
            {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
            <Route
              path="/question-bank/category"
              element={
                <ProtectedRoute name="Questions">
                  <Questions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/question-bank/category/:categoryId/questions"
              element={
                <ProtectedRoute name="Questions">
                  <Questions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/question-bank/category/:categoryId/create"
              element={
                <ProtectedRoute name="Questions">
                  <Questions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/question-bank/category/:categoryId/questions/:questionId"
              element={
                <ProtectedRoute name="Questions">
                  <Questions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-assessment"
              element={
                <ProtectedRoute name="CreateAssessment">
                  <CreateAssessment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/test"
              element={
                <ProtectedRoute name="TestDetails">
                  <TestDetails />
                </ProtectedRoute>
              }
            />

            {/* <Route
              path="/category/:categoryId/questions"
              element={
                <ProtectedRoute name="CategoryQues">
                  <CategoryQues />
                </ProtectedRoute>
              }
            /> */}
          </Routes>
        </Content>
      </Background>
    </BrowserRouter>
  );
}
