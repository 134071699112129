/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import fairhireelogo from 'app/assets/images/fairhirewhite.svg';

import eyedashIcon from 'app/assets/images/eyedash.png';
import jumpIcon from 'app/assets/images/jump.png';
import {
  FooterDiv,
  InputContainer,
  JobListingContainer,
  JobListingHomeHeader,
  JobListingHomeHeaderLogo,
  JumpImg,
  JumpSpan,
  LoginContentWrapper,
  LoginFormWrapper,
  LoginHeadingWrapper,
  Menu,
  NavMenu,
  ResetButton,
} from './Styles/loginStyles';

import { useForm } from 'react-hook-form';
import { getLogin, setToken, setEmail } from 'app/services/auth/auth';
import {
  getUsersProfile,
  setProfileImage,
  setUserId,
} from 'app/services/users';
import { ErrorDiv } from '../LoginModal/loginStyles';
import { Input } from 'app/components/UI/atoms/Input';
import Button from 'app/components/UI/atoms/Button';
import buttonLoader from 'app/assets/images/loader-black.svg';

export const LandingPageLogin = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const [isLoading, setLoading] = useState<boolean>(false);

  const onSubmit = handleSubmit(async (data: any) => {
    // data.role = role === 'js' ? 'job_seeker' : 'job_provider';
    setLoading(true);
    const loginResp = await getLogin(data);
    if (
      loginResp.name === 'AxiosError' ||
      loginResp.code === 'ERR_BAD_REQUEST'
    ) {
      setError('email', {
        type: 'server',
        message: 'Something went wrong!',
      });
    } else {
      setToken(loginResp?.token);
      setEmail(data.email);
      const userProfileData = await getUsersProfile();
      if (userProfileData?.id) {
        setUserId(userProfileData?.id);
      }
      if (userProfileData?.profileUrl) {
        setProfileImage(userProfileData?.profileUrl);
      }

      navigate('/assessments');
      reset();
    }
    setLoading(false);
  });

  return (
    <JobListingContainer>
      <JobListingHomeHeader>
        <JobListingHomeHeaderLogo>
          <img src={fairhireelogo} alt="Logo" onClick={() => navigate('/')} />
        </JobListingHomeHeaderLogo>
        <NavMenu>
          <a href="https://www.fairhiree.com">Home</a>
          <a href="https://www.fairhiree.com/products">Products</a>
          <a href="https://www.fairhiree.com/blog/blog">Blog</a>
          <a href="https://share.hsforms.com/1GOyZZdJ8T1Sz06KoQpUDcgqk5d2">
            Demo
          </a>
          <a href="https://www.fairhiree.com/contact-us">Contact Us</a>
        </NavMenu>

        <Menu>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="#ffffff"
              d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"
            />
          </svg>
        </Menu>
      </JobListingHomeHeader>

      <LoginContentWrapper>
        <LoginHeadingWrapper>
          <h1>
            <JumpSpan>J</JumpSpan>ump into <b>One Stop</b> Shop for{' '}
          </h1>
          <h2>
            <b>'Hire 2 Retire'</b>{' '}
          </h2>
          <h3>Solution </h3>

          <h4>Fuelled by Fairhiree Artificial Intelligence! </h4>
          <JumpImg>
            <img src={jumpIcon} alt="jump" />
          </JumpImg>
        </LoginHeadingWrapper>

        <LoginFormWrapper>
          <form noValidate={true} onSubmit={onSubmit}>
            {errors?.email?.type === 'server' ? (
              <ErrorDiv>
                {(errors?.email?.message as string) ||
                  'Incorrect email or password!'}
              </ErrorDiv>
            ) : (
              ''
            )}
            {errors?.email?.type === 'required' ? (
              <ErrorDiv>Enter user email!</ErrorDiv>
            ) : (
              ''
            )}
            <InputContainer>
              {/* <input type="email" placeholder="Enter Email " required /> */}
              <Input
                id="email"
                type="text"
                label="Please enter User name / Email"
                placeholder="User name / Email"
                {...register('email', {
                  required: true,
                })}
                required
              />

              {/* <img src={crossIcon}  /> */}
            </InputContainer>
            <InputContainer>
              <Input
                id="password"
                type="password"
                label="Password"
                placeholder="Password"
                {...register('password', {
                  required: true,
                })}
                required
              />
              <img src={eyedashIcon} />
            </InputContainer>
            <ResetButton onClick={() => navigate('/reset-pwd')}>
              Reset Password?
            </ResetButton>
            <Button
              type="submit"
              className="mainsavebutton"
              content="Sign In"
              bgColor="#fdb82c"
              leftIcon={isLoading === true ? buttonLoader : ''}
              disabled={isLoading === true ? true : false}
            />
            {/* <OrWrapper >
              <DashDiv > </DashDiv>	Or continue with <DashDiv > </DashDiv>
            </OrWrapper> */}
          </form>
        </LoginFormWrapper>
      </LoginContentWrapper>
      <FooterDiv> ​Copyright @2024 | Fairhiree India Technology</FooterDiv>
    </JobListingContainer>
  );
};
