import styled from 'styled-components';
import { device } from 'styles/media';

export const AssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin: 0;
`;

export const AssessmentTitle = styled.div`
  font-weight: 600;
  font-style: normal;
  color: #3d3d3d;
`;

export const AssessmentTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 400;
`;

export const InvitePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 40px;
  width: auto;
  height: auto;
  margin-block: 20px;
  margin-inline: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  table {
    margin-bottom: 20px;
  }
  tr {
    :nth-child(even) {
      background-color: #ffffff;
    }
  }
`;

export const CardTitle = styled.div<any>`
  margin-top: 30px;
  font-weight: 600;
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  color: #3d3d3d;
`;
export const InviteLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 97%;
  width: auto;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  justify-content: center;
  margin-top: 30px;
  @media ${device.desktop} {
    width: 99%;
  }
  @media ${device.laptopXl} {
    width: 99%;
  }
  @media ${device.laptopL} {
    width: 97%;
  }
  @media ${device.laptop} {
    width: 97%;
  }
  @media ${device.tablet} {
    width: 98%;
    flex-direction: column;
  }
`;
export const CategoriesHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CategoriesHeaderLeft = styled.div`
  width: 50%;
`;

export const CategoriesHeaderRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 30px;
  font-weight: bold;
`;
export const DurationTitle = styled.span`
  padding-left: 5px;
  color: #3d3d3d;
`;
export const TimerTitle = styled.span`
  padding-left: 5px;
  color: #e53366;
`;

export const InviteWithInstructionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const InviteCardLeft = styled.div`
  width: 50%;
  padding: 20px;
`;

export const InviteCardRight = styled.div`
  width: 50%;
  padding: 20px;
`;

export const BodyDiv = styled.div`
  width: 100%;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: left;
    /* color: #3d3d3d; */
  }
`;
