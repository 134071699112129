import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { Input } from 'app/components/UI/atoms/Input';
// import Button from 'app/components/UI/atoms/Button';
import { Title1 } from 'app/components/UI/atoms/Titles';
import {
  LoginMain,
  // ClearDiv,
  // ErrorDiv,
  SubTitle,
  SubTitle2,
  HeadWrapDiv,
} from './signUpStyles';
import { getToken } from 'app/services/auth/auth';
import { useForm } from 'react-hook-form';
import { A } from 'app/components/UI/atoms/Anchor';

export const SignUpModal = ({ changeModalVisibility, role, isShowModal }) => {
  const token = getToken();
  // const [email, setEmail] = useState<string>('');
  let navigate = useNavigate();

  if (typeof token !== 'undefined' && token !== null) {
    navigate('/jobs', { replace: true });
  }
  const {
    // register,
    // handleSubmit,
    reset,
    // setError,
    // formState: { errors },
  } = useForm();

  React.useEffect(() => {
    reset();
  }, [reset]);

  // const onSubmit = handleSubmit(async (data: any) => {
  //   data.role = role === 'js' ? 'user' : 'employee';
  //   // const signUpResp = await commonPostCall(data, '/user');
  //   // if (
  //   //   signUpResp.name === 'AxiosError' ||
  //   //   signUpResp.code === 'ERR_BAD_REQUEST'
  //   // ) {
  //   //   setError('email', {
  //   //     type: 'server',
  //   //     message: 'Something went wrong!',
  //   //   });
  //   // } else {
  //   //   // setToken(loginResp?.result.accessToken);
  //   //   // setEmail(data.email);
  //   //   // navigate('/dashboard');
  //   //   // signUpToggle();
  //   //   reset();
  //   //   changeModalVisibility(isShowModal, true);
  //   //   navigate('/verification-pending', {
  //   //     replace: true,
  //   //     state: { jt: signUpResp.result.accessToken, email: data.email },
  //   //   });
  //   // }
  //   navigate('/verification-pending', {
  //     replace: true,
  //     state: { email: data.email },
  //   });
  // });

  return (
    // <LoginWrapper>
    // <LogoImg src={evolve} alt="evolve login" />
    <LoginMain>
      <Title1>Hi there! </Title1>
      {/* <span>
        Don't have an account? <A>Sign up</A>
      </span> */}
      <HeadWrapDiv>
        <SubTitle>Please mail us at&nbsp;</SubTitle>
        {/* <SubTitle2 onClick={signUpToggle}> */}
        <SubTitle2 onClick={changeModalVisibility}>
          <A href="mailto:care@fairhiree.com">care@fairhiree.com</A>
        </SubTitle2>
        <SubTitle>&nbsp;to get access.</SubTitle>
      </HeadWrapDiv>
    </LoginMain>
  );
};
