import React, { useEffect, useState } from 'react';
import { Header } from 'app/components/UI/organisms/Header';
import {
  ContentDivWrapper,
  TitleWrapper,
} from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import products from 'app/assets/images/Products.svg';
import { BodyDiv } from './subscriptionStyles';
import { getAllSubscriptions } from 'app/services/organisations';
import { getToken } from 'app/services/auth/auth';
import jwt_decode from 'jwt-decode';
import spinnerLoad from 'app/assets/images/loader.gif';

interface Subscriptions {
  purchased?: number;
  used?: number;
  balance?: number;
}

export const Subscription = () => {
  const token = getToken();
  const decodedJT: any = jwt_decode(token);
  const { organisationId } = decodedJT.user;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [subscriptionsData, setSubscriptionsData] = useState<Subscriptions[]>(
    [],
  );

  const getSubscriptions = async () => {
    setLoading(true);
    const subscriptionsResp = await getAllSubscriptions(organisationId);
    if (
      subscriptionsResp.length > 0 &&
      typeof subscriptionsResp[0].name !== 'undefined'
    ) {
      setSubscriptionsData(subscriptionsResp);
    } else {
      // error
      console.log('something went wrong!');
    }
    setLoading(false);
  };

  useEffect(() => {
    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const content = (
  //   <ModelContent>
  //     <MailIcon>
  //       <img src={successIcon} alt="mail-icon" width="80px" height="50px" />
  //     </MailIcon>
  //     <Message>
  //       <PrimaryParagraph>Profile Edited successfully!</PrimaryParagraph>
  //     </Message>
  //   </ModelContent>
  // );

  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          {isLoading === true ? (
            <img
              src={spinnerLoad}
              alt=""
              width={100}
              height={100}
              style={{ marginTop: '20%', marginLeft: '40%' }}
            />
          ) : (
            <BodyDiv>
              <TitleWrapper>Subscription</TitleWrapper>
              <div className="subscription-wrapper">
                <div className="subscription-frame">
                  <div className="subscription-image">
                    <img src={products} alt="products" />
                    <h2 className="subscription-h2">Assessments</h2>
                  </div>

                  <div className="subscription-content">
                    <div className="subscription-info">
                      Purchased: <b>{subscriptionsData[0]?.purchased}</b>
                    </div>
                    <div className="subscription-info">
                      {' '}
                      Used: <b>{subscriptionsData[0]?.used}</b>
                    </div>
                    <div className="subscription-info">
                      Balance: <b>{subscriptionsData[0]?.balance}</b>
                    </div>
                  </div>
                </div>
              </div>
            </BodyDiv>
          )}
        </RightContainerDiv>
      </ContentDivWrapper>
    </>
  );
};
