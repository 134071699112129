/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Buffer } from 'buffer';
import Lottie from 'react-lottie';
import {
  BodyDiv,
  DescPara,
  FooterDiv,
  HeaderDiv,
  Logo,
  MainWrapperDiv,
  MainWrapperSubDiv,
  TechSetWraper,
  TechSetWraperSpan,
  TitleDesc,
  BorderLine,
  PhotoTitleDiv,
  UserPhotoImg,
  PhotoBodyDiv,
  PhotoContainerDiv,
} from './gettingStartedStyles';
import logoBloack from 'app/assets/images/logo-faihiree.png';
import Button from 'app/components/UI/atoms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAssessmentByTestIdLite,
  movePhoto,
  validatePhoto,
} from 'app/services/assessments';
import { Box } from '@mui/system';
import Webcam from 'react-webcam';
import { CircularProgress, Stack } from '@mui/material';
import AWS from 'aws-sdk';
import * as animationData from './photo-processing.lottie.json';
import spinnerLoad from 'app/assets/images/loader.gif';

const S3_BUCKET = 'fairhiree-dev-image-processing';
const REGION = 'ap-south-1';

AWS.config.update({
  accessKeyId: 'AKIAT2HOT3SYDU3GC5D5',
  secretAccessKey: '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',
});

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface Category {
  categoryId: string;
  name: string;
}

interface Section {
  sequence: number;
  proficiency: string;
  categories: Category[];
}

interface Assessment {
  assessmentId: string;
  name: string;
  cheatDetectionEnabled?: boolean;
  duration: number;
  questionCount: number;
  score: number;
  sections: Section[];
}

export const StartAssessment = () => {
  const { assessmentId, userId, ucaId } = useParams<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assessmentDetails, setAssessmentDetails] = useState<Assessment>();
  const [assessmentSelected, setAssessmentSelected] = useState<boolean>(false);
  const webcamRef = useRef<any>(null);
  const [userPhoto, setUserPhoto] = useState<string>();
  const [cameraReady, setCameraReady] = useState<boolean>(false);
  const [photoProcessing, setPhotoProcessing] = useState<boolean>(false);
  // const [photoProcessFailed, setPhotoProcessFailed] = useState<boolean>(false);
  const [photoValidationFailed, setPhotoValidationFailed] =
    useState<boolean>(false);
  const [photoValidationMsg, setPhotoValidationMsg] = useState<string>('');
  let navigate = useNavigate();
  const getAssessmentDetails = async () => {
    const assessmentResp = await getAssessmentByTestIdLite(assessmentId);
    setAssessmentDetails(assessmentResp);
    if (assessmentResp.photoUrl) {
      setUserPhoto(assessmentResp.photoUrl);
    }
  };
  // [TODO] What happens when the remote fails? Assessment not found?
  useEffect(() => {
    setIsLoading(!isLoading);
    getAssessmentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const capturePhoto = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setUserPhoto(imageSrc);
    }
  }, [webcamRef]);

  const resetPhoto = () => {
    setUserPhoto('');
    setPhotoValidationFailed(false);
    setPhotoValidationMsg('');
    setCameraReady(false);
  };

  const onCameraReady = () => {
    setCameraReady(true);
  };

  const uploadPhotoToS3 = async () => {
    return new Promise((res, rej) => {
      const pathName = `${assessmentId}_${userId}_photo.jpg`;
      s3.putObject(
        {
          Bucket: S3_BUCKET,
          Key: pathName,
          Body: Buffer.from(
            userPhoto!.replace(/^data:image\/\w+;base64,/, ''),
            'base64',
          ),
          ContentEncoding: 'base64',
          ContentType: 'image/jpeg',
        },
        (err, data) => {
          if (err) {
            console.error('Err: ', err);
            rej(err);
          } else
            res({
              filename: `${assessmentId}_${userId}_photo`,
            });
        },
      );
    });
  };

  const gotoAssessment = () => {
    navigate(`/st/${assessmentId}/${userId}/${ucaId}`, {
      state: {
        isCheatDetectionEnabled: assessmentDetails?.cheatDetectionEnabled
          ? assessmentDetails?.cheatDetectionEnabled
          : false,
        questionCount: assessmentDetails?.questionCount,
        duration: assessmentDetails?.duration,
      },
    });
  };

  const processPhoto = async () => {
    try {
      setPhotoProcessing(true);
      const s3params = await uploadPhotoToS3();
      const validationResponse = await validatePhoto(s3params);
      if (
        validationResponse.wearingGlasses ||
        validationResponse.eyesClosed ||
        validationResponse.noFaceDetected
      ) {
        setPhotoValidationFailed(true);
        setUserPhoto('');
        if (validationResponse.wearingGlasses)
          setPhotoValidationMsg(
            'Are you wearing glasses? Please remove and take another photo.',
          );
        else if (validationResponse.noFaceDetected)
          setPhotoValidationMsg(
            "We couldn't find you. Please take another photo.",
          );
        else
          setPhotoValidationMsg('Photo not clear. Please take another photo.');
      } else {
        await movePhoto({
          assessmentId,
          ucaid: ucaId,
          userId,
        });
        gotoAssessment();
      }
      setPhotoProcessing(false);
    } catch (err) {
      setPhotoProcessing(false);
      // setPhotoProcessFailed(true);
      console.error(err);
      alert('Something went wrong. Please refresh and try after some time.');
    }
  };

  const renderAssessmentOverview = assessmentDetails => {
    return (
      <>
        <HeaderDiv>
          <BorderLine>
            <Logo src={logoBloack} className="assessment-logo"></Logo>
            <h1 className="good-luck-heading">
              Good luck on your Fairhiree Screening Assessment!
            </h1>
            <h1 className="good-luck-heading">
              {' '}
              Show us your best and let your skills shine. All the best!
            </h1>
          </BorderLine>
        </HeaderDiv>
        {isLoading === true ? (
          <img
            src={spinnerLoad}
            alt=""
            width={100}
            height={100}
            style={{ marginTop: '20%', marginLeft: '40%' }}
          />
        ) : (
          <>
            <TitleDesc>
              <DescPara>
                {assessmentDetails && assessmentDetails?.name}
              </DescPara>
            </TitleDesc>
            <BodyDiv>
              <TechSetWraper>
                <TechSetWraperSpan>Duration : </TechSetWraperSpan>
                <TechSetWraperSpan>
                  {Math.floor(assessmentDetails.duration / 60)} Min
                </TechSetWraperSpan>
              </TechSetWraper>
              <TechSetWraper>
                <TechSetWraperSpan>Total Questions : </TechSetWraperSpan>
                <TechSetWraperSpan>
                  {assessmentDetails.questionCount}
                </TechSetWraperSpan>
              </TechSetWraper>
            </BodyDiv>
            <FooterDiv>
              <Button
                type="button"
                content={'Cancel'}
                className="btnCancel"
                bgColor="#fff"
                onClick={() => navigate('/')}
              />
              {userPhoto ? (
                <Button
                  type="button"
                  content={'Start Assessment'}
                  color="#fff"
                  onClick={() => gotoAssessment()}
                />
              ) : (
                <Button
                  type="button"
                  content={'Continue'}
                  color="#fff"
                  onClick={() => setAssessmentSelected(true)}
                />
              )}
            </FooterDiv>
          </>
        )}
      </>
    );
  };

  const renderCaptureWebcam = () => {
    return (
      <Box sx={{ padding: '2rem', textAlign: 'center' }}>
        <Box sx={{ alignItems: 'center', mt: '1rem' }}>
          <PhotoTitleDiv>
            {!photoValidationFailed ? 'Take a Photo' : 'Retake Photo'}
          </PhotoTitleDiv>
          <p
            style={{
              fontSize: '0.875rem',
              ...(photoValidationFailed && { color: 'red' }),
            }}
          >
            {photoValidationFailed
              ? photoValidationMsg
              : 'To continue to start assessment verify your profile by taking a photo'}
          </p>
        </Box>
        <PhotoBodyDiv>
          <PhotoContainerDiv>
            {photoProcessing && (
              <Lottie options={defaultLottieOptions} height={200} width={200} />
            )}
            {!photoProcessing &&
              (userPhoto ? (
                <UserPhotoImg src={userPhoto} alt="webcam" />
              ) : (
                <Stack alignItems="center">
                  {!cameraReady && (
                    <CircularProgress
                      sx={{
                        position: 'absolute',
                        top: '40%',
                        zIndex: '10',
                      }}
                    />
                  )}
                  <Webcam
                    mirrored={true}
                    height={360}
                    width={360}
                    minScreenshotWidth={1280}
                    ref={webcamRef}
                    onUserMedia={onCameraReady}
                    screenshotFormat="image/jpeg"
                  ></Webcam>
                </Stack>
              ))}
          </PhotoContainerDiv>
        </PhotoBodyDiv>
        <FooterDiv className="cameraCapture">
          {photoProcessing ? (
            <p style={{ fontSize: '0.875rem' }}>
              Please wait. Your photo is being processed.
            </p>
          ) : userPhoto ? (
            <>
              <Button
                type="button"
                content={'Retake Photo'}
                className="btnTakePhoto"
                onClick={() => resetPhoto()}
              />
              <Button
                type="button"
                content={'Start Assessment'}
                className="btnStartAsst"
                onClick={processPhoto}
              />
            </>
          ) : (
            <Button
              type="button"
              content={'Take Photo'}
              className="btnTakePhoto"
              disabled={!cameraReady}
              onClick={() => capturePhoto()}
            />
          )}
        </FooterDiv>
      </Box>
    );
  };

  if (!assessmentDetails) {
    return (
      <Stack alignItems="center">
        <CircularProgress sx={{ position: 'absolute', top: '40%' }} />
      </Stack>
    );
  } else {
    return (
      <MainWrapperDiv>
        <MainWrapperSubDiv>
          {assessmentSelected
            ? renderCaptureWebcam()
            : renderAssessmentOverview(assessmentDetails)}
        </MainWrapperSubDiv>
      </MainWrapperDiv>
    );
  }
};
