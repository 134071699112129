import React from 'react';

import { useForm } from 'react-hook-form';
import {
  AddLanguage,
  // AddProjects,
  CheckCompanyWrapper,
  CoCheck,
  CompanyBox,
  DataCal,
  EmpProof,
  // FairHireLogo,
  FlexTab,
  IndustryProof,
  // JobExperienceHeader,
  JobExpWrapper,
  // JobWrapper,
  LocationBox,
  SkipWrap,
  // TabPane,
  TitleBox,
  JobHelloHeading,
  // StepperCommonContainer,
  StepperContainer,
  NextButton,
  NextPrevious,
  Next,
  JobHeadingContainer,
  JobTabPane,
  JobStepperContainer,
  RemoveButton,
} from '../ResumeInfoStyles';

function JobExperience({
  steps,
  jobList,
  handleExpereienceAdd,
  handleExperienceRemove,
  activeStep,
  setActiveStep,
  handleFormData,
  formStepperData,
}) {
  const {
    register,
    // reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const ss = data => {
  //   console.log('data', data);
  // };
  const JobExperianceHandle = data => {
    handleFormData(data);

    setActiveStep(activeStep + 1);
    console.log(data);
  };
  return (
    <JobTabPane>
      <JobHelloHeading>
        " Experience gives you the test first and the lesson later"
        <br />
        <span>
          "You dont have any job yet? Dont worry everyone started as a fresher"
        </span>
      </JobHelloHeading>
      <JobStepperContainer>
        <JobHeadingContainer>
          <h3> Job Experience</h3>
          <SkipWrap type="submit"> Skip</SkipWrap>
        </JobHeadingContainer>
        {jobList.map((SingleJobExperience, index) => (
          <StepperContainer>
            <JobExpWrapper key={index}>
              <form onSubmit={handleSubmit(JobExperianceHandle)} noValidate>
                <FlexTab>
                  <TitleBox
                    type="text"
                    id="title"
                    placeholder="Title"
                    defaultValue={formStepperData.title}
                    className={
                      errors?.title?.type === 'required' ? 'invalid' : ''
                    }
                    {...register('title', {
                      required: 'kindly add values',
                      minLength: {
                        value: 3,
                        message: 'atleast 3 letters are requierd',
                      },
                      maxLength: {
                        value: 30,
                        message: 'You cant add letters which are more than 30',
                      },
                    })}
                  ></TitleBox>
                  <>{errors.Title?.message} </>

                  <br />

                  <EmpProof
                    required
                    defaultValue={formStepperData.Emplyoment_type}
                    {...register('Emplyoment_type')}
                  >
                    <option value=" Employment Type">Employment Type</option>
                    <option value="Permanent">Permanent</option>
                    <option value="Contract">Contract</option>
                    <option value="Fresher">Fresher</option>
                    <option value="Intern">Intern</option>
                  </EmpProof>
                </FlexTab>

                <FlexTab>
                  <CompanyBox
                    type="text"
                    id="company"
                    // name="company"
                    placeholder="Company Name"
                    className={
                      errors?.Company_name?.type === 'required' ? 'invalid' : ''
                    }
                    defaultValue={formStepperData.Company_name}
                    {...register('Company_name', {
                      required: 'kindly add values',
                      minLength: {
                        value: 3,
                        message: 'atleast 3 letters are requierd',
                      },
                      maxLength: {
                        value: 30,
                        message: 'You cant add letters which are more than 30',
                      },
                    })}
                  ></CompanyBox>
                  <>{errors.CompanyName?.message} </>

                  <CheckCompanyWrapper>
                    <CoCheck
                      type="checkbox"
                      id="myCheckbox"
                      name="myCheckbox"
                      value="myValue"
                    ></CoCheck>
                    <label htmlFor="myCheckbox">
                      I am currently working in this role
                    </label>
                  </CheckCompanyWrapper>
                </FlexTab>

                <FlexTab>
                  <DataCal
                    type="text"
                    id="date"
                    // name="date"
                    placeholder="Start Date DD/YYYY"
                    required
                    defaultValue={formStepperData.Start_date}
                    {...register('Start_date')}
                  ></DataCal>
                  <DataCal
                    type="text"
                    id="date"
                    // name="date"
                    placeholder="End Date DD/YYYY"
                    required
                    value="end date"
                    defaultValue={formStepperData.End_date}
                    {...register('End_date')}
                  ></DataCal>

                  <LocationBox
                    // required
                    defaultValue={formStepperData.Location}
                    {...register('Location')}
                    id="Location"
                    name="Location"
                    type="text"
                    placeholder="Location"
                    className="input-text"
                  ></LocationBox>
                </FlexTab>

                <FlexTab>
                  <IndustryProof
                    required
                    defaultValue={formStepperData.Industry}
                    {...register('Industry')}
                  >
                    <option value="">Industry</option>
                    <option value="IT">IT/Software</option>
                    <option value="Architecture">Architecture</option>
                    <option value="Farming">Farming</option>
                    <option value="Automation">Automation</option>
                    <option value="Defence">Defence</option>
                  </IndustryProof>
                </FlexTab>

                {jobList.length - 1 === index && jobList.length < 6 && (
                  <AddLanguage type="button" onClick={handleExpereienceAdd}>
                    Add Experience
                  </AddLanguage>
                )}
                {jobList.length > 1 && (
                  <RemoveButton
                    type="button"
                    onClick={() => handleExperienceRemove(index)}
                  >
                    Remove
                  </RemoveButton>
                )}
                {activeStep !== steps.length - 1 && (
                  <NextButton>
                    {activeStep !== 0 && activeStep !== steps.length - 1 && (
                      <NextPrevious
                        type="submit"
                        onClick={() => setActiveStep(activeStep - 1)}
                      >
                        Previous
                      </NextPrevious>
                    )}

                    <Next type="submit">Next</Next>
                  </NextButton>
                )}
              </form>
            </JobExpWrapper>
          </StepperContainer>
        ))}
      </JobStepperContainer>
    </JobTabPane>
  );
}

export default JobExperience;
