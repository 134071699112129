import React, { useEffect, useState } from 'react';
import Button from 'app/components/UI/atoms/Button';
import { Header } from 'app/components/UI/organisms/Header';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import { BodyDiv, SearchWrap, SearchInpDiv } from './inboxListingStyles';
import { JobListTable } from './indexListTable';
import { Input } from 'app/components/UI/atoms/Input';
import { mockData } from './mock/mock';

export const InterviewProgress = () => {
  const [inboxList, setInboxList] = useState<any>();
  const [inboxListFirstLoad, setInboxListFirstLoad] = useState<any>();
  const headers = [
    { id: 'name', name: 'Name' },
    { id: 'role', name: 'Role' },
    { id: 'experience', name: 'Experience' },
    { id: 'location', name: 'Location' },
    { id: 'salary', name: 'Current Salary' },
    { id: 'download', name: 'Download Resume' },
    { id: 'shortlist', name: 'Shortlist Candidate' },
  ];
  let jobsData: any = [];
  const getAllInbox = async () => {
    // jobsData = await getAllInbox();
    setInboxList(mockData);
    console.log(mockData);
    setInboxListFirstLoad(jobsData.jobs);
  };
  useEffect(() => {
    getAllInbox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startsWith = (array, key) => {
    const matcher = new RegExp(`^${key.toLowerCase()}`, 'g');
    return array.filter(word => word.toLowerCase().match(matcher));
  };

  const handleSearch = event => {
    const newJobsObj = inboxListFirstLoad.filter(jb => {
      return (
        jb.title.toLowerCase().includes(event.target.value) ||
        startsWith(jb.skillsRequired, event.target.value).length > 0
      );
    });
    console.log('result', newJobsObj);
    setInboxList(newJobsObj);
  };
  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <SearchWrap>
              <h5> Inbox</h5>
              <SearchInpDiv className="locationskillswrapper-input">
                <Input
                  id={'search'}
                  className="skills-input"
                  type={'text'}
                  label={'Skills '}
                  onChange={handleSearch}
                  placeholder="Skills or Role"
                />
                <Input
                  id={'location'}
                  className="location-input"
                  type={'text'}
                  label={'Location '}
                  onChange={handleSearch}
                  placeholder="Location"
                />
                <Button type="button" content="" className="search-icon" />
              </SearchInpDiv>
            </SearchWrap>
            <JobListTable headers={headers} rowData={inboxList} />
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
      {/* <ModalContainer>
        <Modal
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={false}
          modalContent={
            <AddQuestionModal
              categoryId={categoryId}
              onAddQuestionSubmit={onAddQuestionSubmit}
            />
          }
        />
      </ModalContainer> */}
    </>
  );
};
