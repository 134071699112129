import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: auto;
  padding: 0px 100px;
`;

export const Table = styled.table<any>`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  // width: ${props => (props.width ? props.width : '96%')};
`;

export const TableHead = styled.thead`
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  background: #3d3d3d;
`;

export const TableBody = styled.tbody`
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
`;

export const TableTh = styled.th`
  text-align: left;
  padding: 15px;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  :nth-child(7) {
    text-align: center;
  }
`;

export const TableTd = styled.td`
  text-align: left;
  padding: 10px;
  font-size: 14px;
  position: relative;
  font-weight: 500;
  color: #000000;
`;

export const TableRow = styled.tr`
  min-height: 68px;
  color: #3d3d3d;
  cursor: pointer;
  :nth-child(even) {
    background-color: #f8f8f8;
  }
  .okbutton {
    background-color: #000000;
    color: #ffffff;
    margin: 0px auto;
    padding: 10px 20px;
    font-weight: 500;
  }
  .okbutton:hover {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
  }
`;

export const ActionDiv = styled.div`
  position: absolute;
  width: 118px;
  background: #ffffff;
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  left: 0;
  right: 43px;
  z-index: 10;
  margin-left: auto;
  top: 41px;
`;

export const ActionUl = styled.ul``;

export const ActionLi = styled.li`
  list-style: none;
  padding: 10px;
  border-bottom: 1px solid #f3f3f3;
  font-weight: 400;
  font-size: 14px;
  color: #141414;
  cursor: pointer;
`;
