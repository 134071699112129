import styled from 'styled-components';
import { device } from 'styles/media';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 18px;
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  padding: 0 60px;
  margin-top: 40px;
  font-family: 'Montserrat', sans-serif;
  @media ${device.desktop} {
    width: 95%;
  }
  @media ${device.laptopL} {
    width: 95%;
  }
  @media ${device.laptop} {
    width: 95%;
  }
  @media ${device.tablet} {
    width: 95%;
    padding: 0 60px;
  }
  @media ${device.mobileL} {
    width: 100%;
    padding: 0 20px;
  }
  @media ${device.mobileM} {
    width: 100%;
    padding: 0 20px;
  }
  @media ${device.mobileS} {
    width: 100%;
    padding: 0 20px;
  }
`;

export const HeadWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 50px;
  padding-left: 50px;
  background: #fbfbfb;
  font-weight: 400;
  padding: 15px 30px;
  /* @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobileL} {
    display: none;
  }
  @media ${device.mobileM} {
    display: none;
  }
  @media ${device.mobileS} {
    display: none;
  } */
`;

export const CardIcon = styled.img`
  width: 25px;
  margin-right: 15px;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  margin: 10px 0;
`;
export const ProgressBarLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media ${device.desktop} {
    width: 10%;
  }
  @media ${device.laptopL} {
    width: 10%;
  }
  @media ${device.laptop} {
    width: 10%;
  }
  @media ${device.tablet} {
    display: 10%;
  }
  /* @media ${device.mobileL} {
    display: none;
  }
  @media ${device.mobileM} {
    display: none;
  }
  @media ${device.mobileS} {
    display: none;
  } */
`;
export const ProgressBarRight = styled.div`
  width: 75%;
  height: auto;
  margin-left: 20px;
`;
export const QuestionCardButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 50px;
  /* @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobileL} {
    display: none;
  }
  @media ${device.mobileM} {
    display: none;
  }
  @media ${device.mobileS} {
    display: none;
  } */
`;

export const QuestionCardSkipButton = styled.div`
  padding: 10px 40px;
  border-radius: 5px;
  background: #ffffff;
  margin-right: 15px;
  outline: solid 1px #000;
  color: #3d3d3d;
  font-weight: 600;
`;

export const QuestionCardButton = styled.div`
  padding: 10px 30px;
  border-radius: 5px;
  background: #000;
  color: #ffffff;
  outline: solid 1px #000;
  font-weight: 600;
  span {
    color: #fff;
  }
`;

export const TimeText = styled.div`
  width: 6%;
  margin-left: 20px;
  display: flex;
  align-items: center;
  h6 {
    margin: 0;
  }
`;
export const CardBody = styled.div`
  display: flex;
  width: 100%;
  min-height: 485px;
  height: auto;
  background: #ffffff;
  flex-direction: row;
  text-align: left;
  margin-top: 30px;
  /* @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobileL} {
    display: none;
  }
  @media ${device.mobileM} {
    display: none;
  }
  @media ${device.mobileS} {
    display: none;
  } */
`;
export const CardBodyLeft = styled.div`
  display: block;
  width: 60%;
  @media ${device.desktop} {
    width: 60%;
  }
  @media ${device.laptopL} {
    width: 60%;
  }
  @media ${device.laptop} {
    width: 60%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
  @media ${device.mobileM} {
    width: 100%;
  }
  @media ${device.mobileS} {
    width: 100%;
  }
`;
export const CardBodyLeftTitle = styled.div`
  font-weight: 600;
`;
export const QuestionCardBodyLeft = styled.div`
  display: block;
  width: 50%;
  padding-inline-start: 50px;
  padding-inline-end: 20px;
`;

export const QuestionCardBodyRight = styled.div`
  display: block;
  width: 50%;
`;

export const AnswerChoice = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 22px;
  font-size: 14px;
  color: #3d3d3d;
  letter-spacing: 1px;
`;

export const AnswerChoiceCheckboxContainer = styled.div`
  display: flex;
  min-height: 60px;
  width: auto;
  height: auto;
  border-radius: 10px;
  background: ${props => props.color};
  margin-bottom: 10px;
  padding-left: 20px;
  align-items: center;
  justify-content: flex-start;
`;

export const ProgressBarLeftText = styled.span`
  margin-left: 10px 0;
  h6 {
    margin: 0;
  }
`;

export const QuestionNumber = styled.div`
  font-weight: 600;
  font-style: normal;
`;

export const QuestionLabel = styled.div`
  font-weight: 600;
  margin-left: 20px;
  h5 {
    padding-bottom: 10px;
  }
`;

export const QuestionDescription = styled.div`
  font-weight: 300;
`;

export const DescriptiveAnswer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding-left: 22px;
  color: #959595;
`;

export const WordCount = styled.div`
  position: absolute;
  display: block;
  margin-left: 90%;
  h6 {
    margin: 0;
  }
`;
