import styled from 'styled-components/macro';

export const PrimaryParagraph = styled.p<any>`
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '20px')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '400')};
  color: #3d3d3d;
  .exciting-news {
    padding: 20px;
    line-height: 30px;
    display: block;
    font-size: 15px;
  }
`;
export const SecondaryParagraph = styled.p`
  font-size: 13px;
  font-style: normal;
`;
