import styled from 'styled-components';
import Pagination from '@mui/material/Pagination';

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    color: 'black',
    margin: '0',
    borderRadius: '0 !important',
    border: '0.1px solid lightgrey !important',
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    backgroundColor: 'black !important',
    color: 'white !important',
  },
  '& .MuiPaginationItem-ellipsis': {
    lineHeight: '2.2 !important',
    fontSize: '14px !important',
    padding: '0 12px !important',
  },
}));
