import styled from 'styled-components/macro';

export const ModalContainer = styled.div`
  width: 100% !important;
  margin-left: 50px;
`;

export const ModelContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  font-weight: 400;
  margin: -10px;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  .background-opacity {
    background: #000000;
    width: 100%;
    position: fixed;
    left: 0;
    min-height: 100%;
    z-index: -1;
    opacity: 0.8;
    top: 0;
  }

  .modal-wrapper-content {
    display: flex;
    flex-direction: column;
  }
`;

export const MailIcon = styled.div`
  font-size: 14px;
  align-self: center;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Message = styled.div`
  padding-bottom: 20px;
  font-weight: 400;
  align-self: auto;
  justify-self: center;
  margin-top: 20px;
`;
