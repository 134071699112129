import React from 'react';
import {
  AlertBoxStyle,
  IconStyle,
  TextStyle,
  CloseButton,
} from './AlertStyles';
import CloseButtonIcon from 'app/assets/images/close-icon.svg';

const Alert = ({ setShowAlert, msg }) => {
  const triggerAlert = () => {
    setShowAlert(false);
  };

  return (
    <AlertBoxStyle>
      <CloseButton
        onClick={triggerAlert}
        src={CloseButtonIcon}
        alt="close-button-icon"
      />
      <IconStyle>!</IconStyle>
      <TextStyle>Alert: {msg}</TextStyle>
    </AlertBoxStyle>
  );
};

export default Alert;
