import styled from 'styled-components';
import logoBg from 'app/assets/images/login-bg.png';
import userIcon from 'app/assets/images/user.svg';
import lockIcon from 'app/assets/images/lock.png';
// import { clear } from 'node:console';

export const LogoImg = styled.img<any>`
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
`;

export const LoginWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(${logoBg});
  background-size: cover;
  min-height: 100%;
`;

export const VerificationPending = styled.div<any>`
  margin: 40px auto 0px auto;
  padding-bottom: 20px !important;
  width: 492px;
  background: #fdb82c;
  padding: 0px;
  border-radius: 20px;
  box-shadow: 7px 8px 25px #888, -2px -2px 4px rgba(0, 0, 255, 0.5);

  .congratulations {
    text-align: center;
    color: #ffffff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #3d3d3d;
  }

  .success {
    margin: 0px auto 30px auto;
    display: block;
    width: 90px;
  }
`;

export const LoginMain = styled.div<any>`
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  width: 492px;
  text-align: justify;
  /* box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.1);
  border-radius: 27px; */

  /* margin-left: 100px; */
  /* width: 492px; */
  input {
    padding-left: 30px;
  }
  input[type='text'] {
    background-image: url(${userIcon}) !important;
    background-position: left 10px center;
    background-repeat: no-repeat;
  }
  input[type='password'] {
    background-image: url(${lockIcon}) !important;
    background-position: left 10px center;
    background-repeat: no-repeat;
    background-size: 25px;
  }

  button {
    line-height: 50px;
    color: #fff;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    &.forgot {
      color: #474747;
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      margin-top: -30px;
    }
  }
`;

export const ClearDiv = styled.div`
  clear: both;
`;
export const ErrorDiv = styled.span`
  color: #e92626;
  font-size: 14px;
`;

export const SubTitle = styled.div`
  left: 0;
  top: 0;
  color: #474747;
  position: relative;
`;
export const SubTitle2 = styled.div`
  height: 21;
  left: 128;
  top: 0;
  color: #4f86ed;
  position: relative;
  text-decoration: 'underline';
`;

export const ResendMainDiv = styled.div`
  .resend-email {
    background-color: #3d3d3d;
    margin-left: 20px;
    margin-right: 20px;
    width: 91%;
    border-radius: 50px;
    margin-bottom: 30px;
    color: #ffffff;
    padding: 20px;
    font-size: 18px;
    font-weight: 500;
  }
  input {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
`;
export const TextDiv = styled.div`
  padding: 20px;
  font-weight: 500;
  font-size: 20px;
`;

export const TextParentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  .text-or-border {
    width: 42%;
    height: 1px;
    background: #3d3d3d;
  }
  .text-or {
    padding: 20px;
    font-weight: bold;
    font-size: 20px;
  }
`;

export const TextChildDiv = styled.div``;

export const HeadWrapDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px;
  font-size: 13px;
`;
