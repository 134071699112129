import styled from 'styled-components';

export const JobDetailsWrapper = styled.div`
  display: flex;
  padding: 0px 25px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const JobDetailsLeftWrapper = styled.div`
  width: 75%;
  position: relative;
  overflow-x: hidden;
  padding-right: 20px;
  h1 {
    color: #3d3d3d;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  h2 {
    color: #3d3d3d;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  h4 {
    color: #3d3d3d;
    font-size: 15px;
    margin-top: 30px;

    margin-bottom: 10px;
  }
  h5 {
    font-size: 15px;
    margin-top: 15px;

    margin-bottom: 10px;
  }
  @media (max-width: 1000px) {
    width: auto;
    padding-right: 0px;
  }
`;
export const JobSkills = styled.div`
  background-color: #fdb82c;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  margin-top: 20px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
export const JobSkillMain = styled.div`
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  background-color: #3d3d3d;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
  h3 {
    font-size: 15px;
    font-weight: 500;
  }
  @media (max-width: 700px) {
    margin-right: 0px;
    margin-top: 20px;
  }
`;

export const JobPara = styled.p`
  font-size: 13px;
  line-height: 1.8;
  color: rgba(105, 105, 105, 1);
`;

export const JobDetailsList = styled.ul`
  padding-left: 15px;
`;

export const JobDetailsListLiHead = styled.ul`
  font-size: 13px;
  color: rgba(105, 105, 105, 1);
  padding-bottom: 10px;
`;

export const JobDetailsListLi = styled.li`
  font-size: 13px;
  color: rgba(105, 105, 105, 1);
  padding-bottom: 10px;
`;

export const JobRightWrapper = styled.div`
  width: 25%;
  position: relative;
  overflow-x: hidden;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid rgba(74, 74, 74, 1);
  margin-bottom: 40px;
  img.wipro-logo {
    width: 90px;
    margin: 0px auto;
    display: block;
  }

  h1 {
    color: #3d3d3d;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  h2 {
    color: #3d3d3d;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  @media (max-width: 1000px) {
    width: auto;
    padding-right: 0px;
  }
`;
export const JobDetailsFameInfo = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  margin-top: 15px;
  img {
    margin-right: 10px;
  }
`;
export const JobDetailsImgWrapper = styled.div`
  width: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;
`;

export const JobDetailsContent = styled.div``;
export const JobDetailsPostWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: -5px;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
`;
export const JobDetailsPostSubWrapper = styled.div`
  width: 96px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
`;
export const JobDetailsButtonWrapper = styled.div`
  display: flex;
  margin-top: 80px;
  margin-bottom: 80px;
  position: absolute;
  justify-content: center;
  bottom: 10px;
  justify-content: center;
  @media (max-width: 1000px) {
    position: relative;
  }
  button.job-details-cancel {
    width: auto;
    padding: 8px 10px;
    margin-right: 15px;
    border-radius: 5px;
    border: 1px solid #000000;
    background-color: transparent;
    cursor: pointer;
  }
  button.job-details-apply {
    padding: 8px 10px;
    width: auto;
    color: #000000;
    font-weight: bold;
    background-color: #ffd361;
    border: 1px solid #ffd361;
    border-radius: 5px;
    cursor: pointer;
  }
`;
export const JobDetailsFooterSubDiv = styled.div``;
export const JobDetailsFooter = styled.div`
  padding: 20px;
  background: #ffd361;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
