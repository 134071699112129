import React, { useEffect } from 'react';
import Button from 'app/components/UI/atoms/Button';
import { Input } from 'app/components/UI/atoms/Input';
import {
  ButtonDiv,
  InputWrapperDiv,
  ModalFlexDiv,
  ModalTagFlex,
  ModalTextFlex2,
} from './writingQuestionMOdalStyles';
import { useForm } from 'react-hook-form';

export const WritingQuestionModal = ({
  quesLevel,
  categoryId,
  onAddQuestionSubmit,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // const { isShown, toggle } = useModal();
  useEffect(() => {
    reset();
  }, [reset]);

  const onSubmit = handleSubmit(async (data: any) => {
    onAddQuestionSubmit({ ...data, level: quesLevel });
    // const respData = await addQuestion(reqData);
    // console.log(JSON.stringify(respData));
  });

  return (
    <form className="Input-Wrapper" noValidate={true} onSubmit={onSubmit}>
      {errors && Object.keys(errors).length > 0 && (
        <span>Please fill all values</span>
      )}
      <ModalFlexDiv>
        <InputWrapperDiv>
          <Input
            id="questionText"
            type="text"
            // label="question text2"
            placeholder="question text"
            {...register('questionText', {
              required: true,
            })}
            required
          />
        </InputWrapperDiv>
        <ModalTextFlex2>
          <ModalTagFlex>
            <InputWrapperDiv>
              <Input
                id="keywords"
                type="text"
                // label="question text2"
                placeholder="question text"
                {...register('questionText', {
                  required: true,
                })}
                required
              />
            </InputWrapperDiv>
          </ModalTagFlex>
        </ModalTextFlex2>
      </ModalFlexDiv>
      <ButtonDiv>
        {/* <Button type="button" content="Duration" className="duration" /> */}
        <Button type="button" content="Cancel" className="cancel" />
        <Button type="submit" content="Save" className="save" />
      </ButtonDiv>
    </form>
  );
};
