import styled from 'styled-components';

export const Input = styled.input`
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #12b19e !important;
  border-radius: 50%;
  background: #12b19e !important;
  position: relative;
  margin-left: 5px;
  margin-right: 10px;

  /* :checked &::after {
    background: #12b19e !important;
    border: 4px solid #12b19e !important;
    box-shadow: inset 0 0 0 4px #3197ee;
    border-radius: 50%; */
  /* } */
`;
