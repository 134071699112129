import styled from 'styled-components';

export const LogoImg = styled.img<any>`
  margin-left: 0;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
`;
export const RocketImg = styled.img<any>`
  position: absolute;
  left: -5px;
  right: 0px;
  margin: 0px auto;
  z-index: 1;
  bottom: 0px;
  width: 200px;
  /* width: 200px; */
  @media (max-width: 1000px) {
    position: inherit;
    z-index: 10000000000;
    width: 65%;
  }
  @keyframes rocket {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-1000px);
    }
  }
  animation: rocket 4s linear infinite;
`;
export const MainWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const LeftWrapper = styled.div<any>`
  width: 50%;
  padding: 50px;
  position: relative;
  z-index: 100000;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  h2 {
    font-weight: 400;
    margin-top: 70px;
    font-size: 28px;
    color: #393939;
  }
  h3 {
    font-weight: 400;
    font-size: 28px;
    color: #393939;
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
  }
  @media (max-width: 1000px) {
    width: 80%;
    padding: 10px;
  }
`;

export const ParaWrap = styled.p`
  font-weight: 400;
  font-size: 18px;
  margin-top: 40px;
  line-height: 24px;
  color: #000000;
`;
export const RightWrapper = styled.div<any>`
  width: 50%;
  background-color: #fdb82c;
  padding: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  > * {
    align-self: center;
  }
  h1 {
    font-weight: 700;
    font-size: 36px;
    color: #393939;
  }
  input {
    width: 100%;
    position: relative;
    z-index: 100000;
    margin-top: 30px;
    padding: 10px;
    text-indent: 10px;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  button {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
    color: #ffffff;
    width: 98px;
    cursor: pointer;
    height: 38px;
    background: #000000;
    border-radius: 5px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    height: 100%;
    padding: 20px 0px;
  }
`;
export const Title1 = styled.h1`
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #393939;
`;
export const InputMain = styled.div<any>`
  input[type='text'] {
    background-position: left center;
    background-repeat: no-repeat;
  }
  input[type='text'] {
    background-position: left center;
    background-repeat: no-repeat;
  }
  button {
    line-height: 40px;
    color: #fff;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    &.forgot {
      color: #474747;
      font-weight: 500;
      font-size: 14px;
    }
  }
`;

export const ClearDiv = styled.div`
  clear: both;
`;
export const ErrorDiv = styled.span`
  color: #e92626;
  font-size: 14px;
`;
export const ButtonWrap = styled.div`
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: end;
`;
