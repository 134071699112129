import styled from 'styled-components';
import profileIcon from 'app/assets/images/userIcon.jpg';
import logOutFrame from 'app/assets/images/logout.svg';
import ViewprofileIcon from 'app/assets/images/view-profile.svg';
import supportIcon from 'app/assets/images/support.svg';
import { getProfileImage } from 'app/services/users';

export const HeaderDiv = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  justify-content: space-between;
  height: 92px;
  display: flex;
  align-items: center;
  button.varma-icon {
  }

  .header-toggle {
    background-image: url(${getProfileImage()
      ? getProfileImage()
      : profileIcon});
    /* background-image: url(${profileIcon}); */
    width: 50px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 20px;
    border: none;
    cursor: pointer;
    border-radius: 100px;
  }

  .dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px;
    border-radius: 10px;
    min-width: 222px;
    overflow: auto;
    z-index: 1000;
    right: 0px;
    top: 72px;
  }

  .dropdown-content a {
    font-weight: 500;
    color: rgb(61, 61, 61);
    text-decoration: none;
    font-size: 13px;
    text-indent: 35px;
    border-bottom: 1px solid rgb(241, 241, 241);
    padding: 15px 10px;
    background-repeat: no-repeat;
    background-position: left 15px center;
  }

  .logout-button {
    float: none;
  }

  .view-profile {
    background-image: url(${ViewprofileIcon});
  }

  .support-header {
    background-image: url(${supportIcon});
  }

  .logout-header {
    background-image: url(${logOutFrame});
  }

  .dropdown-content a:hover {
    color: #000000;
  }
`;
export const OrgLogo = styled.img<any>`
  margin-left: 20px;
  width: 120px;
`;

export const DropDownDiv = styled.div.attrs(({ ref }) => ({
  ref: ref,
}))`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 20px;
  a {
    &:hover {
      background-color: #f2f2f2;
    }
  }
  button {
    background-color: transparent;
    border: none;
    color: white;
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-top: 13px;
    background-repeat: no-repeat;
    background-size: contain;
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
`;
export const ArrowDown = styled.div`
  width: 0px;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #555;
  margin-top: 20px;
  margin-left: 5px;
  cursor: pointer;
`;

export const MenuTogglerDiv = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  cursor: pointer;
  .filter-icon {
    width: 40px;
    height: 40px;
  }
`;

export const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-width: 222px;
  overflow: auto;
  z-index: 1;
  right: 0px;
  top: 0px;
  a {
    color: #3d3d3d;
    padding: 12px 16px;
    font-weight: 500;
    text-decoration: none;
    font-size: 13px;
    text-indent: 35px;
    border-bottom: 1px solid #f1f1f1;
    padding: 15px 10px;
    background-repeat: no-repeat;
    background-position: left 15px center;
  }
  button {
    float: none;
  }
`;
export const ContentDivWrapper = styled.div`
  margin-left: 290px;
  // font-size: 28px;
  @media (max-width: 800px) {
    margin-left: 187px;
    font-size: 28px;
    width: 100%;
  }
`;
export const TitleWrapper = styled.h1`
  width: 100%;
  justify-content: space-between !important;
  h1 {
    margin: 0px !important;
    padding: 0px;
  }
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  text-transform: capitalize;
  color: #1e1e1e;
  background: #fdb82c;
  text-align: left;
  margin-bottom: 30px !important;
  padding: 13px 0px 13px 10px;
  font-weight: bold;
  margin-top: 20px;
`;
