import React, { forwardRef } from 'react';
import { Input } from './RadioStyles';

export type RadioProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface Props extends RadioProps {
  id: string;
  label?: string;
  className?: string;
  placeholder?: string;
  value?: string;
  isSelected?: boolean | undefined;
}

export const Radio = forwardRef<HTMLInputElement, Props>(
  ({ value, isSelected, onChange, name, id, label, disabled }: Props, ref) => {
    return (
      <>
        <Input
          id={id}
          ref={ref}
          type="radio"
          name={name}
          value={value}
          checked={isSelected}
          onChange={onChange}
          disabled={disabled}
        />
        <label htmlFor={id}>
          <span>{label}</span>
        </label>
      </>
    );
  },
);
