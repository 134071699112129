import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from 'app/services/auth/auth';
import spinnerLoad from 'app/assets/images/loader.gif';

export const Logout = () => {
  let navigate = useNavigate();
  const logoutUser = async () => {
    await logout();
    navigate('/', { replace: true });
  };

  useEffect(() => {
    logoutUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <img
        src={spinnerLoad}
        alt=""
        width={100}
        height={100}
        style={{ marginTop: '20%', marginLeft: '40%' }}
      />
      {/* Logging out ... */}
    </>
  );
};
