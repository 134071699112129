import axios from 'axios';
import { getToken } from './auth/auth';

const baseUrl = process.env.REACT_APP_API_URL;

export const getAllCategories = async () => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}questionbank/category`,
      method: 'get',
      data: JSON.stringify(''),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    return res.data.result;
  } catch (err) {
    console.log('getCategory error: ', err);
    return err;
  }
};

export const getQuestionsByCategory = async (
  categoryId: string,
  proficiency: string,
) => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}questionbank/category/${categoryId}/question?type=mcq&status=active&proficiency=${proficiency}`,
      method: 'get',
      data: JSON.stringify(''),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    return res.data.result;
  } catch (err) {
    console.log('getCategory error: ', err);
    return err;
  }
};

export const createCategory = async ({ name }: { name: string }) => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}/category/v1/category`,
      data: { name },
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res;
  } catch (err) {
    console.log('createCategory post error: ', err);
    return err;
  }
};

export const getQuestionsMinMaxTimeEstimate = async data => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}questionbank/category/all/estimate`,
      data,
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res.data.result;
  } catch (err) {
    console.log('createCategory post error: ', err);
    return err;
  }
};
