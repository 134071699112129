import styled from 'styled-components';
// import searchIcon from 'app/assets/images/searchIcon.svg';

export const BodyDiv = styled.div`
  width: 100%;
  .profile-heading {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    background-color: #fdb82c;
    padding: 10px;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #3d3d3d;
    font-family: 'Montserrat', sans-serif;
  }

  .customer-service {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .support-wrapper {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 40px;
  }

  @media screen and (max-width: 1000px) {
    .support-wrapper {
      flex-direction: column;
    }
  }

  .support-frame {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 33%;
    min-height: 100%;
    align-items: center;
    border-radius: 30px;
  }

  @media screen and (max-width: 1000px) {
    .support-frame {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .phonenum {
    display: flex;
    align-items: center;
  }

  .support-content {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: center;
  }

  .prefer-service {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 6px;
  }

  .support-image {
    width: 100%;
    padding: 20px;
    background: #fdb82c;
    border-top-left-radius: 30px;
  }

  .support-image img {
    width: 50px;
  }

  .last-frame {
    margin-right: 0px;
  }
  .jobs-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .breadcrumbwrapper {
    display: flex;
  }
`;
export const OopsSpan = styled.span`
  display: table;
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 131%;
  color: #3d3d3d;
`;

export const AddCategoryModal = styled.div`
  width: 480px;
  @media (max-width: 800px) {
    width: 100%;
  }
  input {
    top: 20px;
    width: 380px;
  }
`;

export const SearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: right;
  select {
    width: 133px;
    cursor: pointer;
    margin-left: 20px;
    height: 36px;
  }
  h5 {
    padding-right: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #3d3d3d;
  }
  button {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    height: 38px;
    width: auto;
    background-position: center left 10px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border-radius: 5px;
  }

  .create-job-btn {
    background: #fdb82c !important;
    color: #000000;
    margin-right: 5px;
  }
`;
export const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    text-align: left;
  }
`;
export const TopDiv = styled.div`
  display: flex;
  align-items: center;
  .job-post {
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    padding: 10px 25px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    margin: 0px auto;
    font-family: 'Montserrat', sans-serif;
  }
  input {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-right: -8px;
    width: 200px;
    height: 38px;
    text-indent: 10px;
    margin-bottom: 0px;
    font-family: 'Montserrat', sans-serif;
  }
  & select {
    width: 133px;
    cursor: pointer;
    margin-left: 20px;
    height: 36px;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const ContentDiv = styled.div`
  .post-job-content-flex {
    margin-bottom: 20px;
  }

  #workMode {
    margin-right: 3%;
  }
`;
export const ContentSubDiv = styled.div`
  .profile-form {
    background: #ffffff;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    margin: 20px auto;
    display: block;
    width: 50%;
    padding: 25px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  }
  h1 {
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 16px;
    color: #3d3d3d;
  }
  button {
    float: right;
  }

  .textareawrapper {
    width: 100%;
    display: block;
  }
  select {
    padding: 0px 18px 0px 5px !important;
  }
`;
export const ErrorDiv = styled.span`
  color: #e92626;
  font-size: 14px;
`;

export const ClearDiv = styled.div`
  clear: both;
`;

export const HolderDiv = styled.div`
  position: relative;
`;

export const ReqPlaceholder = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
`;

export const ReqSpan = styled.span`
  color: red;
`;

export const InpWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  div.location-multi-select {
    width: 100%;
    margin-right: 3%;
    font-size: 14px;
  }
  div.width-margin {
    width: 107%;
    margin-right: 0% !important;
  }
  div.select-drop {
    position: relative;
    width: 100%;
    height: 40px;
    margin-right: 3%;
  }

  .right-select-drop {
    position: relative;
    width: 100%;
    height: 40px;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 38px;
    font-family: 'Montserrat', sans-serif;
    text-indent: 10px;
  }
  textarea {
    height: 40px;
    border-radius: 5px;
    resize: none;
    font-family: 'Montserrat', sans-serif;
  }
  select {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 38px;
    cursor: pointer;
    width: 100%;
  }
  .jobdesc {
    position: absolute;
    height: 100px !important;
  }
  input[required='required']:valid + .req_placeholder {
    display: none;
  }
  .req_placeholder {
    position: absolute;
    top: 2px;
    left: 2px;
  }
  .req_placeholder span {
    color: red;
  }
  .dummy {
    visibility: hidden;
  }
  .abt-comp-text-area {
    padding-right: 10px;
  }
`;

export const CloseModal = styled.div``;
export const ClosePara = styled.div``;
export const CloseTitle = styled.h3``;
