import axios from 'axios';
import { getToken } from './auth/auth';

const baseUrl = process.env.REACT_APP_API_URL;

export const getUserByEmail = async email => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}user/user/email/${email}`,
      method: 'get',
      headers: { 'content-type': 'application/json', Authorization: token },
    });

    const { data: resp } = res;
    // invalid token check tbd
    if (resp.result.error) {
      return {};
    }
    return resp.result;
  } catch (err: any) {
    console.log('Test fetch error: ', err);
    // tbd move it
    if (
      err?.response?.data?.result?.error === 'Error: Error validating token'
    ) {
      // history.push("/logout");
    }
    return {};
  }
};

export const getUsersProfile = async () => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}user/user/profile`,
      method: 'get',
      headers: { 'content-type': 'application/json', Authorization: token },
    });

    const { data: resp } = res;
    // invalid token check tbd
    if (resp.result.error) {
      return {};
    }
    return resp.result;
  } catch (err: any) {
    console.log('Test fetch error: ', err);
    // tbd move it
    if (
      err?.response?.data?.result?.error === 'Error: Error validating token'
    ) {
      // history.push("/logout");
    }
    return {};
  }
};

export const setProfileImage = (img: string) => {
  localStorage.setItem('pi', img);
};

export const getProfileImage = () => {
  return localStorage.getItem('pi');
};
export const setUserId = (ui: string) => {
  localStorage.setItem('ui', ui);
};

export const getUserId = () => {
  return localStorage.getItem('ui');
};

export const editUsersProfile = async data => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}user/user/profile`,
      data,
      method: 'PUT',
      headers: { 'content-type': 'application/json', Authorization: token },
    });

    const { data: resp } = res;
    // invalid token check tbd
    if (resp?.result?.error) {
      return {};
    }
    const { profileUrl } = await getUsersProfile();
    setProfileImage(profileUrl);
    // const headerToggle = document.querySelector('.header-toggle');
    // // Change the background-image property
    // if (headerToggle) {
    //   //@ts-ignore
    //   headerToggle.style.backgroundImage = `url(${profileUrl})`;
    // }
    return resp;
  } catch (err: any) {
    console.log('Test fetch error: ', err);
    // tbd move it
    if (
      err?.response?.data?.result?.error === 'Error: Error validating token'
    ) {
      // history.push("/logout");
    }
    return {};
  }
};
