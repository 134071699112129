import styled from 'styled-components';

export const LoadingWrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 1000px;
  align-items: center;
  background-color: #393939;
  width: 100%;
  #circle2,
  #circle3,
  #circle4 {
    opacity: 0;
  }
  #circle1 {
    animation: highlight 2s ease-in-out infinite;
  }
  #circle2 {
    animation: highlight 2s ease-in-out infinite;
    animation-delay: 0.5s;
  }
  #circle3 {
    animation: highlight 2s ease-in-out infinite;
    animation-delay: 1s;
  }
  #circle4 {
    animation: highlight 2s ease-in-out infinite;
    animation-delay: 1.5s;
  }
  img.loader-book {
    animation: bounce 5s infinite;
  }
  @keyframes highlight {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
`;
export const LoaderLogoImg = styled.img<any>`
  position: absolute;
  top: 20px;
  left: 20px;
`;
export const HeaderOops = styled.h1<any>`
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  color: #ffffff;
`;
export const LoadingContent = styled.h1<any>`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  color: #ffffff;
  margin-bottom: 25px;
  .request-heading {
    margin-bottom: 20px;
  }
`;
