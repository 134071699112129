import styled from 'styled-components';

export const ModalFlexDiv = styled.div`
  display: flex;
  padding-bottom: 20px;
  margin-top: 15px;
  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;
export const ModalTextFlex = styled.div`
  padding-right: 20px;
  @media (max-width: 1010px) {
    width: 100%;
  }
  @media (max-width: 1010px) {
    width: 420px;
  }
`;
export const ModalTextFlex2 = styled.div``;
export const InputWrapperDiv = styled.div`
  position: relative;
  width: 100%;
  margin-right: 20px;
  button {
    width: 24px !important;
    cursor: pointer !important;
    height: 26px !important;
    background: #000000 !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-size: 25px !important;
    line-height: 21px !important;
    position: absolute !important;
    right: 0 !important;
    top: 7px !important;
  }
  @media (min-width: 1010px) {
    width: 420px;
  }
  @media (max-width: 1010px) {
    width: 100%;
  }
  input {
    width: 70%;
    height: 36px;
    margin-right: 20px;
    text-indent: 10px;
  }
`;

export const ModalTagFlex = styled.div`
  display: flex;
  button {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    width: 118px;
    height: 38px;
    background-color: #fdb82c;
    background-image: url('../images/plus.svg');
    background-position: center left 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border-radius: 5px;
    color: #000000;
    margin-top: 0px;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: right;
  .duration {
    width: 114px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #141414;
    margin-right: 10px;
    cursor: pointer;
  }

  .cancel {
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #141414;
    margin-right: 10px;
    width: 114px;
    height: 38px;
    background: #ffffff;
  }

  .save {
    width: 94px;
    height: 38px;
    background: #000000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
  }
`;
