import React, { forwardRef } from 'react';
import { Wrapper } from './TextAreaStyles';

export type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export interface Props extends TextAreaProps {
  id: string;
  label: string;
  className?: string;
  placeholder?: string;
  value?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    { id, className, placeholder, label, value, onChange, ...props }: Props,
    ref,
  ) => {
    return (
      <Wrapper>
        <textarea
          ref={ref}
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          {...props}
        ></textarea>
      </Wrapper>
    );
  },
);
