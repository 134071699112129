import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const StepCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 103px;
  height: 103px;
  background: #fdb82c;
  border: 3px solid #fdb82c;
  border-radius: 100%;
`;

export const StepLine = styled.div`
  width: 3px;
  height: 78px;
  background: #fdb82c;
`;

export const ResultWrapper = styled.div`
  width: 857px;
  display: flex;
  height: 239px;
  background: #ffffff;
  border: 3px solid #fdb82c;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  padding: 50px;
`;
