import React from 'react';
import SuccessIcon from 'app/assets/images/success-icon.svg';
import ProfileImg from 'app/assets/images/user.jpeg';
import {
  ListWrapper,
  ListTable,
  ListHead,
  ListBody,
  ListRow,
  ListTabDiv,
  ListTh,
  // AddFuncBtn,
  // DownloadReport,
  // ToggleView,
} from 'app/components/pages/GlobalStyles/GlobalStyles';
import {
  MailIcon,
  Message,
  ModalContainer,
  ModelContent,
} from 'app/components/UI/globalStyles/modalStyles';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import { Modal } from 'app/components/UI/Modal/SimpleModal';
import { useModal } from 'app/components/UI/Modal/SimpleModal/userModal';
import { DownloadDiv, InprogressIcon, ScoreLevelDiv } from '../Styles';
import invalid from 'app/assets/images/invalid.svg';

export const RespondantsList = ({ headers, rowData }) => {
  const { isShown, toggle } = useModal();

  const handleShowQues = (quesId: string) => {
    // setRedirect(quesId);
  };

  const content = (
    <ModelContent>
      <MailIcon>
        <img src={SuccessIcon} alt="mail-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <PrimaryParagraph>The public link has been copied</PrimaryParagraph>
      </Message>
    </ModelContent>
  );

  const handleClick = url => {
    window.open(url, '_blank', 'noreferrer');
  };

  const dateTime = timestamp => {
    return new Date(timestamp).toLocaleString('en-US');
  };

  const getIcon = (status: string, reportUrl?: string) => {
    if (status === 'Incomplete') {
      return <img src={invalid} alt="In Complete" />;
    } else if (status === 'Complete') {
      return (
        <DownloadDiv
          className="downloaddiv"
          onClick={() => handleClick(reportUrl)}
        ></DownloadDiv>
      );
    } else {
      return <InprogressIcon className="inprogressicon"></InprogressIcon>;
    }
  };

  return (
    <ListWrapper>
      <ListTable className="respondent-list">
        <ListHead>
          <ListRow>
            {headers.map(header => (
              <ListTh key={header.id}>{header.name}</ListTh>
            ))}
          </ListRow>
        </ListHead>
        <ListBody>
          {rowData &&
            rowData.length > 0 &&
            rowData.map((row, index) => {
              return (
                <ListRow key={row.userId}>
                  <ListTabDiv
                    onClick={() => {
                      handleShowQues(row.userId);
                    }}
                  >
                    <div className="profile-image-wrapper">
                      <img src={ProfileImg} alt="" width={50} />

                      {row.name ? row.name : 'Not Given'}
                    </div>
                  </ListTabDiv>
                  <ListTabDiv>{row.email}</ListTabDiv>
                  <ListTabDiv>{row.phoneNumber}</ListTabDiv>
                  <ListTabDiv>{dateTime(row.completionDate)}</ListTabDiv>
                  <ListTabDiv>
                    <ScoreLevelDiv>
                      <div className="row-score">{row.score} %</div>
                      {getIcon(row.status, row.reportUrl)}
                    </ScoreLevelDiv>
                  </ListTabDiv>
                </ListRow>
              );
            })}
        </ListBody>
      </ListTable>
      <ModalContainer>
        <Modal
          isShown={isShown}
          isShowCloseButton={true}
          hide={toggle}
          modalContent={content}
        />
      </ModalContainer>
    </ListWrapper>
  );
};
