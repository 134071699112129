import React, { useEffect, useRef, useState } from 'react';
import { A } from 'app/components/UI/atoms/Anchor';
import { HeaderDiv, OrgLogo } from './headerStyles';
import Button from 'app/components/UI/atoms/Button';
// import membershipIcon from 'app/assets/images/membership.svg';
import jwt_decode from 'jwt-decode';
import { getToken } from 'app/services/auth/auth';
import { ModalContainer } from '../Modal/MessageModelStyles';
import { Modal } from '../Modal/SimpleModal';
import { ConfirmationModal } from 'app/components/UI/Modal/confirmationModal/confirmationModal';
import { useModal } from 'app/components/UI/Modal/SimpleModal/userModal';
import { useNavigate } from 'react-router-dom';

export const Header = () => {
  const { isShown, toggle } = useModal();
  // const dropdownBtnRef = useRef<HTMLButtonElement>(null);
  const token = getToken();
  const decodedJT: any = jwt_decode(token);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const profileDropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navigate = useNavigate();
  const logoutAction = e => {
    e.preventDefault();
    toggle();
  };
  const onConfirm = () => {
    // return true;
    navigate('/logout');
  };

  const onCancel = () => {
    toggle();
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        profileDropdownRef.current &&
        !(profileDropdownRef.current as HTMLElement).contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Detach the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutContent = (
    <ConfirmationModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      message=""
      title="Are you sure you want to Logout?"
      confirmButtonContent="Log out"
      cancelButtonContent="Cancel"
    />
  );

  return (
    <>
      <HeaderDiv>
        {/* <OrgLogo src={trinity} alt="org-logo1" /> */}
        {decodedJT?.user?.organisationId && (
          <OrgLogo
            src={`${process.env.REACT_APP_S3_ASSETS_URL}logos/${decodedJT.user.organisationId}.png`}
            alt="org-logo1"
          />
        )}

        <div>
          <div onClick={toggleDropdown}>
            <button className="header-toggle"></button>
          </div>

          {isDropdownOpen && (
            <div ref={profileDropdownRef} className="dropdown-content">
              {/* Dropdown content */}
              <a href="/profile" className="view-profile">
                View Profile
              </a>
              <a href="/support" className="support-header">
                Support
              </a>
              {/* <a href="/logout" className="logout-header">
                Logout
              </a> */}
              <A href="/logout" className="logout-header">
                {/* Log out */}
                <Button
                  content={'Log out'}
                  type={'button'}
                  onClick={logoutAction}
                  className="logout-button"
                  bgType="secondary"
                />
              </A>
            </div>
          )}
        </div>
      </HeaderDiv>
      <ModalContainer>
        <Modal
          width="50%"
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={true}
          modalContent={logoutContent}
        />
      </ModalContainer>
    </>
  );
};
