import styled from 'styled-components';

export const AlertBoxStyle = styled.div`
  display: flex;
  align-items: center;
  background-color: #fdb82c;
  padding: 10px;
  border-radius: 10px;
`;

export const IconStyle = styled.div`
  font-size: 24px;
  margin-right: 10px;
  padding-left: 10px;
  color: red;
`;

export const TextStyle = styled.div`
  font-size: 16px;
  color: red;
`;

export const CloseAlert = styled.div`
  background-image: url('app/assets/images/close-icon.svg');
`;

export const CloseButton = styled.img`
  padding: 10px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-left: 0px !important;
  margin-right: 0px !important;
`;
