import React, { useEffect, useState } from 'react';
import Button from 'app/components/UI/atoms/Button';
import { Header } from 'app/components/UI/organisms/Header';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import { BodyDiv, SearchWrap } from './inboxListingStyles';
import { JobListTable } from './indexListTable';
import { Input } from 'app/components/UI/atoms/Input';
import { SearchInpDiv } from './inboxListingStyles';
import { mockData, mockUserData } from './mock/mock';
import { getToken } from 'app/services/auth/auth';
import jwt_decode from 'jwt-decode';
// import { Navigate } from 'react-router-dom';

export const TestReports = () => {
  const [inboxList, setInboxList] = useState<any>();
  // const [headers, setHeaders] = useState<any>();
  const [inboxListFirstLoad, setInboxListFirstLoad] = useState<any>();
  const token = getToken();

  const decodedJT: any = jwt_decode(token);
  const { role } = decodedJT.user;
  const JpHeaders = [
    { id: 'name', name: 'Name' },
    { id: 'email', name: 'Email' },
    { id: 'testName', name: 'Test Name' },
    { id: 'receivedDate', name: 'Received Date' },
    { id: 'completedDate', name: 'Completed Date' },
    { id: 'score', name: 'Score' },
    { id: '', name: '' },
  ];

  const userHeaders = [
    { id: 'testName', name: 'Test Name' },
    { id: 'receivedDate', name: 'Received Date' },
    { id: 'completedDate', name: 'Completed Date' },
    { id: 'score', name: 'Score' },
    { id: '', name: '' },
  ];
  let jobsData: any = [];
  const getAllInbox = async () => {
    // jobsData = await getAllInbox();
    if (role === 'user') {
      setInboxList(mockUserData);
      // setHeaders(userHeaders);
    } else {
      setInboxList(mockData);
      // setHeaders(JpHeaders);
    }

    console.log(mockData);
    setInboxListFirstLoad(jobsData.jobs);
  };
  useEffect(() => {
    getAllInbox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  // if (!token || token === null) {
  //   return <Navigate replace to="/" />;
  // }

  const startsWith = (array, key) => {
    const matcher = new RegExp(`^${key.toLowerCase()}`, 'g');
    return array.filter(word => word.toLowerCase().match(matcher));
  };

  const handleSearch = event => {
    const newJobsObj = inboxListFirstLoad.filter(jb => {
      return (
        jb.title.toLowerCase().includes(event.target.value) ||
        startsWith(jb.skillsRequired, event.target.value).length > 0
      );
    });
    console.log('result', newJobsObj);
    setInboxList(newJobsObj);
  };
  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <SearchWrap>
              <h5> Reports</h5>
              <SearchInpDiv className="locationskillswrapper-input">
                <Input
                  id={'search'}
                  className="skills-input"
                  type={'text'}
                  label={'Skills '}
                  onChange={handleSearch}
                  placeholder="Test name"
                />

                <Input
                  id={'location'}
                  className="location-input"
                  type={'text'}
                  label={'Location '}
                  onChange={handleSearch}
                  placeholder="Location"
                />
                <Button type="button" content="" className="search-icon" />
              </SearchInpDiv>
            </SearchWrap>
            <JobListTable
              headers={role === 'user' ? userHeaders : JpHeaders}
              rowData={inboxList}
              role={role}
            />
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
      {/* <ModalContainer>
        <Modal
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={false}
          modalContent={
            <AddQuestionModal
              categoryId={categoryId}
              onAddQuestionSubmit={onAddQuestionSubmit}
            />
          }
        />
      </ModalContainer> */}
    </>
  );
};
