import styled from 'styled-components';

export const ModelContentWrapper = styled.div`
  display: flex;
  left: 0;
  right: 0;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 20px;

  .logout-heading {
    font-weight: 500;
    margin-top: 50px;
  }

  .logoutmodalbuttons {
    font-weight: 500;
    font-size: 14px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
`;

export const CancelButton = styled.div``;

export const ConfirmButton = styled.div``;
