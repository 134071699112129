export const mockData = [
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    company: 'Infosys',
    assessmentName: 'Fullstack Dev',
    receivedDate: '01/04/2023',
    expiryDate: '01/10/2023',
    status: 'Start',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b911',
    company: 'Pine Labs',
    assessmentName: 'Nodejs Dev',
    receivedDate: '01/02/2023',
    expiryDate: '01/06/2023',
    status: 'Completed',
    statusColor: '#28C28B',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b912',
    company: 'DXC',
    assessmentName: 'Javascript Dev',
    receivedDate: '01/06/2023',
    expiryDate: '01/12/2023',
    status: 'Start',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b914',
    company: 'Elixir Web',
    assessmentName: 'Reactjs Dev',
    receivedDate: '01/04/2023',
    expiryDate: '01/10/2023',
    status: 'Start',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b915',
    company: 'Yellow Pages',
    assessmentName: 'Fullstack Dev',
    receivedDate: '01/05/2023',
    expiryDate: '01/11/2023',
    status: 'In Progress',
    statusColor: '#FC9A28',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b911',
    company: 'Magic Box',
    assessmentName: 'Nodejs Dev',
    receivedDate: '01/07/2023',
    expiryDate: '01/12/2023',
    status: 'In Progress',
    statusColor: '#FC9A28',
    dateCreated: 1690280019702,
  },
];
