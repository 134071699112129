//
import { Input } from 'app/components/UI/atoms/Input';
import React from 'react';
import { useForm } from 'react-hook-form';
// import { useNavigate } from 'react-router-dom';
// import { ResumePrint } from '../../Resume/ResumePrint';
import {
  AddLanguage,
  DataCal,
  FlexTab,
  JobExpWrapper,
  StepperContainer,
  NextButton,
  Next,
  NextPrevious,
  RemoveButton,
  CertificateHeading,
  CertificationJobTabPane,
} from '../ResumeInfoStyles';

function Certification({
  steps,
  activeStep,
  setActiveStep,
  certificateList,
  handleCertificateAdd,
  handleCertificateRemove,
  handleFormData,
  formStepperData,
  setisSaving,
}) {
  const {
    register,
    // reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const navigate = useNavigate();

  const CerificateInformationHandel = data => {
    handleFormData(data);
    setisSaving(true);
  };

  return (
    <>
      <CertificationJobTabPane>
        <CertificateHeading>
          “And now you almost created your Resume, Best of Luck”
        </CertificateHeading>
        <StepperContainer>
          <h2>Certification</h2>
          {certificateList.map((SingleCertificate, index) => (
            <JobExpWrapper>
              <form
                onSubmit={handleSubmit(CerificateInformationHandel)}
                noValidate
              >
                <FlexTab>
                  <Input
                    type="text"
                    id="certification"
                    placeholder="Name of the certification"
                    defaultValue={formStepperData.Certification}
                    className={
                      errors?.Certification?.type === 'required'
                        ? 'invalid'
                        : ''
                    }
                    {...register('Certification', {
                      required: true,
                      minLength: {
                        value: 2,
                        message: 'atleast 3 letters are requierd',
                      },
                      maxLength: {
                        value: 30,
                        message: 'You cant add letters which are more than 30',
                      },
                    })}
                  />
                  <>{errors.Certification?.message} </>

                  <Input
                    type="text"
                    id="Organization"
                    placeholder="Issuing Organization"
                    defaultValue={formStepperData.Organisation}
                    className={
                      errors?.Organisation?.type === 'required' ? 'invalid' : ''
                    }
                    {...register('Organisation', {
                      required: true,
                      minLength: {
                        value: 2,
                        message: 'atleast 3 letters are requierd',
                      },
                      maxLength: {
                        value: 30,
                        message: 'You cant add letters which are more than 30',
                      },
                    })}
                  />
                  <>{errors.Organization?.message} </>
                </FlexTab>

                <FlexTab>
                  <Input
                    type="text"
                    id="field"
                    placeholder="Field of study"
                    defaultValue={formStepperData.Study}
                    className={
                      errors?.Study?.type === 'required' ? 'invalid' : ''
                    }
                    {...register('Study', {
                      required: true,
                      minLength: {
                        value: 3,
                        message: 'atleast 3 letters are requierd',
                      },
                      maxLength: {
                        value: 30,
                        message: 'You cant add letters which are more than 30',
                      },
                    })}
                  ></Input>
                  <>{errors.Field?.message} </>

                  <DataCal
                    type="text"
                    id="date"
                    placeholder="Start Date DD/YYYY"
                    required
                    {...register('From_Date')}
                    defaultValue={formStepperData.From_Date}
                  ></DataCal>
                  <DataCal
                    type="text"
                    id="date"
                    placeholder="end Date DD/YYYY"
                    required
                    {...register('To_Date')}
                    defaultValue={formStepperData.To_Date}
                  ></DataCal>
                </FlexTab>

                <FlexTab>
                  <Input
                    type="text"
                    id="Certificate-ID"
                    placeholder="Certificate ID"
                    className="input-text main-grade"
                    required
                    defaultValue={formStepperData.Certificate_ID}
                    {...register('Certificate_ID')}
                  ></Input>

                  <Input
                    type="text"
                    id="Credential-URL"
                    placeholder="Credential URL"
                    {...register('Credential_Url')}
                    defaultValue={formStepperData.Credential_Url}
                  ></Input>
                </FlexTab>

                {certificateList.length > 1 && (
                  <RemoveButton
                    type="button"
                    onClick={() => handleCertificateRemove(index)}
                  >
                    {' '}
                    Remove
                  </RemoveButton>
                )}
                {certificateList.length - 1 === index &&
                  certificateList.length < 6 && (
                    <AddLanguage
                      type="button"
                      className="add-language"
                      onClick={handleCertificateAdd}
                    >
                      Add Certification
                    </AddLanguage>
                  )}
                {activeStep === steps.length - 1 && (
                  <NextButton>
                    <NextPrevious
                      type="submit"
                      onClick={() => setActiveStep(activeStep - 1)}
                    >
                      Previous
                    </NextPrevious>
                    <Next type="submit">Create Resume</Next>
                  </NextButton>
                )}
              </form>
            </JobExpWrapper>
          ))}
        </StepperContainer>
      </CertificationJobTabPane>
    </>
  );
}

export default Certification;
