import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import {
  AddLanguage,
  ExperienceInputWrapper,
  ExperienceWrapper,
  ExpLabel,
  MonthsSelect,
  RateSkillWrapper,
  SkillInput,
  SkillSelect,
  SkillsWrapper,
  TabPane,
  KeySkillsStepperContainer,
  NextButton,
  NextPrevious,
  Next,
  StepperCommonContainer,
  RemoveButton,
  KeySkillHelloHeading,
  StarRating,
} from '../ResumeInfoStyles';

function KeySkills({
  KeyList,
  handleKeySkillAdd,
  handleKeySkillRemove,
  setActiveStep,
  activeStep,
  steps,
  handleFormData,
  formStepperData,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const KeySkillHandle = data => {
    console.log('data', data);
    handleFormData(data);
    setActiveStep(activeStep + 1);
  };

  const [rating, setRating] = useState(0);
  // const handleClick = value => {
  //   setRating(value);
  // };

  // Calculate the percentage value
  // const percentage = (rating / 5) * 100;

  return (
    <TabPane>
      <KeySkillHelloHeading>
        "The future belong to those who learn more skills and combine them in
        creative way "
      </KeySkillHelloHeading>
      <KeySkillsStepperContainer>
        <h2>Key Skills</h2>
        <form onSubmit={handleSubmit(KeySkillHandle)} noValidate>
          {KeyList.map((singlleService, index) => (
            <StepperCommonContainer>
              <SkillsWrapper key={index}>
                <SkillInput
                  type="text"
                  id="Skill"
                  placeholder="Skill"
                  defaultValue={formStepperData.skill}
                  {...register('skill', {
                    required: true,
                    minLength: {
                      value: 2,
                      message: 'atleast 3 letters are requierd',
                    },
                    maxLength: {
                      value: 30,
                      message: 'You cant add letters which are more than 30',
                    },
                  })}
                />
                <>{errors.Skill?.message} </>
                <SkillInput
                  type="text"
                  id="version"
                  placeholder="Version"
                  className="skill-input"
                  defaultValue={formStepperData.version}
                  {...register('version', {
                    required: true,
                    minLength: {
                      value: 2,
                      message: 'atleast 3 letters are requierd',
                    },
                    maxLength: {
                      value: 30,
                      message: 'You cant add letters which are more than 30',
                    },
                  })}
                />
                <>{errors.version?.message} </>

                <SkillSelect
                  id="type"
                  placeholder="Last Used"
                  required
                  defaultValue={formStepperData.last_used}
                  {...register('last_used')}
                >
                  <option value="Last Used">Last Used</option>
                  <option value="Type 2">2 months back</option>
                  <option value="Type 3">3 months back</option>
                  <option value="Type 4">4 months back</option>
                  <option value="Type 5">5 months back</option>
                </SkillSelect>

                <ExperienceWrapper>
                  <ExpLabel> Experience</ExpLabel>

                  <ExperienceInputWrapper>
                    <MonthsSelect
                      // name="type"
                      id="type"
                      placeholder="Last Used"
                      required
                      {...register('years')}
                      defaultValue={formStepperData.years}
                    >
                      <option value="Years">Years</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </MonthsSelect>
                    <MonthsSelect
                      // name="type"
                      id="type"
                      required
                      {...register('month')}
                      defaultValue={formStepperData.month}
                    >
                      <option
                      // value="Months"
                      >
                        Months
                      </option>
                      <option value="1-3">1-3</option>
                      <option value="3-6">3-6</option>
                      <option value="6-8">6-8</option>
                      <option value="8-12">8-12</option>
                    </MonthsSelect>
                  </ExperienceInputWrapper>
                </ExperienceWrapper>
                <RateSkillWrapper>
                  <ExpLabel> Rate your Skill</ExpLabel>

                  <StarRating>
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <button
                          type="button"
                          key={index}
                          className={index <= rating ? 'on' : 'off'}
                          onClick={() => setRating(index)}
                        >
                          <span className="star">&#9733;</span>
                        </button>
                      );
                    })}
                    {/* </div> */}
                  </StarRating>
                </RateSkillWrapper>
              </SkillsWrapper>
              <input type="hidden" id="rating" name="rating" />
              {KeyList.length > 1 && (
                <RemoveButton onClick={() => handleKeySkillRemove(index)}>
                  {' '}
                  Remove
                </RemoveButton>
              )}
              {KeyList.length - 1 === index && KeyList.length < 4 && (
                <AddLanguage onClick={handleKeySkillAdd}>
                  Add Key Skill
                </AddLanguage>
              )}
            </StepperCommonContainer>
          ))}
          {activeStep !== steps.length - 1 && (
            <NextButton>
              {activeStep !== 0 && activeStep !== steps.length - 1 && (
                <NextPrevious
                  type="submit"
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </NextPrevious>
              )}

              <Next type="submit">Next</Next>
            </NextButton>
          )}
        </form>
      </KeySkillsStepperContainer>
    </TabPane>
  );
}

export default KeySkills;
