import React, { useState } from 'react';
import Stepper from 'react-stepper-horizontal';
// import fairhirewhite from 'app/assets/images/fairhirewhite.svg';
// import { useForm } from 'react-hook-form';
import userlogo from 'app/assets/images/userlogo.png';
import bulbbefore from 'app/assets/images/bulbbefore.png';
import bagbefore from 'app/assets/images/bagbefore.png';
import capbefore from 'app/assets/images/capbefore.png';
import badgeactive from 'app/assets/images/badgeactive.png';

import {
  ProgressContainer,
  // FairHireLogo,
  MaincontainerDiv,
  StepperConatiner,
  ActiveStepperContainer,
  BodyDiv,
} from './ResumeInfoStyles';
import BasicInformation from './subModules/BasicInformation';
import KeySkills from './subModules/KeySkills';
import JobExperience from './subModules/JobExperience';
import Education from './subModules/Education';

import Certification from './subModules/Certification';
import { ResumePrint } from '../Resume/ResumePrint';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import { Header } from '../Resume/ResumePrintStylings';

function ProgressUser({
  basicInfoData,
  activeStep,
  steps,
  setActiveStep,
  formStepperData,
  handleFormData,
}) {
  return (
    <>
      <BasicInformation
        activeStep={activeStep}
        steps={steps}
        basicInfoData={basicInfoData}
        setActiveStep={setActiveStep}
        handleFormData={handleFormData}
        formStepperData={formStepperData}
      />
    </>
  );
}

function ProgressBulb({
  KeyList,
  handleKeySkillAdd,
  handleKeySkillRemove,
  setActiveStep,
  activeStep,
  steps,
  formStepperData,
  handleFormData,
}) {
  return (
    <>
      <KeySkills
        steps={steps}
        KeyList={KeyList}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleKeySkillAdd={handleKeySkillAdd}
        handleKeySkillRemove={handleKeySkillRemove}
        handleFormData={handleFormData}
        formStepperData={formStepperData}
      />
    </>
  );
}

function ProgressBag({
  steps,
  jobList,
  activeStep,
  setActiveStep,
  handleExpereienceAdd,
  handleExperienceRemove,
  formStepperData,
  handleFormData,
}) {
  return (
    <>
      <JobExperience
        steps={steps}
        jobList={jobList}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleExpereienceAdd={handleExpereienceAdd}
        handleExperienceRemove={handleExperienceRemove}
        handleFormData={handleFormData}
        formStepperData={formStepperData}
      />
    </>
  );
}
function ProgressDegree({
  steps,
  eduList,
  activeStep,
  setActiveStep,
  handleEducationAdd,
  handleEducationRemove,
  formStepperData,
  handleFormData,
}) {
  return (
    <>
      <Education
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        eduList={eduList}
        handleEducationAdd={handleEducationAdd}
        handleEducationRemove={handleEducationRemove}
        handleFormData={handleFormData}
        formStepperData={formStepperData}
      />
    </>
  );
}
function ProgressBadge({
  steps,
  certificateList,
  activeStep,
  setActiveStep,
  handleCertificateAdd,
  handleCertificateRemove,
  formStepperData,
  handleFormData,
  setisSaving,
}) {
  return (
    <>
      <Certification
        certificateList={certificateList}
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleCertificateAdd={handleCertificateAdd}
        handleCertificateRemove={handleCertificateRemove}
        handleFormData={handleFormData}
        formStepperData={formStepperData}
        setisSaving={setisSaving}
      />
    </>
  );
}

export const ResumeInfo = () => {
  // const {
  //   // register,
  //   // reset,
  //   // handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   mode: 'all',
  // });

  // const initalState = {
  //   isTab1Open: true,
  //   isTab2Open: false,
  //   isTab3Open: false,
  //   isTab4Open: false,
  //   isTab5Open: false,
  // };

  const formData = {
    name: '',
    city: '',
    role: '',
    phone_no: '',
    Email: '',
    proficiency: '',
    location: '',
    skill: '',
    version: '',
    last_used: '',
    years: '',
    month: '',
    Rating: '',
    title: '',
    Company_name: '',
    Emplyoment_type: '',
    Start_date: '',
    End_date: '',
    Location: '',
    Industry: '',
    School: '',
    Degree: '',
    Field_study: '',
    Grade: '',
    Description: '',
    Certification: '',
    Study: '',
    Organisation: '',
    From_Date: '',
    To_Date: '',
    Certificate_ID: '',
    Credential_Url: '',
  };

  const [KeyList, setKeyList] = useState([{ keyskills: '' }]);
  const [jobList, setJobList] = useState([{ jobExp: '' }]);
  const [eduList, setEduList] = useState([{ Education: '' }]);
  const [certificateList, setCertificateList] = useState([{ Certificate: '' }]);
  const [formStepperData, setformStepperData] = useState(formData);
  const [isSaving, setisSaving] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [basicInfoData, setBasicInfoData] = useState({
    basicDataName: '',
  });

  const handleFormData = data => {
    setBasicInfoData({
      ...basicInfoData,
      basicDataName: data.name,
    });
    setformStepperData(prevData => ({
      ...prevData,
      ...data,
    }));
  };

  const handleKeySkillAdd = () => {
    setKeyList(prevList => [...prevList, { keyskills: '' }]);
  };

  const handleKeySkillRemove = index => {
    setKeyList(prevList => {
      const list = [...prevList];
      list.splice(index, 1);
      return list;
    });
  };

  const handleExpereienceAdd = () => {
    setJobList(prevList => [...prevList, { jobExp: '' }]);
  };

  const handleExperienceRemove = index => {
    setJobList(prevList => {
      const list = [...prevList];
      list.splice(index, 1);
      return list;
    });
  };

  const handleEducationAdd = () => {
    setEduList(prevList => [...prevList, { Education: '' }]);
  };

  const handleEducationRemove = index => {
    setEduList(prevList => {
      const list = [...prevList];
      list.splice(index, 1);
      return list;
    });
  };

  const handleCertificateAdd = () => {
    setCertificateList(prevList => [...prevList, { Certificate: '' }]);
  };

  const handleCertificateRemove = index => {
    setCertificateList(prevList => {
      const list = [...prevList];
      list.splice(index, 1);
      return list;
    });
  };

  const steps = [
    { id: 1, icon: userlogo, onClick: () => setActiveStep(0) },
    { id: 2, icon: bulbbefore, onClick: () => setActiveStep(1) },
    { id: 3, icon: bagbefore, onClick: () => setActiveStep(2) },
    { id: 4, icon: capbefore, onClick: () => setActiveStep(3) },
    { id: 5, icon: badgeactive, onClick: () => setActiveStep(4) },
  ];

  const getSectionComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ProgressUser
            activeStep={activeStep}
            steps={steps}
            basicInfoData={basicInfoData}
            setActiveStep={setActiveStep}
            handleFormData={handleFormData}
            formStepperData={formStepperData}
          />
        );
      case 1:
        return (
          <ProgressBulb
            activeStep={activeStep}
            KeyList={KeyList}
            steps={steps}
            handleKeySkillAdd={handleKeySkillAdd}
            setActiveStep={setActiveStep}
            handleKeySkillRemove={handleKeySkillRemove}
            handleFormData={handleFormData}
            formStepperData={formStepperData}
          />
        );
      case 2:
        return (
          <ProgressBag
            steps={steps}
            jobList={jobList}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleExpereienceAdd={handleExpereienceAdd}
            handleExperienceRemove={handleExperienceRemove}
            handleFormData={handleFormData}
            formStepperData={formStepperData}
          />
        );
      case 3:
        return (
          <ProgressDegree
            steps={steps}
            eduList={eduList}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleEducationRemove={handleEducationRemove}
            handleEducationAdd={handleEducationAdd}
            handleFormData={handleFormData}
            formStepperData={formStepperData}
          />
        );
      case 4:
        return (
          <ProgressBadge
            steps={steps}
            certificateList={certificateList}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleCertificateAdd={handleCertificateAdd}
            handleCertificateRemove={handleCertificateRemove}
            handleFormData={handleFormData}
            formStepperData={formStepperData}
            setisSaving={setisSaving}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            {!isSaving ? (
              <MaincontainerDiv>
                <ProgressContainer>
                  {/* <FairHireLogo src={fairhirewhite} alt="evolve resume" /> */}
                  <StepperConatiner>
                    <Stepper
                      steps={steps}
                      completeColor="#ffbd13"
                      activeColor="#ffbd13"
                      defaultColor="#656565"
                      completeBarColor="#ffbd13"
                      activeStep={activeStep}
                    />
                  </StepperConatiner>
                  <ActiveStepperContainer>
                    {getSectionComponent()}
                  </ActiveStepperContainer>
                </ProgressContainer>
              </MaincontainerDiv>
            ) : (
              <ResumePrint
                basicInfoData={basicInfoData}
                formStepperData={formStepperData}
              />
            )}
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
    </>
  );
};
