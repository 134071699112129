import styled from 'styled-components';

export const LeftSecDiv = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fdb82c;
  min-height: 100vh;
  img {
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    width: auto;
    padding: 50px 20px;
    height: auto;
    min-height: auto;
  }
`;

export const RightSecDiv = styled.div`
  width: 50%;
  padding: 30px 40px 100px 40px;
  background-color: #fdb82c;
  min-height: 100vh;
`;
export const HeadingH1 = styled.h1``;
export const ParaDiv = styled.div``;
