import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from './services/auth/auth';
import jwt_decode from 'jwt-decode';

export const ProtectedRoute = ({ children, name }) => {
  const jobProviderRoleAccess = [
    'Assessment',
    'PostJob',
    'Jobs',
    'Assessments',
    'Inbox',
    'InterviewProgress',
    'Questions',
    'CategoryQues',
    'CreateTest',
    'MyApplications',
    'InviteWithInstructions',
    'TestDetails',
    'TestReports',
    'Profile',
    'Categories',
    'CreateAssessment',
    'ScreenTests',
    'Respondants',
    'Support',
    'Subscription',
  ];
  const jobSeekerRoleAccess = [
    'MyApplications',
    'SearchJobs',
    'InterviewProgress',
    'MyAssessments',
    'TestReports',
    'CreatePath',
    'CareerResult',
    'ResumeInfo',
    'Support',
  ];
  const token = getToken();
  if (!token || token === null) {
    return <Navigate replace to="/" />;
  }
  const decodedJT: any = jwt_decode(token);
  const { role } = decodedJT.user;
  let isRouteAllowedAsPerRoles = false;
  let defaultRouteAsPerRoles = '/';
  // console.log('children.type ---', children);
  if (role === 'employee') {
    isRouteAllowedAsPerRoles = jobProviderRoleAccess.includes(name)
      ? true
      : false;
    defaultRouteAsPerRoles = '/assessments';
  } else {
    isRouteAllowedAsPerRoles = jobSeekerRoleAccess.includes(name)
      ? true
      : false;
    defaultRouteAsPerRoles = '/search-jobs';
  }
  return token && isRouteAllowedAsPerRoles === true ? (
    children
  ) : (
    <Navigate replace to={defaultRouteAsPerRoles} />
  );
};
