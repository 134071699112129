import styled from 'styled-components';
import eye from 'app/assets/images/viewIcon.svg';

export const CategoryListWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  /* border-radius: 20px; */
  overflow: auto;
  padding: 20px;
  .techcategorytable th {
    text-align: center;
  }
  .techcategorytable th:nth-child(2) {
    width: 14%;
  }
  .techcategorytable th:nth-child(4) {
    width: 20%;
    white-space: nowrap;
    text-align: center;
  }

  .add-view-question {
    white-space: nowrap;
  }

  @media (max-width: 1100px) {
    .techcategorytable {
      table-layout: auto;
    }
  }
`;

export const CategoryListTable = styled.table<any>`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  // width: ${props => (props.width ? props.width : '96%')};
`;

export const CategoryListHead = styled.thead`
  font-weight: 600;
  font-size: 18px;
  font-style: normal;
  background: #f9f9f9;
`;

export const CategoryListBody = styled.tbody`
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
`;

export const CategoryListTh = styled.th`
  text-align: left;
  padding: 7px;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  background-color: #3d3d3d;
  border: 1px solid rgba(239, 239, 239, 1);
  :first-child {
    width: 2%;
  }
  :last-child {
    width: 20%;
  }
`;

export const CategoryListTabDiv = styled.td`
  text-align: left;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  border: 1px solid rgba(239, 239, 239, 1);
  .expert-level-wrapper {
    display: flex;
    justify-content: center;
  }
  .expertise {
    background: #fdb82c;
    padding: 5px;
    border-radius: 10px;
    font-size: 12px;
    margin-right: 5px;
  }
  :first-child {
    width: 2%;
  }
  button {
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    padding-right: 15px;
    height: 38px;
    background-position: center left 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border-radius: 5px;
    padding: 10px;
    /* margin-bottom: 15px; */
    margin: 5px auto;
  }
  :nth-child(6) {
    padding-top: 10px;
  }
`;

export const CategoryListRow = styled.tr`
  min-height: 68px;
  color: #3d3d3d;
  /* :nth-child(even) {
    background-color: #f8f8f8;
  } */
`;

export const AddCategoryBtn = styled.button`
  font-weight: 600;
  font-size: 14px;
  text-align: right;
  padding-right: 15px;
  height: 38px;
  background-position: center left 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #fdb82c;
`;

export const ToggleView = styled.div`
  width: 30px;
  height: 30px;
  background-size: contain;
  background-image: url(${eye});
`;
