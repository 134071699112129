import styled from 'styled-components';

export const ModalFlexDiv = styled.div`
  padding-bottom: 20px;
  margin-top: 15px;
  /* @media (max-width: 1010px) {
    flex-direction: column;
  } */
  .expectedTimeText {
    /* width: 100%; */
  }
  .timeBy select {
    width: 100% !important;
  }
  .typeAndTimeWrapper {
    display: flex;
    flex-direction: row;
  }
`;
export const ModalTextFlex = styled.div`
  padding-right: 20px;
  @media (max-width: 1010px) {
    width: 100%;
  }
  .container {
    display: flex !important;
    flex-wrap: wrap !important;
    contain: content !important;
    contain: strict !important;
    height: 250px !important;
    overflow-y: hidden;
  }

  .item {
    width: 100px !important;
    height: 100px !important;
    background-color: lightgray;
    margin: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 24px;
    border: 1px solid gray;
  }

  .item:nth-child(4n + 1) {
    margin-left: 0 !important;
  }
`;

export const ModalTextFlex2 = styled.div`
  /* position: absolute;
  top: 550px; */
`;
export const ModalTextFlex3 = styled.div`
  display: flex;
  /* padding-right: 20px; */
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 600px;
  width: 100%;
  @media (max-width: 1010px) {
    width: 100%;
  }
  .inputSelectorWrapper {
    padding-right: 0px;
  }
`;

export const InputWrapperDiv = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* width: 75%; */
  margin-right: 20px;
  button {
    width: 220px !important;
    cursor: pointer !important;
    height: 40px !important;
    background: #000000 !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-size: 15px !important;
    line-height: 21px !important;
    float: left;
  }
  input {
    width: 100%;
    height: 36px;
    margin-right: 20px;
    text-indent: 10px;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #000000;
    border-radius: 5px;
  }
`;

export const TextInputWrapperDiv = styled.div`
  position: relative;
  width: 75%;
  margin-right: 20px;
  button {
    width: 24px !important;
    cursor: pointer !important;
    height: 26px !important;
    background: #000000 !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-size: 25px !important;
    line-height: 21px !important;
    position: absolute !important;
    right: 0 !important;
    top: 7px !important;
  }
  input {
    width: 100%;
    height: 36px;
    margin-right: 20px;
    text-indent: 10px;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #000000;
    border-radius: 5px;
  }
`;

export const SelectInputWrapperDiv = styled.div`
  position: relative;
  width: 25%;
  button {
    width: 24px !important;
    cursor: pointer !important;
    height: 26px !important;
    background: #000000 !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    font-size: 25px !important;
    line-height: 21px !important;
    position: absolute !important;
    right: 0 !important;
    top: 7px !important;
  }
  input {
    width: 100%;
    height: 36px;
    margin-right: 20px;
    text-indent: 10px;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #000000;
    border-radius: 5px;
  }
  .typeSelector select {
    width: 100%;
  }
`;

export const ModalTagFlex = styled.div`
  display: flex;
  flex-direction: column;
  .container {
    width: 1024px;
  }
  button {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    width: 118px;
    height: 38px;
    background-color: #fdb82c;
    background-image: url('../images/plus.svg');
    background-position: center left 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border-radius: 5px;
    color: #000000;
    margin-top: 0px;
  }
  .addOptionBtn {
    background-color: #000;
    color: #fff;
  }
  /*
  .removeOptionBtn {
    margin-bottom: 20px !important;
  } */
`;

export const ButtonDiv = styled.div`
  justify-content: right;
  display: flex;
  position: absolute;
  align-items: center;
  right: 20px;
  margin-top: -10px !important;
  .duration {
    padding: 10px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    color: #141414;
    margin-right: 10px;
    cursor: pointer;
  }

  .cancel {
    border: 1px solid #000000;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    color: #141414;
    margin-right: 10px;
    padding: 10px;
    height: 38px;
    background: transparent;
  }

  .save {
    padding: 10px;
    height: 38px;
    background: #000000;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    margin-right: 10px;
  }
`;
export const AnswerWrapDiv = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  input {
    max-width: 370px;
    margin-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;
