import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableTd,
  TableTh,
  TableWrapper,
} from '../List/ListStyles';
import {
  AddQuestionMain,
  AddQuesTitle,
  SearchInpDiv,
  MainSearchMandatory,
  InfoDiv,
} from './createAssessmentStyles';
import Button from 'app/components/UI/atoms/Button';
import { Input } from 'app/components/UI/atoms/Input';
// import { useForm } from 'react-hook-form';
import {
  getQuestionsByCategory,
  getQuestionsMinMaxTimeEstimate,
} from 'app/services/categories';
// import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import { Checkbox } from 'app/components/UI/atoms/Checkbox';
import { A } from 'app/components/UI/atoms/Anchor';
import viewIcon from 'app/assets/images/viewIcon.svg';
import { useNavigate } from 'react-router-dom';
import Pagination from 'app/components/UI/organisms/Pagination';

export const AddQuestion = ({
  mandatoryQuestionData,
  changeModalVisibility,
  isShowModal,
  addQuestionsToCategoryTech,
}) => {
  // const { isShown, toggle } = useModal();
  const [questionListData, setQuestionListData] = useState<any>([]);
  const [firstLoadData, setFirstLoadData] = useState<any>();
  const [questionSelected, setQuestions] = useState<any>([]);
  const [questionSelectedCount, setQuestionsSelectCount] = useState<number>(0);
  const [errorMsg, setError] = useState<string>('');
  const {
    technology: categoryId,
    proficiency,
    questionCount,
  } = mandatoryQuestionData;
  const [currentQuestions, setCurrentQuestions] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const questionsPerPage = 5;

  let navigate = useNavigate();

  const headers = [
    { id: 'question', name: 'Question' },
    { id: 'status', name: 'Status' },
    { id: 'action', name: 'Action' },
  ];
  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   setError,
  //   formState: { errors },
  // } = useForm();

  const fetchQuestions = async () => {
    const questionsData = await getQuestionsByCategory(categoryId, proficiency);

    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = questionsData?.questions.slice(
      indexOfFirstQuestion,
      indexOfLastQuestion,
    );
    const count = Math.ceil(questionsData?.questions.length / questionsPerPage);

    setCurrentQuestions(currentQuestions);
    setQuestionListData(questionsData);
    setFirstLoadData(questionsData);
    setCount(count);
    //setQuestions
    // eslint-disable-next-line array-callback-return
    questionsData?.questions?.map((qs, i) => {
      if (
        mandatoryQuestionData?.mandatorySelectedQuesArr?.includes(
          qs.question.questionId,
        )
      ) {
        setQuestions(oldVal => [...oldVal, qs.question]);
      }
    });
  };

  useEffect(() => {
    fetchQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addQuestion = (e, question: any) => {
    const isChecked = e.target.checked;
    // console.log('questions', questionSelected);
    if (isChecked === true) {
      setQuestions(oldVal => [...oldVal, question]);
      setQuestionsSelectCount(oldCount => oldCount + 1);
    } else {
      // const questionCheckedArr = questionSelected;
      const questionCheckedArr = questionSelected
        // eslint-disable-next-line array-callback-return
        .map(qs => {
          if (qs.questionId !== question.questionId) {
            return qs;
          }
        })
        .filter(v => v !== undefined);
      setQuestions(questionCheckedArr);
      setQuestionsSelectCount(oldCount => oldCount - 1);
    }
  };
  // const onSubmit = handleSubmit(async (data: any) => { });
  const toggleModal = () => {
    changeModalVisibility();
  };
  const confirmQuestions = async () => {
    if (questionSelectedCount > questionCount) {
      setError(`You have selected ${questionSelectedCount} questions.`);
      window.scrollTo(0, 0);
      return false;
    }
    const quesData = [
      {
        questionCount: questionSelected.length,
        proficiency,
        categories: [
          {
            categoryId,
            mandatoryQuestions: questionSelected.map(qu => qu.questionId),
          },
        ],
      },
    ];
    const quesMinMaxTimeData = await getQuestionsMinMaxTimeEstimate(quesData);

    addQuestionsToCategoryTech(quesMinMaxTimeData, questionSelected);
  };
  // const isAlreadySelected = qId => {
  //   const isFound = questionSelected.find(ques => ques.id === qId);
  //   if (isFound) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const handleSearch = event => {
    let newObj: any[] = [];
    newObj = firstLoadData?.questions?.filter(fl => {
      return fl.question.text
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    // setQuestionListData({ ...questionListData, questions: newObj });
    const indexOfLastQuestion = 1 * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = newObj.slice(
      indexOfFirstQuestion,
      indexOfLastQuestion,
    );
    setCurrentQuestions(currentQuestions);
  };

  const onPageChangeHandler = (value: number) => {
    const currentPage = value;
    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = firstLoadData?.questions.slice(
      indexOfFirstQuestion,
      indexOfLastQuestion,
    );
    setCurrentPage(currentPage);
    setCurrentQuestions(currentQuestions);
  };

  return (
    <AddQuestionMain>
      <MainSearchMandatory
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <AddQuesTitle>
          Add mandatory Questions for {questionListData?.name}
        </AddQuesTitle>
        <SearchInpDiv className="locationskillswrapper-input">
          <Input
            id={'search'}
            className="category-input"
            type={'text'}
            label={'Category '}
            onChange={handleSearch}
            placeholder="Search"
          />
          <Button type="button" content="" className="search-icon" />
        </SearchInpDiv>
      </MainSearchMandatory>
      <InfoDiv>
        You can select upto {questionCount} question from the list.
      </InfoDiv>
      {errorMsg !== '' && <InfoDiv>{errorMsg}</InfoDiv>}

      <TableWrapper>
        {/* {redirectToTest ? (
        <Redirect
          to={{
            pathname: '/test',
            state: { testId: redirectToTest },
          }}
        />
      ) : null} */}
        <Table>
          <TableHead>
            <TableRow>
              <TableTh>{''}</TableTh>
              {headers.map(header => (
                <TableTh key={header.id}>{header.name}</TableTh>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentQuestions?.length > 0 &&
              currentQuestions?.map((q, index) => {
                let setChecked = false;
                // setChecked =
                //   mandatoryQuestionData?.mandatorySelectedQuesArr?.includes(
                //     q.question.questionId,
                //   );
                if (
                  questionSelected.some(
                    q2 => q2.questionId === q.question.questionId,
                  )
                ) {
                  setChecked = true;
                }
                return (
                  <TableRow key={q.question.questionId}>
                    <TableTd>
                      <Checkbox
                        id={q.question.questionId}
                        label={''}
                        className="chkQuestion"
                        checked={setChecked === true ? 'checked' : null}
                        onChange={e => addQuestion(e, q.question)}
                        // disabled={
                        //   !isAlreadySelected(q.question.questionId) &&
                        //     questionSelectedCount >= questionCount
                        //     ? true
                        //     : false
                        // }
                      ></Checkbox>
                    </TableTd>
                    <TableTd>{q.question.text}</TableTd>
                    <TableTd>{q.question.status}</TableTd>
                    <TableTd
                      onClick={() => {
                        navigate(`/question/${q.question.questionId}`);
                      }}
                    >
                      <A>
                        <img src={viewIcon} alt="" />
                      </A>
                    </TableTd>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {currentQuestions?.length > 0 && (
          <div style={{ marginTop: '15px' }}>
            <Pagination
              count={count}
              currentPage={currentPage}
              onPageChangeHandler={onPageChangeHandler}
            />
          </div>
        )}
        <div className="button-wrapper">
          <Button
            type="button"
            content="cancel"
            className="cancelButton"
            bgType=""
            bgColor="#fdb82c"
            onClick={toggleModal}
          />
          <Button
            type="button"
            content="Confirm Questions"
            bgType=""
            bgColor="#fdb82c"
            onClick={() => confirmQuestions()}
          />
        </div>
      </TableWrapper>
    </AddQuestionMain>
  );
};
