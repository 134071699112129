import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import {
  Wrapper,
  Header,
  StyledModal,
  Content,
  Backdrop,
  CloseButton,
  HeaderText,
  Close,
} from './ModalStyles';
import CloseButtonIcon from 'app/assets/images/close-icon.svg';
export interface ModalProps {
  isShown: boolean;
  hide?: () => void;
  modalContent: JSX.Element | any;
  classname?: string;
  isShowCloseButton: boolean;
  Headertext?: string;
  width?: string;
}
export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  modalContent,
  isShowCloseButton,
  Headertext,
  width,
}) => {
  const modal = (
    <React.Fragment>
      <Backdrop />
      <Wrapper width={width}>
        <StyledModal>
          <Header>
            <HeaderText>{Headertext}</HeaderText>
          </Header>
          {isShowCloseButton && (
            <Close>
              <CloseButton
                onClick={hide}
                src={CloseButtonIcon}
                alt="close-button-icon"
              />
            </Close>
          )}
          <Content>{modalContent}</Content>
        </StyledModal>
      </Wrapper>
    </React.Fragment>
  );
  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
