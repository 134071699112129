import axios from 'axios';
import { getToken } from './auth/auth';

const baseUrl = process.env.REACT_APP_API_URL;

export const getAllSubscriptions = async orgId => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}organisation/${orgId}/subscription`,
      method: 'get',
      data: JSON.stringify(''),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    return res.data.result;
  } catch (err) {
    console.log('getOrg error: ', err);
    return err;
  }
};
