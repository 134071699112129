import axios from 'axios';
import { getToken } from '../auth/auth';

const baseUrl = process.env.REACT_APP_API_URL;

export const captureIncident = async ({
  assessmentId,
  userId,
  ucaId,
  incident,
  timestamp,
}) => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}assessment/${assessmentId}/${userId}/ucaid/${ucaId}/incident`,
      data: {
        incidents: [{ incident, timestamp }],
      },
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res;
  } catch (err: any) {
    console.log('createAssessment post error: ', err);
    return err?.response?.data;
  }
};
