/* eslint-disable array-callback-return */
import { Title3, Title5 } from 'app/components/UI/atoms/Titles';
import React, { useEffect, useState } from 'react';
import {
  BodyDiv,
  DescPara,
  ErrorDiv,
  FooterDiv,
  HeaderDiv,
  InpWrapper,
  Logo,
  MainWrapperDiv,
  MainWrapperSubDiv,
  // TechSetWraper,
  // TechSetWraperSpan,
  TitleDesc,
  BorderLine,
} from './gettingStartedStyles';
import logoBloack from 'app/assets/images/logo-black.svg';
import { Input } from 'app/components/UI/atoms/Input';
import Button from 'app/components/UI/atoms/Button';
import { useForm } from 'react-hook-form';
import { getUserByEmail } from 'app/services/users';
import { useParams } from 'react-router-dom';
import {
  generateAssessmentLink,
  getAssessmentByTestIdLite,
} from 'app/services/assessments';
import { Select } from 'app/components/UI/atoms/Select';
import {
  MailIcon,
  Message,
  ModalContainer,
  ModalHeader,
  ModelContent,
} from 'app/components/UI/globalStyles/modalStyles';
import { Modal } from 'app/components/UI/Modal/SimpleModal';
import { useModal } from 'app/components/UI/Modal/SimpleModal/userModal';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import SuccessIcon from 'app/assets/images/success-icon.svg';
import { commonPostCall } from 'app/services/auth/auth';
import { Table } from '../MyApplications/ListStyles';
import spinnerLoad from 'app/assets/images/loader.gif';
import errorIcon from 'app/assets/images/errorIcon.svg';
import { ErrorText } from 'app/components/UI/atoms/ErrorMessage';

export const GettingStarted = () => {
  const { assessmentId } = useParams<string>();
  const [step, setStep] = useState<number>(0);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [assessmentDetails, setAssessmentDetails] = useState<any>();
  const [selectedTechnology, setSelectedTechnology] = useState<any>([]);
  const [error, setError] = useState<string | null>(null);
  const [isSent, setIsSent] = useState(false);
  const { isShown, toggle } = useModal();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getAssessmentDetails = async () => {
    const assessmentResp = await getAssessmentByTestIdLite(assessmentId);
    setAssessmentDetails(assessmentResp);
    if (
      assessmentResp?.status === 'inactive' ||
      (assessmentResp?.status === 'active' &&
        assessmentResp?.validUntil < new Date().getTime())
    ) {
      setError('Assessment not available!');
    }
  };
  useEffect(() => {
    getAssessmentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit(async (data: any) => {
    if (showDetails === true || step === 1) {
      const signUpResp = await commonPostCall(
        {
          ...data,
          role: 'user',
          name: `${data.firstName} ${data.lastName}`,
          phoneNumber: data.phone,
        },
        'iam/user',
        false,
      );
      if (signUpResp.statusCode !== 0) {
        return false;
      }
      setUserId(signUpResp.result.userId);
      if (step === 1) {
        setStep(2);
        setShowDetails(false);
      }
    } else if (step === 2) {
      //get single technology
      const singleTechnologiesArr = assessmentDetails?.sections
        ?.map(cat => {
          if (cat.categories?.length === 1) {
            return cat.categories[0].categoryId;
          }
        })
        .filter(f => typeof f !== 'undefined');
      const assessmentData = {
        categories: [...singleTechnologiesArr, ...selectedTechnology].filter(
          f => typeof f !== 'undefined',
        ),
      };
      const generateAssessmentResp: any = await generateAssessmentLink(
        assessmentId,
        userId,
        assessmentData,
      );
      if (generateAssessmentResp?.statusCode === 0) {
        setIsSent(true);
        toggle();
      } else {
        setError(generateAssessmentResp?.response.data.error);
        return false;
      }
    } else {
      const loginResp = await getUserByEmail(data.email);
      if (
        typeof loginResp !== 'undefined' &&
        typeof loginResp.id !== 'undefined'
      ) {
        setUserId(loginResp.id);
        setStep(2);
      } else {
        setShowDetails(true);
        setStep(1);
      }
    }
  });
  const handleTechSetChange = (e, i) => {
    const { value } = e.target;
    const onchangeVal: any = selectedTechnology;
    onchangeVal[i] = value;
    console.log(JSON.stringify(onchangeVal));
    setSelectedTechnology(onchangeVal);
  };

  const responseContent = (
    <ModelContent>
      <MailIcon>
        <img src={errorIcon} alt="mail-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <ErrorText text={error} />
      </Message>
    </ModelContent>
  );

  const onSubmitSuccessContent = (
    <ModelContent>
      <ModalHeader>
        <Title3>Congratulations! </Title3>
      </ModalHeader>
      <MailIcon>
        <img
          src={SuccessIcon}
          alt="success-icon"
          width="80px"
          height="50px"
          className="success-icon"
        />
      </MailIcon>
      <Message>
        <PrimaryParagraph>
          <b> Exciting news! </b>
          <br />
          <span className="exciting-news">
            Your exclusive assessment link has just been sent to your registered
            email address. Get ready to embark on your journey - access your
            email now and dive into the assessment! Wishing you the very best of
            luck!
          </span>
        </PrimaryParagraph>
      </Message>
    </ModelContent>
  );
  const toggleAndMove = () => {
    window.location.href = 'https://www.fairhiree.com';
  };
  return (
    <MainWrapperDiv>
      <MainWrapperSubDiv>
        <HeaderDiv>
          <BorderLine>
            <Logo src={logoBloack}></Logo>
            <Title5>
              To access your assessment and move forward in the application
              process, please fill out your essential personal information
              below.
            </Title5>
          </BorderLine>
        </HeaderDiv>
        <TitleDesc>
          <DescPara>
            Assessment Name: {assessmentDetails && assessmentDetails?.name}
          </DescPara>
        </TitleDesc>
        {assessmentDetails ? (
          <form noValidate={true} onSubmit={onSubmit}>
            <BodyDiv>
              {errors?.email?.type === 'required' ||
              errors?.firstName?.type === 'required' ||
              errors?.lastName?.type === 'required' ||
              errors?.phone?.type === 'required' ? (
                <ErrorDiv>Please fill all required fields!</ErrorDiv>
              ) : (
                ''
              )}
              {errors?.phone?.type === 'maxLength' ||
              errors?.phone?.type === 'minLength' ? (
                <ErrorDiv>Phone number length should be 10!</ErrorDiv>
              ) : (
                ''
              )}
              {(step === 0 || step === 1) && (
                <>
                  <InpWrapper>
                    <Input
                      type={'text'}
                      id={'email'}
                      placeholder="Email*"
                      {...register('email', {
                        required: true,
                      })}
                    />
                  </InpWrapper>
                  {showDetails === true && (
                    <>
                      <InpWrapper>
                        <Input
                          type={'text'}
                          id={'firstName'}
                          placeholder="First Name*"
                          {...register('firstName', {
                            required: showDetails && true,
                          })}
                        />
                      </InpWrapper>
                      <InpWrapper>
                        <Input
                          type={'text'}
                          id={'lastName'}
                          placeholder="Last Name*"
                          {...register('lastName', {
                            required: showDetails && true,
                          })}
                        />
                      </InpWrapper>
                      <InpWrapper>
                        <Input
                          type={'number'}
                          id={'phone'}
                          placeholder="Phone*"
                          {...register('phone', {
                            required: showDetails && true,
                            maxLength: 12,
                            minLength: 10,
                          })}
                        />
                      </InpWrapper>
                    </>
                  )}
                </>
              )}
              {step === 2 && (
                <>
                  <h5>Choose Technology Options</h5>
                  <InpWrapper>
                    {assessmentDetails &&
                      assessmentDetails?.sections?.map((cat, i) => {
                        if (cat.categories?.length === 1) {
                          return (
                            <Table className="select-info-table">
                              <tbody>
                                <tr>
                                  <td className="tech-set">
                                    Technology Set {i + 1} :{' '}
                                  </td>
                                  <td>
                                    {cat.categories[0].name ||
                                      'Test Assessment'}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          );
                        } else if (cat.categories?.length > 1) {
                          const categoryOptions = cat.categories.map(ct => {
                            return { label: ct.name, value: ct.categoryId };
                          });
                          categoryOptions.unshift({
                            label: 'Select technology',
                            value: '',
                          });
                          return (
                            <Table className="select-info-table">
                              <tbody>
                                <tr>
                                  <td className="tech-set">
                                    <span>Technology Set {i + 1} : </span>
                                  </td>
                                  <td>
                                    <Select
                                      key={i}
                                      id={`categorySet${i + 1}`}
                                      aria-label="category set"
                                      className="select-drop"
                                      options={categoryOptions}
                                      {...register(`categorySet${i + 1}`, {
                                        required: step === 2 && true,
                                      })}
                                      onChange={e => handleTechSetChange(e, i)}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          );
                        }
                      })}
                  </InpWrapper>
                </>
              )}
            </BodyDiv>
            {error && (
              <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
            )}
            <FooterDiv>
              <Button
                disabled={isSent || error !== null || !assessmentDetails?.name}
                type="submit"
                content={step === 2 ? 'Get Assessment Link' : 'Next'}
                color="#fff"
              />
            </FooterDiv>
            <div className="note-exp">
              Note: For the best experience, we recommend using a laptop to take
              the assessment. Please note that the Fairhiree assessment platform
              is not optimized for mobile devices at this time. Thank you for
              your understanding
            </div>
          </form>
        ) : (
          <img
            src={spinnerLoad}
            alt=""
            width={100}
            height={100}
            style={{ marginTop: '10%', marginLeft: '40%' }}
          />
        )}
      </MainWrapperSubDiv>
      <ModalContainer>
        <Modal
          isShown={isShown}
          width="50%"
          hide={toggleAndMove}
          isShowCloseButton={true}
          modalContent={error ? responseContent : onSubmitSuccessContent}
        />
      </ModalContainer>
    </MainWrapperDiv>
  );
};
