/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import {
  MainWrapper,
  LeftWrapper,
  RightWrapper,
  ErrorDiv,
  DivWrapper,
  FlyImg,
  ButtonWrap,
} from './createPathStyles';
import { useForm } from 'react-hook-form';
import Button from 'app/components/UI/atoms/Button';
import { Input } from 'app/components/UI/atoms/Input';
import { callChatGpt } from 'app/services/chatgpt';
import {
  LoadingWrapper,
  LoaderLogoImg,
  HeaderOops,
  LoadingContent,
} from './loadingWrapperStyles';
import loaderLogo from 'app/assets/images/loader-logo.svg';
import loaderBook from 'app/assets/images/loader-book.svg';
import loaderShadow from 'app/assets/images/shadow.svg';
import animImg from 'app/assets/images/anim.svg';
import flySvg from 'app/assets/images/fly.svg';
import { CareerGuideResult } from './careerGuideResult';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import { BodyDiv } from '../ResumeInfo/ResumeInfoStyles';
import { Header } from '../Resume/ResumePrintStylings';

interface LocationState {
  data: any;
  from: {
    pathname: string;
  };
}

export const CreatePath = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  if (!state?.data) {
    // navigate('/', { replace: true });
  }
  window.history.replaceState({}, document.title);
  // navigate(location.pathname, { replace: true });
  const [showResult, setShowResult] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [pathResult, setPathResult] = useState<Array<String>>([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
  }, [reset]);
  useEffect(() => {
    // set state here
    // navigate(location.pathname, { replace: true });
  }, [location.pathname, navigate]);

  let ArryList: string[] = [];
  const onSubmit = handleSubmit(async (data: any) => {
    //add loading here
    setIsLoading(true);
    try {
      const gptResp = await callChatGpt(data);
      if (gptResp.choices.length > 0) {
        setIsLoading(false);
        setShowResult(true);
        const gptRespText = gptResp.choices[0].text;
        ArryList = gptRespText.split('\n\n');
        let isWebLinks = false;
        let usefulLinkStr = '';
        // let totalLinkCount = 0;
        const mergedArr: any = ArryList.map(arr => {
          let replaceStr = arr.replace(/[0-9]./, '');

          if (replaceStr.length > 10 && !arr.includes('Career Path:')) {
            if (arr.includes('Useful Website Links:')) {
              usefulLinkStr = arr;
              isWebLinks = true;
              return;
            }
            if (isWebLinks) {
              // totalLinkCount++;
              usefulLinkStr = `${usefulLinkStr} <br/><br/>${arr.replaceAll(
                '\n',
                '<br/><br/>',
              )}`;
              return;
            }
            return replaceStr;
          }
        }).filter(f => typeof f !== 'undefined');
        // if (totalLinkCount > 0) {
        mergedArr.push(usefulLinkStr);
        // }

        setPathResult(mergedArr);
      }
    } catch (err) {
      setIsError(true);
    }
  });

  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <MainWrapper>
              {isError ? (
                <LoadingWrapper>
                  <LoaderLogoImg src={loaderLogo} />
                  <img src={loaderBook} className="loader-book" />
                  <img src={loaderShadow} className="loader-shadow" />
                  <HeaderOops> Oops!</HeaderOops>
                  <LoadingContent>
                    {' '}
                    Fairhiree is at capacity right now try again in some time
                  </LoadingContent>
                </LoadingWrapper>
              ) : isLoading ? (
                <LoadingWrapper>
                  <LoaderLogoImg src={loaderLogo} />
                  <img src={animImg} className="loader-book" />
                  <img src={loaderShadow} className="loader-shadow" />
                  <LoadingContent className="request-heading">
                    {' '}
                    Your request is in motion and the future is yours to
                    visualize!
                  </LoadingContent>
                  <svg
                    width="124"
                    height="16"
                    viewBox="0 0 124 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle id="circle1" cx="8" cy="8" r="8" fill="white" />
                    <circle id="circle2" cx="44" cy="8" r="8" fill="#fdb82c" />
                    <circle id="circle3" cx="80" cy="8" r="8" fill="white" />
                    <circle id="circle4" cx="116" cy="8" r="8" fill="white" />
                  </svg>
                </LoadingWrapper>
              ) : showResult ? (
                <CareerGuideResult pathResult={pathResult} />
              ) : (
                <>
                  <DivWrapper>
                    <LeftWrapper>
                      <h1>
                        {' '}
                        Unlock Your Career Potential with Your Personal{' '}
                        <b>AI Career Councillor</b>
                      </h1>
                      {/* <h2>
              {' '}
              Your Personal <b>AI Career Councillor</b>{' '}
            </h2> */}
                      <h3>
                        {' '}
                        Let's Make Your Dreams a <br />
                        Reality Today!
                      </h3>
                    </LeftWrapper>
                    <FlyImg src={flySvg} alt="fly" />
                    <RightWrapper>
                      <form
                        className="Input-Wrapper"
                        noValidate={true}
                        onSubmit={onSubmit}
                      >
                        <label className="free-tool-create-label">
                          {' '}
                          Currently I am{' '}
                        </label>
                        <Input
                          id="currentPosition"
                          type="text"
                          label="Computer Science Engineering Student"
                          placeholder="Computer Science Engineering Student"
                          {...register('currentPosition', {
                            required: true,
                          })}
                          required
                        />
                        {/* <input type="text" className="free-science-text-input" placeholder="computer science engineering student" /> */}
                        <label className="free-tool-create-label1">
                          {' '}
                          I want to Become{' '}
                        </label>
                        <Input
                          id="goal"
                          type="text"
                          label="Software Engineer at Google"
                          placeholder="Software Engineer at Google"
                          {...register('goal', {
                            required: true,
                          })}
                          required
                        />
                        {errors?.currentPosition?.type === 'required' ? (
                          <ErrorDiv>Enter where you are!</ErrorDiv>
                        ) : errors?.goal?.type === 'required' ? (
                          <ErrorDiv>Enter goal!</ErrorDiv>
                        ) : (
                          ''
                        )}
                        {/* <input type="text" className="free-science-text-input" placeholder="Software engineer at Google" /> */}
                        <ButtonWrap>
                          {/* <button className="create-my-path">Create My Path </button> */}
                          <Button type="submit" content="Create My Path" />
                        </ButtonWrap>
                      </form>
                    </RightWrapper>
                  </DivWrapper>
                </>
              )}
            </MainWrapper>
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
    </>
  );
};
