/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Header } from 'app/components/UI/organisms/Header';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import {
  AssessmentDetailWrapper,
  BodyDiv,
  CategorySectionWrapper,
  CategorySet,
  DeleteBtn,
  TechnologyDetails,
  QuestionDetails,
  ProficiencySelect,
  ContentDiv,
  ContentSubDiv,
  SearchWrap,
  TechnologyWrapper,
  CollpasedWrap,
  CategoryWrap,
  CategorySetInner,
  CategoriesName,
  ProficienyDiv,
  CategorySpan,
  ErrorDiv,
  TechnologyDetailsWrap,
} from './createAssessmentStyles';
import Button from 'app/components/UI/atoms/Button';
import { Input } from 'app/components/UI/atoms/Input';
import { useForm } from 'react-hook-form';
import { Select } from 'app/components/UI/atoms/Select';
import { getAllCategories } from 'app/services/categories';

import { useNavigate, useParams } from 'react-router-dom';
import { getAssessmentByTestId } from 'app/services/assessments';
import DownArrow from 'app/assets/images/DownArrow.svg';
import spinnerLoad from 'app/assets/images/loader.gif';

export const ViewAssessment = () => {
  const { assessmentId } = useParams();
  const [allCategories, setAllCategories] = useState<any>();
  const [assessmentData, setAssessmentData] = useState<any>();
  const [tech, setTech] = useState([{ set: '', technology: '' }]);
  let navigate = useNavigate();
  const [categorySet, setCategorySet] = useState([
    {
      questionCount: '',
      proficiency: '',
      technology: [
        {
          set: '',
          technology: '',
          mandatorySelectedQuesArr: [],
          mandatorySelectedQues: [],
          maxTime: 0,
          minTime: 0,
        },
      ],
    },
  ]);
  const {
    formState: { errors },
  } = useForm();

  const fetchCategories = async () => {
    const categorisData = await getAllCategories();
    const categoryOptions = categorisData.map(cat => {
      return { label: cat.name, value: cat.id };
    });
    categoryOptions.unshift({ label: 'Select technology', value: '' });
    setAllCategories(categoryOptions);
  };
  const fetchAssessmentById = async assessmentId => {
    const assessmentRespData = await getAssessmentByTestId(assessmentId);
    setAssessmentData(assessmentRespData);
  };
  useEffect(() => {
    fetchAssessmentById(assessmentId);
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const proficiencyValueOptions = [
    { label: 'Select Proficiency', value: '' },
    { label: 'Beginner', value: 'beginner' },
    { label: 'Intermediate', value: 'intermediate' },
    { label: 'Expert', value: 'expert' },
  ];
  const handleAddTech = i => {
    setTech([...tech, { set: i, technology: '' }]);
    const categorySetTemp = categorySet;
    categorySetTemp[i].technology.push({
      set: '',
      technology: '',
      mandatorySelectedQuesArr: [],
      mandatorySelectedQues: [],
      maxTime: 0,
      minTime: 0,
    });
    setCategorySet(categorySetTemp);
  };

  const [selected, setSelected] = useState<number | null>(null);
  const toggleAccordion = i => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  const getTimeStampToLocal = (validFrom: number) => {
    const date = new Date(validFrom);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16);
  };
  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            {assessmentData ? (
              <form noValidate={true}>
                <SearchWrap>
                  <h5> View Assessment</h5>
                  <div>
                    <Button
                      className="btnCancel"
                      type="button"
                      content="Cancel"
                      onClick={() => navigate('/assessments')}
                    />
                    <Button type="button" content="Edit" disabled />
                  </div>
                </SearchWrap>
                {/* <form noValidate={true} onSubmit={onSubmit}> */}
                <ContentDiv>
                  <ContentSubDiv>
                    <h1>Assessment Detail</h1>
                    <div className="base-wrapper-category">
                      {Object.keys(errors).length > 0 ? (
                        <ErrorDiv>Please enter required field</ErrorDiv>
                      ) : (
                        ''
                      )}
                      <div className="test-name-wrapper first-wrapper">
                        <label className="name-label">Test Name</label>
                        <Input
                          className="assessment-title-input"
                          type={'text'}
                          id={'title'}
                          placeholder="Title*"
                          value={assessmentData?.name}
                          style={{ width: '100%' }}
                          disabled
                        />
                      </div>
                      <AssessmentDetailWrapper className="validity-duration-wrapper">
                        <div className="test-name-wrapper duration-wrapper">
                          <label className="name-label">Duration</label>
                          <Input
                            type={'text'}
                            className="assessment-title-duration"
                            id={'duration'}
                            placeholder="Duration*"
                            value={assessmentData?.duration / 60}
                            disabled
                          />
                        </div>
                        <div className="test-name-wrapper percentage-wrapper">
                          <label className="name-label">Pass %</label>
                          <Input
                            type={'text'}
                            className="percentage"
                            id={'percentage'}
                            placeholder="Percentage*"
                            value={assessmentData?.score}
                            disabled
                          />
                        </div>
                        <div className="test-name-wrapper">
                          <label className="name-label">Validity From</label>
                          <Input
                            type={'datetime-local'}
                            className="date-pick"
                            id={'validFrom'}
                            placeholder="Valid From"
                            value={getTimeStampToLocal(
                              assessmentData?.validFrom,
                            )}
                            disabled
                          />
                        </div>
                        <div className="test-name-wrapper">
                          <label className="name-label">Validity To</label>
                          <Input
                            type={'datetime-local'}
                            className="date-pick"
                            id={'validTo'}
                            placeholder="Valid To"
                            value={getTimeStampToLocal(
                              assessmentData?.validUntil,
                            )}
                            disabled
                          />
                        </div>
                      </AssessmentDetailWrapper>
                    </div>
                  </ContentSubDiv>
                  {assessmentData?.sections.map((val, c) => (
                    <>
                      <CategorySet>
                        <div>
                          <CategoryWrap style={{ display: 'block' }}>
                            {selected !== c ? (
                              <CollpasedWrap
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  flexBasis: '100%',
                                  background: '#fff',
                                  padding: '20px',
                                  flexWrap: 'wrap',
                                }}
                              >
                                <h1
                                  className="category-set"
                                  style={{
                                    backgroundImage: `url(${DownArrow})`,
                                  }}
                                  onClick={() => toggleAccordion(c)}
                                >
                                  Category Set {`${c + 1}`}
                                </h1>

                                <div className="prof-level">
                                  <div>Total Questions</div>
                                  <ProficienyDiv>
                                    <b>{val?.questionCount}</b>
                                  </ProficienyDiv>
                                </div>
                                <div className="prof-level">
                                  <div>Proficiency</div>
                                  <ProficienyDiv>
                                    <b>{val?.proficiency}</b>
                                  </ProficienyDiv>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    fontSize: '16px',
                                  }}
                                >
                                  <div style={{ marginBottom: '20px' }}>
                                    Category Name
                                  </div>
                                  <CategoriesName>
                                    {val?.categories?.map(tech => (
                                      <CategorySpan style={{ padding: '10px' }}>
                                        {tech.name}
                                      </CategorySpan>
                                    ))}
                                  </CategoriesName>
                                </div>
                              </CollpasedWrap>
                            ) : (
                              ''
                            )}
                            <CategorySetInner>
                              {selected === c ? (
                                <h1
                                  className="category-set"
                                  style={{
                                    backgroundImage: `url(${DownArrow})`,
                                  }}
                                  onClick={() => toggleAccordion(c)}
                                >
                                  Category Set {`${c + 1}`}
                                </h1>
                              ) : (
                                ''
                              )}
                              <CategorySectionWrapper
                                className={selected === c ? 'show' : ''}
                              >
                                <QuestionDetails>
                                  <Input
                                    name="questionCount"
                                    type={'number'}
                                    id={'questionCount'}
                                    placeholder="Question Count"
                                    value={val?.questionCount || ''}
                                    disabled
                                  />
                                </QuestionDetails>
                                <ProficiencySelect>
                                  <Select
                                    name="proficiency"
                                    id="proficiency"
                                    aria-label="Proficiency"
                                    className="select-drop"
                                    value={val?.proficiency.toLowerCase() || ''}
                                    options={proficiencyValueOptions}
                                    // onChange={e =>
                                    //   // handleCategorySetChange(e, c)
                                    // }
                                    disabled
                                  />
                                </ProficiencySelect>
                              </CategorySectionWrapper>
                            </CategorySetInner>
                          </CategoryWrap>
                        </div>
                        <TechnologyDetailsWrap
                          className={selected === c ? 'show' : ''}
                        >
                          <TechnologyDetails className="category-set-wrapper">
                            {val?.categories?.map((te, i) => (
                              <TechnologyWrapper>
                                <div className="select-wrapper">
                                  <Select
                                    name="technology"
                                    aria-label="Technology"
                                    className="select-drop"
                                    value={te?.categoryId}
                                    options={allCategories}
                                    disabled
                                  />
                                  <div className="frameflex">
                                    {categorySet[c]?.technology[i]
                                      ?.technology !== '' &&
                                    val?.proficiency &&
                                    categorySet[c]?.technology[i]
                                      ?.mandatorySelectedQues?.length >= 0 ? (
                                      <span className="circle-frame">
                                        {
                                          categorySet[c].technology[i]
                                            ?.mandatorySelectedQues?.length
                                        }
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    {categorySet &&
                                    // eslint-disable-next-line prettier/prettier
                                      categorySet[c]?.technology[i]?.technology !== '' &&
                                    val?.proficiency ? (
                                      <Button
                                        type="button"
                                        content="Add mandatory questions"
                                        className="btnAddMandatory"
                                        bgColor="#fdb82c"
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>

                                {val.technology?.length > 1 ? (
                                  <Button
                                    type="button"
                                    content="-"
                                    className="btnDelete"
                                    bgColor="#fdb82c"
                                    style={{ marginBottom: '12px' }}
                                  />
                                ) : (
                                  ''
                                )}
                              </TechnologyWrapper>
                            ))}
                            <Button
                              type="button"
                              content="+"
                              bgColor="#fdb82c"
                              className="btnAdd"
                              onClick={() => handleAddTech(c)}
                            />
                          </TechnologyDetails>
                        </TechnologyDetailsWrap>
                      </CategorySet>
                      <DeleteBtn>
                        {categorySet?.length > 1 ? (
                          <Button
                            type="button"
                            content="Delete"
                            bgColor="#ADADAD"
                          />
                        ) : (
                          ''
                        )}
                      </DeleteBtn>
                    </>
                  ))}
                </ContentDiv>
              </form>
            ) : (
              <img
                src={spinnerLoad}
                alt=""
                width={100}
                height={100}
                style={{ marginTop: '20%', marginLeft: '40%' }}
              />
            )}
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
      {/* <ModalContainer>
        <Modal
          isShown={isShown}
          // width="fit-content"
          hide={toggle}
          isShowCloseButton={true}
          modalContent={
            <AddQuestion
              changeModalVisibility={toggle}
              isShowModal={isShown}
              mandatoryQuestionData={mandatoryQuestionData}
              addQuestionsToCategoryTech={addQuestionsToCategoryTech}
            />
          }
        />
      </ModalContainer>
      <ModalContainer>
        <Modal
          isShown={isShown2}
          isShowCloseButton={true}
          hide={() => setToggle2(false)}
          modalContent={responseContent}
        />
      </ModalContainer> */}
    </>
  );
};
