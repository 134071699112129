import React, { useEffect, useState, useRef } from 'react';
import {
  CategoryListWrapper,
  CategoryListHead,
  CategoryListBody,
  CategoryListTh,
  CategoryListTabDiv,
  CategoryListTable,
  CategoryListRow,
  ToggleView,
} from './QuestionListStyles';
// import Button from 'app/components/UI/atoms/Button';
// import { Switch } from 'app/components/UI/atoms/Switch';
import MultiSwitch from 'app/components/UI/atoms/MultiSwitch';
// import thinkingIcon from 'app/assets/images/thinking-face.svg';
// import { OopsSpan } from './questionsStyles';
import { updateQuestionStatus } from 'app/services/questions';
import { ErrorText } from 'app/components/UI/atoms/ErrorMessage';
import {
  ModelContent,
  Message,
  MailIcon,
} from 'app/components/UI/Modal/MessageModelStyles';
import errorIcon from 'app/assets/images/errorIcon.svg';
import { ModalContainer } from 'app/components/UI/organisms/Modal/MessageModelStyles';
import { Modal } from 'app/components/UI/organisms/Modal/SimpleModal';
import { useNavigate } from 'react-router-dom';
import Pagination from 'app/components/UI/organisms/Pagination';
import { StyledStack } from './questionsStyles';

export const QuestionList = ({ data, viewQuest }) => {
  const statusRef = useRef<any | null>();
  const { categoryId, allQuestions } = data;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [checked, setChecked] = useState<string | null>();
  const [showError, setShowError] = useState<boolean>(false);
  const [errText, setErrText] = useState<string>(
    'Question status updation failed!',
  );
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10;

  const navigate = useNavigate();
  const userId = window.localStorage.getItem('ui');
  // console.log(viewQuest);
  const headers = [
    'Question',
    'Question Type',
    'Proficiency',
    'Created By',
    'Status',
    'Action',
  ];

  useEffect(() => {
    console.log('new data:');
    console.log(data);
    setCurrentPage(1);
  }, [data, allQuestions]);

  const changeHandler = async (e, quest, statusRef) => {
    try {
      if (!quest || typeof quest.status === 'undefined') return;
      const ind = allQuestions.findIndex(
        ques => ques.question.questionId === quest.questionId,
      );
      const prevStatus = allQuestions[ind].question.status;
      // if (prevStatus.toLowerCase() === 'active') {
      allQuestions[ind].question.status =
        allQuestions[ind].question.status.toLowerCase() === 'active'
          ? 'inactive'
          : 'active';
      const resp = await updateQuestionStatus(
        quest.questionId,
        allQuestions[ind].question.status,
      );
      console.log(resp);
      if (resp && resp?.statusCode === 0) {
        setChecked(allQuestions[ind].question.status);
      } else {
        if (resp && resp?.statusCode === 20202) {
          setErrText(resp?.error);
        }
        allQuestions[ind].question.status = prevStatus;
        setShowError(true);
        // modalRef.current?.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
      // } else {
      //   setErrText('Question cannot be activated as it is in Inactive state!');
      //   setShowError(true);
      //   // modalRef.current?.scrollIntoView({ behavior: 'smooth' });
      //   window.scrollTo({
      //     top: 0,
      //     left: 0,
      //     behavior: 'smooth',
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = allQuestions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion,
  );
  const count = Math.ceil(allQuestions.length / questionsPerPage);

  const onPageChangeHandler = (value: number) => {
    setCurrentPage(value);
  };

  const responseContent = (
    <ModelContent>
      <MailIcon>
        <img src={errorIcon} alt="mail-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <ErrorText text={errText} />
      </Message>
    </ModelContent>
  );

  return (
    allQuestions &&
    allQuestions.length > 0 && (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CategoryListWrapper>
          <CategoryListTable>
            <CategoryListHead>
              <CategoryListRow>
                {headers.map((header, index) => (
                  <CategoryListTh key={index}>{header}</CategoryListTh>
                ))}
              </CategoryListRow>
            </CategoryListHead>
            <CategoryListBody>
              {currentQuestions &&
                currentQuestions.length > 0 &&
                currentQuestions.map((ques, index) => {
                  const { question, answers } = ques;
                  // setChecked(question.status === 'ACTIVE' ? true : false);
                  return (
                    <CategoryListRow key={question.questionId}>
                      {/* <CategoryListTabDiv>#</CategoryListTabDiv> */}
                      <CategoryListTabDiv>{question.text}</CategoryListTabDiv>
                      <CategoryListTabDiv>{question.type}</CategoryListTabDiv>
                      <CategoryListTabDiv>
                        {question.proficiency}
                      </CategoryListTabDiv>
                      <CategoryListTabDiv>
                        {question.createdByName || 'System'}
                      </CategoryListTabDiv>
                      <CategoryListTabDiv ref={statusRef}>
                        {question.status.charAt(0).toUpperCase() +
                          question.status.slice(1)}
                      </CategoryListTabDiv>
                      <CategoryListTabDiv className="EyeWrapper">
                        {question.createdBy === userId && (
                          <MultiSwitch
                            checked={
                              question.status.toLowerCase() === 'active'
                                ? true
                                : false
                            }
                            onChange={e =>
                              changeHandler(e, question, statusRef)
                            }
                          />
                        )}
                        <ToggleView
                          onClick={() => {
                            viewQuest(categoryId, question, answers);
                            navigate(
                              `/question-bank/category/${categoryId}/questions/${question.questionId}`,
                              {
                                state: {
                                  categoryId,
                                  question,
                                  answers,
                                },
                              },
                            );
                          }}
                        ></ToggleView>
                      </CategoryListTabDiv>
                    </CategoryListRow>
                  );
                })}
            </CategoryListBody>
          </CategoryListTable>
        </CategoryListWrapper>
        <ModalContainer>
          <Modal
            isShown={showError}
            isShowCloseButton={true}
            hide={() => setShowError(false)}
            modalContent={responseContent}
          />
        </ModalContainer>
        <StyledStack>
          <Pagination
            count={count}
            currentPage={currentPage}
            onPageChangeHandler={onPageChangeHandler}
          />
        </StyledStack>
      </div>
    )
  );
};
