import React from 'react';
import {
  ButtonContent,
  Icon,
  StyledButton,
  StyledButtonSecondary,
} from './ButtonStyles';

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
export type ButtonType = 'submit' | 'reset' | 'button';

interface Props extends ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
  children?: React.ReactNode; // make the component able to receive children elements
  disabled?: boolean;
  content: string;
  icon?: string;
  type: ButtonType;
  leftIcon?: string;
  bgType?: string;
  bgImage?: string;
  bgColor?: string;
  className?: string;
}

export default function Button({
  content,
  icon,
  leftIcon,
  type = 'button',
  disabled,
  onClick,
  id,
  bgType,
  bgImage,
  bgColor,
  color,
  className,
}: Props) {
  return bgType === 'secondary' ? (
    <StyledButtonSecondary
      type={type}
      onClick={onClick}
      disabled={disabled}
      id={id}
      bgImage={bgImage}
      className={className}
    >
      {leftIcon && <Icon src={leftIcon} alt="icon" />}
      <ButtonContent>{content}</ButtonContent>
      {icon && <Icon src={icon} alt="icon" />}
    </StyledButtonSecondary>
  ) : (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      id={id}
      bgImage={bgImage}
      bgColor={bgColor}
      color={color}
      className={className}
    >
      {leftIcon && <Icon src={leftIcon} alt="icon" />}
      <ButtonContent>{content}</ButtonContent>
      {icon && <Icon src={icon} alt="icon" />}
    </StyledButton>
  );
}
