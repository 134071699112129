import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: auto;
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  Button {
    padding: 10px 20px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
  }
  .cancelButton {
    border: 2px solid #000;
    background: #fff;
  }
  .cancelButton:hover {
    border: 2px solid #000;
    background: #000;
    color: #fff;
  }
`;

export const Table = styled.table<any>`
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 30px;
  width: 100%;
  // width: ${props => (props.width ? props.width : '96%')};
`;

export const TableHead = styled.thead`
  font-weight: bold;
  font-style: normal;
`;

export const TableBody = styled.tbody`
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
`;

export const TableTh = styled.th`
  text-align: left;
  padding: 15px;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  border: 1px solid rgba(239, 239, 239, 1);
  background-color: #f9f9f9;
  &:nth-child(1) {
    width: 10%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 10%;
  }
`;

export const TableTd = styled.td`
  text-align: left;
  padding: 10px;
  font-size: 14px;
  border: 1px solid rgba(239, 239, 239, 1);
  font-weight: 500;
  color: #000000;

  &:nth-child(1) {
    text-align: center;
  }
  &:nth-child(4) {
    text-align: center;
  }
`;

export const TableRow = styled.tr`
  min-height: 68px;
  cursor: pointer;
  :nth-child(even) {
    background-color: #f8f8f8;
  }
`;
