export const mockData = [
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    name: 'Amit k',
    role: 'Sales manager',
    experience: '4-7 years',
    location: 'Bangalore',
    salary: '₹ 1000000',
    isShortListed: false,
    status: 'Shortlisted',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b911',
    name: 'Tushar gaurav',
    role: 'Software engineer',
    experience: '7-10 years',
    location: 'Bangalore',
    salary: '₹ 4000000',
    isShortListed: true,
    status: 'Next Round',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b912',
    name: 'Abhishek krishna',
    role: 'UI/UX',
    experience: '5-7 years',
    location: 'Bangalore',
    salary: '₹ 2000000',
    isShortListed: false,
    status: 'Next Round',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b913',
    name: 'Vishnu panja',
    role: 'Fullstack developer',
    experience: '10 years',
    location: 'Gurugram',
    salary: '₹ 3000000',
    isShortListed: true,
    status: 'Rejected',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b914',
    name: 'Prajwal sai',
    role: 'Data engineer',
    experience: '4-5 years',
    location: 'Bangalore',
    salary: '₹ 1000000',
    isShortListed: false,
    status: 'On Hold',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b915',
    name: 'Praddep Gond',
    role: 'BA',
    experience: '12 years',
    location: 'Noida',
    salary: '₹ 2500000',
    isShortListed: false,
    status: 'Rejected',
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b916',
    name: 'Navaneeth K',
    role: 'Sales manager',
    experience: '4-7 years',
    location: 'Bangalore',
    salary: '₹ 2000000',
    isShortListed: false,
    status: 'Job Offered',
    dateCreated: 1690280019702,
  },
];
