export const mockData = [
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    name: 'Amit das',
    email: 'amit.d12@gmail.com',
    testName: 'Nodejs Dev',
    location: 'Bangalore',
    receivedDate: '02/12/2023',
    completedDate: '02/18/2023',
    score: 86,
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    name: 'Diwesh kumar',
    email: 'diwesh1231@gmail.com',
    testName: 'Nodejs Dev',
    location: 'Bangalore',
    receivedDate: '02/12/2023',
    completedDate: '02/20/2023',
    score: 72,
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    name: 'Deepak sharma',
    email: 'dsharma4_23@gmail.com',
    testName: 'Python Dev',
    location: 'Bangalore',
    receivedDate: '03/04/2023',
    completedDate: '03/06/2023',
    score: 55,
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    name: 'amrita pai',
    email: 'amrita.pai@gmail.com',
    testName: 'Python Dev',
    location: 'Bangalore',
    receivedDate: '03/04/2023',
    completedDate: '03/08/2023',
    score: 88,
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    name: 'ajay prabhu',
    email: 'ajay.pb1244@gmail.com',
    testName: 'Nodejs Dev',
    location: 'Bangalore',
    receivedDate: '02/12/2023',
    completedDate: '02/19/2023',
    score: 88,
    dateCreated: 1690280019702,
  },
];

export const mockUserData = [
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    testName: 'Nodejs Dev',
    location: 'Bangalore',
    receivedDate: '02/12/2023',
    completedDate: '02/18/2023',
    score: 86,
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    testName: 'Nodejs Dev',
    location: 'Bangalore',
    receivedDate: '02/12/2023',
    completedDate: '02/20/2023',
    score: 72,
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    testName: 'Python Dev',
    location: 'Bangalore',
    receivedDate: '03/04/2023',
    completedDate: '03/06/2023',
    score: 55,
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    testName: 'Python Dev',
    location: 'Bangalore',
    receivedDate: '03/04/2023',
    completedDate: '03/08/2023',
    score: 88,
    dateCreated: 1690280019702,
  },
  {
    id: '34a19225-c235-42b5-bab0-db321fc8b910',
    testName: 'Nodejs Dev',
    location: 'Bangalore',
    receivedDate: '02/12/2023',
    completedDate: '02/19/2023',
    score: 88,
    dateCreated: 1690280019702,
  },
];
