import React, { forwardRef } from 'react';
import { Label, Indicator, Input } from './CheckboxStyles';

export type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface Props extends CheckboxProps {
  id: string;
  label: string;
  className?: string;
  placeholder?: string;
  value?: string;
  checked?: any;
  disabled?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ value, checked, onChange, name, id, label, disabled }: Props, ref) => {
    return (
      <Label htmlFor={id}>
        {label}
        <Input
          id={id}
          ref={ref}
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <Indicator />
      </Label>
    );
  },
);
