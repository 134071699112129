import React from 'react';
import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableTd,
  TableTh,
  TableWrapper,
  TableTdDiv,
} from './ListStyles';
import Button from 'app/components/UI/atoms/Button';
import { getJobById } from 'app/services/jobs';
// import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
// import {
//   Message,
//   ModelContent,
// } from 'app/components/UI/Modal/MessageModelStyles';
import DownloadIcon from 'app/assets/images/download-resume.svg';
import { Select } from 'app/components/UI/atoms/Select';
// import { useHistory, Redirect } from 'react-router-dom';
// import { update } from 'app/services/';
export const JobListTable = ({ headers, rowData }) => {
  // const { isShown, toggle } = useModal();
  // const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [status, setStatus] = useState<Array<string>>([]);
  const [action, setAction] = useState<boolean>(false);
  // const [id, setId] = useState<number>();
  // const [contentData, setContentData] = useState<any>();
  // const changeModalVisibility = (nval = !isShowModal, isSuccess = false) => {
  //   if (nval === true || nval === false) {
  //     setIsShowModal(nval);
  //   } else {
  //     setIsShowModal(false);
  //   }

  //   if (isSuccess === true) {
  //     // toggle();
  //   }
  // };
  const showJobDetails = async (JobId: string) => {
    await getJobById(JobId);
    // setContentData(jobResp);
    // toggle();
  };

  useEffect(() => {
    rowData &&
      rowData.length > 0 &&
      rowData.map(ques => {
        if (ques.status === 'ACTIVE') {
          status.push(ques.id);
          setStatus(status);
        }
        return ques;
      });
  }, [rowData, status]);
  const processOptions = [
    { label: 'Select Status', value: '' },
    { label: 'Shortlisted', value: 'shortlisted', selected: false },
    { label: 'Next Round', value: 'next-round' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'On Hold', value: 'onhold' },
    { label: 'Job Offered', value: 'selected' },
  ];

  const handleProcessChange = e => {
    // check value and change color
    switch (e.target.value) {
      case 'shortlisted':
        e.target.style = 'background: #0F8CFF; color: #ffffff; font-weight:600';
        break;
      case 'next-round':
        e.target.style = 'background: #CCCC00; color: #ffffff; font-weight:600';
        break;
      case 'rejected':
        e.target.style = 'background: #ED5063; color: #ffffff; font-weight:600';
        break;
      case 'onhold':
        e.target.style = 'background: #FFA500; color: #ffffff; font-weight:600';
        break;
      case 'selected':
        e.target.style = 'background: #28C28B; color: #ffffff; font-weight:600';
        break;
      default:
        e.target.style = 'background: #ffffff; color: #959595; font-weight:500';
    }
  };

  return (
    <TableWrapper>
      {/* {redirectToques ? (
        <Redirect
          to={{
            pathname: '/ques',
            state: { quesId: redirectToques },
          }}
        />
      ) : null} */}
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableTh key={header.id}>{header.name}</TableTh>
            ))}
            {/* <TableTh>Action</TableTh> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData &&
            rowData.length > 0 &&
            rowData.map((row, index) => {
              const statusOptions = processOptions.map(op => {
                if (op.value === row.status) {
                  op.selected = true;
                  return op;
                } else {
                  return op;
                }
              });
              // const days = Math.floor(
              //   (new Date().getTime() - ques.dateUpdated) / 1000 / 60 / 60 / 24,
              // );
              return (
                <TableRow key={row.id}>
                  <TableTd
                  // onClick={() => {
                  //   handleShowQues(row.jobId);
                  // }}
                  >
                    {row.name}
                  </TableTd>
                  <TableTd>{row.role}</TableTd>
                  <TableTd>{row.experience}</TableTd>
                  <TableTd>{row.location}</TableTd>
                  <TableTd>
                    <TableTdDiv>{row.salary}</TableTdDiv>
                  </TableTd>
                  <TableTd>
                    <Button
                      content="Download Resume"
                      className="download-resume"
                      type={'button'}
                      bgColor="#fff"
                      onClick={() => {
                        showJobDetails(row.id);
                      }}
                      bgImage={DownloadIcon}
                    ></Button>
                    {/* {<Switch id={row.id} checked={row.status} />} */}
                  </TableTd>
                  <TableTd
                    onClick={() => {
                      setAction(!action);
                      // setId(row.id);
                    }}
                  >
                    {/* <Button
                      className={
                        row.isShortListed === true ? 'shortlisted' : ''
                      }
                      content={
                        row.isShortListed === true ? 'Shortlisted' : 'Shortlist'
                      }
                      type={'button'}
                      bgType=""
                      bgColor="#fdb82c"
                      disabled={row.workMode === 'closed' ? true : false}
                      onClick={closeJobAction}
                    ></Button> */}

                    <Select
                      id="experience"
                      aria-label="experience"
                      className="select-drop exp"
                      // label={'experience'}
                      options={statusOptions}
                      // {...register('experience', {
                      //   required: true,
                      // })}
                      onChange={e => handleProcessChange(e)}
                    />
                  </TableTd>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
