/* eslint-disable react/style-prop-object */
import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  JobDetailsButtonWrapper,
  JobDetailsContent,
  JobDetailsFameInfo,
  JobDetailsFooter,
  JobDetailsFooterSubDiv,
  JobDetailsImgWrapper,
  JobDetailsLeftWrapper,
  JobDetailsList,
  JobDetailsListLi,
  JobDetailsListLiHead,
  JobDetailsPostSubWrapper,
  JobDetailsPostWrapper,
  JobDetailsWrapper,
  JobPara,
  JobRightWrapper,
  JobSkillMain,
  JobSkills,
} from '../jobDetailsStyles';
// import everserveIcon from 'app/assets/images/wipro.png';
import mapsIcon from 'app/assets/images/maps.png';
import globeIcon from 'app/assets/images/globe.png';
// import postIcon from 'app/assets/images/post.png';
import rupeeIcon from 'app/assets/images/ruppee.png';
import suiteIcon from 'app/assets/images/suite.png';
import timeIcon from 'app/assets/images/time.png';
import fbIcon from 'app/assets/images/facebook.png';
import linkdinIcon from 'app/assets/images/LinkedIn.png';
import twitterIcon from 'app/assets/images/twitter.png';

export const JobDetails = (JobData: any, toggle: any) => {
  // const [selectedLocationOption, setSelectedLocationOption] = useState<any>();
  // let navigate = useNavigate();

  useEffect(() => {
    console.log();
  }, []);

  return (
    <JobDetailsWrapper className="job-details-wrapper">
      <JobDetailsLeftWrapper className="job-left-details-wrapper">
        <h1>Job Details </h1>
        <h2> Required Skills </h2>
        <JobSkills className="job-star-wrapper">
          <JobSkillMain className="job-star-main">
            <h3>Java </h3>
            {/* <div className="star-rating" data-rating="3"></div> */}
          </JobSkillMain>
          <JobSkillMain className="job-star-main">
            <h3>Agile </h3>
            {/* <div className="star-rating" data-rating="4"></div> */}
          </JobSkillMain>
          <JobSkillMain className="job-star-main">
            <h3>Testing </h3>
            {/* <div className="star-rating" data-rating="2"></div> */}
          </JobSkillMain>
          <JobSkillMain className="job-star-main">
            <h3>PHP </h3>
            {/* <div className="star-rating" data-rating="5"></div> */}
          </JobSkillMain>
        </JobSkills>
        <h4>Job Description </h4>
        <JobPara className="job-det-para">
          We are currently seeking an experienced and motivated Technical
          Delivery Manager to join our dynamic team. As a Technical Delivery
          Manager, you will play a critical role in overseeing the successful
          delivery of complex technical projects and ensuring that they meet our
          clients' expectations. Your expertise in project management, technical
          knowledge, and leadership skills will be instrumental in driving our
          projects to successful completion.
        </JobPara>
        <h5>Your Role and Responsibilities </h5>
        <JobDetailsList className="job-details-list">
          <JobDetailsListLi>Project Planning and Management.</JobDetailsListLi>
          <JobDetailsListLi>
            Develop comprehensive project plans, including scope, schedule,
            resources, and budget allocation
          </JobDetailsListLi>
          <JobDetailsListLi>
            Monitor project progress, identify potential risks, and proactively
            address issues to ensure timely delivery
          </JobDetailsListLi>
          <JobDetailsListLiHead>
            Stakeholder Collaboration:{' '}
          </JobDetailsListLiHead>
          <JobDetailsListLi>
            Act as the primary point of contact for clients and internal teams
            throughout the project lifecycle.{' '}
          </JobDetailsListLi>
          <JobDetailsListLi>
            acilitate effective communication and collaboration between
            technical teams, stakeholders, and business units.{' '}
          </JobDetailsListLi>
          <JobDetailsListLiHead>Team Leadership: </JobDetailsListLiHead>
          <JobDetailsListLi>
            Lead and motivate a diverse team of technical professionals,
            ensuring clear roles and responsibilities.{' '}
          </JobDetailsListLi>
          <JobDetailsListLi>
            Provide guidance, mentorship, and support to team members, fostering
            a positive and collaborative work environment.{' '}
          </JobDetailsListLi>
          <JobDetailsListLiHead>Quality Assurance: </JobDetailsListLiHead>
          <JobDetailsListLi>
            Enforce quality standards and best practices throughout the project
            lifecycle.{' '}
          </JobDetailsListLi>
          <JobDetailsListLi>
            Conduct thorough testing and review processes to ensure deliverables
            meet quality requirements.{' '}
          </JobDetailsListLi>
          <JobDetailsListLiHead>Risk Management: </JobDetailsListLiHead>
          <JobDetailsListLi>
            Identify potential risks and develop risk mitigation strategies.{' '}
          </JobDetailsListLi>
        </JobDetailsList>
        <h5>Qualification and Requirements</h5>
        <JobDetailsList className="job-details-list">
          <JobDetailsListLi>
            Bachelor's degree in Computer Science, Engineering, or a related
            technical field. A master's degree is a plus.
          </JobDetailsListLi>
          <JobDetailsListLi>
            Proven experience as a Technical Delivery Manager or in a similar
            technical leadership role.
          </JobDetailsListLi>
          <JobDetailsListLi>
            Strong project management skills, with a track record of
            successfully delivering complex technical projects on time and
            within budget.{' '}
          </JobDetailsListLi>
          <JobDetailsListLi>
            Excellent communication and interpersonal skills to collaborate
            effectively with diverse stakeholders.{' '}
          </JobDetailsListLi>
        </JobDetailsList>
      </JobDetailsLeftWrapper>
      <JobRightWrapper className="job-right-details-wrapper">
        {/* <img src={everserveIcon} alt="ever" className="wipro-logo" /> */}
        <h1>Project Manager </h1>
        <h2>Wipro Technology</h2>
        {/* <div className="star-rating" data-rating="3"></div> */}
        <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={mapsIcon} alt="maps" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            Bangalore, Noida
          </JobDetailsContent>
        </JobDetailsFameInfo>
        <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={globeIcon} alt="globe" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            <a href="https://www.wipro.com/" target="blank">
              https://www.wipro.com/
            </a>
          </JobDetailsContent>
        </JobDetailsFameInfo>
        {/* <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={postIcon} alt="post" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            hr.neha@everservesolutions.com
          </JobDetailsContent>
        </JobDetailsFameInfo> */}
        <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={rupeeIcon} alt="rupeeIcon" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            <b> 18,00,000 - 25,00,000</b>
          </JobDetailsContent>
        </JobDetailsFameInfo>
        <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={suiteIcon} alt="suite" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            <b> 7 to 10 Years Experience</b>
          </JobDetailsContent>
        </JobDetailsFameInfo>
        <JobDetailsFameInfo className="job-details-fame-info">
          <JobDetailsImgWrapper className="job-details-image-wrapper">
            <img src={timeIcon} alt="time" />
          </JobDetailsImgWrapper>
          <JobDetailsContent className="job-details-content-address">
            Fulltime (Remote)
          </JobDetailsContent>
        </JobDetailsFameInfo>
        <JobDetailsPostWrapper className="job-details-post-wrapper">
          <JobDetailsPostSubWrapper className="job-details-posted">
            Posted <span>:</span>
          </JobDetailsPostSubWrapper>
          <div>3 days before</div>
        </JobDetailsPostWrapper>
        <JobDetailsPostWrapper className="job-details-post-wrapper">
          <JobDetailsPostSubWrapper className="job-details-posted">
            Openings <span>:</span>
          </JobDetailsPostSubWrapper>
          <div>2</div>
        </JobDetailsPostWrapper>
        {/* <JobDetailsPostWrapper className="job-details-post-wrapper">
          <JobDetailsPostSubWrapper className="job-details-posted">
            Job Applicants <span>:</span>
          </JobDetailsPostSubWrapper>
          <div>150</div>
        </JobDetailsPostWrapper> */}

        <JobDetailsButtonWrapper className="job-details-button-wrapper">
          <button type="submit" className="job-details-cancel">
            Cancel{' '}
          </button>
          <button type="submit" className="job-details-apply">
            Close Job
          </button>
        </JobDetailsButtonWrapper>

        <JobDetailsFooter className="job-details-footer">
          <JobDetailsFooterSubDiv>Share the job</JobDetailsFooterSubDiv>
          <img src={fbIcon} alt="fb" />
          <img src={linkdinIcon} alt="li" />
          <img src={twitterIcon} alt="tw" />
        </JobDetailsFooter>
      </JobRightWrapper>
    </JobDetailsWrapper>
  );
};
