/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
// import { isMobile } from 'react-device-detect';
// import { jsPDF } from 'jspdf';
import parse from 'html-react-parser';
// import html2canvas from 'html2canvas';
import loaderLogo from 'app/assets/images/loader-logo.svg';
import sitingImg from 'app/assets/images/sitting.svg';
import standingImg from 'app/assets/images/standing.svg';
import {
  ButtonWrapper,
  DivStep0,
  DivStepBorder,
  DivStepCircle,
  DivStepContent,
  DivStepN,
  FooterLogo,
  ResultWrapper,
  ResultWrapperContent,
  ResultWrapperLogo,
  StandingImg,
} from './careerGuideResultStyles';

export const CareerGuideResult = pathResult => {
  const introArr = [
    "Your career is not just a job, it's a journey of self-discovery, growth, and fulfillment. Embrace the opportunity to explore, learn, and make a positive impact",
    'The future belongs to those who believe in the beauty of their dreams',
    'Find your passion, embrace challenges, and never stop learning. Your career journey will be filled with ups and downs, but the experiences and growth opportunities along the way are what will shape your future success. Believe in yourself and your abilities, and always strive to make the most of every opportunity.',
    'Find your passion, pursue your purpose, and never stop learning. Your career is a lifelong journey, and with the right mindset and effort, you can achieve greatness and fulfill your potential. Believe in yourself, take risks, and never settle for less than you deserve',
    'Take control of your career journey by setting clear goals, gaining relevant experiences, and continuously learning and growing. Believe in yourself and your abilities, and never stop pursuing your passions. Your career is a lifelong journey, so make the most of it',
    'Your career is your journey, chart your own path with passion, purpose, and determination. Believe in yourself and never stop exploring, learning, and growing. The sky is the limit for your future success',
    'Discover your passions, gain relevant skills and experiences, and never stop learning and growing. Your career journey requires dedication, hard work, and a positive mindset. Believe in yourself and the beauty of your dreams, and turn them into a fulfilling and successful reality.',
    'Dream big, work hard, and never stop learning. Your career journey is yours to create, so make the most of every opportunity and never settle for anything less than your best',
    'Find your passion and make it your profession. Embrace opportunities for growth, seek guidance from those who have walked the path before you, and never stop learning and striving towards your goals. Your career is a journey, make it one filled with purpose, fulfillment, and success',
    'Discover your passion and turn it into a purpose. Embrace opportunities for growth and learning, build a strong network, and never stop striving for excellence. Your career is a journey, make every step count',
    'Your career is a journey, not a destination. Embrace opportunities to explore, learn, and grow. Believe in your passions and never stop pursuing them. With hard work and determination, you can turn your dreams into reality and achieve success.',
  ];

  const endingArr = [
    "Identify your strengths and passions: Take some time to reflect on what you're good at and what you enjoy doing. This will help you find a career that aligns with your skills and interests.",
    'Research your target industry: Do your homework and learn about the companies, roles, and trends in your desired field. This will give you a better understanding of what it takes to succeed.',
    'Network with people in the industry: Connect with professionals in your target field and build relationships. They can provide valuable insights, advice, and even job leads.',
    'Build relevant skills: Consider taking courses, volunteering, or working part-time to gain the skills and experience needed for your desired role.',
    'Update your resume and LinkedIn profile: Make sure your resume and LinkedIn profile highlight your transferable skills and showcase your achievements.',
    "Be persistent and flexible: It may take time to find the right opportunity, but don't give up. Also, be open to new opportunities, even if they're not exactly what you had in mind.",
    'Stay positive and confident: Changing careers can be challenging, but remember to believe in yourself and your ability to succeed.',
    'Remember, making a career change is a process, not a one-time event. With hard work, determination, and a positive attitude, you can successfully transition to a new career and achieve your goals.',
  ];
  const resultHtml: any = [];
  // const printDocument = () => {
  //   const input: any = document.getElementById('careerPath');
  //   // document.getElementById('buttonWrap')?.remove();
  //   // const pdf = new jsPDF('p', 'pt', 'a4');
  //   html2canvas(input).then(canvas => {
  //     // const imgData = canvas.toDataURL('image/png');
  //     const imgData = canvas.toDataURL('image/jpeg', 1.0);
  //     const pdf = new jsPDF();
  //     // pdf.addImage(imgData, 'JPEG', 0, 0, 200, 300);
  //     pdf.addImage(imgData, 'JPEG', 0, 0, 215, 300);
  //     // pdf.output('dataurlnewwindow');
  //     pdf.save('download.pdf');
  //   });

  //   // const pageCount = Math.floor(input.clientHeight / window.innerHeight);
  //   // for (let i = 0; i < pageCount; i++) {
  //   //   html2canvas(input, {
  //   //     windowWidth: input.scrollWidth,
  //   //     windowHeight: input.scrollHeight,
  //   //     scrollX: 0,
  //   //     scrollY: i * window.innerHeight,
  //   //   }).then(canvas => {
  //   //     const imgData = canvas.toDataURL('image/jpeg', 1.0);
  //   //     pdf.addImage(imgData, 'JPEG', 0, 0, 595, 842);
  //   //     if (i === pageCount - 1) {
  //   //       pdf.save('download.pdf');
  //   //     } else {
  //   //       pdf.addPage();
  //   //     }
  //   //   });
  //   // }
  // };

  return (
    <ResultWrapper id="careerPath" role="presentation">
      <ResultWrapperLogo>{/* <img src={loaderLogo} /> */}</ResultWrapperLogo>
      <ResultWrapperContent role="presentation">
        {/* <h1>Becoming a software engineer at Google is a great goal! </h1> */}
        <h6 className="free-tool-heading6 free-tool-heading6-top">
          {introArr[Math.floor(Math.random() * (10 - 0 + 1) + 0)]}. Here's a
          suggested career path to help you achieve that:{' '}
        </h6>
        {pathResult?.pathResult?.forEach((item, index) => {
          if (index === 0) {
            resultHtml.push(
              <DivStep0>
                <DivStepCircle>Step 1</DivStepCircle>
                <DivStepBorder />
                <img src={sitingImg} className="sitting-svg" />
                <DivStepContent>
                  {/* <h3> Focus on your education: </h3> */}
                  <h4>{item}</h4>
                </DivStepContent>
              </DivStep0>,
            );
          } else {
            resultHtml.push(
              <DivStepN className="free-tool-step-down">
                <DivStepBorder />
                <DivStepCircle>Step {index + 1}</DivStepCircle>
                <DivStepBorder />
                <DivStepContent>
                  {/* <h3 className="free-tool-heading3"> Gain hands-on experience: </h3> */}
                  <h4>{parse(item)}</h4>
                </DivStepContent>
              </DivStepN>,
            );
          }
        })}
        {resultHtml}
        <StandingImg src={standingImg} />
        {/* <h5 className="free-tool-heading5">
          {' '}
          Remember that becoming a software engineer at Google is a competitive
          process,{' '}
        </h5> */}
        <h6 className="free-tool-heading6">
          {endingArr[Math.floor(Math.random() * (7 - 0 + 1) + 0)]}
        </h6>
        <h1 className="good-luck"> Good luck! </h1>
        <ButtonWrapper id="buttonWrap">
          {/* <button className="share-button"> </button> */}
          <button
            className="download-free-button"
            onClick={() => window.print()}
          ></button>
        </ButtonWrapper>
      </ResultWrapperContent>
      <FooterLogo>
        <img src={loaderLogo} alt="" />
      </FooterLogo>
    </ResultWrapper>
  );
};
