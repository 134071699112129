import axios from 'axios';
import { getToken } from './auth/auth';

const baseUrl = process.env.REACT_APP_API_URL;

export const getAllAssessments = async () => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}assessment/`,
      method: 'get',
      headers: { 'content-type': 'application/json', Authorization: token },
    });

    const { data: resp } = res;
    // invalid token check tbd
    if (resp.result.error) {
      return {};
    }
    return resp.result;
  } catch (err: any) {
    console.log('Test fetch error: ', err);
    // tbd move it
    if (
      err?.response?.data?.result?.error === 'Error: Error validating token'
    ) {
      // history.push("/logout");
    }
    return {};
  }
};

export const getAssessmentByTestId = async (assessmentId: string) => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}assessment/${assessmentId}`,
      method: 'get',
      headers: { 'content-type': 'application/json', Authorization: token },
    });

    const { data: resp } = res;
    // invalid token check tbd
    if (resp.result.error) {
      return {};
    }
    return resp.result;
  } catch (err: any) {
    console.log('Test fetch error: ', err);
    // tbd move it
    if (
      err?.response?.data?.result?.error === 'Error: Error validating token'
    ) {
      // history.push("/logout");
    }
    return {};
  }
};

export const getAssessmentByTestIdLite = async assessmentId => {
  try {
    const res = await axios({
      url: `${baseUrl}assessment/${assessmentId}/lite`,
      method: 'get',
      headers: { 'content-type': 'application/json' },
    });

    const { data: resp } = res;
    // invalid token check tbd
    if (resp.result.error) {
      return {};
    }
    return resp.result;
  } catch (err: any) {
    console.log('Test fetch error: ', err);
    // tbd move it
    throw err;
  }
};

export const createAssessment = async data => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}assessment/`,
      data,
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res;
  } catch (err: any) {
    console.log('createAssessment post error: ', err);
    return err?.response?.data;
  }
};

export const updateAssessment = async (id, data) => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}/category/v1/category`,
      // data: { name },
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res;
  } catch (err) {
    console.log('createCategory post error: ', err);
    return err;
  }
};
export const generateAssessmentLink = async (
  assessmentId: string | undefined,
  userId: string,
  data: any,
) => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}assessment/${assessmentId}/user/${userId}/link`,
      data,
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });

    return res.data;
  } catch (err) {
    console.log('createAssessment post error: ', err);
    return err;
  }
};
export const SubmitAnswer = async (credentials: any) => {
  try {
    const res = await axios({
      url: `${baseUrl}/screening/test/v1/assessment`,
      data: credentials,
      method: 'put',
      headers: {
        'content-type': 'application/json',
        Authorization: getToken(),
      },
    });

    if (res.data.result.error) {
      return {};
    }
    return res.data.result;
  } catch (err) {
    console.log('SubmitAnswer fetch error: ', err);
    return err;
  }
};

interface FetchQuestionAndSubmitAnswer {
  questionNumber: number;
  userId: string;
  assessmentId: string;
  ucaId: string;
  questionId?: string | null;
  assessmentQuestionId?: string | null;
  answer?: string | null;
}

export const fetchQuestionAndSubmitAnswer = async ({
  questionNumber,
  userId,
  assessmentId,
  ucaId,
  questionId = null,
  assessmentQuestionId = null,
  answer = null,
}: FetchQuestionAndSubmitAnswer) => {
  try {
    const token = getToken();
    const data =
      questionNumber === 1
        ? {}
        : {
            questionId,
            assessmentQuestionId,
            answer,
          };

    const res = await axios({
      url: `${baseUrl}assessment/${assessmentId}/${userId}/ucaid/${ucaId}`,
      data,
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    // console.log('RES =>', res.data);
    if (res.data && res.data.result.message === 'End of assessment!') {
      res.data.status = 'COMPLETED';
    }
    return res.data;
  } catch (err: any) {
    console.log('createAssessment post error: ', err);
    return err?.response?.data || err;
  }
};

export const getRespondantsByAssessmentId = async assessmentId => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}assessment/${assessmentId}/attendees`,
      method: 'get',
      headers: { 'content-type': 'application/json', Authorization: token },
    });

    const { data: resp } = res;
    // invalid token check tbd
    if (resp.result.error) {
      return {};
    }
    return resp.result;
  } catch (err: any) {
    console.log('Test fetch error: ', err);
    // tbd move it
    if (
      err?.response?.data?.result?.error === 'Error: Error validating token'
    ) {
      // history.push("/logout");
    }
    return {};
  }
};

export const updateAssessmentStatus = async (status, assessmentId) => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}assessment/${assessmentId}/${status}`,
      data: {},
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log('createAssessment post error: ', err);
    return err?.response?.data;
  }
};
export const updateAntiCheatStatus = async (status, assessmentId) => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}assessment/${assessmentId}/anticheat/${status}`,
      data: {},
      method: 'put',
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log('Anti cheat status toggle error: ', err);
    return err?.response?.data;
  }
};

export const sendAssessmentInviteLink = async (assessmentId, emails) => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}assessment/${assessmentId}/email/sendInvites`,
      method: 'post',
      data: { emails },
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res;
  } catch (err: any) {
    console.log('createAssessment post error: ', err);
    return err?.response?.data;
  }
};

export const validatePhoto = async props => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}analytics/photo`,
      method: 'post',
      data: props,
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res.data;
  } catch (err: any) {
    console.error('photo upload error: ', err);
    throw err?.response?.data;
  }
};

export const movePhoto = async props => {
  try {
    const token = getToken();
    const res = await axios({
      url: `${baseUrl}assessment/${props.assessmentId}/assessmentphoto`,
      method: 'post',
      data: props,
      headers: {
        'content-type': 'application/json',
        Authorization: token,
      },
    });
    return res.data;
  } catch (err: any) {
    console.error('photo upload error: ', err);
    throw err?.response?.data;
  }
};
