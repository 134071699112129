import React, { forwardRef } from 'react';
import { PrimaryParagraph } from '../Paragraph';
import { Title1 } from '../Titles';
import {
  MainWrapper,
  StepCircle,
  StepLine,
  ResultWrapper,
} from './StepperStyles';

export type StepperProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

export interface Props extends StepperProps {
  id: string;
  index: string;
  label?: string;
  resultData?: String;
}

export const Stepper = forwardRef<HTMLSelectElement, Props>(
  ({ id, index, resultData, label, ...props }: Props, ref) => {
    return (
      <MainWrapper>
        <StepCircle>
          <Title1>Step {index}</Title1>
        </StepCircle>
        <StepLine />
        <ResultWrapper>
          <PrimaryParagraph>{resultData}</PrimaryParagraph>
        </ResultWrapper>
      </MainWrapper>
    );
  },
);
