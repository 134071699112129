import React, { forwardRef } from 'react';
import { InputField } from './InputStyles';

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type InputType =
  | 'text'
  | 'email'
  | 'password'
  | 'number'
  | 'date'
  | 'file'
  | 'checkbox'
  | 'datetime-local';

export interface Props extends InputProps {
  id: string;
  type: InputType;
  label?: string;
  className?: string;
  placeholder?: string;
  value?: string | number;
  // ref: any;
}

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      type,
      className,
      placeholder,
      label,
      value,
      maxLength,
      onChange,
      ...props
    }: Props,
    ref,
  ) => {
    return (
      <InputField
        type={type}
        className={className}
        ref={ref as any}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        {...props}
        required
      />
    );
  },
);
