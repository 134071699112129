import styled from 'styled-components';
import goldIcon from 'app/assets/images/gold.png';

export const JobListingContainer = styled.div`
  width: 100%;
  /* display: flex; */
  /* overflow: auto; */
  min-height: 100vh;
  /* align-items: center; */
  /* flex-direction: column; */
  font-family: 'Montserrat', sans-serif;
  background-color: #232323;
`;

export const JobListingHomeHeader = styled.header`
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 30px 0px 30px;
`;

export const JobListingHomeHeaderLogo = styled.div`
  width: 100px;
`;

export const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  a {
    position: relative;
    color: #fff;
    text-decoration: none;
    margin-left: 20px;
  }
  a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: #fff;
    transition: width 0.3s ease;
  }
  a:hover::after {
    width: 100%;
  }
  a:hover {
    color: #ffc107;
  }
`;
export const Menu = styled.nav`
  display: none;
  cursor: pointer;
`;
export const LoginContentWrapper = styled.div`
  padding: 0px 40px 40px 40px;
  background-image: url(${goldIcon});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
  h1 {
    white-space: nowrap;
  }
  h1,
  h2,
  h3 {
    color: #ffffff;
    font-weight: normal;
    font-size: 60px;
    line-height: 1.6em;
  }
  h4 {
    color: #ffffff;
    font-weight: normal;
    font-size: 20px;
    margin-top: 40px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    h1,
    h2,
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 14px;
      margin-top: 20px;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1028px) {
    h1 {
      white-space: normal;
    }
  }
`;
export const LoginHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const JumpSpan = styled.span`
  display: inline-block;
  color: #ffc107;
  font-weight: bold;
  font-size: 50px;
  animation: jump 0.5s infinite;
  @keyframes jump {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
      /* Adjust the jump height as needed */
    }

    100% {
      transform: translateY(0);
    }
  }
`;
export const JumpImg = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  img {
    width: 300px;
    margin-top: 200px;
    position: absolute;
    right: -64px;
  }
  @media screen and (max-width: 768px) {
    img {
      width: 200px;
      height: fit-content;
      margin-bottom: 40px;
      margin-top: 40px;
      position: revert;
    }
  }
  @media screen and (min-width: 1400px) {
    img {
      width: 500px;
      margin-top: 50px;
      left: 650px;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1028px) {
    img {
      width: 187px;
      margin-top: 0;
      position: revert;
    }
  }
`;
export const LoginFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 100px;
  margin-top: 40px;
  form {
    margin-left: 20px;
  }
  button {
    width: 350px;
    height: 50px;
    font-size: 14px;
    font-weight: 600;
  }
  @media screen and (max-width: 768px) {
    form {
      margin: 0px;
    }
  }
  .otp-container {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 4px;
    padding: 0px 10px 5px 0px;
    margin-bottom: 20px;
  }
`;
export const InputContainer = styled.div`
  position: relative;
  /* margin-bottom: 20px; */
  display: flex;
  width: 350px;
  margin-top: 10px;
  input {
    padding: 10px 30px 10px 15px;
    border: 1px solid #ccc;
    background: #eaf0f7;
    border-radius: 4px;
    outline: none;
    width: 100%;
  }
  img {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  input.otp-input {
    margin-bottom: 20px;
    width: 40px !important;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 20px;
    padding: 10px 0px 10px 0px;
    justify-content: center;
  }
  input.otp-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
export const OrWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  margin-top: 20px;
  white-space: nowrap;
`;
export const DashDiv = styled.div`
  width: 60px;
  height: 1px;
  background: #ffffff;
  margin: 8px;
`;
export const FooterDiv = styled.div`
  color: #ffffff;
  margin-left: 30px;
  position: absolute;
  bottom: 20px;
  @media screen and (max-width: 768px) {
    position: relative;
    bottom: 0;
    left: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
`;
export const SignInButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #fdb82b;
  border: none;
  border-radius: 4px;
  color: #3d3d3d;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 40px;
  :hover {
    text-decoration: underline;
  }
`;
export const ResetButton = styled.a`
  display: block;
  text-align: right;
  margin-bottom: 15px;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
`;
export const OtpSpan = styled.span`
  margin-top: 30px;
  padding-right: 20px;
  padding-left: 10px;
  font-weight: 200;
`;
