import React from 'react';
import { RightContentDiv } from './rightSideContainerStyles';

interface RightContainerProps {
  children?: JSX.Element;
}

export const RightContainerDiv = ({ children }: RightContainerProps) => {
  return <RightContentDiv>{children}</RightContentDiv>;
};
