import styled from 'styled-components';
import searchIcon from 'app/assets/images/searchIcon.svg';
import { Stack } from '@mui/system';

export const BodyDiv = styled.div`
  width: 100%;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #3d3d3d;
  }
  .filter-dropdown {
    display: flex;
    align-items: center;
    min-height: 100%;
    margin-right: 20px;
    flex-direction: column;
    margin-top: 40px;
  }
`;
export const OopsSpan = styled.span`
  display: table;
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 131%;

  color: #3d3d3d;
`;

export const AddCategoryModal = styled.div`
  width: 380px;
  margin: 40px;
  @media (max-width: 800px) {
    width: 100%;
  }
  input {
    margin-top: 20px;
    width: 100%;
    height: 36px;
    margin-right: 20px;
    text-indent: 10px;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #000000;
    border-radius: 5px;
  }
`;

export const SearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    padding-right: 15px;
    height: 38px;
    background-position: center left 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
    border-radius: 5px;
  }
  position: relative;
  margin-top: 20px;
`;

export const CatDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  .breadcrumbwrapper {
    display: flex;
  }
  .category-list {
    margin-top: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  h1 {
    text-align: left;
  }
`;

export const SearchInpDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  button {
    background: #fdb82c;
    height: 39px;
    margin-bottom: 0px;
    width: 50px;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0;
    background-image: url(${searchIcon});
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 0px;
  }
  input {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-right: -8px;
    width: 200px;
    height: 38px;
    text-indent: 10px;
    margin-bottom: 0px;
  }
  & select {
    width: 133px !important;
    cursor: pointer !important;
    margin-left: 20px !important;
    height: 36px !important;
  }
`;
export const ButtonWrapDiv = styled.div`
  display: flex;
  justify-content: right;
  button {
    margin-right: 10px;
    width: 94px;
    height: 34px;
  }
`;

export const CategoryNameSpan = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: #3d3d3d;
`;
export const ErrorDiv = styled.span`
  color: #e92626;
  font-size: 14px;
`;

export const StyledSearchLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledStack = styled(Stack)`
  width: 396px;
  height: 38px;
  padding-left: 20px;
  margin-bottom: 30px;
`;
