import { Input } from 'app/components/UI/atoms/Input';
import React from 'react';
import { useForm } from 'react-hook-form';
// import fairhirewhite from 'app/assets/images/fairhirewhite.svg';

import {
  AddLanguage,
  // CurrentlyStudy,
  CurrentStudy,
  DateBox,
  Description,
  // EducationHearder,
  // FairHireLogo,
  FlexTab,
  // JobExpWrapper,
  Slider,
  Switch,
  SwitchWrapper,
  TabPane,
  TabPane2,
  // HelloHeading,
  StepperContainer,
  NextPrevious,
  Next,
  NextButton,
  RemoveButton,
  EducationHeading,
  CheckCompanyWrapper,
  CoCheck,
} from '../ResumeInfoStyles';

function Education({
  eduList,
  handleEducationAdd,
  handleEducationRemove,
  steps,
  activeStep,
  setActiveStep,
  handleFormData,
  formStepperData,
}) {
  const {
    register,
    // reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const ss = data => {};
  const EducationInformationHandle = data => {
    console.log(data);
    handleFormData(data);

    setActiveStep(activeStep + 1);
  };
  return (
    <TabPane>
      <EducationHeading>
        "Never stop because life never stops teaching you
      </EducationHeading>
      <StepperContainer>
        <TabPane2>Education </TabPane2>
        {eduList.map((SingleEducation, index) => (
          <form onSubmit={handleSubmit(EducationInformationHandle)} noValidate>
            <FlexTab>
              <Input
                type="text"
                id="school"
                placeholder="School / University"
                defaultValue={formStepperData.School}
                className={errors?.School?.type === 'required' ? 'invalid' : ''}
                {...register('School', {
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'atleast 3 letters are requierd',
                  },
                  maxLength: {
                    value: 30,
                    message: 'You cant add letters which are more than 30',
                  },
                })}
              />
              <>{errors.School?.message} </>

              <Input
                type="text"
                id="degree"
                placeholder="degree"
                defaultValue={formStepperData.Degree}
                className={errors?.Degree?.type === 'required' ? 'invalid' : ''}
                {...register('Degree', {
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'atleast 3 letters are requierd',
                  },
                  maxLength: {
                    value: 30,
                    message: 'You cant add letters which are more than 30',
                  },
                })}
              />
              <>{errors.Degree?.message} </>
            </FlexTab>

            <FlexTab>
              <Input
                type="text"
                id="school"
                placeholder="School / University"
                defaultValue={formStepperData.Field_study}
                className={
                  errors?.Field_study?.type === 'required' ? 'invalid' : ''
                }
                {...register('Field_study', {
                  required: true,
                })}
              />

              <Input
                type="text"
                id="school"
                placeholder="grade"
                defaultValue={formStepperData.Grade}
                className={errors?.Grade?.type === 'required' ? 'invalid' : ''}
                {...register('Grade', {
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'atleast 3 letters are requierd',
                  },
                  maxLength: {
                    value: 30,
                    message: 'You cant add letters which are more than 30',
                  },
                })}
              />
              <>{errors.Grade?.message} </>

              <DateBox
                type="text"
                id="date"
                // name="date"
                placeholder="Start Date DD/YYYY"
                required
              ></DateBox>
              <DateBox
                type="text"
                id="date"
                name="date"
                placeholder="End Date DD/YYYY"
                required
              ></DateBox>
              <SwitchWrapper>
                {' '}
                <CurrentStudy>
                  <CheckCompanyWrapper>
                    <CoCheck
                      type="checkbox"
                      id="education"
                      name="education"
                      value="currentEducation"
                    ></CoCheck>
                    <label htmlFor="education">
                      I'm currently studying here
                    </label>
                  </CheckCompanyWrapper>
                  {/* <CurrentlyStudy> </CurrentlyStudy>
                  I'm currently studying here */}
                  <Switch>
                    <Slider> </Slider>
                  </Switch>
                </CurrentStudy>
              </SwitchWrapper>
              <Description
                id="Description"
                placeholder="Description"
                name="Description"
              ></Description>
            </FlexTab>
            {eduList.length > 1 && (
              <RemoveButton
                type="button"
                onClick={() => handleEducationRemove(index)}
              >
                Remove
              </RemoveButton>
            )}
            {eduList.length - 1 === index && eduList.length < 6 && (
              <AddLanguage type="button" onClick={handleEducationAdd}>
                Add Education
              </AddLanguage>
            )}

            {activeStep !== steps.length - 1 && (
              <NextButton>
                {activeStep !== 0 && activeStep !== steps.length - 1 && (
                  <NextPrevious
                    type="submit"
                    onClick={() => setActiveStep(activeStep - 1)}
                  >
                    Previous
                  </NextPrevious>
                )}

                <Next type="submit">Next</Next>
              </NextButton>
            )}
          </form>
        ))}
      </StepperContainer>
    </TabPane>
  );
}

export default Education;
