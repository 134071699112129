import React, { useState } from 'react';
import Button from 'app/components/UI/atoms/Button';
import { Input } from 'app/components/UI/atoms/Input';
import { TextArea } from 'app/components/UI/atoms/TextArea';
import { Select } from 'app/components/UI/atoms/Select';
import Alert from '../../atoms/Alert';
// import { Switch } from 'app/components/UI/atoms/Switch';
import {
  AnswerWrapDiv,
  // ButtonDiv,
  InputWrapperDiv,
  TextInputWrapperDiv,
  SelectInputWrapperDiv,
  ModalFlexDiv,
  ModalTagFlex,
  ModalTextFlex3,
  ModalTextFlex2,
} from './mcqQuestionMOdalStyles';
import { Radio } from '../../atoms/Radio';
import { useForm } from 'react-hook-form';
// import { BoxDiv } from 'app/components/pages/Dashboard/dashboardStyles';
import { addQuestion, editQuestion } from 'app/services/questions';
// import { useModal } from '../Modal/SimpleModal/userModal';

export const McqsQuestionModal = ({
  quesLevel,
  quesTech,
  categoryId,
  onAddQuestionSubmit,
  saveDraftRef,
  closeQuesModal,
  type,
  questData,
  submitRef,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [minOptions, setMinOptions] = useState(2);
  const typeOptions = [
    { label: 'MCQs', value: 'MCQ' },
    { label: 'Reading', value: 'reading', disabled: true },
    { label: 'Writing', value: 'writing', disabled: true },
  ];
  const timeBySel = [{ label: 'Seconds', value: 'second' }];
  const [quesType, setQuesType] = useState('MCQ');
  const [timeBy, setTimeBy] = useState('second');
  const maxOptions = 10;
  const [disableAddOption, setDisableAddOption] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<any>(false);
  const [selectedOption, setSelectedOption] = useState(null);
  let [options, setOptions] = useState([
    {
      input: {
        id: 'answer1',
        placeHolder: 'Option 1',
        isRequired: true,
      },
      weightage: {
        label: '',
        id: 'answer_1',
        name: 'options',
        value: '0',
      },
    },
    {
      input: {
        id: 'answer2',
        placeHolder: 'Option 2',
        isRequired: true,
      },
      weightage: {
        label: '',
        id: 'answer_2',
        name: 'options',
        value: '0',
      },
    },
  ]);
  const {
    register,
    reset,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
    setValue,
  } = useForm();

  // const [storelocalDraft, setStoreLocalDraft] = useState({});
  let localDraft = localStorage.draftedQuest || {};

  const processAddQuesData = async data => {
    console.log('Received from form:', data);
    // Construct proper object to create question
    const quesObjData = {
      proficiency: data.proficiency.toLowerCase(),
      title: data.questionText,
      questionType: data.questionType.toLowerCase(),
      expectedTimeForResponse: parseInt(data.expectedTimeForResponse),
      categoryId: data.categoryId,
      questionId: data?.questionId || null,
      answer: data.answer,
      isDraft: data.isDraft || false,
    };
    return quesObjData;
  };

  const checkSubmittingData = data => {
    let mandateKeys = [
      'proficiency',
      'title',
      'questionType',
      'expectedTimeForResponse',
      'answer',
    ];

    if (type === 'editQuestion') {
      mandateKeys = [
        'proficiency',
        'title',
        'questionType',
        'expectedTimeForResponse',
        'answer',
      ];
    }

    // Check whether data is object or null
    if (typeof data !== 'object') {
      let err = new Error(
        'Invalid Question Data! Please fill the Question form.',
      );
      // @ts-ignore
      err.code = 11;
      throw err;
    }

    // Check whether data has all mandatory properties or not
    mandateKeys.map(key => {
      if (!data.hasOwnProperty(key)) {
        let err = new Error(
          'Invalid Question Data! Please fill all the mandatory fields',
        );
        // @ts-ignore
        err.code = 12;
        throw err;
      }
      return null;
    });

    // Check whether answer key is valid or not
    if (!Array.isArray(data.answer) || data.answer.length <= 0) {
      let err = new Error(
        'Invalid Question Data! Please fill all the answer fields',
      );
      // @ts-ignore
      err.code = 12;
      throw err;
    }

    // Check whether answer is an array object and has proper keys
    let weightageCount = 0;
    data.answer.map(ansObj => {
      if (
        !ansObj.hasOwnProperty('answerText') ||
        typeof ansObj.answerText === 'undefined'
      ) {
        let err = new Error(
          'Invalid Question Data! Please fill all the answer fields',
        );
        // @ts-ignore
        err.code = 13;
        throw err;
      }

      if (ansObj.hasOwnProperty('weightage')) weightageCount++;
      return null;
    });
    if (weightageCount !== 1 && type === 'addQuestion') {
      let err = new Error(
        'Invalid Question Data! Please fill all the answer fields',
      );
      // @ts-ignore
      err.code = 14;
      throw err;
    }
  };

  const submitHandler = async data => {
    if (typeof localDraft !== 'object') {
      console.log('Invalid question data submitted!!');
    }
    try {
      let respData;
      const reqData = await processAddQuesData({
        ...data,
        proficiency: quesLevel,
        questionType: quesType,
        expectedTimeForResponseUnit: timeBy,
        categoryId,
      });
      console.log(reqData);
      // Check whether submitting data is correct or not
      checkSubmittingData(reqData);
      if (type === 'editQuestion') {
        respData = await editQuestion(reqData);
      } else {
        respData = await addQuestion(reqData);
      }
      console.log(respData);
      if (respData.statusCode === 0) {
        //   // Clear local storage draft
        console.log('entered success response');
        localStorage.removeItem('draftedQuest');
        //   onAddQuestionSubmit({ ...respData, categoryId });
        console.log(respData);
        closeQuesModal();
      } else {
        console.log('Failed to add/edit new question', respData);
        setShowAlert('Failed to add/edit new question!');
      }
    } catch (err) {
      // @ts-ignore
      setShowAlert(err?.message);
      console.log('Failed to add/edit question:', err);
    }
  };

  const draftHandler = async data => {
    if (typeof localDraft !== 'object') {
      console.log('Invalid question data submitted!!');
    }
    try {
      let respData;
      const reqData = await processAddQuesData({
        ...data,
        proficiency: quesLevel,
        questionType: quesType,
        categoryId,
        isDraft: true,
      });
      console.log('Draft Data');
      // Check whether submitting data is correct or not
      checkSubmittingData(reqData);
      if (type === 'editQuestion') {
        console.log('Cannot do draft in Edit mode!');
        closeQuesModal();
      } else {
        respData = await addQuestion(reqData);
      }
      console.log(respData);
      if (respData.statusCode === 0) {
        //   // Clear local storage draft
        console.log('entered success response');
        localStorage.removeItem('draftedQuest');
        //   onAddQuestionSubmit({ ...respData, categoryId });
        console.log(respData);
        closeQuesModal();
      } else {
        console.log('Failed to add/edit new question', respData);
        setShowAlert('Failed to add/edit new question!');
      }
    } catch (err) {
      // @ts-ignore
      setShowAlert(err?.message);
      console.log('Failed to add/edit question:', err);
    }
  };

  const handleQuesTypeChange = e => {
    // Type change comes here
    console.log(e.target.value);
    setQuesType(e.target.value);
  };

  const handleTimeByChange = e => {
    // Time type change comes here
    setTimeBy(e.target.value);
  };

  const changeHandler = e => {
    // Check the type of localDraft obj and parse accordingly
    if (typeof localDraft === 'string') {
      localDraft = JSON.parse(localDraft);
    }
    // Check which obj is the target and add to localdraft accordingly
    if (e.target.name === 'options') {
      console.log(e.target);
      // e.target.checked = true;
      options.forEach(obj => (obj.weightage.value = '0'));
      let selectedOpt = options.find(opt => opt.weightage.id === e.target.id);
      if (selectedOpt !== null && selectedOpt !== undefined) {
        selectedOpt.weightage.value = '100';
        // options = [...options, selectedOpt];
      }
      console.log(options);
    } else {
      localDraft = { ...localDraft, [e.target.name]: e.target.value };
    }

    if (type === 'addQuestion') {
      // Generate answer array from received data
      const answer = [];
      options.map(option => {
        let ans = { answerText: localDraft[option.input.id] };
        if (option.weightage.value === '100') {
          // @ts-ignore
          ans.weightage = '100';
        }
        // @ts-ignore
        answer.push(ans);
        return null;
      });
      localDraft.answer = answer;
    }
    //  else if (type === 'editQuestion' && e.target.name === 'options') {
    //   localDraft.answer = options;
    // }
    console.log('Updated localdraft: ', localDraft);
    resetAndSetData(false);
  };

  const addOption = () => {
    let tempOptions = options;
    if (tempOptions.length + 1 <= maxOptions) {
      const currentNoOfOptions = tempOptions.length + 1;
      const optToInsert = {
        input: {
          id: `answer${currentNoOfOptions}`,
          placeHolder: `Option ${currentNoOfOptions}`,
          isRequired: true,
        },
        weightage: {
          label: '',
          id: `answer_${currentNoOfOptions}`,
          name: 'options',
          value: '0',
        },
      };
      tempOptions.push(optToInsert);
      setOptions(tempOptions);
      console.log('added new option', options);
      resetAndSetData();
      currentNoOfOptions + 1 > maxOptions
        ? setDisableAddOption(true)
        : setDisableAddOption(false);
    } else {
      setDisableAddOption(true);
      console.log('Max options reached. Cannot add new options');
    }
  };

  const removeOption = (id: any) => {
    console.log('remove id: ', id);
    let removedOption;
    if (options && options.length > minOptions) {
      if (typeof id !== 'number') {
        removedOption = options.pop();
      } else {
        removedOption = options.splice(id, 1);
      }
      console.log('removed option id: ', removedOption[0].input.id);
      delete localDraft[removedOption[0].input.id];
      resetAndSetData();
      // Adding second delete for last deletion case
      delete localDraft[removedOption[0].input.id];
      resetAndSetData();
      setDisableAddOption(false);
    }
  };

  const resetAndSetData = (doReset = true) => {
    if (typeof localDraft === 'string') {
      localDraft = JSON.parse(localDraft);
    }
    // console.log('before localstorage: ', localDraft);
    // console.log(JSON.stringify(localDraft));

    localStorage.setItem('draftedQuest', JSON.stringify(localDraft));
    if (doReset) {
      reset();
    }
    // console.log('after reset: ', localDraft);
    Object.keys(localDraft).forEach((key, index) => {
      setValue(key, localDraft[key]);
    });
    // console.log('store: ', localStorage.getItem('draftedQuest'));
    let tempDraftObj: any = localStorage.getItem('draftedQuest');
    localDraft = tempDraftObj !== undefined ? JSON.parse(tempDraftObj) : {}; //storelocalDraft;
    // console.log('local after parse: ', localDraft);
  };

  const GenerateOptions = () => {
    return (
      <>
        <InputWrapperDiv className="container">
          {options.map(({ input, weightage }, index) => (
            <AnswerWrapDiv key={index} className="item">
              <Input
                id={input.id}
                type="text"
                // label="question text2"
                placeholder={input.placeHolder}
                {...register(input.id, {
                  required: input.isRequired,
                })}
                required
                onChange={changeHandler}
              />
              <Radio
                width="30px"
                id={weightage.id}
                {...register(weightage.id)}
                name="options"
                value={weightage.value}
                defaultChecked={false}
                onChange={changeHandler}
              />
              {options.length > 2 && (
                <div
                  id={`removeOption${index}`}
                  onClick={() => removeOption(index)}
                  className="removeOptionBtn"
                  hidden={type === 'viewQuestion'}
                  style={{
                    marginRight: '20px',
                    backgroundColor: '#fdb82c',
                    width: '45px',
                    content: '-',
                    cursor: 'pointer',
                    textAlign: 'center',
                    borderRadius: '20%',
                  }}
                >
                  -
                </div>
              )}
            </AnswerWrapDiv>
          ))}
          <Button
            id="addOption"
            type="button"
            content="+ Add Option"
            onClick={() => addOption()}
            className="addOptionBtn"
            hidden={true}
            disabled={disableAddOption}
          />
        </InputWrapperDiv>
      </>
    );
  };

  // Below function is to View Question
  const ViewOptions = () => {
    // console.log('iterating choice values in view');
    return (
      <InputWrapperDiv className="container">
        {/* {console.log('entered choices map in view: ', questData)} */}
        {/* {console.log('showing choices: ', questData.choices)} */}
        {questData.choices.map(
          (choice, i) =>
            i >= 0 && (
              <AnswerWrapDiv key={i} className="item">
                <Input
                  id={'inp_' + choice.metadata}
                  type="text"
                  value={choice.text}
                  disabled={true}
                />
                <Radio
                  width="30px"
                  id={'rad_' + i}
                  isSelected={parseInt(choice.weightage) > 0}
                  name="options"
                  disabled={true}
                />
              </AnswerWrapDiv>
            ),
        )}
      </InputWrapperDiv>
    );
  };

  const mapToLocalDraft = origData => {
    localDraft.proficiency = origData.proficiency;
    localDraft.questionText = origData.text;
    localDraft.questionType = origData.type;
    localDraft.expectedTimeForResponse = origData.time;
    localDraft.categoryId = origData.categoryId;
    localDraft.questionId = origData.questionId;
    localDraft.answer = origData.choices;
    origData.choices.forEach((choice, idx) => {
      localDraft.answer[idx].answerText = choice.text;
      localDraft.answer[idx].weightage = choice.weightage;
      // localDraft.answer[idx].text = null;
    });
  };

  const editChoiceHandler = (e, i) => {
    setSelectedOption(i);
    // Check the type of localDraft obj and parse accordingly
    if (typeof localDraft === 'string') {
      localDraft = JSON.parse(localDraft);
    }
    // Check which obj is the target and add to localdraft accordingly
    if (e.target.name === 'options') {
      const id = e.target.id;
      const idx = id.split('_')[1];
      localDraft.answer.forEach((ans, ind) => {
        ans.weightage = 0;
      });
      localDraft.answer[idx].weightage = 1;
    }
    console.log('Updated localdraft: ', localDraft);
  };

  // Below function is to edit Question
  const EditOptions = () => {
    console.log('Quest from server:');
    console.log(questData);
    console.log('Quest from localdraft:');
    console.log(localDraft);
    // Map Quest data to localdraft
    mapToLocalDraft(questData);
    console.log('localdraft after mapping:');
    console.log(localDraft);
    return (
      <InputWrapperDiv className="container">
        {console.log('entered choices map in edit mode: ', questData)}
        {console.log('showing choices in edit mode: ', questData.choices)}
        {questData.choices.map((choice, i) => {
          if (parseInt(choice.weightage) > 0) {
            setSelectedOption(i);
          }

          return (
            <AnswerWrapDiv key={i} className="item">
              <Input
                id={'inp_' + choice.metadata}
                type="text"
                defaultValue={choice.text}
                onChange={changeHandler}
              />
              <Radio
                width="30px"
                id={'rad_' + i}
                isSelected={selectedOption === i}
                name="options"
                onChange={e => editChoiceHandler(e, i)}
              />
            </AnswerWrapDiv>
          );
        })}
      </InputWrapperDiv>
    );
  };

  return (
    <form name="question" className="Input-Wrapper" noValidate={true}>
      <button
        type="button"
        ref={submitRef}
        style={{ display: 'none' }}
        onClick={() => {
          console.log('form got saved');
          submitHandler(localDraft);
        }}
      />
      <button
        type="button"
        ref={saveDraftRef}
        style={{ display: 'none' }}
        onClick={() => {
          console.log('form got saved to draft');
          draftHandler(localDraft);
        }}
      />
      <div>Question and Answer</div>
      {showAlert && <Alert setShowAlert={setShowAlert} msg={showAlert} />}
      <ModalFlexDiv>
        <ModalTextFlex3 className="inputSelectorWrapper">
          <TextInputWrapperDiv>
            <TextArea
              id="questionText"
              label="Question text"
              // label="question text2"
              placeholder="question text"
              {...register('questionText', {
                required: true,
              })}
              rows={5}
              required
              // onChange={type === 'editQuestion' ? editHandler : changeHandler}
              onChange={changeHandler}
              disabled={type === 'viewQuestion'}
              defaultValue={
                typeof questData !== 'undefined' && questData.text
                  ? questData.text
                  : ''
              }
            />
          </TextInputWrapperDiv>
          <SelectInputWrapperDiv>
            <Select
              id={'quesType'}
              // label={'Select Type'}
              options={typeOptions}
              onChange={handleQuesTypeChange}
              value={quesType ? quesType : ''}
              disabled={type === 'viewQuestion'}
              className="typeSelector"
            />
            <ModalFlexDiv className="typeAndTimeWrapper">
              <Input
                id="expectedTimeForResponse"
                type="text"
                label="Expected time for response"
                placeholder="Expected Time"
                required
                {...register('expectedTimeForResponse', {
                  required: true,
                })}
                // onChange={type === 'editQuestion' ? editHandler : changeHandler}
                onChange={changeHandler}
                disabled={type === 'viewQuestion'}
                defaultValue={
                  typeof questData !== 'undefined' && questData.time !== ''
                    ? parseInt(questData.time) === 0
                      ? 3
                      : parseInt(questData.time)
                    : ''
                }
                className="expectedTimeText"
              />
              <Select
                id={'timeBy'}
                // label={'Select Type'}
                options={timeBySel}
                onChange={handleTimeByChange}
                value={timeBy ? timeBy : ''}
                style={{ width: '60%' }}
                disabled={type === 'viewQuestion'}
                className="timeBy"
              />
            </ModalFlexDiv>
          </SelectInputWrapperDiv>
        </ModalTextFlex3>

        <ModalTextFlex2>
          <ModalTagFlex>
            {type === 'addQuestion' && <GenerateOptions />}
            {type === 'viewQuestion' && <ViewOptions />}
            {type === 'editQuestion' && <EditOptions />}
          </ModalTagFlex>
        </ModalTextFlex2>
      </ModalFlexDiv>
    </form>
  );
};
