import styled from 'styled-components';

export const InviteLinkWrapper = styled.div`
  display: flex;
  flex-direction: inherit;
  justify-content: inherit;
  align-items: center;
`;

export const InviteLinkTitle = styled.div`
  font-weight: 600;
  color: #3d3d3d;
  text-transform: capitalize;
  // margin: 0 20px;
  padding: 30px 20px;
`;

export const InviteLinkCard = styled.div`
  display: flex;
  min-width: 474px;
  width: auto;
  height: 65px;
  background-color: #f9f9f9;
  color: #000000;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 0 10px;
  padding: 30px 20px;
`;

export const CopyLinkButton = styled.div`
  margin: 35px 0;
  width: 180px;
  height: 50px;
  /* outline: solid 1px #e53366; */
  background: #ffffff;
  font-weight: 400;
  border-radius: 10px;
`;
