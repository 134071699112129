import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  input[type='text'] {
    background: #ffffff;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin-right: -8px;
    font-family: 'Montserrat', sans-serif;
    width: 200px;
    height: 38px;
    text-indent: 10px;
  }
`;

export const Label = styled.label`
  color: #959595;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  background: white;
  padding: 0 5px;
  left: 18px;
  top: 16px;
  align-items: center;
  transition: 0.2s ease all;
  input:focus ~ &,
  input:not(:placeholder-shown).input:not(:focus) ~ .label,
  input:focus.input:invalid,
  input:valid ~ & {
    top: -10px;
    font-size: 12px;
    color: #959595;
  }
`;

export const SearchIcon = styled.span`
  .send-link {
    font-weight: bold;
  }
  background: #fdb82c;
  height: 39px;
  margin-bottom: 0px;
  border: none;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  background-image: url('../images/search.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  svg {
    fill: #a8a8a8;
    transition: 0.3s;
  }
`;
