import React from 'react';
import Button from 'app/components/UI/atoms/Button';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import { Title5, Title6 } from 'app/components/UI/atoms/Titles';
import {
  ModelContent,
  MailIcon,
  Message,
  ModalContainer,
} from '../Modal/MessageModelStyles';
import {
  CopyLinkButton,
  InviteLinkCard,
  InviteLinkTitle,
  InviteLinkWrapper,
} from './InvitationStyles';
import SuccessIcon from 'app/assets/images/success-icon.svg';
import { Modal } from '../Modal/SimpleModal';
import { useModal } from '../Modal/SimpleModal/userModal';

export const Invitation = ({ inviteLink }) => {
  const { isShown, toggle } = useModal();

  const copyLink = inviteLink => {
    navigator.clipboard.writeText(inviteLink);
    toggle();
  };

  const content = (
    <ModelContent>
      <MailIcon>
        <img src={SuccessIcon} alt="mail-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <PrimaryParagraph>The public link has been copied</PrimaryParagraph>
      </Message>
    </ModelContent>
  );

  return (
    <>
      <InviteLinkWrapper>
        <InviteLinkTitle>
          <Title5>By Public Link:</Title5>
        </InviteLinkTitle>
        <InviteLinkCard>
          <Title6>{inviteLink}</Title6>
        </InviteLinkCard>
        <CopyLinkButton>
          <Button
            bgColor="#fdb82c"
            content="Copy Link"
            type={'button'}
            onClick={() => copyLink(inviteLink)}
          />
        </CopyLinkButton>
      </InviteLinkWrapper>
      <ModalContainer>
        <Modal
          isShown={isShown}
          isShowCloseButton={true}
          hide={toggle}
          modalContent={content}
        />
      </ModalContainer>
    </>
  );
};
