import React, { useEffect, useState } from 'react';
import Button from 'app/components/UI/atoms/Button';
import { Header } from 'app/components/UI/organisms/Header';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import { BodyDiv, SearchWrap, SearchInpDiv } from './categoriesStyles';
import { CategoriesListTable } from './List/categoriesListTable';
import { Input } from 'app/components/UI/atoms/Input';
import { mockData } from './mock/mock';

export const Categories = () => {
  const [categoriesList, setCategoriesList] = useState<any>();
  const [categoriesListFirstLoad, setCategoriesListFirstLoad] = useState<any>();
  const headers = [
    { id: 'category', name: 'category' },
    { id: 'activeQuestions', name: 'Active Question Count' },
    { id: 'action', name: 'Action' },
  ];
  let jobsData: any = [];
  const getAllcategories = async () => {
    // jobsData = await getAllcategories();
    setCategoriesList(mockData);
    console.log(mockData);
    setCategoriesListFirstLoad(jobsData.jobs);
  };
  useEffect(() => {
    getAllcategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startsWith = (array, key) => {
    const matcher = new RegExp(`^${key.toLowerCase()}`, 'g');
    return array.filter(word => word.toLowerCase().match(matcher));
  };

  const handleSearch = event => {
    const newJobsObj = categoriesListFirstLoad.filter(jb => {
      return (
        jb.title.toLowerCase().includes(event.target.value) ||
        startsWith(jb.skillsRequired, event.target.value).length > 0
      );
    });
    console.log('result', newJobsObj);
    categoriesList(newJobsObj);
  };
  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <SearchWrap>
              <h5> categories</h5>
              <SearchInpDiv className="locationskillswrapper-input">
                <Input
                  id={'search'}
                  className="category-input"
                  type={'text'}
                  label={'Category '}
                  onChange={handleSearch}
                  placeholder="Category"
                />
                <Button type="button" content="" className="search-icon" />
              </SearchInpDiv>
            </SearchWrap>
            <CategoriesListTable headers={headers} rowData={categoriesList} />
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
      {/* <ModalContainer>
        <Modal
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={false}
          modalContent={
            <AddQuestionModal
              categoryId={categoryId}
              onAddQuestionSubmit={onAddQuestionSubmit}
            />
          }
        />
      </ModalContainer> */}
    </>
  );
};
