/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
// import Button from 'app/components/UI/atoms/Button';
import { Header } from 'app/components/UI/organisms/Header';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import {
  AssessmentDetailWrapper,
  BodyDiv,
  CategorySectionWrapper,
  CategorySet,
  DeleteBtn,
  TechnologyDetails,
  QuestionDetails,
  ProficiencySelect,
  ContentDiv,
  ContentSubDiv,
  SearchWrap,
  TechnologyWrapper,
  CollpasedWrap,
  CategoryWrap,
  MinmaxRequired,
  CategorySetInner,
  // TotalTimeDiv,
  CategoriesName,
  ProficienyDiv,
  CategorySpan,
  ErrorDiv,
  // MandatoryQuesDiv,
  TechnologyDetailsWrap,
  AntiCheatWrapper,
  // MandatoryQuesBodyDiv,
  // MandatoryQuesHeadDiv,
} from './createAssessmentStyles';
import Button from 'app/components/UI/atoms/Button';
import { Input } from 'app/components/UI/atoms/Input';
import { useForm } from 'react-hook-form';
import { Select } from 'app/components/UI/atoms/Select';
import {
  getAllCategories,
  getQuestionsMinMaxTimeEstimate,
} from 'app/services/categories';
import { ModalContainer } from 'app/components/UI/organisms/Modal/MessageModelStyles';
import { Modal } from 'app/components/UI/organisms/Modal/SimpleModal';
import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import { AddQuestion } from './AddQuestion';
import { useNavigate } from 'react-router-dom';
import { createAssessment } from 'app/services/assessments';
import DownArrow from 'app/assets/images/DownArrow.svg';
import { MailIcon, Message, ModelContent } from 'app/components/UI/globalStyles/modalStyles';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import errorIcon from 'app/assets/images/errorIcon.svg';
// import Breadcrumb from 'app/components/UI/molecules/Breadcrumb';
import buttonLoader from 'app/assets/images/loader-yellow.svg';
import { SwitchComponent } from 'app/components/UI/atoms/Switch';

export const CreateAssessment = () => {
  const { isShown, toggle } = useModal();
  const [isShown2, setToggle2] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('something went wrong!');
  const [allCategories, setAllCategories] = useState<any>();
  const [tech, setTech] = useState([{ set: '', technology: '' }]);
  const [mandatoryQuestionData, setMandatoryQuestionData] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);
  let navigate = useNavigate();
  // const [mandatoryQuestionCategoryId, setMandatoryQuestionCategoryId] =
  //   useState<string>();
  const [categorySet, setCategorySet] = useState([
    {
      questionCount: '',
      proficiency: '',
      technology: [
        {
          set: '',
          technology: '',
          mandatorySelectedQuesArr: [],
          mandatorySelectedQues: [],
          maxTime: 0,
          minTime: 0,
        },
      ],
    },
  ]);
  const [minTime, setMinTimeRequired] = useState<string>();
  const [maxTime, setMaxTimeRequired] = useState<string>();
  const {
    register,
    handleSubmit,
    setValue,
    // reset,
    // setError,
    formState: { errors },
  } = useForm();

  const fetchCategories = async () => {
    const categoriesData = await getAllCategories();

    /**
     * Function to sort categories by name
     */
    const sortedCategories = categoriesData.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    const categoryOptions = sortedCategories.map(cat => ({
      label: cat.name,
      value: cat.id
    }));

    categoryOptions.unshift({ label: 'Select technology', value: '' });
    setAllCategories(categoryOptions);
  };

  useEffect(() => {
    fetchCategories();
    const date = new Date().toLocaleDateString("fr-CA");
    const dateTo = new Date();
    dateTo.setDate(dateTo.getDate() + 3);
    setValue('validFrom', date);
    setValue('validTo', dateTo.toLocaleDateString("fr-CA"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const startsWith = (array, key) => {
  //   const matcher = new RegExp(`^${key.toLowerCase()}`, 'g');
  //   return array.filter(word => word.toLowerCase().match(matcher));
  // };
  const [assessmentTittle, setAssessmentTitle] = useState<string>('');
  const [assessmentDuration, setAssessmentDuration] = useState<number>(0);
  const [assessmentScore, setAssessmentScore] = useState<number>(0);
  const [assessmentValidFrom, setAssessmentValidFrom] = useState<string>('');
  const [assessmentValidTo, setAssessmentValidTo] = useState<string>('');

  const [isAntiCheatEnabled, setAntiCheatEnabled] = useState(false);
  const onHandleChange = async (checked) => {
    setAntiCheatEnabled(!isAntiCheatEnabled);
  };

  const onSubmit = handleSubmit(async (data: any) => {
    setLoading(true);
    const { title, duration: time, percentage: score, validFrom, validTo } = data;
    const sectionArr: any = [];

    categorySet?.map((cs, i) => {
      const tempCategories: any = [];
      cs.technology?.map((tech: any, j) => {
        if (tech?.technology === "") {
          setErrorMsg('category set fields are empty!')
          setToggle2(true);
          setLoading(false);

          // throw new Error('category set fields are empty!')
        }
        tempCategories.push({
          categoryId: tech.technology,
          mandatoryQuestions: tech.mandatorySelectedQuesArr,
        });
      });
      sectionArr.push({
        sequence: i + 1,
        questionCount: cs.questionCount,
        proficiency: cs.proficiency,
        categories: tempCategories,
      });
    });

    const requestData = {
      title,
      time: time * 60,
      score,
      cheatDetectionEnabled: isAntiCheatEnabled,
      validFrom: new Date(validFrom).getTime(),
      validUntil: new Date(validTo).getTime(),
      sections: sectionArr,
    };
    const createAssessmentResp: any = await createAssessment(requestData);
    const errorCodes = [30203];
    if (createAssessmentResp.statusCode === 30201) {
      setErrorMsg('Not enough questions for the selected category!');
      setToggle2(true);
    } else if (createAssessmentResp.statusCode === 30202) {
      setErrorMsg('Minimum time required for all the categories added exceeds the total assessment time!')
      setToggle2(true);
    } else if (createAssessmentResp.statusCode === 2) {
      setErrorMsg('category set fields are empty!')
      setToggle2(true);
    } else if (errorCodes.includes(createAssessmentResp?.statusCode)) {
      setErrorMsg(createAssessmentResp?.error)
      setToggle2(true);
    }
    setLoading(false);
    if (createAssessmentResp.status === 200) {
      navigate('/assessments');
    }
    // console.log('submitted', createAssessmentResp);
  });
  const responseContent = (
    <ModelContent>
      <MailIcon>
        <img src={errorIcon} alt="mail-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <PrimaryParagraph>{errorMsg}</PrimaryParagraph>
      </Message>
    </ModelContent>
  );
  const handleTitleChange = (e) => {
    setAssessmentTitle(e.target.value);
  }
  const handleDurationChange = (e) => {
    setAssessmentDuration(e.target.value);
  }
  const handleScoreChange = (e) => {
    setAssessmentScore(e.target.value);
  }
  const handleValidFromChange = (e) => {
    setAssessmentValidFrom(e.target.value);
  }
  const handleValidToChange = (e) => {
    setAssessmentValidTo(e.target.value);
  }
  // const saveDraft = async (data) => {
  //   // setLoading(true);
  //   if (typeof assessmentTittle === 'undefined' || assessmentTittle === '') {
  //     setErrorMsg('Please fill assessment title to save as a draft!')
  //     setToggle2(true);
  //     return;
  //   }
  //   const sectionArr: any = [];

  //   categorySet?.map((cs, i) => {
  //     const tempCategories: any = [];
  //     cs.technology?.map((tech: any, j) => {
  //       tempCategories.push({
  //         categoryId: tech.technology,
  //         mandatoryQuestions: tech.mandatorySelectedQuesArr,
  //       });
  //     });
  //     sectionArr.push({
  //       sequence: i + 1,
  //       questionCount: cs.questionCount,
  //       proficiency: cs.proficiency,
  //       categories: tempCategories,
  //     });
  //   });

  //   const requestData = {
  //     title: assessmentTittle,
  //     time: assessmentDuration * 60,
  //     score: assessmentScore,
  //     isDraft: true,
  //     validFrom: new Date(assessmentValidFrom).getTime(),
  //     validUntil: new Date(assessmentValidTo).getTime(),
  //     sections: sectionArr,
  //   };
  //   //create assessment with draft
  //   const createAssessmentDraftResp: any = await createAssessment(requestData);
  //   const errorCodes = [30203];
  //   if (createAssessmentDraftResp.statusCode === 30201) {
  //     setErrorMsg('Not enough questions for the selected category!');
  //     setToggle2(true);
  //   } else if (createAssessmentDraftResp.statusCode === 30202) {
  //     setErrorMsg('Minimum time required for all the categories added exceeds the total assessment time!')
  //     setToggle2(true);
  //   } else if (createAssessmentDraftResp.statusCode === 2) {
  //     setErrorMsg('category set fields are empty!')
  //     setToggle2(true);
  //   } else if (errorCodes.includes(createAssessmentDraftResp?.statusCode)) {
  //     setErrorMsg(createAssessmentDraftResp?.error)
  //     setToggle2(true);
  //   }
  //   setLoading(false);
  //   if (createAssessmentDraftResp.status === 200) {
  //     navigate('/assessments');
  //   }
  // };
  // const durationValueOptions = [
  //   { label: 'Minutes', value: 'minutes' },
  //   // { label: 'Seconds', value: 'seconds' },
  // ];
  const proficiencyValueOptions = [
    { label: 'Select Proficiency', value: '' },
    { label: 'Beginner', value: 'beginner' },
    { label: 'Intermediate', value: 'intermediate' },
    { label: 'Expert', value: 'expert' },
  ];
  const handleAddTech = i => {
    setTech([...tech, { set: i, technology: '' }]); // nont in use
    const categorySetTemp = categorySet;
    categorySetTemp[i].technology.push({
      set: '',
      technology: '',
      mandatorySelectedQuesArr: [],
      mandatorySelectedQues: [],
      maxTime: 0,
      minTime: 0,
    });
    setCategorySet(categorySetTemp);
    // setCategorySet(prevState => {
    //   const newCategorySet = [...prevState];
    //   newCategorySet[i].technology = [
    //     ...newCategorySet[i].technology,
    //     { set: '', technology: '' },
    //   ];
    //   return newCategorySet;
    // });
  };
  const getTimeFormated = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes} m ${seconds} s`;
  };
  const setTotalMinMaxTime = (categorySetData) => {
    let minTemp = 0;
    let maxTemp = 0;
    let subcategoryMin = -1;
    let subcategoryMax = 0;
    categorySetData?.map(cs => {

      cs?.categories.map(ct => {
        subcategoryMin = (ct.minTime < subcategoryMin) ? ct.minTime : subcategoryMin !== -1 ? subcategoryMin : ct.minTime;
        subcategoryMax = ct.maxTime > subcategoryMax ? ct.maxTime : subcategoryMax;
      });
      minTemp += subcategoryMin;
      maxTemp += subcategoryMax;
    });

    setMinTimeRequired(getTimeFormated(minTemp));
    setMaxTimeRequired(getTimeFormated(maxTemp));
  }
  const handleTechChange = async (e, c, i) => {
    const { name, value } = e.target;
    const allTechSelected = tech.map(te => te.technology);
    const isAlreadySelected = allTechSelected.some(at => at === value);
    if (isAlreadySelected) {
      setErrorMsg('Category already selected.Please select other category.');
      setToggle2(true);
      return;
    }

    const onchangeVal = [...tech];
    onchangeVal[i][name] = value;
    setTech(onchangeVal);

    const categorySetTemp = categorySet;
    // console.log('categorySet---', categorySet);
    // categorySetTemp[c].technology.push({ set: '', technology: value });
    categorySetTemp[c].technology[i] = {
      set: c,
      technology: value,
      mandatorySelectedQuesArr: [],
      mandatorySelectedQues: [],
      maxTime: 0,
      minTime: 0,
    };
    setCategorySet(categorySetTemp);
    // if no mandatory question selected && proficiency and technology is set
    const quesData = [
      {
        questionCount: categorySetTemp[c].questionCount,
        proficiency: categorySetTemp[c].proficiency,
        categories: [
          {
            categoryId: categorySetTemp[c].technology[i].technology,
            mandatoryQuestions: [],
          },
        ],
      },
    ];
    if (quesData[0]?.questionCount !== '' && quesData[0]?.proficiency !== '') {
      const finalCtegorySetForMinMaxAPI = categorySetTemp.map((cat: any) => {
        cat.categories = cat?.technology?.map((tech: any) => {
          tech.categoryId = tech.technology;
          tech.mandatoryQuestions = tech.mandatorySelectedQuesArr;
          // delete tech.technology;
          // delete tech.set;
          return tech;
        });
        return cat;
      });
      // const quesMinMaxTimeData = await getQuestionsMinMaxTimeEstimate(quesData);
      const quesMinMaxTimeData = await getQuestionsMinMaxTimeEstimate(finalCtegorySetForMinMaxAPI);
      setTotalMinMaxTime(quesMinMaxTimeData);
    }

  };
  const handleTechDelete = (c, i) => {
    const deleteVal = [...tech];
    deleteVal.splice(i, 1);
    setTech(deleteVal);

    const categorySetTemp = categorySet;
    categorySetTemp[c].technology.splice(i, 1);
    setCategorySet(categorySetTemp);
  };
  const handleAddCategorySet = () => {
    setCategorySet([
      ...categorySet,
      {
        questionCount: '',
        proficiency: '',
        technology: [
          {
            set: '',
            technology: '',
            mandatorySelectedQuesArr: [],
            mandatorySelectedQues: [],
            maxTime: 0,
            minTime: 0,
          },
        ],
      },
    ]);
    toggleAccordion(categorySet.length);
  };
  const handleCategorySetChange = async (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...categorySet];
    onchangeVal[i][name] = value;
    const techs = onchangeVal[i].technology.map((tech) => {
      tech.mandatorySelectedQuesArr = [];
      tech.mandatorySelectedQues = [];
      return tech;
    });
    onchangeVal[i].technology = techs;
    // console.log(JSON.stringify(onchangeVal));
    setCategorySet(onchangeVal);
    // ask tushar to change min/max response to give a sum of min/max
    if (onchangeVal[i].technology[0].technology !== "") {
      const finalCtegorySetForMinMaxAPI = onchangeVal.map((cat: any) => {
        cat.categories = cat.technology.map((tech: any) => {
          tech.categoryId = tech.technology;
          tech.mandatoryQuestions = [];
          // delete tech.technology;
          // delete tech.set;
          return tech;
        });
        return cat;
      });
      const quesMinMaxTimeData2 = await getQuestionsMinMaxTimeEstimate(finalCtegorySetForMinMaxAPI);
      if (Array.isArray(quesMinMaxTimeData2)) {
        setTotalMinMaxTime(quesMinMaxTimeData2);
      }
    }

  };
  const handleCategorySetDelete = i => {
    const deleteVal = [...categorySet];
    deleteVal.splice(i, 1);
    setCategorySet(deleteVal);
    // toggleAccordion(categorySet.length);
  };
  const addMandatoryQues = (c, i) => {
    const { technology, mandatorySelectedQuesArr } =
      categorySet[c].technology[i];
    const { proficiency, questionCount } = categorySet[c];
    // setMandatoryQuestionCategoryId(technology);
    setMandatoryQuestionData({
      technology,
      proficiency,
      questionCount,
      categorySequence: c,
      techSequence: i,
      mandatorySelectedQuesArr,
    });
    toggle();
  };

  const [selected, setSelected] = useState<number | null>(0);
  const toggleAccordion = i => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  const getCategoryName = v => {
    const cat = allCategories?.find(ac => {
      if (ac.value === v) {
        return ac.label;
      }
    });
    return cat?.label;
  };

  const addQuestionsToCategoryTech = (quesMinMaxTimeData, questionSelected) => {
    const { categorySequence, techSequence } = mandatoryQuestionData;
    // categorySet[categorySequence].technology[techSequence].categories = questionData;
    categorySet[categorySequence].technology[
      techSequence
    ].mandatorySelectedQues = questionSelected;
    categorySet[categorySequence].technology[
      techSequence
    ].mandatorySelectedQuesArr = questionSelected.map(qArr => qArr.questionId);
    categorySet[categorySequence].technology[techSequence].maxTime =
      quesMinMaxTimeData[0].categories[0]?.maxTime;
    categorySet[categorySequence].technology[techSequence].minTime =
      quesMinMaxTimeData[0].categories[0]?.minTime;
    // console.log('category id', categoryId);

    let minTemp = 0;
    let maxTemp = 0;
    categorySet.map(cs => {
      cs?.technology.map(ct => {
        minTemp += ct.minTime;
        maxTemp += ct.maxTime;
      });
    });
    // setMinTimeRequired(Math.ceil(minTemp / 60));
    // setMaxTimeRequired(Math.floor(maxTemp / 60));
    setMinTimeRequired(getTimeFormated(minTemp));
    setMaxTimeRequired(getTimeFormated(maxTemp));
    toggle();
  };
  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            {/* <Breadcrumb url="/assessments" text="Assessment List" /> */}
            <form noValidate={true} onSubmit={onSubmit}>
              <SearchWrap>
                <h5 className="assessmentTitle"> Create Assessment</h5>
                <div>
                  {/* <Button
                    type="button"
                    content="Save as Draft"
                    bgType="secondary"
                    className="btnSaveDraft"
                    onClick={() => saveDraft(onSubmit)}

                  /> */}
                  <Button
                    className="btnCancel"
                    type="button"
                    content="Cancel"
                    onClick={() => navigate('/assessments')}
                    leftIcon={isLoading === true ? buttonLoader : ''}
                    disabled={isLoading === true ? true : false}
                  />
                  <Button type="submit"
                    className="mainsavebutton"
                    content="Save"
                    leftIcon={isLoading === true ? buttonLoader : ''}
                    disabled={isLoading === true ? true : false}
                  />
                </div>
              </SearchWrap>
              {/* <form noValidate={true} onSubmit={onSubmit}> */}
              <ContentDiv>
                <ContentSubDiv>
                  <h1>Assessment Detail</h1>
                  <div className='base-wrapper-category'>
                    <MinmaxRequired>
                      <p>Min Time : {minTime}</p>
                      <p>Max Time : {maxTime}</p>
                    </MinmaxRequired>
                    {/* {errors?.title?.type === 'required' */}
                    {Object.keys(errors).length > 0 ? (
                      <ErrorDiv>Please enter required field</ErrorDiv>
                    ) : (
                      ''
                    )}

                    <div className="test-name-wrapper first-wrapper">
                      <label className="name-label">Test Name</label>
                      <Input
                        className="assessment-title-input"
                        type={'text'}
                        id={'title'}
                        placeholder="Title*"
                        {...register('title', {
                          required: true,
                        })}
                        onChange={handleTitleChange}
                        required
                      />
                    </div>
                    <AntiCheatWrapper className="test-name-wrapper">
                      <label className="name-label">Enable Anti-cheat AI</label>
                      <SwitchComponent
                        className="btntoggle"
                        id="assessment-ai-toogle"
                        checked={isAntiCheatEnabled}
                        handleSwitch={checked => onHandleChange(checked)}
                      // disabled={row.status === 'deleted' ? true : false}
                      />
                    </AntiCheatWrapper>
                    <AssessmentDetailWrapper className="validity-duration-wrapper">
                      <div className="test-name-wrapper duration-wrapper">
                        <label className="name-label">Duration</label>
                        <Input
                          type={'text'}
                          className="assessment-title-duration"
                          id={'duration'}
                          placeholder="Duration(Min)*"
                          {...register('duration', {
                            required: true,
                          })}
                          onChange={handleDurationChange}
                          required
                        />
                      </div>
                      <div className="test-name-wrapper percentage-wrapper">
                        <label className="name-label">Pass %</label>
                        <Input
                          type={'text'}
                          className="percentage"
                          id={'percentage'}
                          placeholder="Pass % *"
                          {...register('percentage', {
                            required: true,
                          })}
                          onChange={handleScoreChange}
                          required
                        />
                      </div>
                      <div className="test-name-wrapper">
                        <label className="name-label">Validity From</label>
                        <Input
                          type={'datetime-local'}
                          className="date-pick"
                          id={'validFrom'}
                          placeholder="Valid From"
                          min={new Date().toISOString().slice(0, 16)}
                          {...register('validFrom', {
                            required: true,
                          })}
                          onChange={handleValidFromChange}
                        />
                      </div>

                      <div className="test-name-wrapper">
                        <label className="name-label">Validity To</label>
                        <Input
                          type={'datetime-local'}
                          className="date-pick"
                          id={'validTo'}
                          placeholder="Valid To"
                          min={new Date().toISOString().slice(0, 16)}
                          {...register('validTo', {
                            required: true,
                          })}
                          onChange={handleValidToChange}
                        />
                      </div>
                    </AssessmentDetailWrapper >
                  </div >
                </ContentSubDiv >
                {
                  categorySet.map((val, c) => (
                    <><CategorySet>
                      <div>
                        <CategoryWrap style={{ display: 'block' }}>
                          {selected !== c ? (
                            <CollpasedWrap style={{ display: 'flex', flexDirection: 'row', flexBasis: '100%', background: '#fff', padding: '20px', flexWrap: 'wrap' }}>
                              <h1 className='category-set' style={{ backgroundImage: `url(${DownArrow})` }} onClick={() => toggleAccordion(c)}>Category Set {`${c + 1}`}</h1>

                              <div className='prof-level'>
                                <div><b>Proficiency</b>{' '}</div>
                                <ProficienyDiv>{val?.proficiency}</ProficienyDiv>
                              </div>
                              {/* <div style={{ display: 'flex', flexDirection: 'column', fontSize: '16px' }}>
          <div>Total Time </div> <TotalTimeDiv>10 min</TotalTimeDiv>
        </div> */}
                              <div style={{ display: 'flex', flexDirection: 'column', fontSize: '16px' }}>
                                <div style={{ marginBottom: '20px' }}><b>Category Name </b></div>
                                <CategoriesName>
                                  {val?.technology.map(tech => (
                                    <CategorySpan style={{ padding: '10px' }}>
                                      {getCategoryName(tech.technology)}
                                    </CategorySpan>
                                  ))}
                                </CategoriesName>
                              </div>
                            </CollpasedWrap>
                          ) : (
                            ''
                          )}
                          <CategorySetInner>
                            {selected === c ? <h1 className='category-set' style={{ backgroundImage: `url(${DownArrow})` }} onClick={() => toggleAccordion(c)}>Category Set {`${c + 1}`}</h1> : ''}

                            {/* <span>{selected === c ? '^' : '⌄'}</span> */}
                            <CategorySectionWrapper
                              className={selected === c ? 'show' : ''}
                            >
                              <QuestionDetails>
                                <Input
                                  name="questionCount"
                                  type={'number'}
                                  id={'questionCount'}
                                  placeholder="Question Count"
                                  value={val?.questionCount || ''}
                                  onChange={e => handleCategorySetChange(e, c)}
                                  required />
                              </QuestionDetails>
                              <ProficiencySelect>
                                <Select
                                  name="proficiency"
                                  id="proficiency"
                                  aria-label="Proficiency"
                                  className="select-drop"
                                  value={val?.proficiency || ''}
                                  // label={'experience'}
                                  options={proficiencyValueOptions}
                                  onChange={e => handleCategorySetChange(e, c)} />
                              </ProficiencySelect>


                            </CategorySectionWrapper>
                          </CategorySetInner>
                        </CategoryWrap>
                      </div>
                      <TechnologyDetailsWrap className={selected === c ? 'show' : ''}>
                        <TechnologyDetails className="category-set-wrapper">
                          {/* {tech.map((t, i) => ( */}
                          {categorySet &&
                            val?.technology?.map((te, i) => (
                              <TechnologyWrapper>
                                {i > 0 && <span className="or-button"> OR </span>}
                                <div className='select-wrapper'>
                                  <Select
                                    name="technology"
                                    aria-label="Technology"
                                    className="select-drop"
                                    value={te?.technology}
                                    // label={'experience'}
                                    options={allCategories}
                                    // {...register('technology', {
                                    //   required: true,
                                    // })}
                                    onChange={e => handleTechChange(e, c, i)} />
                                  <div className='frameflex'>
                                    {categorySet[c].technology[i].technology !== '' &&
                                      categorySet[c].questionCount !== '' &&
                                      val?.proficiency && categorySet[c].technology[i]
                                        ?.mandatorySelectedQues.length >= 0 ? <span className='circle-frame'>{categorySet[c].technology[i]
                                          ?.mandatorySelectedQues.length}</span> : ''}

                                    {categorySet &&
                                      categorySet[c].questionCount !== '' &&
                                      categorySet[c].technology[i].technology !== '' &&
                                      val?.proficiency ? (
                                      <Button
                                        // {categorySet && val?.proficiency && val.technology.length > 0 ? <Button
                                        type="button"
                                        content="Add / View mandatory questions"
                                        className='btnAddMandatory'
                                        bgColor="#fdb82c"
                                        onClick={() => addMandatoryQues(c, i)} />
                                    ) : (
                                      ''
                                    )}
                                  </div>

                                </div>

                                {val.technology.length > 1 ? (
                                  <Button
                                    type="button"
                                    content="-"
                                    className='btnDelete'
                                    bgColor="#fdb82c"
                                    onClick={() => handleTechDelete(c, i)}
                                    style={{ marginBottom: '12px' }} />
                                ) : (
                                  ''
                                )}
                                {/* commenting table for now */}
                                {/* {categorySet &&
                  categorySet[c].technology[i].technology !== '' &&
                  val?.proficiency ? (
                  <MandatoryQuesDiv>
                    <table>
                      {categorySet[c].technology[i]
                        ?.mandatorySelectedQues.length > 0 ? (
                        <>
                          <tr>
                            <th>
                              Selected Mandatory questions for
                              Technology
                            </th>
                            <th>Time required</th>
                          </tr>
                          {categorySet[c].technology[
                            i
                          ]?.mandatorySelectedQues.map(
                            (mq: any) => (
                              <tr>
                                <td>{mq.text}</td>
                                <td>{mq.time} Min</td>
                              </tr>
                            ),
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </table>
                  </MandatoryQuesDiv>
                ) : (
                  ''
                )} */}
                              </TechnologyWrapper>
                            ))}
                          {val.technology[0].technology !== "" && val.technology.length > 0 && <Button
                            type="button"
                            content="+"
                            bgColor="#fdb82c"
                            className="btnAdd"
                            onClick={() => handleAddTech(c)} />}


                        </TechnologyDetails>

                      </TechnologyDetailsWrap>

                    </CategorySet><DeleteBtn>
                        {categorySet.length > 1 ? (
                          <Button
                            type="button"
                            content="Delete"
                            bgColor="#ADADAD"
                            onClick={() => handleCategorySetDelete(c)} />
                        ) : (
                          ''
                        )}
                      </DeleteBtn></>
                  ))

                }
                {categorySet[(categorySet.length - 1)].proficiency !== "" && categorySet[(categorySet.length - 1)].questionCount !== "" && categorySet.length > 0 && < Button
                  type="button"
                  content="Add Technology Set"
                  bgColor="#fdb82c"
                  className="btnAddTechnology"
                  onClick={handleAddCategorySet}
                />}

                {/* </InpWrapper> */}
              </ContentDiv >
            </form >
          </BodyDiv >
        </RightContainerDiv >
      </ContentDivWrapper >
      <ModalContainer>
        <Modal
          isShown={isShown}
          // width="fit-content"
          hide={toggle}
          isShowCloseButton={true}
          modalContent={
            <AddQuestion
              changeModalVisibility={toggle}
              isShowModal={isShown}
              mandatoryQuestionData={mandatoryQuestionData}
              addQuestionsToCategoryTech={addQuestionsToCategoryTech}
            />
          }
        />
      </ModalContainer>
      <ModalContainer>
        <Modal
          isShown={isShown2}
          isShowCloseButton={true}
          hide={() => setToggle2(false)}
          modalContent={responseContent}
        />
      </ModalContainer>
    </>
  );
};