import styled from 'styled-components';

export const HomeHeader = styled.div<any>`
  background-color: #fdb82c;
  padding: 20px;
`;
export const HomeHeaderSub = styled.div<any>`
  background-color: #343434;
  padding: 5% 9%;
  font-size: 40px;
  color: #ffffff;
  text-align: center;
`;

export const MenuDiv = styled.div<any>`
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  background-image: url(../images/hum.png);
  background-size: 30px;
  background-color: #000000;
  padding: 5px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  :before {
    top: -7px;
  }
  :after {
    bottom: -7px;
  }
`;

export const HomeHeaderSubDiv = styled.div<any>`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LogoImg = styled.img<any>`
  margin-left: 20px;
`;

export const MainList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export const MainLi = styled.li`
  float: left;
  a {
    display: block;
    color: #000000;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    padding: 14px 16px;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;
export const DivSlider = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 50px;
`;
export const SlidesDiv = styled.div`
  display: flex;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  text-align: left;
  h1,
  h2 {
    color: #000000;
    font-size: 60px;
    text-align: center;
  }
`;
export const Slides = styled.div`
  flex: 0 0 100%;
  width: 100%;
  text-align: left;
  font-size: 18px;

  button {
    background-color: #fdb82c;
    padding: 10px 40px;
    border: solid 2px #000;
    color: #000000;
    font-size: 24px;
    margin-top: 30px;
    border-radius: 50px;
    cursor: pointer;
    width: 220px;
    height: 70px;
    z-index: 99;
    position: relative;
    :hover {
      background: #000000 !important;
      color: #ffffff !important;
    }

    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    :nth-of-type(2) {
    }
  }

  button:hover {
    background-color: #fdb82c;
    border: 2px solid #000000;
    color: #000000;
    font-size: 24px;
  }

  .or-name {
    font-size: 20px;
    font-weight: bold;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 40px;
  }
`;

export const SlidePara = styled.p`
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  width: 100%;
`;
export const ButtonWrapper = styled.div`
  text-align: center;
  display: flex;
  div {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  span {
    position: absolute;
    background: #000;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    width: 50px;
    height: 50px;
    top: 36%;
    left: 50%;
  }
`;
export const MainContentDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 50px 20px;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const MainContentSubDiv = styled.div`
  padding: 20px;
  box-shadow: 0px 0px 15px 0px #0000000d;
  border: 1px solid #dbdbdb;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: calc(20% - 20px);
  /* Adjust the width calculation to account for the 20px space */
  margin-right: 20px;
  :last-child {
    margin-right: 0;
  }
  h3 {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }
  h4 {
    font-size: 20px;
    margin-top: 10px;
  }
  h5 {
    font-size: 17px;
    margin-top: 5px;
  }
  @media (max-width: 768px) {
    padding: 20px;
    width: 90%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;
export const ImgAnimation = styled.img<any>`
  animation: heart-pump 2s infinite;
`;
export const ClearDiv = styled.div`
  clear: both;
`;
export const WrapperDiv = styled.div`
  position: relative;
  background-color: #fdb82c;
  padding: 60px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const TechWrapDiv = styled.div`
  position: relative;
  padding: 16px;
  width: 25%;
  text-align: center;
  @media (max-width: 1000px) {
    width: 250px;
    height: 250px;
    background-image: url(../images/varma.png);
    background-size: 176px;
    background-repeat: no-repeat;
    margin: 40px auto;
    border-radius: 200px;
    border: 10px solid grey;
    background-position: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    animation: heartPump 1.5s ease-in-out infinite;
    img {
      display: none;
    }
  }
  img {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 33px;
    width: 500px;
    left: -89px;
  }
`;

export const MainFooterDiv = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;
  gap: 20px;
  align-items: flex-start;
  background-color: #000000;
  color: #ffffff;
  padding: 60px;
  line-height: 29px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MainFooterDivContent = styled.div`
  width: auto;
`;

export const CommWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const CommWrapSubDiv = styled.div`
  display: flex;
  align-items: self-start;
  h1 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
    margin-top: 15px;
  }
`;
export const CommWrapSubFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  @keyframes heartPump {
    margin-left: 0px;
  }
`;

export const CultDiv = styled.div`
  display: flex;
  align-items: self-start;
  margin-top: 50px;
  h1 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
    margin-top: 15px;
  }
`;
export const CultFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  @keyframes heartPump {
    margin-left: 0px;
  }
`;
export const FooterContentDiv = styled.div`
  padding: 10px;
  text-align: left;
  p {
    width: 80%;
  }
`;

export const PhoneContainerSpan = styled.span`
  font-size: 13px;
  text-decoration: underline;
  margin-top: 40px;
  display: block;
`;
export const EmailContainerSpan = styled.span`
  font-size: 13px;
  text-decoration: underline;
  margin-top: 10px;
  display: block;
`;
export const FooterContent3Div = styled.div`
  padding: 10px;
  text-align: left;
  ul li {
    list-style: none;
    cursor: pointer;
    font-size: 14px;
  }

  ul li a {
    color: #ffffff;
  }
  span {
    font-size: 13px;
    margin-top: 40px;
    display: block;
  }
`;

export const SliderButtonWrap = styled.div`
  margin-top: 40px;
  button.prev-btn {
    background-image: url(../images/left-slide-menu.png);
    width: 37px;
    height: 37px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: contain;
    background-color: transparent;
    border: none;
  }

  button.next-btn {
    background-image: url(../images/right-slide-menu.png);
    width: 37px;
    height: 37px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: contain;
    background-color: transparent;
    border: none;
  }
`;
export const NewBanner = styled.div`
  display: flex;
  postion: relative;
  width: 100%;
  .thumb-arrow {
    position: absolute;
    bottom: 28%;
    width: 100%;
    z-index: 9;
  }
`;
export const BannerLeft = styled.div`
  width: 50%;
  display: flex;
  z-index: 99;
`;
export const BannerRight = styled.div`
  width: 50%;
  display: flex;
  z-index: 99;
`;
