import styled from 'styled-components';

export const MainConatiner = styled.div`
  border: 3px solid;
  width: 100vh;

  height: 1800px;
  padding: 50px;
  position: relative;
  left: 23%;

  @media (max-width: 1130px) {
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 2.9%;
    left: 0.1%;
  }
  /* background: url(image.png); */
`;
export const Header = styled.h1`
  /* font-family: Normal; */
  font-weight: normal;
  font-size: 50px;
  color: #3d3d3d;
`;
export const UxDesigner = styled.p`
  /* font-family: Normal; */
  font-weight: normal;
  color: #3d3d3d;
  font-size: 30px;
`;
export const Phone = styled.p`
  /* font-family: Normal; */
  font-weight: normal;
  padding-top: 4%;
  font-size: 20px;
  color: #3d3d3d;
`;
export const Linkdin = styled.p`
  position: absolute;
  left: 35%;
  color: #3d3d3d;
  /* font-family: Normal; */
  font-weight: normal;
  bottom: 86%;
  font-size: 20px;
  @media (max-width: 1130px) {
    width: 98%;
    position: relative;
    top: 3.5%;
    left: 0.1%;
  }
`;
export const Mail = styled.p`
  /* font-family: Normal; */
  font-size: 20px;
  color: #3d3d3d;
  font-weight: normal;
  @media (max-width: 1130px) {
    width: 98%;
    position: relative;
    bottom: 1%;
    left: 0.1%;
  }
`;
export const Objective = styled.p`
  /* border: 3px solid; */
  padding-top: 30px;
  font-size: 20px;
  /* font-family: Normal; */
  font-weight: normal;
  @media (max-width: 1130px) {
    width: 90%;
    margin-bottom: 20px;
  }
`;
export const ExpObjective = styled.p`
  /* border: 3px solid; */
  /* padding-top: 30px; */
  font-size: 20px;
  position: relative;

  width: 84%;
  left: 23%;
  /* font-family: Normal; */
  font-weight: normal;
  @media (max-width: 1130px) {
    width: 90%;
    margin-bottom: 20px;
  }
`;
export const Experiance = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  color: #3d3d3d;
  font-size: 25px;
  padding-top: 25px;
`;
export const FirstDate = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;

  font-size: 21px;
  padding-top: 20px;
`;
export const FirstSubHeading = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  font-size: 30px;
  position: relative;
  color: #3d3d3d;
  left: 23%;
  bottom: 2.5%;
  @media (max-width: 1130px) {
    width: 98%;
    position: relative;
    bottom: 4%;
    left: 0%;
  }
`;
export const SecondSubHeading = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  color: #3d3d3d;
  font-size: 23px;
  position: relative;
  left: 23%;
  bottom: 3.5%;
  @media (max-width: 1130px) {
    width: 98%;
    position: relative;
    bottom: 3%;
    left: 0.1%;
  }
`;
export const EducationFirstSubHeading = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  color: #3d3d3d;
  font-size: 23px;
  position: relative;
  left: 23%;
  bottom: 5.2%;
  @media (max-width: 1130px) {
    width: 95%;
    position: relative;
    bottom: 30px;
  }
`;
export const EducationSecondSubHeading = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  color: #3d3d3d;
  font-size: 23px;
  position: relative;
  left: 23%;
  bottom: 5.2%;
  @media (max-width: 1130px) {
    width: 95%;
    position: relative;
    bottom: 35px;
  }
`;
export const SecondDate = styled.h2`
  /* font-family: Normal; */
  font-size: 21px;
  font-weight: normal;
  position: relative;
  bottom: 1%;
  padding-top: 18px;
  @media (max-width: 1130px) {
    width: 98%;
    position: relative;
    top: 1%;
    left: 0.1%;
  }
`;
export const ThirdDate = styled.h2`
  /* font-family: Normal; */
  font-size: 21px;
  font-weight: normal;
  position: relative;
  bottom: 3%;
  padding-top: 18px;
`;
export const FourthDate = styled.h2`
  /* font-family: Normal; */
  font-size: 21px;
  font-weight: normal;
  position: relative;
  bottom: 3%;
  padding-top: 18px;
`;

export const FirstPara = styled.p`
  /* border: 3px solid; */
  width: 80%;
  position: relative;
  left: 22.5%;
  padding-bottom: 19px;
  font-size: 20px;

  bottom: 1%;
  @media (max-width: 1130px) {
    width: 98%;
    position: relative;
    bottom: 2.9%;
    left: 0.1%;
  }
`;
export const CountryTag = styled.p`
  position: relative;
  left: 22.5%;
  bottom: 0.2%;
  color: #3d3d3d;
  padding-bottom: 3px;
  @media (max-width: 1130px) {
    width: 98%;
    position: relative;
    bottom: 2.9%;
    left: 0.1%;
  }
`;
export const Profecciency = styled.p`
  position: relative;
  left: 22.5%;
  bottom: 2%;
  color: #3d3d3d;

  padding-bottom: 3px;
`;
export const SecondCountryTag = styled.p`
  position: relative;
  left: 23%;
  bottom: 55px;
  color: #3d3d3d;
  padding-bottom: 3px;
  @media (max-width: 1130px) {
    width: 98%;
    position: relative;
    bottom: 1%;
    left: 0.1%;
  }
`;
export const SecondPara = styled.p`
  /* border: 3px solid; */
  width: 78%;
  position: relative;
  left: 22.5%;
  font-size: 20px;
  bottom: 3%;
  padding-bottom: 19px;
  @media (max-width: 1130px) {
    width: 98%;
    position: relative;
    bottom: 0.5%;
    left: 0.1%;
  }
`;
export const Progress = styled.progress`
  accent-color: black;
  position: relative;
  left: 88.8%;
  top: 1%;
  width: 100px;
  @media (max-width: 1130px) {
    width: 70%;
    position: relative;
    bottom: 8px;
    left: 29%;
  }
`;
export const SecondProgress = styled.progress`
  accent-color: black;
  position: relative;
  left: 77.2%;
  top: 2.8%;
  width: 100px;

  @media (max-width: 1130px) {
    width: 70%;
    position: relative;
    bottom: 8px;
    left: 29%;
  }
`;
export const ThirdProgress = styled.progress`
  accent-color: black;
  position: relative;
  left: 65.6%;
  top: 4.7%;
  width: 100px;

  @media (max-width: 1130px) {
    width: 70%;
    position: relative;
    bottom: 8px;
    left: 29%;
  }
`;
export const FourthProgress = styled.progress`
  accent-color: black;
  position: relative;
  left: 53.8%;
  top: 6.5%;
  width: 100px;

  @media (max-width: 1130px) {
    width: 70%;
    position: relative;
    bottom: 8px;
    left: 29%;
  }
`;
export const FifthProgress = styled.progress`
  accent-color: black;
  position: relative;
  left: 42%;
  top: 8%;
  width: 100px;

  @media (max-width: 1130px) {
    width: 70%;
    position: relative;
    bottom: 8px;
    right: 4%;
  }
`;
export const Education = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  position: relative;
  bottom: 3%;
  color: #3d3d3d;
  font-size: 25px;
  padding-top: 25px;
`;
export const EducationSubHeading = styled.p`
  /* font-family: Normal; */
  font-weight: normal;
  color: #3d3d3d;
  font-size: 20px;
  position: relative;
  left: 22.5%;
  bottom: 85px;
  @media (max-width: 1130px) {
    width: 95%;
    position: relative;
    bottom: 30px;
  }
`;
export const Conference = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  position: relative;
  bottom: 3%;
  font-size: 25px;
  padding-top: 25px;
  color: #3d3d3d;
`;
export const CertificationStudy = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  position: relative;
  bottom: 2.5%;
  left: 23%;
  font-size: 23px;
  padding-bottom: 5px;
  /* border: 5px solid; */
  color: #3d3d3d;
`;
export const CertificationCourse = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  position: relative;
  top: 4px;
  left: 23%;
  font-size: 23px;
  /* padding-top: 20px; */
  /* border: 5px solid; */
  color: #3d3d3d;
`;
export const CertificationDate = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  position: relative;
  bottom: 65px;
  left: 0.1%;
  font-size: 19px;
  /* padding-top: 20px; */
  /* border: 5px solid; */
  color: #3d3d3d;
`;
export const Skills = styled.h2`
  /* font-family: Normal; */
  font-weight: normal;
  color: #3d3d3d;
  position: relative;
  /* left: 20%; */
  font-size: 25px;
  padding-top: 5px;
`;

export const ConferenceData = styled.p`
  position: relative;
  font-weight: normal;
  bottom: 4%;
  font-size: 20px;
  padding-top: 20px;
  /* left: 22.5%;
  bottom: 3%;
  font-size: 20px;
  padding-bottom: 20px; */
  @media (max-width: 1130px) {
    width: 95%;
    position: relative;
    top: 10px;
  }
`;
export const SkillData = styled.p`
  position: relative;
  left: 23%;
  bottom: 2%;
  padding-bottom: 20px;
  font-size: 20px;
`;
export const SkillVersion = styled.p`
  position: relative;
  left: 45%;
  bottom: 6.6%;
  /* padding-bottom: 20px; */
  font-size: 20px;
`;
