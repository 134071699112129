import styled from 'styled-components';
export const Title = styled.h1`
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  text-transform: capitalize;
  color: #1e1e1e;
  background: #fdb82c;
  /* display: flex; */
  text-align: left;
  margin-bottom: 30px !important;
  padding: 13px 0px 13px 10px;
  text-align: center;
  font-weight: bold;
  /* border-radius: 10px; */
  margin-top: 20px;
`;

export const Title1 = styled.h1`
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #1e1e1e;
  background: #fdb82c;
  width: 100%;
  display: block;
  text-align: left;
  margin-bottom: 30px !important;
  padding: 13px;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
`;

export const Title2 = styled.h2`
  font-size: 32px;
  font-style: normal;
  line-height: 42px;
  font-weight: inherit;
  margin: 0;
`;

export const Title3 = styled.h3`
  font-size: 25px;
  font-style: normal;
  line-height: 31px;
  font-weight: inherit;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
`;

export const Title4 = styled.h4`
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const Title5 = styled.h5`
  margin: 0;
`;

export const Title6 = styled.h6`
  font-size: 14px;
  font-style: normal;
  line-height: 18.34px;
  font-weight: inherit;
  margin: 0;
`;
