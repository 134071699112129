/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ClearDiv,
  HomeHeader,
  HomeHeaderSubDiv,
  LogoImg,
  MainContentDiv,
  MainFooterDiv,
  MainFooterDivContent,
  MainLi,
  MainList,
  FooterContentDiv,
  // PhoneContainerSpan,
  EmailContainerSpan,
  FooterContent3Div,
} from './Styles/landingPageStyles';
import fairhireelogo from 'app/assets/images/logo-faihiree.png';

import { A } from 'app/components/UI/atoms/Anchor';
import footerLogo from 'app/assets/images/footer-logo.jpg';
import jsLandingImg from 'app/assets/images/js-landing.svg';
import { Modal } from 'app/components/UI/organisms/Modal/SimpleModal';
import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import { LoginModal } from 'app/components/pages/LoginModal';
import { getToken } from 'app/services/auth/auth';
import { ButtonGroupDiv, LineTextDiv } from './Styles/loginStyles';
import {
  HeadingH1,
  LeftSecDiv,
  ParaDiv,
  RightSecDiv,
} from './Styles/jobSeekerStyles';
import { SignUpModal } from '../SignUpModal';
import {
  Message,
  ModalContainer,
  ModelContent,
} from 'app/components/UI/Modal/MessageModelStyles';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';

export const JobSeekerLandingPage = () => {
  const { isShown, toggle } = useModal();
  let navigate = useNavigate();

  const token = getToken();
  if (typeof token !== 'undefined' && token !== null) {
    navigate('/jobs', { replace: false });
  }
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  // const toggle = () => setIsShown(!isShown);

  const [modalC, setModalContainer] = useState<any>();

  const changeModalVisibility = (nval = !isShowModal, isSuccess = false) => {
    if (nval === true || nval === false) {
      setIsShowModal(nval);
    } else {
      setIsShowModal(false);
    }

    if (isSuccess === true) {
      toggle();
    }
  };
  const modalAction = () => {
    setModalContainer(<LoginModal toggle={changeModalVisibility} role="js" />);
    changeModalVisibility(!isShowModal);
  };
  const modalSignUp = () => {
    setModalContainer(
      <SignUpModal
        changeModalVisibility={changeModalVisibility}
        role="js"
        isShowModal={isShowModal}
      />,
    );
    changeModalVisibility(!isShowModal);
  };
  const content = (
    <ModelContent>
      {/* <MailIcon>
        <img src={SuccessIcon} alt="mail-icon" width="80px" height="50px" />
      </MailIcon> */}
      <Message>
        <PrimaryParagraph>
          Sign Up successfull.Verification email is sent on you email address.
        </PrimaryParagraph>
      </Message>
    </ModelContent>
  );

  return (
    <>
      <HomeHeader>
        <HomeHeaderSubDiv>
          {' '}
          <LogoImg src={fairhireelogo} />
          {/* <MenuDiv className="hamburger-menu"></MenuDiv> */}
          <MainList>
            <MainLi>
              <A href="https://www.fairhiree.com/about/how-it-works">About</A>
            </MainLi>
            <MainLi>
              <A href="https://www.fairhiree.com/about/about-us">Company</A>
            </MainLi>
            <MainLi>
              <A href="https://www.fairhiree.com/about/vetting-process">
                Services
              </A>
            </MainLi>
            <MainLi>
              <A href="https://www.fairhiree.com/pricing">Pricing</A>
            </MainLi>
            <MainLi>
              <A href="https://www.fairhiree.com/about/contact-us">
                Contact Us
              </A>
            </MainLi>
          </MainList>
        </HomeHeaderSubDiv>
      </HomeHeader>
      <div className="clear"> </div>
      <MainContentDiv className="main-home-flex-content">
        <LeftSecDiv>
          <img src={jsLandingImg} alt="job seeker landing page"></img>
        </LeftSecDiv>
        <div
          className="Line39"
          style={{
            width: 405,
            height: 0,
            transform: 'rotate(90deg)',
            transformOrigin: '0 0',
            border: '0.50px #937B28 solid',
          }}
        ></div>
        <RightSecDiv>
          <HeadingH1 className="create-job-listing">
            Unlock Your Career Potential:
          </HeadingH1>
          <ParaDiv>
            Join Now and Explore Endless Opportunities - Your Path to Success
            Starts Here!
          </ParaDiv>
          <ButtonGroupDiv>
            <button className="job-listing-login-btn" onClick={modalAction}>
              Login
            </button>
            <LineTextDiv className="line-with-text">
              {/* <LineHr className="line" /> */}
              <span className="text">OR</span>
              {/* <LineHr className="line" /> */}
            </LineTextDiv>
            <button className="job-listing-sign-btn1" onClick={modalSignUp}>
              {' '}
              Sign Up
            </button>
          </ButtonGroupDiv>
        </RightSecDiv>
      </MainContentDiv>
      <ClearDiv className="clear"> </ClearDiv>
      <ClearDiv className="clear"> </ClearDiv>
      <MainFooterDiv className="main-home-footer">
        <MainFooterDivContent className="main-home-footer-content1">
          <img src={footerLogo} alt="Footer Logo" />
        </MainFooterDivContent>

        <FooterContentDiv className="main-home-footer-content2">
          <p>
            Fairhiree, Dollars Colony, JP Nagar 4th Phase, Bengaluru, Karnataka
            560076
          </p>

          <EmailContainerSpan className="email-footer">
            care@fairhiree.com
          </EmailContainerSpan>
        </FooterContentDiv>

        <FooterContent3Div className="main-home-footer-content3">
          <ul>
            <li>
              <a href="https://www.fairhiree.com">About</a>
            </li>
            <li>
              <a href="https://www.fairhiree.com/contact-us">Contact Us</a>
            </li>
          </ul>
          <span className="copyright-footer">
            © 2023 Fairhiree. All rights reserved.{' '}
          </span>
        </FooterContent3Div>
        <FooterContent3Div className="main-home-footer-content4">
          <ul>
            <li>
              <a
                href="https://in.linkedin.com/company/fairhiree"
                target="_blank"
                rel="noreferrer"
              >
                Linkedin
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/fairhiree/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
          </ul>
        </FooterContent3Div>

        {/* <button className="footer-up-arrow"></button> */}
      </MainFooterDiv>

      <ModalContainer>
        <Modal
          isShown={isShowModal}
          hide={changeModalVisibility}
          isShowCloseButton={true}
          modalContent={modalC}
        />
      </ModalContainer>

      <ModalContainer>
        <Modal
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={true}
          modalContent={content}
        />
      </ModalContainer>
    </>
  );
};
