import Button from 'app/components/UI/atoms/Button';
import { Header } from 'app/components/UI/organisms/Header';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
import React, { useEffect, useState } from 'react';
import { BodyDiv, CatDiv, SearchInpDiv, SearchWrap } from './Styles';
import { RespondantsList } from './List';
import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import { Input } from 'app/components/UI/atoms/Input';
import { ModalContainer } from 'app/components/UI/Modal/MessageModelStyles';
import { Modal } from 'app/components/UI/Modal/SimpleModal';
import { Title } from 'app/components/UI/atoms/Titles';
// import { useNavigate } from 'react-router-dom';
import { getRespondantsByAssessmentId } from 'app/services/assessments';
import spinnerLoad from 'app/assets/images/loader.gif';
import { useNavigate, useParams } from 'react-router-dom';
import thinkingIcon from 'app/assets/images/thinking-face.svg';
import { OopsSpan } from 'app/components/pages/QuestionBank/questionsStyles';
// import Breadcrumb from 'app/components/UI/molecules/Breadcrumb';

export const Respondants = () => {
  const { assessmentId: assessId } = useParams();
  const { isShown, toggle } = useModal();
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [respondantsList, setRespondantsList] = useState<any>();
  const [firstLoadData, setFirstLoadData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const headers = [
    // { id: 'profilePic', name: 'Pic' },
    { id: 'name', name: 'Name' },
    { id: 'email', name: 'Email' },
    { id: 'Phone number', name: 'Phone' },
    { id: 'completionDate', name: 'Completion Date' },
    { id: 'score', name: 'Score' },
  ];

  const fetchAssessments = async () => {
    const { attendees } = await getRespondantsByAssessmentId(assessId);
    setRespondantsList(attendees);
    setFirstLoadData(attendees);
    setIsLoading(!isLoading);
  };

  useEffect(() => {
    fetchAssessments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = event => {
    const newObj = firstLoadData?.filter(fl => {
      return fl.name
        ? fl.name.toLowerCase().includes(event.target.value.toLowerCase())
        : fl.email.includes(event.target.value);
    });
    setRespondantsList(newObj);
  };

  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <SearchWrap>
              <CatDiv>
                <Title> Respondant List</Title>
                <Button
                  type="button"
                  content="Assessment List"
                  className="assessment-list"
                  bgColor="#fdb82c"
                  onClick={() => navigate('/assessments')}
                />
              </CatDiv>
              {/* <Button
                type="button"
                content="Back"
                className="backto-assessment"
                bgColor="#fdb82c"
                onClick={() => navigate('/assessments')}
              /> */}
            </SearchWrap>
            <SearchInpDiv>
              <Input
                id={'search'}
                type={'text'}
                label={'Search '}
                onChange={handleSearch}
                placeholder="search"
              />
              <Button type="button" content="" />
            </SearchInpDiv>
            {isLoading === true ? (
              <img
                src={spinnerLoad}
                alt=""
                width={100}
                height={100}
                style={{ marginTop: '20%', marginLeft: '40%' }}
              />
            ) : respondantsList && respondantsList.length > 0 ? (
              <>
                <RespondantsList headers={headers} rowData={respondantsList} />
              </>
            ) : (
              <>
                <img src={thinkingIcon} alt="thinking face" />
                <OopsSpan>Oops! There are no respondents yet.</OopsSpan>
              </>
            )}
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
      <ModalContainer>
        <Modal
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={false}
          modalContent={<div></div>}
        />
      </ModalContainer>
    </>
  );
};
