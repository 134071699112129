import styled from 'styled-components';

export const ErrorTextWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 5px;
  color: #eb3f3f;
`;
