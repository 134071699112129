/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoginMain } from './loginStyles';
import { getToken, getUserVerifiedByCode } from 'app/services/auth/auth';
import spinnerLoad from 'app/assets/images/loader.gif';

export const VerifyUser = () => {
  const token = getToken();
  let navigate = useNavigate();
  if (typeof token !== 'undefined' && token !== null) {
    navigate('/assessments', { replace: true });
  }

  const { code } = useParams<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getUserVerified = async () => {
    if (code) {
      const verifyResp = await getUserVerifiedByCode(code);
      console.log('verifyResp', JSON.stringify(verifyResp));
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUserVerified();
  }, []);

  return (
    <LoginMain>
      {isLoading === true ? (
        <img
          src={spinnerLoad}
          alt=""
          width={100}
          height={100}
          style={{ marginTop: '20%', marginLeft: '40%' }}
        />
      ) : (
        'else cases'
      )}
    </LoginMain>
  );
};
