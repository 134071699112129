import React, { useEffect, useState } from 'react';
import Button from 'app/components/UI/atoms/Button';
import { Header } from 'app/components/UI/organisms/Header';
import { ContentDivWrapper } from 'app/components/UI/organisms/Header/headerStyles';
import { LeftSideNav } from 'app/components/UI/organisms/LeftSideNav';
import { RightContainerDiv } from 'app/components/UI/organisms/RightContainerDiv';
// import { useParams } from 'react-router-dom';
// import { BodyDiv, CatDiv, SearchInpDiv, SearchWrap } from './jobListingStyles';
import { BodyDiv, SearchWrap } from './jobListingStyles';
import { JobListTable } from './jobsListTable';
// import { useModal } from 'app/components/UI/organisms/Modal/SimpleModal/userModal';
import { Input } from 'app/components/UI/atoms/Input';
// import { getAllJobs } from 'app/services/jobs';
// import { InputSearch } from 'app/components/UI/atoms/InputSearch';
import { SearchInpDiv } from '../Jobs/jobListingStyles';

export const SearchJobs = () => {
  const [jobsList, setJobsList] = useState<any>();
  const [jobsListFirstLoad, setJobsListFirstLoad] = useState<any>();
  const headers = [
    { id: 'company', name: 'Company' },
    { id: 'role', name: 'Role' },
    { id: 'experience', name: 'Experience' },
    { id: 'location', name: 'Location' },
    { id: 'salary', name: 'Salary' },
    { id: 'worktype', name: 'Work Type' },
    { id: 'workmode', name: 'Work Mode' },
    { id: '', name: '' },
    // { id: '', name: '' },
  ];
  let jobsData: any = [];
  const fetchJobs = async () => {
    // jobsData = await getAllJobs();
    jobsData = [
      {
        company: 'Wipro technologies',
        company_id: '70bf3a24-0b6a-4470-8737-55ab068e5097',
        experience: '3 Years',
        salary: '200,000',
        created_by: 'ce7877e2-0964-46c3-adba-36f4db794619',
        date_updated: 1689069251731,
        status: 'ACTIVE',
        date_created: 1689069251731,
        job_id: 'e0538c9b-e5ca-4383-8e29-28fbf8f18c3c',
        workType: 'full-time',
        skills_required: ['Selenium', 'Manual Testing'],
        locations: ['Bengaluru', 'Hyderabad'],
        notice_period: '15 Days or less',
        workMode: 'Hybrid',
        description:
          "<h2>AutomatedTestingFrameworkDevelopment</h2><p>AsanSDET,youwillberesponsiblefordesigning,developing,andmaintaininganautomatedtestingframeworkforoursoftwareproducts.Youwillcollaboratewiththedevelopmentteamtounderstandsoftwarerequirementsanddevelopcomprehensivetestplansandtestcases.Yourprimaryfocuswillbeonbuildingrobustandscalableautomationframeworksusingindustry-standardtoolsandtechnologies.Youwillalsoberesponsibleforidentifyingandreportingdefects,conductingrootcauseanalysis,andensuringhigh-qualitysoftwarereleases.Additionally,youwillcontributetothecontinuousimprovementofourtestingprocessesandpractices.</p><h3>Requirements:</h3><ul><li>Bachelor'sdegreeinComputerScience,Engineering,orrelatedfield</li><li>StrongexperienceindevelopingandmaintainingautomationframeworksusingtoolssuchasSelenium,Appium,orsimilar</li><li>ProficiencyinprogramminglanguagesuchasJava,Python,orJavaScript</li><li>Solidunderstandingofsoftwaretestingprinciples,methodologies,andbestpractices</li><li>Experienceincreatingandexecutingtestplans,testcases,andtestscripts</li><li>Knowledgeofsoftwaredevelopmentlifecycle(SDLC)andAgilemethodologies</li><li>Familiaritywithversioncontrolsystems(e.g.,Git)</li><li>Excellentproblem-solvinganddebuggingskills</li><li>Strongcommunicationandcollaborationskillstoworkeffectivelywithcross-functionalteams</li><li>Abilitytoadaptquicklytonewtechnologiesandtools</li></ul><h3>Nicetohave:</h3><ul><li>Experiencewithcontinuousintegration/continuousdelivery(CI/CD)pipelines</li><li>Knowledgeofperformancetestingandtools(e.g.,JMeter)</li><li>FamiliaritywithcloudplatformssuchasAWS,Azure,orGCP</li><li>ExperienceinAPItestingusingtoolslikePostmanorRestAssured</li></ul>",
        skills_good_to_have: ['Express, Python'],
        title: 'Software Development Engineer 1',
      },
      {
        company: 'Infosys technologies',
        company_id: '70bf3a24-0b6a-4470-8737-55ab068e5097',
        experience: '5 Years',
        salary: '200,000',
        created_by: 'ce7877e2-0964-46c3-adba-36f4db794619',
        date_updated: 1689068805035,
        status: 'ACTIVE',
        date_created: 1689068805035,
        job_id: 'ddbd4fe2-6988-4242-8948-b6e6e456fbd7',
        workType: 'full-time',
        skills_required: ['Selenium', 'Manual Testing'],
        locations: ['Bengaluru', 'Hyderabad', 'Chennai'],
        notice_period: '15 Days or less',
        workMode: 'Hybrid',
        description:
          "<h2>AutomatedTestingFrameworkDevelopment</h2><p>AsanSDET,youwillberesponsiblefordesigning,developing,andmaintaininganautomatedtestingframeworkforoursoftwareproducts.Youwillcollaboratewiththedevelopmentteamtounderstandsoftwarerequirementsanddevelopcomprehensivetestplansandtestcases.Yourprimaryfocuswillbeonbuildingrobustandscalableautomationframeworksusingindustry-standardtoolsandtechnologies.Youwillalsoberesponsibleforidentifyingandreportingdefects,conductingrootcauseanalysis,andensuringhigh-qualitysoftwarereleases.Additionally,youwillcontributetothecontinuousimprovementofourtestingprocessesandpractices.</p><h3>Requirements:</h3><ul><li>Bachelor'sdegreeinComputerScience,Engineering,orrelatedfield</li><li>StrongexperienceindevelopingandmaintainingautomationframeworksusingtoolssuchasSelenium,Appium,orsimilar</li><li>ProficiencyinprogramminglanguagesuchasJava,Python,orJavaScript</li><li>Solidunderstandingofsoftwaretestingprinciples,methodologies,andbestpractices</li><li>Experienceincreatingandexecutingtestplans,testcases,andtestscripts</li><li>Knowledgeofsoftwaredevelopmentlifecycle(SDLC)andAgilemethodologies</li><li>Familiaritywithversioncontrolsystems(e.g.,Git)</li><li>Excellentproblem-solvinganddebuggingskills</li><li>Strongcommunicationandcollaborationskillstoworkeffectivelywithcross-functionalteams</li><li>Abilitytoadaptquicklytonewtechnologiesandtools</li></ul><h3>Nicetohave:</h3><ul><li>Experiencewithcontinuousintegration/continuousdelivery(CI/CD)pipelines</li><li>Knowledgeofperformancetestingandtools(e.g.,JMeter)</li><li>FamiliaritywithcloudplatformssuchasAWS,Azure,orGCP</li><li>ExperienceinAPItestingusingtoolslikePostmanorRestAssured</li></ul>",
        skills_good_to_have: ['Express, Python'],
        title: 'Software Development Engineer Test',
      },
      {
        company: 'Tech mahindra',
        company_id: '70bf3a24-0b6a-4470-8737-55ab068e5097',
        experience: '5 Years',
        salary: '200,000',
        created_by: 'ce7877e2-0964-46c3-adba-36f4db794619',
        date_updated: 1689068805035,
        status: 'ACTIVE',
        date_created: 1689068805035,
        job_id: 'ddbd4fe2-6988-4242-8948-b6e6e456fbd7',
        workType: 'full-time',
        skills_required: ['Selenium', 'Manual Testing'],
        locations: ['Bengaluru', 'Hyderabad', 'Chennai'],
        notice_period: '15 Days or less',
        workMode: 'Hybrid',
        description:
          "<h2>AutomatedTestingFrameworkDevelopment</h2><p>AsanSDET,youwillberesponsiblefordesigning,developing,andmaintaininganautomatedtestingframeworkforoursoftwareproducts.Youwillcollaboratewiththedevelopmentteamtounderstandsoftwarerequirementsanddevelopcomprehensivetestplansandtestcases.Yourprimaryfocuswillbeonbuildingrobustandscalableautomationframeworksusingindustry-standardtoolsandtechnologies.Youwillalsoberesponsibleforidentifyingandreportingdefects,conductingrootcauseanalysis,andensuringhigh-qualitysoftwarereleases.Additionally,youwillcontributetothecontinuousimprovementofourtestingprocessesandpractices.</p><h3>Requirements:</h3><ul><li>Bachelor'sdegreeinComputerScience,Engineering,orrelatedfield</li><li>StrongexperienceindevelopingandmaintainingautomationframeworksusingtoolssuchasSelenium,Appium,orsimilar</li><li>ProficiencyinprogramminglanguagesuchasJava,Python,orJavaScript</li><li>Solidunderstandingofsoftwaretestingprinciples,methodologies,andbestpractices</li><li>Experienceincreatingandexecutingtestplans,testcases,andtestscripts</li><li>Knowledgeofsoftwaredevelopmentlifecycle(SDLC)andAgilemethodologies</li><li>Familiaritywithversioncontrolsystems(e.g.,Git)</li><li>Excellentproblem-solvinganddebuggingskills</li><li>Strongcommunicationandcollaborationskillstoworkeffectivelywithcross-functionalteams</li><li>Abilitytoadaptquicklytonewtechnologiesandtools</li></ul><h3>Nicetohave:</h3><ul><li>Experiencewithcontinuousintegration/continuousdelivery(CI/CD)pipelines</li><li>Knowledgeofperformancetestingandtools(e.g.,JMeter)</li><li>FamiliaritywithcloudplatformssuchasAWS,Azure,orGCP</li><li>ExperienceinAPItestingusingtoolslikePostmanorRestAssured</li></ul>",
        skills_good_to_have: ['Express, Python'],
        title: 'Software Development Engineer Test',
      },
      {
        company: 'IBM technologies',
        company_id: '70bf3a24-0b6a-4470-8737-55ab068e5097',
        experience: '5 Years',
        salary: '200,000',
        created_by: 'ce7877e2-0964-46c3-adba-36f4db794619',
        date_updated: 1689068805035,
        status: 'ACTIVE',
        date_created: 1689068805035,
        job_id: 'ddbd4fe2-6988-4242-8948-b6e6e456fbd7',
        workType: 'full-time',
        skills_required: ['Selenium', 'Manual Testing'],
        locations: ['Bengaluru', 'Hyderabad', 'Chennai'],
        notice_period: '15 Days or less',
        workMode: 'Hybrid',
        description:
          "<h2>AutomatedTestingFrameworkDevelopment</h2><p>AsanSDET,youwillberesponsiblefordesigning,developing,andmaintaininganautomatedtestingframeworkforoursoftwareproducts.Youwillcollaboratewiththedevelopmentteamtounderstandsoftwarerequirementsanddevelopcomprehensivetestplansandtestcases.Yourprimaryfocuswillbeonbuildingrobustandscalableautomationframeworksusingindustry-standardtoolsandtechnologies.Youwillalsoberesponsibleforidentifyingandreportingdefects,conductingrootcauseanalysis,andensuringhigh-qualitysoftwarereleases.Additionally,youwillcontributetothecontinuousimprovementofourtestingprocessesandpractices.</p><h3>Requirements:</h3><ul><li>Bachelor'sdegreeinComputerScience,Engineering,orrelatedfield</li><li>StrongexperienceindevelopingandmaintainingautomationframeworksusingtoolssuchasSelenium,Appium,orsimilar</li><li>ProficiencyinprogramminglanguagesuchasJava,Python,orJavaScript</li><li>Solidunderstandingofsoftwaretestingprinciples,methodologies,andbestpractices</li><li>Experienceincreatingandexecutingtestplans,testcases,andtestscripts</li><li>Knowledgeofsoftwaredevelopmentlifecycle(SDLC)andAgilemethodologies</li><li>Familiaritywithversioncontrolsystems(e.g.,Git)</li><li>Excellentproblem-solvinganddebuggingskills</li><li>Strongcommunicationandcollaborationskillstoworkeffectivelywithcross-functionalteams</li><li>Abilitytoadaptquicklytonewtechnologiesandtools</li></ul><h3>Nicetohave:</h3><ul><li>Experiencewithcontinuousintegration/continuousdelivery(CI/CD)pipelines</li><li>Knowledgeofperformancetestingandtools(e.g.,JMeter)</li><li>FamiliaritywithcloudplatformssuchasAWS,Azure,orGCP</li><li>ExperienceinAPItestingusingtoolslikePostmanorRestAssured</li></ul>",
        skills_good_to_have: ['Express, Python'],
        title: 'Software Development Engineer Test',
      },
      {
        company: 'oracle',
        company_id: '70bf3a24-0b6a-4470-8737-55ab068e5097',
        experience: '5 Years',
        salary: '200,000',
        created_by: 'ce7877e2-0964-46c3-adba-36f4db794619',
        date_updated: 1689068805035,
        status: 'ACTIVE',
        date_created: 1689068805035,
        job_id: 'ddbd4fe2-6988-4242-8948-b6e6e456fbd7',
        workType: 'full-time',
        skills_required: ['Selenium', 'Manual Testing'],
        locations: ['Bengaluru', 'Hyderabad', 'Chennai'],
        notice_period: '15 Days or less',
        workMode: 'Hybrid',
        description:
          "<h2>AutomatedTestingFrameworkDevelopment</h2><p>AsanSDET,youwillberesponsiblefordesigning,developing,andmaintaininganautomatedtestingframeworkforoursoftwareproducts.Youwillcollaboratewiththedevelopmentteamtounderstandsoftwarerequirementsanddevelopcomprehensivetestplansandtestcases.Yourprimaryfocuswillbeonbuildingrobustandscalableautomationframeworksusingindustry-standardtoolsandtechnologies.Youwillalsoberesponsibleforidentifyingandreportingdefects,conductingrootcauseanalysis,andensuringhigh-qualitysoftwarereleases.Additionally,youwillcontributetothecontinuousimprovementofourtestingprocessesandpractices.</p><h3>Requirements:</h3><ul><li>Bachelor'sdegreeinComputerScience,Engineering,orrelatedfield</li><li>StrongexperienceindevelopingandmaintainingautomationframeworksusingtoolssuchasSelenium,Appium,orsimilar</li><li>ProficiencyinprogramminglanguagesuchasJava,Python,orJavaScript</li><li>Solidunderstandingofsoftwaretestingprinciples,methodologies,andbestpractices</li><li>Experienceincreatingandexecutingtestplans,testcases,andtestscripts</li><li>Knowledgeofsoftwaredevelopmentlifecycle(SDLC)andAgilemethodologies</li><li>Familiaritywithversioncontrolsystems(e.g.,Git)</li><li>Excellentproblem-solvinganddebuggingskills</li><li>Strongcommunicationandcollaborationskillstoworkeffectivelywithcross-functionalteams</li><li>Abilitytoadaptquicklytonewtechnologiesandtools</li></ul><h3>Nicetohave:</h3><ul><li>Experiencewithcontinuousintegration/continuousdelivery(CI/CD)pipelines</li><li>Knowledgeofperformancetestingandtools(e.g.,JMeter)</li><li>FamiliaritywithcloudplatformssuchasAWS,Azure,orGCP</li><li>ExperienceinAPItestingusingtoolslikePostmanorRestAssured</li></ul>",
        skills_good_to_have: ['Express, Python'],
        title: 'Software Development Engineer Test',
      },
      {
        company: 'Pine Labs',
        company_id: '70bf3a24-0b6a-4470-8737-55ab068e5097',
        experience: '5 Years',
        salary: '200,000',
        created_by: 'ce7877e2-0964-46c3-adba-36f4db794619',
        date_updated: 1689068805035,
        status: 'ACTIVE',
        date_created: 1689068805035,
        job_id: 'ddbd4fe2-6988-4242-8948-b6e6e456fbd7',
        workType: 'full-time',
        skills_required: ['Selenium', 'Manual Testing'],
        locations: ['Bengaluru', 'Hyderabad', 'Chennai'],
        notice_period: '15 Days or less',
        workMode: 'Hybrid',
        description:
          "<h2>AutomatedTestingFrameworkDevelopment</h2><p>AsanSDET,youwillberesponsiblefordesigning,developing,andmaintaininganautomatedtestingframeworkforoursoftwareproducts.Youwillcollaboratewiththedevelopmentteamtounderstandsoftwarerequirementsanddevelopcomprehensivetestplansandtestcases.Yourprimaryfocuswillbeonbuildingrobustandscalableautomationframeworksusingindustry-standardtoolsandtechnologies.Youwillalsoberesponsibleforidentifyingandreportingdefects,conductingrootcauseanalysis,andensuringhigh-qualitysoftwarereleases.Additionally,youwillcontributetothecontinuousimprovementofourtestingprocessesandpractices.</p><h3>Requirements:</h3><ul><li>Bachelor'sdegreeinComputerScience,Engineering,orrelatedfield</li><li>StrongexperienceindevelopingandmaintainingautomationframeworksusingtoolssuchasSelenium,Appium,orsimilar</li><li>ProficiencyinprogramminglanguagesuchasJava,Python,orJavaScript</li><li>Solidunderstandingofsoftwaretestingprinciples,methodologies,andbestpractices</li><li>Experienceincreatingandexecutingtestplans,testcases,andtestscripts</li><li>Knowledgeofsoftwaredevelopmentlifecycle(SDLC)andAgilemethodologies</li><li>Familiaritywithversioncontrolsystems(e.g.,Git)</li><li>Excellentproblem-solvinganddebuggingskills</li><li>Strongcommunicationandcollaborationskillstoworkeffectivelywithcross-functionalteams</li><li>Abilitytoadaptquicklytonewtechnologiesandtools</li></ul><h3>Nicetohave:</h3><ul><li>Experiencewithcontinuousintegration/continuousdelivery(CI/CD)pipelines</li><li>Knowledgeofperformancetestingandtools(e.g.,JMeter)</li><li>FamiliaritywithcloudplatformssuchasAWS,Azure,orGCP</li><li>ExperienceinAPItestingusingtoolslikePostmanorRestAssured</li></ul>",
        skills_good_to_have: ['Express, Python'],
        title: 'Software Development Engineer Test',
      },
    ];
    setJobsList(jobsData);
    setJobsListFirstLoad(jobsData);
  };
  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startsWith = (array, key) => {
    const matcher = new RegExp(`^${key.toLowerCase()}`, 'g');
    return array.filter(word => word.toLowerCase().match(matcher));
  };

  const handleSearch = event => {
    console.log('here 1----->', jobsListFirstLoad);
    const newJobsObj = jobsListFirstLoad.filter(jb => {
      // console.log('Jb ----->', jb.skillsRequired);
      return (
        jb.title.toLowerCase().includes(event.target.value) ||
        startsWith(jb.skillsRequired, event.target.value).length > 0
      );
    });
    console.log('result', newJobsObj);
    setJobsList(newJobsObj);
  };
  return (
    <>
      <LeftSideNav />
      <ContentDivWrapper>
        <Header />
        <RightContainerDiv>
          <BodyDiv>
            <SearchWrap>
              <h5>Jobs</h5>
              {/* <InputSearch
                id={'search'}
                type={'text'}
                label={'Search '}
                // onChange={handleSearch}
                placeholder="search"
              /> */}
              {/* <Select
                id={'type'}
                // label={'Select Type'}
                // options={typeOptions}
                // onChange={handleChange}
                // value={categorySelected ? categorySelected.id : ''}
              /> */}
              {/* <CatDiv> */}
              {/* <Title1>Assessment</Title1> */}
              <SearchInpDiv>
                <Input
                  id={'search'}
                  type={'text'}
                  className="skills-input"
                  label={'Skills '}
                  onChange={handleSearch}
                  placeholder="Skills or Role"
                />
                <Input
                  id={'location'}
                  type={'text'}
                  className="location-input"
                  label={'Location '}
                  onChange={handleSearch}
                  placeholder="Location"
                />
                <Button type="button" content="" className="search-icon" />
              </SearchInpDiv>
              {/* <Input
                  id={'search'}
                  type={'text'}
                  label={'Skills '}
                  onChange={handleSearch}
                  placeholder="Skills or Role"
                /> */}
              {/* </CatDiv> */}
              {/* <Button
                type="button"
                content="Find job"
                bgColor="#fdb82c"
              // onClick={() => toggle()}
              /> */}
            </SearchWrap>
            <JobListTable headers={headers} rowData={jobsList} />
          </BodyDiv>
        </RightContainerDiv>
      </ContentDivWrapper>
      {/* <ModalContainer>
        <Modal
          isShown={isShown}
          hide={toggle}
          isShowCloseButton={false}
          modalContent={
            <AddQuestionModal
              categoryId={categoryId}
              onAddQuestionSubmit={onAddQuestionSubmit}
            />
          }
        />
      </ModalContainer> */}
    </>
  );
};
