import axios from 'axios';
import { GraphQLClient, gql } from 'graphql-request';
import { v4 as uuidv4 } from 'uuid';

// const baseUrl = process.env.REACT_APP_API_URL;
const appSyncUrl: any = process.env.REACT_APP_APPSYNC_URL;
const appSyncApiKey: any = process.env.REACT_APP_APPSYNC_X_API_KEY;
const chatGptUrl: any = process.env.REACT_APP_CHATGPT_URL;
const tokenPoll = ['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'];
// const tokenPoll = [
//   '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',
//   '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',
//   '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',
//   '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',
//   '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',
//   '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',
// ];

export const callChatGpt = async (
  data,
  iteration = 0,
  tokenNumber = 0,
  model = 'text-davinci-003', //change it
) => {
  try {
    const res = await axios({
      url: chatGptUrl,
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${tokenPoll[tokenNumber]}`,
      },
      data: {
        model,
        // prompt: `I am ${data.currentPosition}, steps to become ${data.goal}`,
        prompt: `I am a ${data.currentPosition} and want to become ${data.goal} create career path and suggest useful website links`,
        temperature: 0,
        max_tokens: 1500,
        n: 1,
        best_of: 2,
      },
    });
    return res.data;
  } catch (err: any) {
    if (iteration < 3) {
      let newModel = model;
      if (err.response.status === 429) {
        newModel = 'text-davinci-001';
      }
      return callChatGpt(data, iteration + 1, tokenNumber + 1, newModel);
    }
    console.log('chatgpt post error: ', err);
    return err;
  }
};

export const createLeads = async (data: any) => {
  const endpoint: any = appSyncUrl;

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      'x-api-key': appSyncApiKey,
    },
  });

  const mutation = gql`
    mutation saveLeads(
      $emailId: String!
      $id: String
      $source: String
      $name: String
    ) {
      saveLeads(id: $id, source: $source, emailId: $emailId, name: $name) {
        id
        name
        source
        emailId
      }
    }
  `;
  const variables = {
    emailId: data.email,
    source: 'chatgpt',
    id: uuidv4(),
    name: data.name,
  };
  await graphQLClient.request(mutation, variables);
};
