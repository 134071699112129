import React from 'react';
import Switch from 'react-switch';

export const SwitchComponent = props => {
  return (
    <div>
      <Switch
        onChange={(checked, event) => {
          props.handleSwitch(checked, event, 'id');
        }}
        checked={props.checked}
        onColor="#9FE6A0"
        offColor="#FF0000"
        offHandleColor="#ffffff"
        onHandleColor="#519872"
        handleDiameter={21}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={21}
        width={45}
        className="react-switch"
        id="material-switch"
        disabled={props.disabled}
      />
    </div>
  );
};
