/* eslint-disable prettier/prettier */
import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { SwitchComponent } from 'app/components/UI/atoms/Switch';

import Button from 'app/components/UI/atoms/Button';
import LinkIcon from 'app/assets/images/link.svg';
import {
  ListWrapper,
  ListTable,
  ListHead,
  ListBody,
  ListRow,
  ListTabDiv,
  ListTh,
  AssessmentDetails,
  // AddFuncBtn,
  ToggleView,
  ViewAttendees,
  Share,
} from 'app/components/pages/GlobalStyles/GlobalStyles';
import {
  MailIcon,
  Message,
  ModalContainer,
  ModelContent,
  // MailIcon,
  // Message,
} from 'app/components/UI/globalStyles/modalStyles';
// import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import { Title4 } from 'app/components/UI/atoms/Titles';
import { Modal } from 'app/components/UI/Modal/SimpleModal';
import { useModal } from 'app/components/UI/Modal/SimpleModal/userModal';
import { useNavigate } from 'react-router-dom';
import { CopyLink } from 'app/components/UI/organisms/CopyLink';
import {
  Table,
  TableBody,
  TableRow,
  TableTd,
  TableWrapper,
} from './ListStyles';
import {
  updateAssessmentStatus,
  sendAssessmentInviteLink,
  updateAntiCheatStatus,
} from 'app/services/assessments';
import { PrimaryParagraph } from 'app/components/UI/atoms/Paragraph';
import SuccessIcon from 'app/assets/images/success-icon.svg';
import AssessmentViewIcon from 'app/assets/images/assessment-view-icon.png';
import {
  SearchIcon,
  Wrapper,
} from 'app/components/UI/atoms/SendLink/InputSearchStyles';
import buttonLoader from 'app/assets/images/loader-black.svg';
import { ConfirmationModal } from 'app/components/UI/Modal/confirmationModal/confirmationModal';

export const AssessmentTable = ({ headers, rowData }) => {
  const { isShown, toggle } = useModal();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<Array<string>>([]);
  const [isCheatDetectionEnabled, setCheatDetectionEnabled] = useState<Array<string>>([]);
  const emailRef = useRef<any | null>();
  const [assessmentId, setAssessmentId] = useState<string | null>(null);
  const [assessmentLink, setAssessmentLink] = useState<string | null>();
  let navigate = useNavigate();
  const handleShowQues = (quesId: string) => {
    // setRedirect(quesId);
  };

  useEffect(() => {
    if (rowData && rowData.length > 0) {
      const activeAssessmentIds = rowData
        .filter(ques => ques.status === 'active')
        .map(ques => ques.assessmentId);

      setStatus(activeAssessmentIds);

      const antiCheatEnabledAssessmentIds = rowData
        .filter(rw => rw.cheatDetectionEnabled === true)
        .map(ques => ques.assessmentId);

      setCheatDetectionEnabled(antiCheatEnabledAssessmentIds);
    }
  }, [rowData]);

  const sendLinkHandler = async () => {
    setLoading(true);
    const emailList = emailRef.current.value.split(',');

    // Regular expression to validate an email address
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zAZ0-9-]+)*$/;

    let allEmailsValid = true;

    emailList.forEach(email => {
      if (typeof email.trim() !== 'string' || !email.trim().match(validRegex)) {
        allEmailsValid = false;
        return;
      }
    });

    if (!allEmailsValid) {
      emailRef.current.value = 'Invalid Email(s)';
      setLoading(false);
      return false;
    }

    // If all emails are valid, send the invite links to the backend
    const emailsToSend = emailList.map(email => email.trim());
    // Send the emails to the backend
    await sendAssessmentInviteLink(assessmentId, emailsToSend);
    changeModalVisibility();
    toggle();
    setLoading(false);
    return true;
  };

  const onChangeAntiCheatHandle = async (test, checked) => {
    if (isCheatDetectionEnabled.includes(test.assessmentId) || checked === false) {
      const updateResp = await updateAntiCheatStatus(
        false,
        test.assessmentId,
      );
      if (updateResp.statusCode === 0) {
        setCheatDetectionEnabled(isCheatDetectionEnabled.filter(id => id !== test.assessmentId));
      }
    } else {
      const updateResp = await updateAntiCheatStatus(
        true,
        test.assessmentId,
      );
      if (updateResp.statusCode === 0) {
        setCheatDetectionEnabled([...isCheatDetectionEnabled, test.assessmentId]);
      }
    }
  };
  const statusChangeHandle = async (test, checked) => {
    if (checked === false) {
      changeStatusModalVisibility(test);
    } else {
      // console.log('here 2--- true ');
    }

  }

  const onHandleChange = async (test, checked) => {
    if (status.includes(test.assessmentId) || checked === false) {
      const updateResp = await updateAssessmentStatus(
        'deactivate',
        test.assessmentId,
      );
      if (updateResp.statusCode === 0) {
        setStatus(status.filter(id => id !== test.assessmentId));
      }
    } else {
      const updateResp = await updateAssessmentStatus(
        'activate',
        test.assessmentId,
      );
      if (updateResp.statusCode === 0) {
        setStatus([...status, test.assessmentId]);
      }
    }
  };

  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const changeModalVisibility = () => {
    setIsShowModal(!isShowModal);
  };

  const successContent = (
    // eslint-disable-next-line react-hooks/exhaustive-deps
    <ModelContent>
      <MailIcon>
        <img src={SuccessIcon} alt="mail-icon" width="80px" height="50px" />
      </MailIcon>
      <Message>
        <PrimaryParagraph>
          Invitation mail has been sent with invitation link
        </PrimaryParagraph>
      </Message>
    </ModelContent>
  );

  const content = (
    <ModelContent>
      <Title4>
        <center>Invite Candidates Using this link</center>
      </Title4>
      <TableWrapper>
        <Table>
          <TableBody>
            <TableRow>
              <TableTd>
                <CopyLink inviteLink={assessmentLink}></CopyLink>
              </TableTd>
            </TableRow>
            <TableRow>
              <TableTd style={{ backgroundColor: 'white' }}>
                <center>
                  <strong>Or </strong>
                </center>
              </TableTd>
            </TableRow>
            <TableRow>
              <TableTd>
                <Wrapper>
                  <input
                    type={'text'}
                    id={'invitation'}
                    placeholder="Email Address"
                    required
                    style={{ width: '100%' }}
                    ref={emailRef}
                    // required
                    size={50}
                  />
                  <SearchIcon>
                    <div
                      style={{
                        padding: '10px',
                        width: 'auto',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Button
                        bgColor="#fdb82c"
                        content="Send Link"
                        leftIcon={isLoading === true ? buttonLoader : LinkIcon}
                        className="send-link"
                        type={'button'}
                        onClick={sendLinkHandler}
                        bgType={'secondary'}
                      />
                    </div>
                  </SearchIcon>
                </Wrapper>
              </TableTd>
            </TableRow>
            <TableRow>
              <TableTd
                style={{
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}
              >
                <Button
                  bgColor="#fad44b0"
                  className="okbutton"
                  content="OK"
                  type={'button'}
                  // bgType={'secondary'}
                  onClick={() => changeModalVisibility()}
                />
              </TableTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>
    </ModelContent>
  );

  const [isShowStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [statusChangeTest, setStatusChangeTest] = useState<any>();
  const changeStatusModalVisibility = (test = undefined) => {
    window.scrollTo(0, 0);
    if (typeof test !== 'undefined' && test !== undefined) {
      setStatusChangeTest(test);
    } else {
      setStatusChangeTest({});
    }
    setShowStatusModal(!isShowStatusModal);
  };
  const onConfirm = async () => {
    await onHandleChange(statusChangeTest, false);
    setShowStatusModal(false);
  };

  const onCancel = () => {
    changeStatusModalVisibility();
  };
  const onSkipcontent = (
    <ConfirmationModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      message="Once you deactivate the assessment you can not activate back"
      title="Are you sure you want to deactivate assessment ?"
      confirmButtonContent="Deactivate"
      cancelButtonContent="cancel"
    />
  );

  return (
    <AssessmentDetails>
      <ListWrapper>
        <ListTable>
          <ListHead className="ListHead">
            <ListRow>
              {headers.map(header => (
                <ListTh key={header.id}>{header.name}</ListTh>
              ))}
              {/* <TableTh>Action</TableTh>
            <TableTh>Active</TableTh> */}
            </ListRow>
          </ListHead>
          <ListBody>
            {rowData &&
              rowData.length > 0 &&
              rowData.map((row, index) => {
                // const days = Math.floor(
                //   (new Date().getTime() - ques.dateUpdated) / 1000 / 60 / 60 / 24,
                // );
                const d = new Date(row.dateCreated);
                // const validFrom = new Date(row.validFrom);
                const validUntil = new Date(row.validUntil);
                const formatDate = (date) => {
                  const formattedDate = `${("0" + date.getDate()).slice(-2)}-${new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date)}-${date.getFullYear()}`;
                  // const formattedTime = `${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
                  return `${formattedDate}`;
                  // return `${formattedDate} ${formattedTime}`;
                };
                return (
                  <ListRow key={row.assessmentId}>
                    <ListTabDiv
                      onClick={() => {
                        handleShowQues(row.id);
                      }}
                    >
                      <div style={{ display: 'block', float: 'left', width: '100%' }}>
                        <a
                          title="View attendees list"
                          href={`/respondents/${row.assessmentId}`}
                          style={{
                            textDecoration: 'none',
                            color: '#222',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <span style={{ marginRight: 'auto' }}>{row.name}</span>
                          <img src={AssessmentViewIcon} alt="mail-icon" width="20px" height="20px" style={{ marginRight: '0', marginLeft: '10px' }} />
                        </a>
                      </div>
                    </ListTabDiv>
                    <ListTabDiv style={{ textAlign: 'center' }}>
                      {row.createdBy}
                    </ListTabDiv>
                    <ListTabDiv className="durationcell">
                      {("0" + d.getDate()).slice(-2) + '-' +
                        new Intl.DateTimeFormat('en-US', { month: 'short' }).format(d) + '-' +
                        d.getFullYear()}
                      {/* {("0" + d.getDate()).slice(-2) + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()} */}
                    </ListTabDiv>
                    <ListTabDiv className="durationcell">
                      <span>{formatDate(validUntil)}</span>
                    </ListTabDiv>
                    <ListTabDiv
                      className="actionItems"
                      style={{ paddingTop: '1.5%' }}
                    >
                      <div className="views-wrapper">
                        {/* {row.status === 'active' ? 'Active' : 'Inactive'} */}
                        <SwitchComponent
                          className="btntoggle"
                          id={`cheatDetectionEnabled${row.assessmentId}`}
                          checked={
                            isCheatDetectionEnabled.length > 0
                              ? isCheatDetectionEnabled.includes(row.assessmentId)
                              : row.isCheatDetectionEnabled === true
                          }
                          handleSwitch={checked => onChangeAntiCheatHandle(row, checked)}
                        // disabled={typeof row.isCheatDetectionEnabled === 'undefined' ? true : false}
                        />
                      </div>
                    </ListTabDiv>
                    <ListTabDiv
                      className="actionItems"
                      style={{ paddingTop: '1.5%' }}
                    >
                      <div className="views-wrapper">
                        {status.includes(row.assessmentId) ? 'Active' : 'Inactive'}
                        <SwitchComponent
                          className="btntoggle"
                          id={`status${row.assessmentId}`}
                          checked={
                            status.length > 0
                              ? status.includes(row.assessmentId)
                              : row.status === 'active'
                          }
                          handleSwitch={checked => statusChangeHandle(row, checked)}
                          disabled={row.status === 'deleted' ? true : false}
                        />
                      </div>
                    </ListTabDiv>
                    <ListTabDiv className="actionItems">
                      <div className="views-wrapper">
                        <ToggleView
                          title='View assessment details'
                          onClick={() =>
                            navigate(`/assessment/${row.assessmentId}`)
                          }
                          tabIndex={0}
                        />
                        <ViewAttendees
                          title='View attendees list'
                          onClick={() =>
                            navigate(`/respondents/${row.assessmentId}`)
                          }
                          tabIndex={-1}
                        />
                        <Share
                          onClick={() => {
                            setAssessmentId(row.assessmentId);
                            setAssessmentLink(
                              `${process.env.REACT_APP_ASSESSMENT_BASE_URL}/start-screening/${row.assessmentId}`,
                            );
                            changeModalVisibility();
                            window.scrollTo(0, 0);
                          }}
                          tabIndex={0}
                          className={
                            status.includes(row.assessmentId)
                              ? ''
                              : 'disabledShare'
                          }
                        />
                      </div>
                      {/* <div className="views-wrapper">
                        <a content="View Respondents" style={{ color: "blue", cursor: "pointer", textDecoration: "none" }} href={`/respondents/${row.assessmentId}`}>View Attendees List</a>

                      </div> */}
                    </ListTabDiv>
                  </ListRow>
                );
              })}
          </ListBody>
        </ListTable>
        <ModalContainer>
          <Modal
            isShown={isShowModal}
            isShowCloseButton={true}
            hide={changeModalVisibility}
            modalContent={content}
            width="1090px"
          />
        </ModalContainer>
        <ModalContainer>
          <Modal
            isShown={isShowStatusModal}
            isShowCloseButton={true}
            hide={changeStatusModalVisibility}
            modalContent={onSkipcontent}
            width="1090px"
          />
        </ModalContainer>
        <ModalContainer>
          <Modal
            isShown={isShown}
            isShowCloseButton={true}
            hide={toggle}
            modalContent={successContent}
            width="1090px"
          />
        </ModalContainer>
      </ListWrapper>
    </AssessmentDetails >
  );
};
